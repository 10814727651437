<template>
    <div class="order-container">
        <span class="d-inline-block bg-success text-white text-xs p-1" v-html="orderProductionLabel"
              :class="orderProductionLabelClass">
        </span>
        <span class="carrier-name" v-if="carrierName"><i class="fa fa-truck"/> {{carrierName}}</span>

        <ul class="tracking-links" v-if="carrierName && trackingLinks && trackingLinks.length > 0">
            <li v-for="(link, index) in trackingLinks" :key="index">
                <a :href="link.url" target="_blank">{{ link.label }}</a>
            </li>
        </ul>
    </div>
</template>

<script>

export default {
    name: "OrderProductionStatusLabel",
    props: {
        order: {
            type: Object,
            required: true,
        },
    },
    data() {
        const CARRIER_ECOPLI = 2;
        const CARRIER_CHRONOPOST = 6;
        const CARRIER_LETTRE_INTERNATIONALE = 13;
        const CARRIER_LETTRE_SUIVIE = 14;
        const CARRIER_PRISME = 21;
        const CARRIER_DIFFUSION = 23;
        const CARRIER_DPD = 31;
        const CARRIER_DEPOT_BNF = 33;
        const CARRIER_COLISSIMO = 37;

        return {
            CARRIER_ECOPLI,
            CARRIER_CHRONOPOST,
            CARRIER_LETTRE_INTERNATIONALE,
            CARRIER_LETTRE_SUIVIE,
            CARRIER_PRISME,
            CARRIER_DIFFUSION,
            CARRIER_DPD,
            CARRIER_DEPOT_BNF,
            CARRIER_COLISSIMO,
            productionStatuses: {
                pending: {
                    class: "bg-warning",
                    label: this.$t(`OrderProductionStatusLabel.pending`),
                },
                preparing: {
                    class: "bg-info",
                    label: this.$t(`OrderProductionStatusLabel.preparing`),
                },
                shipping: {
                    class: "bg-info",
                    label: this.$t(`OrderProductionStatusLabel.shipping`),
                },
                shipped: {
                    class: "bg-info",
                    label: this.$t(`OrderProductionStatusLabel.shipped`),
                },
                delivered: {
                    class: "bg-success",
                    label: this.$t(`OrderProductionStatusLabel.delivered`),
                },
                canceled: {
                    class: "bg-danger",
                    label: this.$t(`OrderProductionStatusLabel.canceled`),
                },
            },
            carrierMap: {
                [CARRIER_ECOPLI]: { nameKey: "OrderProductionStatusLabel.carrierMap.ecopli" },
                [CARRIER_CHRONOPOST]: { nameKey: "OrderProductionStatusLabel.carrierMap.chronopost", url: "https://www.chronopost.fr/tracking-no-cms/suivi-page?listeNumerosLT=" },
                [CARRIER_LETTRE_INTERNATIONALE]: { nameKey: "OrderProductionStatusLabel.carrierMap.lettreInternationale" },
                [CARRIER_LETTRE_SUIVIE]: { nameKey: "OrderProductionStatusLabel.carrierMap.lettreSuivie", url: "https://www.laposte.fr/outils/suivre-vos-envois?code=" },
                [CARRIER_PRISME]: { nameKey: "OrderProductionStatusLabel.carrierMap.prisme" },
                [CARRIER_DIFFUSION]: { nameKey: "OrderProductionStatusLabel.carrierMap.diffusion" },
                [CARRIER_DPD]: { nameKey: "OrderProductionStatusLabel.carrierMap.dpd", url: "https://trace.dpd.fr/fr/trace/" },
                [CARRIER_DEPOT_BNF]: { nameKey: "OrderProductionStatusLabel.carrierMap.depotBNF" },
                [CARRIER_COLISSIMO]: { nameKey: "OrderProductionStatusLabel.carrierMap.colissimo", url: "https://www.laposte.fr/outils/suivre-vos-envois?code=" },
            },
        };
    },
    computed: {
        orderProductionLabelClass() {
            return this.productionStatuses[this.order.status.production] ? this.productionStatuses[this.order.status.production].class : "";
        },
        orderProductionLabel() {
            return this.productionStatuses[this.order.status.production] ? this.productionStatuses[this.order.status.production].label : "";
        },
        carrierName() {
            const carrier = this.carrierMap[this.order.delivery.carrierSobookId];
            return carrier ? this.$t(carrier.nameKey) : null;
        },
        trackingLinks() {
            const trackingNumbers = this.order.delivery.trackingNumber;
            const carrier = this.carrierMap[this.order.delivery.carrierSobookId];

            if (!trackingNumbers || !carrier || !carrier.url) {
                return [];
            }

            let trackingList = trackingNumbers.includes(",") ? trackingNumbers.split(",") : [trackingNumbers];

            if (this.order.delivery.carrierSobookId === this.CARRIER_ECOPLI) {
                trackingList = trackingList.map(tracking => tracking.replace(/[[\]]/g, "").trim());
            } else {
                trackingList = trackingList.map(tracking => tracking.trim());
            }

            return trackingList.map(tracking => ({
                url: `${carrier.url}${tracking}`,
                label: tracking,
            }));
        },
    },
};
</script>

<style lang="scss" scoped>
.order-container {
  display: flex;
  flex-direction: column;
}

.carrier-name {
  margin-top: 5px;
}

.tracking-links {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  padding-left: 0;
}

.tracking-links li {
  margin-bottom: 5px;
  margin-right: 10px;
  width: 45%;
}
</style>