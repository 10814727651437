import { render, staticRenderFns } from "./CheckoutPaymentAgreement.vue?vue&type=template&id=96d2e0de&scoped=true&"
import script from "./CheckoutPaymentAgreement.vue?vue&type=script&lang=js&"
export * from "./CheckoutPaymentAgreement.vue?vue&type=script&lang=js&"
import style0 from "./CheckoutPaymentAgreement.vue?vue&type=style&index=0&id=96d2e0de&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96d2e0de",
  null
  
)

export default component.exports