<template>
    <div id="hermes-content">
        <vue-headful :title="shop.displayedName"/>
        <transition mode="out-in" name="fade">
            <APILoading id="loading" v-if="loading" :text="$t('Hermes.loadingShopName', { shopName: shop.displayedName })"
                        :font-size="32" key="loading"/>
            <div v-else id="content" key="hermes">
                <Header @show-forgot-password-modal="$refs.forgotPasswordModal.show()" ref="headerView"/>
                <router-view class="fadeIn"/>
                <Footer/>
                <ForgotPasswordModal ref="forgotPasswordModal"/>
                <MainTour/>
            </div>
        </transition>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapGetters, mapActions } from "vuex";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import ForgotPasswordModal from "./Header/Account/LoginPanel/ForgotPasswordModal";
import MainTour from "./MainTour/MainTour";
import APILoading from "../shared/Misc/APILoading";

export default {
    name: "Hermes",
    components: {APILoading, MainTour, ForgotPasswordModal, Footer, Header},
    data() {
        return {
            loading: true,
        };
    },
    computed: {
        ...mapGetters("config", {
            hermesAPIConfig: "hermesAPI",
            shopPrimaryColor: "shopPrimaryColor",
            shop: "shop",
            isCreditCardPaymentEnabled: "isCreditCardPaymentEnabled",
            isPayPalPaymentEnabled: "isPayPalPaymentEnabled",
        }),
        ...mapGetters("preferences", {
            chosenCurrency: "currency",
        }),
    },
    async created() {
        await this.loadStyle();
        const currency = localStorage.getItem("currency") ? localStorage.getItem("currency") : this.hermesAPIConfig.shop.currency;
        await Promise.all([
            this.setCurrency(currency),
            this.getCurrencies(),
            this.getCountries(),
            this.getPublishers(),
            this.checkTokenAndLogin(),
        ]);
        this.loading = false;
        // this.displayMainTourForNewUsers();
    },
    async mounted() {
        if (this.$route.query && this.$route.query["activation-token"]) {
            await this.activateAccount(this.$route.query["activation-token"]);
        }
        if (this.isPayPalPaymentEnabled) {
            this.appendPayPalScript();
        }
        if (this.isCreditCardPaymentEnabled) {
            this.appendStripeScript();
        }
    },
    methods: {
        ...mapActions("tours", {
            startMainTour: "startMainTour",
        }),
        ...mapActions("preferences", {
            setCurrency: "setCurrency",
        }),
        ...mapActions("publishers", {
            getPublishersFromAPI: "getPublishers",
        }),
        ...mapActions("currencies", {
            getCurrenciesFromAPI: "getCurrencies",
            setCurrencies: "setCurrencies",
        }),
        ...mapActions("countries", {
            getCountriesFromAPI: "getCountries",
            setCountries: "setCountries",
        }),
        ...mapActions("user", {
            checkUserTokenAndLogin: "checkTokenAndLogin",
            getUserCart: "getCart",
        }),
        async loadStyle() {
            try {
                const { client } = this.hermesAPIConfig;
                document.querySelector("link[rel*='icon']").href = `/favicon/${client}.png`;
                await import(`../../assets/scss/${client}/styles.scss`);
            } catch {
                document.querySelector("link[rel*='icon']").href = `favicon/default.ico`;
                await import(`../../assets/scss/default/styles.scss`);
            }
        },
        async getPublishers() {
            try {
                await this.getPublishersFromAPI();
            } catch (err) {
                this.$error.displayError(err);
            }
        },
        async getCurrencies() {
            try {
                await this.getCurrenciesFromAPI();
            } catch (err) {
                await this.setCurrencies(null);
                this.$error.displayError(err);
            }
        },
        async getCountries() {
            try {
                await this.getCountriesFromAPI();
            } catch (err) {
                await this.setCountries(null);
                this.$error.displayError(err);
            }
        },
        async checkTokenAndLogin() {
            try {
                await this.checkUserTokenAndLogin();
            } catch (err) {
                localStorage.removeItem("token");
                this.$error.displayError(err);
            }
        },
        async getCart() {
            try {
                await this.getUserCart();
            } catch (err) {
                this.$error.displayError(err);
            }
        },
        appendPayPalScript() {
            const PayPal = this.hermesAPIConfig.payments.PayPal;
            const currency = this.shop.currency;
            const url = `https://www.paypal.com/sdk/js?client-id=${PayPal.client}&currency=${currency}&disable-funding=credit,card`;
            const paypalScript = document.createElement("script");
            paypalScript.setAttribute("src", url);
            document.head.appendChild(paypalScript);
        },
        appendStripeScript() {
            const stripeScript = document.createElement("script");
            stripeScript.setAttribute("src", "https://js.stripe.com/v3/");
            document.head.appendChild(stripeScript);
        },
        async activateAccount(token) {
            try {
                await this.$hermesAPI.activateAccount(token);
                await Swal.fire({
                    icon: "success",
                    title: this.$t("App.accountActivated"),
                    text: this.$t("App.youCanNowLogIn"),
                    confirmButtonColor: this.shopPrimaryColor,
                    confirmButtonText: this.$t("App.logIn"),
                });
                await this.$store.dispatch("sideBarMenu/toggleSection", "account");
            } catch (err) {
                this.$error.displayError(err);
            } finally {
                await this.$router.replace({ query: {} });
            }
        },
        displayMainTourForNewUsers() {
            if (localStorage.getItem("mainTourDone") === "true") {
                return;
            }
            this.$toasted.info(this.$t("App.isItYourFirstTime"), {
                duration: 10000,
                icon: "book-open",
                action: [
                    {
                        text: this.$t("App.haveATour"),
                        icon: "star",
                        onClick: (e, toastObject) => {
                            toastObject.goAway(0);
                            this.startMainTour();
                        },
                    },
                    {
                        text: this.$t("App.dontAskMeAgain"),
                        onClick(e, toastObject) {
                            toastObject.goAway(0);
                            localStorage.setItem("mainTourDone", true);
                        },
                    },
                ],
            });
        },
    },
    watch: {
        chosenCurrency() {
            if (this.chosenCurrency) {
                this.getCart();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    #hermes-content {
        height: 100%;
        #loading {
            height: 100%;
        };
        #content {
            height: 100%;
        }
    }
</style>