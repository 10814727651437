<template>
    <div class="row">
        <div class="col-12 d-flex align-items-center justify-content-center text-info text-less-bold"
            v-tooltip="$t('RequiredForOneClickOrder.whatsOneClick')">
            <i class="fa fa-question-circle text-info mr-2"></i>
            {{ $t("RequiredForOneClickOrder.requiredForOneClick") }}
            <span class="fa-stack ml-1">
                  <i class="fas fa-circle fa-stack-2x"></i>
                  <i class="fas fa-cart-arrow-down fa-stack-1x fa-inverse"></i>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "RequiredForOneClickOrder",
};
</script>

<style lang="scss" scoped>
</style>