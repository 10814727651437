<template>
    <div id="administration-order-modal" data-backdrop="static" data-keyboard="false" class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <form @submit.prevent="updateOrder">
                    <div class="modal-header">
                        <h5 class="modal-title" v-html="$t('AdministrationOrderModal.updateOrder')"/>
                    </div>
                    <div class="modal-body">
                        <transition mode="out-in" name="fade">
                            <APILoading v-if="isLoading.get" :text="$t('AdministrationOrderModal.loading')"/>
                            <div v-else>
                                <div class="form-group row">
                                    <div class="col-6">
                                        <label class="text-muted" for="production-status" v-html="$t('AdministrationOrderModal.productionStatus')"/>
                                        <RedAsterisk class="ml-2"/>
                                        <select id="production-status" v-model="order.status.production" class="form-control">
                                            <option v-for="status of productionStatuses" :key="status" :value="status"
                                                    v-html="$t(`Class.Order.status.production.${status}`)"/>
                                        </select>
                                    </div>
                                    <div class="col-6">
                                        <label class="text-muted" for="payment-status" v-html="$t('AdministrationOrderModal.paymentStatus')"/>
                                        <RedAsterisk class="ml-2"/>
                                        <select id="payment-status" v-model="order.status.payment" class="form-control">
                                            <option v-for="status of paymentStatuses" :key="status" :value="status"
                                                    v-html="$t(`Class.Order.status.payment.${status}`)"/>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </transition>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal" :disabled="isLoading.update"
                                v-html="$t('AdministrationOrderModal.close')"/>
                        <SubmitBtn classes="btn btn-primary" :text="$t('AdministrationOrderModal.update')" :loading="isLoading.update"/>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import Order from "@/classes/Order";
import APILoading from "@/components/shared/Misc/APILoading";
import SubmitBtn from "@/components/shared/Misc/SubmitBtn";
import RedAsterisk from "@/components/shared/Forms/RedAsterisk";

export default {
    name: "AdministrationOrderModal",
    components: {RedAsterisk, SubmitBtn, APILoading },
    data() {
        return {
            isLoading: {
                get: false,
                update: false,
            },
            order: new Order(),
            paymentStatuses: Order.paymentStatuses,
            productionStatuses: Order.productionStatuses,
        };
    },
    methods: {
        async show(orderId) {
            await this.getOrder(orderId);
            // eslint-disable-next-line no-undef
            $("#administration-order-modal").modal("show");
        },
        hide() {
            // eslint-disable-next-line no-undef
            $("#administration-order-modal").modal("hide");
        },
        async getOrder(orderId) {
            try {
                this.isLoading.get = true;
                const { data } = await this.$hermesAPI.getOrder(orderId);
                this.order = new Order(data);
            } catch (err) {
                this.$error.displayError(err);
            } finally {
                this.isLoading.get = false;
            }
        },
        async updateOrder() {
            try {
                this.isLoading.update = true;
                const { data } = await this.$hermesAPI.updateOrder({ _id: this.order._id, status: this.order.status });
                this.$emit("order-updated", new Order(data));
                this.$toasted.success(this.$t("AdministrationOrderModal.orderUpdated"), { icon: "check" });
                this.hide();
            } catch (err) {
                this.$error.displayError(err);
            } finally {
                this.isLoading.update = false;
            }
        },
    },
};
</script>

<style scoped>

</style>