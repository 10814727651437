<template>
    <form id="approve-printing-btn" @submit.prevent="approvePrinting">
        <SubmitBtn :loading="loading" classes="btn btn-success btn-block"
                   :text="`<i class='fa fa-check-circle mr-2'></i>${$t('ApprovePrintingBtn.approvePrinting')}`"/>
    </form>
</template>

<script>
import Swal from "sweetalert2";
import Product from "@/classes/Product";
import SubmitBtn from "@/components/shared/Misc/SubmitBtn";

export default {
    name: "ApprovePrintingBtn",
    components: { SubmitBtn },
    props: {
        product: {
            type: Product,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    methods: {
        confirmReceivedPrintingApproval() {
            return Swal.fire({
                title: this.$t("ApprovePrintingBtn.didYouReceivePrintingApproval"),
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: this.shopPrimaryColor,
                cancelButtonColor: "#D33",
                confirmButtonText: this.$t("ApprovePrintingBtn.yes"),
                cancelButtonText: this.$t("ApprovePrintingBtn.no"),
            });
        },
        confirmApprovePrinting() {
            return Swal.fire({
                title: this.$t("ApprovePrintingBtn.youSure"),
                html: this.$t("ApprovePrintingBtn.youWillApproveThisProduct"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: this.shopPrimaryColor,
                cancelButtonColor: "#D33",
                confirmButtonText: this.$t("ApprovePrintingBtn.yesApprove"),
                cancelButtonText: this.$t("ApprovePrintingBtn.cancel"),
                footer: `<a target="_blank" href="/contact?mail-type=products-creation">${this.$t("ApprovePrintingBtn.ifYouDisapprove")}</a>`,
            });
        },
        async approvePrinting() {
            try {
                const { value: isPrintingApprovalReceived } = await this.confirmReceivedPrintingApproval();
                if (isPrintingApprovalReceived) {
                    const { value: isApprovalConfirmed } = await this.confirmApprovePrinting();
                    if (isApprovalConfirmed) {
                        const updatedProduct = { ...this.product, pod: true };
                        updatedProduct.files.printingApprovedByClient = true;
                        await this.$monSoBookAPI.updateProduct(this.product._id, { printingApprovedByClient: 1 });
                        this.$emit("update-product", updatedProduct);
                    }
                }
            } catch (e) {
                this.$error.displayError(e);
            }
        },
    },
};
</script>

<style scoped>

</style>