<template>
    <button v-if="isOrderProductCustomizationEnabled" :class="classes"
            @click="$emit('show-customize-product-modal', product)">
        <i class="fa fa-edit mr-2"/>
        <span class="d-inline" v-html="$t('CustomizeProductBtn.customize')"/>
    </button>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "CustomizeProductBtn",
    components: {},
    props: {
        product: {
            type: Object,
            required: true,
        },
        classes: {
            type: String,
        },
    },
    computed: {
        ...mapGetters("config", {
            isOrderProductCustomizationEnabled: "isOrderProductCustomizationEnabled",
        }),
    },
};
</script>

<style scoped>

</style>