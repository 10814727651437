<template>
    <div data-backdrop="static" data-keyboard="false" class="modal fade" id="product-review-modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <form @submit.prevent="submit">
                    <div class="modal-header">
                        <h5 class="modal-title" v-html="modalTitle"></h5>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-2">
                            <div class="col-12 text-center">
                                <h5 v-html="product.title"></h5>
                                <img class="shadow-lg" id="product-review-thumbnail" v-lazy="product.firstCoverPhotoUrl" alt="Product"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-center">
                                <star-rating v-model="review.rate" :rating="0" :inline="true" :show-rating="false" :star-size="40"/>
                                <transition name="fade" mode="out-in">
                                    <p v-if="!review.rate" class="text-muted font-italic" v-html="$t('ProductReviewModal.pleaseFillRate')"></p>
                                </transition>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-12">
                                <label for="summary">
                                    {{$t('ProductReviewModal.summary')}}
                                    <span class="red-text">*</span>
                                </label>
                                <input class="form-control" type="text" :placeholder="$t('ProductReviewModal.required')"
                                       required v-model='review.summary' id="summary" maxlength="100">
                                <span class="text-muted text-sm">
                                    {{ $t('ProductReviewModal.maximumLength', { length: 100 }) }}
                                    ({{$tc('ProductReviewModal.lengthLeft', reviewSummaryLengthLeft, { length: reviewSummaryLengthLeft })}})
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-12">
                                <label for="description">
                                    {{$t('ProductReviewModal.description')}}
                                </label>
                                <textarea class="form-control" type="text" :placeholder="$t('ProductReviewModal.optional')"
                                          v-model='review.description' id="description" maxlength="1000" rows="4">
                                </textarea>
                                <span class="text-muted text-sm">
                                    {{ $t('ProductReviewModal.maximumLength', { length: 1000 }) }}
                                    ({{$tc('ProductReviewModal.lengthLeft', reviewDescriptionLengthLeft, { length: reviewDescriptionLengthLeft })}})
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal" :disabled="loading">
                            {{ $t("ProductReviewModal.close") }}
                        </button>
                        <SubmitBtn :disabled="!formValid" classes="btn btn-primary" :loading="loading" :text="submitBtnText"/>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import ProductReview from "../../../../classes/ProductReview";
import { areInputsNotEmpty } from "../../../../helpers/form";
import { decodeJWTToken } from "../../../../helpers/API";
import SubmitBtn from "../../../shared/Misc/SubmitBtn";

export default {
    name: "ProductReviewModal",
    components: {SubmitBtn},
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            review: new ProductReview(),
            loading: false,
        };
    },
    computed: {
        modalTitle() {
            return this.review._id ? this.$t("ProductReviewModal.editReview") : this.$t("ProductReviewModal.addReview");
        },
        submitBtnText() {
            return this.review._id ? this.$t("ProductReviewModal.edit") : this.$t("ProductReviewModal.rate");
        },
        formValid() {
            return areInputsNotEmpty(this.review, ["summary"]) && this.review.rate;
        },
        reviewSummaryLengthLeft() {
            return this.review.summary ? 100 - this.review.summary.length : 100;
        },
        reviewDescriptionLengthLeft() {
            return this.review.description ? 1000 - this.review.description.length : 1000;
        },
    },
    methods: {
        show(review = null) {
            this.review = new ProductReview(review);
            // eslint-disable-next-line no-undef
            $("#product-review-modal").modal("show");
        },
        async submit() {
            try {
                this.loading = true;
                if (this.review._id) {
                    await this.submitEdit();
                } else {
                    await this.submitCreate();
                }
                // eslint-disable-next-line no-undef
                $("#product-review-modal").modal("hide");
            } catch (err) {
                this.$error.displayError(err);
            } finally {
                this.loading = false;
            }
        },
        async submitEdit() {
            const { userId } = decodeJWTToken();
            const { data } = await this.$hermesAPI.editProductReview(this.product._id, { ...this.review, user: userId });
            const review = new ProductReview(data);
            this.$emit("edit-product-review", review);
            this.$toasted.success(this.$t("ProductReviewModal.reviewEdited"), { icon: "star" });
        },
        async submitCreate() {
            const { data } = await this.$hermesAPI.reviewProduct(this.product._id, this.review);
            const review = new ProductReview(data);
            this.$emit("add-review", review);
            this.$toasted.success(this.$t("ProductReviewModal.reviewAdded"), { icon: "star" });
        },
    },
};
</script>

<style lang="scss" scoped>
    #product-review-thumbnail {
        max-height: 130px;
    }

    .form-group {
        label {
            span {
                color: red;
            }
        }
    }
</style>