<template>
    <div id="ilp-home-disclaimer">
        <div class="container">
            <section class="row align-items-center padding-bottom-2x pt-4">
                <div class="col-md-5">
                    <img class="d-block w-270 m-auto" :src="disclaimerIcon" alt="Online Shopping"/>
                </div>
                <div class="col-md-7 text-md-left text-center">
                    <div class="mt-30 hidden-md-up"/>
                    <h2 v-html="this.title"/>
                    <p class="text-sm" v-html="this.content"/>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { importSVG } from "../../../../../helpers/file";

export default {
    name: "ILPHomeDisclaimer",
    data() {
        return {
            disclaimerIcon: importSVG("connected", "ilp"),
        };
    },
    computed: {
        ...mapGetters("user", {
            isUserLogged: "isLogged",
        }),
        ...mapGetters("config", {
            frontConfig: "front",
        }),
        title() {
            return !this.isUserLogged ? "Cher lecteur." : "Cher libraire.";
        },
        content() {
            if (!this.isUserLogged) {
                return "Bienvenue dans LE catalogue ILP où vous trouverez des dizaines de milliers de titres d'éditeurs français et internationaux.<br/><br/>Imprimés à Dakar. Commandez les auprès de votre libraire habituel, il les recevra en 48h à Dakar et en 8 à 10 jours à Abidjan, Bamako, Conakry, Lomé et Ouagadougou.";
            } else {
                return "Bienvenue dans ILP DISTRI où vous pourrez commander en ligne ou auprès de votre distributeur habituel, des dizaines de milliers de titres d'éditeurs français et internationaux.<br/><br/>Imprimés à Dakar, ils seront disponibles en 48h à Dakar et en 8 à 10 jours à Abidjan, Bamako, Conakry, Lomé et Ouagadougou.";
            }
        },
    },
};
</script>

<style scoped>
    #home-disclaimer-ilp {
        border-bottom: 1px solid lightgrey;
    }
</style>