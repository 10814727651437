<template>
    <div>
        <div class="card-header text-lg">
            {{$t("CheckoutCompletePayBillByBankTransfer.youPayedByBankTransfer")}}
        </div>
        <div class="card-body">
            <div class="row align-items-center">
                <div class="col-1">
                    <i class="fas fa-credit-card text-info fa-3x"></i>
                </div>
                <div class="col-10">
                    <h4 class="card-title">{{$t("CheckoutCompletePayBillByBankTransfer.howByBankTransfer")}}</h4>
                </div>
            </div>
            <hr class="mb-4"/>
            <div v-if="hermesAPIConfig.client === 'imprimer-vos-livres'" class="row">
                <p class="col-12 text-center text-less-bold mb-4" v-html="$t('CheckoutCompletePayBillByBankTransfer.printcorpTimeToPay')"/>
            </div>
            <div class="row form-group">
                <label class="col-2 col-form-label text-muted" for="title">{{$t("CheckoutCompletePayBillByBankTransfer.entitled")}}</label>
                <input v-tooltip="$t('CheckoutCompletePayBillByBankTransfer.clickToCopy')" class="form-control col-8" readonly id="title"
                       @click="copy('title')" :value="paymentTitle"/>
            </div>
            <div class="row form-group">
                <label class="col-2 col-form-label text-muted" for="BIC">{{$t("CheckoutCompletePayBillByBankTransfer.BIC")}}</label>
                <input v-tooltip="$t('CheckoutCompletePayBillByBankTransfer.clickToCopy')" class="form-control col-8" readonly id="BIC"
                       @click="copy('BIC')" :value="shop.bank.BIC"/>
            </div>
            <div class="row form-group">
                <label class="col-2 col-form-label text-muted" for="IBAN">{{$t("CheckoutCompletePayBillByBankTransfer.IBAN")}}</label>
                <input v-tooltip="$t('CheckoutCompletePayBillByBankTransfer.clickToCopy')" class="form-control col-8" readonly id="IBAN"
                       @click="copy('IBAN')" :value="shop.bank.IBAN"/>
            </div>
            <div class="row form-group">
                <label class="col-2 col-form-label text-muted" for="amount">{{$t("CheckoutCompletePayBillByBankTransfer.amount")}}</label>
                <input v-tooltip="$t('CheckoutCompletePayBillByBankTransfer.clickToCopy')" class="form-control col-8" readonly id="amount"
                       @click="copy('amount')" :value="properPrice(order.totalWT, order.currency, currencies)"/>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { properPrice } from "@/helpers/price";

export default {
    name: "CheckoutCompletePayBillByBankTransfer",
    props: {
        order: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            paymentTitle: this.$t("CheckoutCompletePayBillByBankTransfer.transferTitle", {reference: this.order.reference}),
        };
    },
    computed: {
        ...mapGetters("currencies", {
            currencies: "currencies",
        }),
        ...mapGetters("config", {
            shop: "shop",
            hermesAPIConfig: "hermesAPI",
        }),
    },
    methods: {
        properPrice,
        copy(id) {
            const copyText = document.getElementById(id);
            copyText.select();
            document.execCommand("copy");
            this.$toasted.success(this.$t("CheckoutCompletePayBillByBankTransfer.copied"), { icon: "copy" });
        },
    },
};
</script>

<style scoped>
    input[readonly="readonly"] {
        cursor: pointer;
        color: #606060;
    }
</style>