<template>
    <tr>
        <td :width="productItemWidth">
            <div class="product-item">
                <router-link class="product-thumb" :to="`/product/${product._id}`">
                    <img class="box-shadow" v-lazy="localProduct.firstCoverPhotoUrl" alt="Product">
                </router-link>
                <div class="product-info">
                    <h4 class="product-title">
                        <router-link :to="`/product/${localProduct._id}`">{{this.localProduct.title}}</router-link>
                    </h4>
                </div>
            </div>
        </td>
        <td v-if="user.canSeePrices" class="text-center text-lg text-medium">{{this.properPrice(this.product.priceWT, chosenCurrency, currencies)}}</td>
        <td v-if="user.canSeePrices" class="text-center text-lg text-medium">{{this.product.discount}} %</td>
        <td v-if="user.canSeePrices" class="text-center text-lg text-medium">{{this.properPrice(this.product.discountedPriceWT, chosenCurrency, currencies)}}</td>
        <td width="1%" class="text-center">
            <input @change="updateCartProduct" v-model.number="localProduct.quantity" type="number" class="form-control"
                   min="1" :disabled="loadingUpdate">
        </td>
        <td v-if="user.canSeePrices" class="text-center text-lg text-medium">{{this.properPrice(this.getSubTotalLine, chosenCurrency, currencies)}}</td>
        <td width="1%" class="text-center">
            <a @click.prevent="confirmDeleteProduct" class="remove-from-cart" :class="{'c-default': loadingDelete}"
               href="#">
                <i v-if="!loadingDelete && !loadingUpdate" class="material-icons icon_close"></i>
                <i v-else class="fas fa-circle-notch fa-spin text-warning"></i>
            </a>
        </td>
    </tr>
</template>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import Product from "../../../classes/Product";
import { properPrice, getSubTotal } from "../../../helpers/price";

export default {
    name: "CartDetailsLine",
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loadingDelete: false, loadingUpdate: false,
            localProduct: new Product(this.product),
        };
    },
    computed: {
        ...mapGetters("currencies", {
            currencies: "currencies",
        }),
        ...mapGetters("preferences", {
            chosenCurrency: "currency",
        }),
        ...mapGetters("config", {
            shopPrimaryColor: "shopPrimaryColor",
        }),
        ...mapGetters("user", {
            user: "user",
        }),
        getSubTotalLine() {
            return getSubTotal([this.product], this.currencies);
        },
        productItemWidth() {
            return this.user.canSeePrices ? "40%" : "98%";
        },
    },
    methods: {
        properPrice,
        async updateCartProduct() {
            try {
                if (!this.localProduct.quantity) {
                    return this.localProduct.quantity = this.product.quantity;
                }
                this.loadingUpdate = true;
                await this.$store.dispatch("user/updateToCart", this.localProduct);
            } catch (err) {
                this.$error.displayError(err);
            } finally {
                this.loadingUpdate = false;
            }
        },
        confirmDeleteProduct() {
            Swal.fire({
                title: this.$t("cartDetailsLine.youSure"),
                text: this.$t("cartDetailsLine.youWillDelete"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: this.shopPrimaryColor,
                cancelButtonColor: "#D33",
                confirmButtonText: this.$t("cartDetailsLine.yesDelete"),
                cancelButtonText: this.$t("cartDetailsLine.cancel"),
            }).then(result => {
                if (result.value) {
                    this.deleteProduct();
                }
            });
        },
        async deleteProduct() {
            try {
                this.loadingDelete = true;
                await this.$store.dispatch("user/deleteToCart", this.localProduct._id);
            } catch (err) {
                this.$error.displayError(err);
            } finally {
                this.loadingDelete = false;
            }
        },
    },
};
</script>

<style scoped>
</style>