<template>
    <div data-backdrop="static" data-keyboard="false" class="modal fade" id="forgot-password-modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <ValidationObserver ref="formObserver" v-slot="{ invalid }">
                    <form @submit.prevent="submit()">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ $t("ForgotPasswordModal.forgotPassword") }}</h5>
                        </div>
                        <div class="modal-body">
                            <div class="row">
                                <div class="col-12 text-center">
                                    <img alt="Forgot password" width="200" :src="forgotPasswordSVG"/>
                                </div>
                            </div>
                            <hr class="mb-3"/>
                            <div class="row mb-3">
                                <div class="col-12">
                                    <span>{{ $t("ForgotPasswordModal.pleaseFillEmail") }}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <ValidationProvider v-slot="{ errors, classes }">
                                        <label class="text-less-bold" for="forgot-password-email">
                                            {{ $t("ForgotPasswordModal.email") }}
                                            <span>*</span>
                                        </label>
                                        <div class="input-group">
                                            <input id="forgot-password-email" class="form-control" type="email" required
                                                   :placeholder="$t('ForgotPasswordModal.required')" v-model="email" :class="classes"/>
                                            <span class="input-group-addon"><i class="material-icons mail"></i></span>
                                        </div>
                                        <div class="invalid-feedback">
                                            {{ errors[0] }}
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" :disabled="loading" class="btn btn-secondary" data-dismiss="modal">
                                {{ $t("ForgotPasswordModal.close") }}
                            </button>
                            <SubmitBtn :classes="'btn btn-primary'" :loading="loading" :disabled="invalid"
                                       :text="$t('ForgotPasswordModal.sendMail')"/>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import SubmitBtn from "../../../../shared/Misc/SubmitBtn";
import { importSVG } from "../../../../../helpers/file";

export default {
    name: "ForgotPasswordModal",
    components: {SubmitBtn},
    data() {
        return {
            email: "",
            loading: false,
        };
    },
    computed: {
        ...mapGetters("config", {
            shopPrimaryColor: "shopPrimaryColor",
            hermesAPIConfig: "hermesAPI",
        }),
        forgotPasswordSVG() {
            return importSVG("forgot_password", this.hermesAPIConfig.client);
        },
    },
    methods: {
        show() {
            // eslint-disable-next-line no-undef
            $("#forgot-password-modal").modal("show");
            this.$refs.formObserver.reset();
            this.email = "";
        },
        async submit() {
            try {
                this.loading = true;
                await this.$hermesAPI.resetPasswordRequest(this.email);
                // eslint-disable-next-line no-undef
                $("#forgot-password-modal").modal("hide");
                Swal.fire({
                    icon: "success",
                    title: this.$t("ForgotPasswordModal.emailSent"),
                    text: this.$t("ForgotPasswordModal.clickLinkInMail"),
                    confirmButtonColor: this.shopPrimaryColor,
                    confirmButtonText: this.$t("ForgotPasswordModal.gotIt"),
                    footer: `<span>${this.$t("ForgotPasswordModal.dontForgetSpam")}</span>`,
                });
            } catch (err) {
                if (err.response && err.response.status === 404) {
                    this.$toasted.error(this.$t("ForgotPasswordModal.emailNotFound"), { icon: "question-circle" });
                } else {
                    this.$error.displayError(err);
                }
            } finally {
                this.loading = false;
                this.email = "";
                this.$refs.formObserver.reset();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    label {
        span {
            color: red;
        }
    }
</style>