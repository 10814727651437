import _ from "lodash";

export function properDate(d) {
    const date = new Date(d);
    const day = _.padStart(date.getDate(), 2, "0");
    const month = _.padStart(date.getMonth() + 1, 2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
}

export function properTime(d) {
    const date = new Date(d);
    const hours = _.padStart(date.getHours(), 2, "0");
    const minutes = _.padStart(date.getMinutes(), 2, "0");
    return `${hours}:${minutes}`;
}