<template>
    <div>
        <transition name="fadein" mode="out-in">
            <div v-if="displayControls" class="row align-items-center"
                 :class="{'justify-content-center': !userAddresses.length, 'justify-content-between': userAddresses.length}">
                <div class="col-1 col-md-4">
                    <button :disabled="!canChange" data-toggle="modal" @click="$refs.addressModal.show()" class="btn btn-primary">
                        <span class="hidden-sm-down">
                            {{$t("shared.address.addressManager.addAddress")}}
                        </span>
                        <span class="hidden-md-up fa fa-plus"></span>
                    </button>
                </div>
                <div v-if="userAddresses.length" class="col-9 col-md-4 text-right">
                    <v-select @input="changeAddress" :clearable="false" :disabled="!canChange" :value="formattedAddressSelected" :options="formattedAddresses"/>
                </div>
                <div class="col-12">
                    <hr class="mt-2 mb-2"/>
                </div>
            </div>
        </transition>
        <transition name="fadein" mode="out-in">
            <div key="noAddress" v-if="!userAddresses.length">
                <h2 class="text-center">{{$t("shared.address.addressManager.noAddress")}}</h2>
                <div class="row justify-content-center">
                    <div class="col-6">
                        <img alt="No address" id="no-address-svg" :src="noAddressSVG"/>
                    </div>
                </div>
            </div>
            <div key="addresses" class="mt-2" v-else>
                <ValidationObserver ref="formObserver" v-slot="{ invalid }">
                    <AddressForm v-if="canEdit" ref="addressForm" @form-valid="isFormValid" :address="addressSelected"/>
                    <AddressSection :address="addressSelected"/>
                    <hr class="margin-top-1x padding-bottom-1x"/>
                    <div v-if="canEdit" class="row justify-content-md-between">
                        <div class="col-6">
                            <SubmitBtn @click="updateAddress" :classes="'btn btn-sm btn-primary'"
                                        :disabled="loadingDelete || !formValid || invalid" :text="$t('shared.address.addressManager.edit')"
                                        :loading="loadingUpdate"/>
                        </div>
                        <div class="col-6">
                            <SubmitBtn :loading="loadingDelete" :disabled="loadingUpdate" :text="$t('shared.address.addressManager.delete')"
                                        @click="confirmDeleteAddress" :classes="'btn btn-sm btn-danger'"/>
                        </div>
                    </div>
                </ValidationObserver>
            </div>
        </transition>
        <AddressModal @add-address="addAddress" ref="addressModal"/>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import AddressModal from "./AddressModal";
import AddressForm from "./AddressForm";
import Swal from "sweetalert2";
import _ from "lodash";
import SubmitBtn from "../../Misc/SubmitBtn";
import Address from "../../../../classes/Address";
import { importSVG } from "../../../../helpers/file";
import AddressSection from "./AddressSection";

export default {
    name: "AddressManager",
    components: {AddressSection, SubmitBtn, AddressForm, AddressModal},
    props: {
        canEdit: {
            type: Boolean,
            default: true,
        },
        canChange: {
            type: Boolean,
            default: true,
        },
        defaultAddressId: {
            type: String,
            default: "0",
        },
        displayControls: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            formValid: false,
            loadingUpdate: false,
            loadingDelete: false,
            addressSelected: new Address(),
            addressIdSelected: 0,
        };
    },
    computed: {
        ...mapGetters("user", {
            userAddresses: "addresses",
        }),
        ...mapGetters("config", {
            shopPrimaryColor: "shopPrimaryColor",
            hermesAPIConfig: "hermesAPI",
        }),
        noAddressSVG() {
            return importSVG("no_address", this.hermesAPIConfig.client);
        },
        formattedAddresses() {
            return this.userAddresses.map(address => ({ value: address._id, label: address.name }));
        },
        formattedAddressSelected() {
            if (this.addressSelected) {
                return this.formattedAddresses.find(address => address.value === this.addressSelected._id);
            }
            return null;
        },
    },
    mounted() {
        if (this.userAddresses.length) {
            if (this.defaultAddressId === "0" || !this.userAddresses.find(address => address._id === this.defaultAddressId)) {
                this.addressIdSelected = this.userAddresses[0]._id;
            } else {
                this.addressIdSelected = this.defaultAddressId;
            }
        }
    },
    methods: {
        isFormValid(valid) {
            this.formValid = valid;
        },
        addAddress(address) {
            this.addressIdSelected = address._id;
        },
        changeAddress(address) {
            this.setSelectedAddress(address.value);
        },
        async updateAddress() {
            try {
                this.loadingUpdate = true;
                const address = this.$refs.addressForm.getAddress();
                await this.$store.dispatch("user/updateAddress", address);
            } catch (err) {
                this.$error.displayError(err);
            } finally {
                this.loadingUpdate = false;
            }
        },
        confirmDeleteAddress() {
            Swal.fire({
                title: this.$t("shared.address.addressManager.youSure"),
                text: this.$t("shared.address.addressManager.youWillDelete"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: this.shopPrimaryColor,
                cancelButtonColor: "#D33",
                confirmButtonText: this.$t("shared.address.addressManager.yesDelete"),
                cancelButtonText: this.$t("shared.address.addressManager.cancel"),
            }).then(result => {
                if (result.value) {
                    this.deleteAddress();
                }
            });
        },
        async deleteAddress() {
            try {
                this.loadingDelete = true;
                await this.$store.dispatch("user/deleteAddress", this.addressIdSelected);
                if (this.userAddresses.length) {
                    this.addressIdSelected = this.userAddresses[0]._id;
                } else {
                    this.addressIdSelected = 0;
                }
            } catch (err) {
                this.$error.displayError(err);
            } finally {
                this.loadingDelete = false;
            }
        },
        getSelectedAddress() {
            return this.addressSelected;
        },
        setSelectedAddress(addressId) {
            this.addressIdSelected = addressId;
        },
    },
    watch: {
        addressIdSelected(newValue) {
            const idx = _.findIndex(this.userAddresses, address => address._id === newValue);
            this.addressSelected = new Address(this.userAddresses[idx]);
            this.$emit("selected-address", this.addressSelected);
            if (this.canEdit) {
                this.$nextTick(() => {
                    this.$refs.formObserver.validate();
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    #no-address-svg {
        max-width: 100%;
    }
</style>