<template>
    <div id="administration-product-modal" data-backdrop="static" data-keyboard="false" class="modal fade" tabindex="-1" role="dialog"
         aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <form @submit.prevent="submitProduct">
                    <div class="modal-header">
                        <h5 class="modal-title" v-html="modalTitle"/>
                    </div>
                    <div class="modal-body">
                        <transition name="fade" mode="out-in">
                            <APILoading v-if="loading.isGettingProduct" :text="$t('AdministrationProductModal.gettingProduct')" key="gettingProductLoading" :font-size="20"/>
                            <div v-else key="productForm">
                                <div class="form-group row">
                                    <div class="col-6">
                                        <label class="text-muted" for="title" v-html="$t('AdministrationProductModal.title')"/>
                                        <RedAsterisk class="ml-2"/>
                                        <input class="form-control" type="text" ref="titleInput" id="title" v-model="product.title"
                                               :placeholder="$t('AdministrationProductModal.required')" required>
                                    </div>
                                    <div class="col-6">
                                        <label class="text-muted" for="isbn" v-html="$t('AdministrationProductModal.isbn')"/>
                                        <RedAsterisk class="ml-2"/>
                                        <input class="form-control" type="text" id="isbn" v-model="product.isbn" maxlength="13"
                                               pattern="^[0-9]+$" :placeholder="$t('AdministrationProductModal.required')" required>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-6">
                                        <label class="text-muted" for="publication-date" v-html="$t('AdministrationProductModal.publicationDate')"/>
                                        <RedAsterisk class="ml-2"/>
                                        <input class="form-control" type="date" id="publication-date" v-model="product.releaseDate"
                                               :placeholder="$t('AdministrationProductModal.required')" required>
                                    </div>
                                    <div class="col-6">
                                        <label class="text-muted" for="publisher" v-html="$t('AdministrationProductModal.publisher')"/>
                                        <RedAsterisk class="ml-2"/>
                                        <transition name="fade" mode="out-in">
                                            <APILoading key="loading" v-if="!publishers" :font-size='12' :icon-size='20'
                                                        :text="$t('AdministrationProductModal.loadingPublishers')"/>
                                            <v-select v-else id="publisher" @input="setSelectedPublisher" :filter-by="filterBy"
                                                      :placeholder='$t("AdministrationProductModal.required")' label="name"
                                                      :options="publishers" :value="selectedPublisher" required>
                                            </v-select>
                                        </transition>
                                    </div>
                                </div>
                                <div class="form-group row d-flex align-items-center">
                                    <div class="col-6">
                                        <label class="text-muted" for="price-w-t" v-html="$t('AdministrationProductModal.priceWT')"/>
                                        <RedAsterisk class="ml-2"/>
                                        <input class="form-control" type="number" step="0.01" min="0.01" id="price-w-t"
                                               v-model.number="product.priceWT"
                                               :placeholder="$t('AdministrationProductModal.required')" required>
                                    </div>
                                    <div class="col-6 text-center">
                                        <div class="custom-control custom-checkbox mt-4">
                                            <input class="custom-control-input c-pointer" v-model="product.reducedTax" :disabled="doesProductExist"
                                                   type="checkbox" id="cultural-product">
                                            <label v-html="$t('AdministrationProductModal.culturalProduct')" class="custom-control-label c-pointer"
                                                   for="cultural-product"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-6">
                                        <label class="text-muted" for="weight" v-html="$t('AdministrationProductModal.weight')"/>
                                        <RedAsterisk class="ml-2"/>
                                        <input class="form-control" type="number" min="1" id="weight" v-model.number="product.weight" required
                                               :placeholder="$t('AdministrationProductModal.required')">
                                    </div>
                                    <div class="col-6">
                                        <label class="text-muted" for="total-paging" v-html="$t('AdministrationProductModal.totalPaging')"/>
                                        <input class="form-control" type="number" :min="selfPublishedProductsMinTotalPages" id="total-paging"
                                               v-model.number="productTotalPaging" :placeholder="$t('AdministrationProductModal.optional')">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-6">
                                        <label class="text-muted" for="author" v-html="$t('AdministrationProductModal.author')"/>
                                        <input class="form-control" type="text" id="author" v-model="product.author"
                                               :placeholder="$t('AdministrationProductModal.optional')">
                                    </div>
                                    <div class="col-6">
                                        <label class="text-muted" for="subtitle" v-html="$t('AdministrationProductModal.subtitle')"/>
                                        <input class="form-control" type="text" id="subtitle" v-model="product.subtitle"
                                               :placeholder="$t('AdministrationProductModal.optional')">
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-12">
                                        <label class="text-muted" for="book-collections" v-html="$t('AdministrationProductModal.collection')"/>
                                        <transition name="fade" mode="out-in">
                                            <APILoading id="loading" key="loading" v-if="!bookCollections" :font-size='12' :icon-size='20'
                                                        :text="$t('AdministrationProductModal.loadingBookCollections')"/>
                                            <v-select v-else id="book-collections" @input="setSelectedBookCollection" :filter-by="filterBy"
                                                      :placeholder='$t("AdministrationProductModal.optional")' key="book-collections" label="name"
                                                      :options="bookCollections" :value="selectedBookCollection" taggable>
                                            </v-select>
                                        </transition>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-12">
                                        <label class="text-muted" for="description" v-html="$t('AdministrationProductModal.description')"/>
                                        <textarea class="form-control" type="text" id="description" v-model="product.description"
                                               :placeholder="$t('AdministrationProductModal.optional')" rows="5"/>
                                    </div>
                                </div>
                                <hr class="my-2"/>
                                <div class="row">
                                    <div v-if="!doesProductExist" class="col-12 d-flex align-items-center">
                                        <i class="fa fa-image fa-3x mr-4 text-primary"/>
                                        <h5 class="font-italic text-muted m-0" v-html="$t('AdministrationProductModal.uploadThumbnailWhenProductCreated')"/>
                                    </div>
                                    <UploadProductThumbnails v-else class="col-12" :product="product" @thumbnails-uploaded="updateProductThumbnails"/>
                                </div>
                            </div>
                        </transition>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal" :disabled="isLoading"
                                v-html="$t('AdministrationProductModal.close')"/>
                        <SubmitBtn classes="btn btn-primary" :text="submitBtnText" :loading="isLoading"/>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import RedAsterisk from "@/components/shared/Forms/RedAsterisk";
import APILoading from "@/components/shared/Misc/APILoading";
import Product from "@/classes/Product";
import Publisher from "@/classes/Publisher";
import {filterBy} from "@/helpers/v-select";
import SubmitBtn from "@/components/shared/Misc/SubmitBtn";
import Vue from "vue";
import UploadProductThumbnails from "@/components/shared/Product/ProductThumbnails/UploadProductThumbnails";

export default {
    name: "AdministrationProductModal",
    components: {UploadProductThumbnails, SubmitBtn, APILoading, RedAsterisk},
    data() {
        return {
            loading: {
                isCreatingProduct: false,
                isUpdatingProduct: false,
                isGettingProduct: false,
            },
            product: new Product({ pod: true }),
            bookCollections: undefined,
            publishers: undefined,
        };
    },
    computed: {
        ...mapGetters("config", {
            selfPublishedProductsMinTotalPages: "selfPublishedProductsMinTotalPages",
        }),
        productTotalPaging: {
            get() {
                return this.product.paging.total;
            },
            set(value) {
                this.product.paging.total = value ? value : undefined;
            },
        },
        selectedPublisher() {
            return this.publishers ? this.publishers.find(({ _id }) => this.product.publisher === _id) : null;
        },
        selectedBookCollection() {
            return this.bookCollections ? this.bookCollections.find(({ name }) => this.product.bookCollection === name) : null;
        },
        doesProductExist() {
            return !!this.product._id;
        },
        modalTitle() {
            return this.doesProductExist ? this.$t("AdministrationProductModal.updateProduct") : this.$t("AdministrationProductModal.addProduct");
        },
        submitBtnText() {
            return this.doesProductExist ? this.$t("AdministrationProductModal.update") : this.$t("AdministrationProductModal.add");
        },
        isLoading() {
            return this.loading.isCreatingProduct || this.loading.isUpdatingProduct || this.loading.isGettingProduct;
        },
        corpusFileInputLabel() {
            return this.corpus.file ? `<i class='fa fa-check text-success mr-2'></i>${this.corpus.file.name}` : this.$t("UploadProductFiles.chooseFile");
        },
    },
    async created() {
        await Promise.all([this.getBookCollections(), this.getPublishers()]);
    },
    methods: {
        async show(product) {
            if (!product) {
                this.resetProduct();
            } else {
                await this.getAndSetProduct(product._id);
            }
            // eslint-disable-next-line no-undef
            $("#administration-product-modal").modal("show");
        },
        hide() {
            // eslint-disable-next-line no-undef
            $("#administration-product-modal").modal("hide");
        },
        resetProduct() {
            this.product = new Product({ type: "book", source: "publisher", weight: 50, reducedTax: true });
        },
        async getAndSetProduct(productId) {
            try {
                this.loading.isGettingProduct = true;
                const { data } = await Vue.prototype.$hermesAPI.getProduct(productId);
                this.product = new Product(data);
                this.product.releaseDate = this.product.releaseDate.toISOString().slice(0, 10);
                this.product.reducedTax = this.product.tax.reduced;
                this.setSelectedPublisher(this.product.publisher);
            } catch (err) {
                this.$error.displayError(err);
            } finally {
                this.loading.isGettingProduct = false;
            }
        },
        setSelectedBookCollection(bookCollection) {
            this.product.bookCollection = bookCollection ? bookCollection.name : undefined;
        },
        setSelectedPublisher(publisher) {
            if (publisher) {
                this.product.publisher = publisher ? publisher._id : null;
                this.product.group = publisher.groups[0];
            }
        },
        async getBookCollections() {
            try {
                const { data } = await this.$hermesAPI.getBookCollections();
                this.bookCollections = data.bookCollections;
            } catch (err) {
                this.$error.displayError(err);
            }
        },
        async getPublishers() {
            try {
                const { data } = await this.$hermesAPI.getPublishers();
                this.publishers = data.map(publisher => new Publisher(publisher));
            } catch (err) {
                this.$error.displayError(err);
            }
        },
        async submitProduct() {
            if (this.doesProductExist) {
                await this.updateProduct();
            } else {
                await this.addProduct();
            }
        },
        async addProduct() {
            try {
                this.loading.isCreatingProduct = true;
                const body = JSON.parse(JSON.stringify(this.product));
                delete body.selfPublisher;
                body.pod = true;
                const { data } = await this.$hermesAPI.addProduct(body);
                this.$toasted.success(this.$t("AdministrationProductModal.productAdded"), {icon: "check"});
                this.$emit("product-added", data);
                this.hide();
            } catch (err) {
                this.$error.displayError(err);
            } finally {
                this.loading.isCreatingProduct = false;
            }
        },
        async updateProduct() {
            try {
                this.loading.isUpdatingProduct = true;
                const body = JSON.parse(JSON.stringify(this.product));
                delete body.selfPublisher;
                body.pod = true;
                const { data } = await this.$hermesAPI.updateProduct(body);
                this.$toasted.success(this.$t("AdministrationProductModal.productUpdated"), {icon: "check"});
                this.$emit("product-updated", data);
                this.hide();
            } catch (err) {
                this.$error.displayError(err);
            } finally {
                this.loading.isUpdatingProduct = false;
            }
        },
        updateProductThumbnails(product) {
            product.firstCoverPhotoUrl = `${product.firstCoverPhotoUrl}?t=${new Date().toISOString()}`;
            product.fourthCoverPhotoUrl = `${product.fourthCoverPhotoUrl}?t=${new Date().toISOString()}`;
            this.product.firstCoverPhotoUrl = product.firstCoverPhotoUrl;
            this.product.fourthCoverPhotoUrl = product.fourthCoverPhotoUrl;
            this.$emit("product-updated", product);
        },
        filterBy,
    },
};
</script>

<style scoped>

</style>|