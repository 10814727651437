<template>
    <div class="modal fade" id="product-creation-workshop-modal" tabindex="-1" role="dialog" aria-hidden="true"
         data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header py-1">
                    <h4 class="modal-title">
                        <i class="fa fa-palette text-primary mr-2"/>
                        <span v-html="$t('ProductCreationWorkshopModal.title', product)"/>
                    </h4>
                </div>
                <div class="modal-body d-flex flex-column h-100 p-0">
                    <transition name="fade" mode="out-in">
                        <vue-friendly-iframe v-if="product._id" v-show="false" class="d-flex flex-grow-1" :src="productOneYearOneBookEditorURL"/>
                    </transition>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline-secondary btn-sm" data-dismiss="modal"
                            v-html="$t('ProductCreationWorkshopModal.close')"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Product from "@/classes/Product";
import Config from "../../../../../../config";
import Swal from "sweetalert2";

export default {
    name: "ProductCreationWorkshopModal",
    components: {},
    data() {
        return {
            product: new Product(),
        };
    },
    computed: {
        productOneYearOneBookEditorURL() {
            return `${Config.oneYearOneBookEditor.baseURL}/${this.product.oneYearOneBook.token}`;
        },
    },
    mounted() {
        window.addEventListener("message", this.receiveMessage);
    },
    methods: {
        show(product) {
            console.log("coucou");
            this.product = new Product(product);
            // eslint-disable-next-line no-undef
            $("#product-creation-workshop-modal").modal("show");
        },
        receiveMessage(message) {
            if (this.product._id) {
                if (message.data === "sendingFiles") {
                    // eslint-disable-next-line no-undef
                    $("#product-creation-workshop-modal").modal("hide");
                    Swal.fire({
                        icon: "success",
                        title: this.$t("ProductCreationWorkshopModal.youAreDone"),
                        text: this.$t("ProductCreationWorkshopModal.yourProductFilesAreGenerating"),
                        footer: `<span>${this.$t("ProductCreationWorkshopModal.dontForgetSpam")}</span>`,
                    });
                }
            }
        },
    },
};
</script>

<style lang="scss">
    #product-creation-workshop-modal {
        .modal-dialog {
            width: 100%;
            height: 100%;
            max-width: 100%;
            margin: 0;
            padding: 0;

            .modal-content {
                height: auto;
                min-height: 100%;
                border-radius: 0;
                overflow-y: hidden;

                .modal-body {
                    border-top: 1px solid #d2d2d2;
                    border-bottom: 1px solid #d2d2d2;
                    border-right: 0;
                    border-left: 0;

                    .vue-friendly-iframe {
                        height: 100%;
                        position: relative;

                        iframe {
                            -webkit-box-flex: 1 !important;
                            flex-grow: 1 !important;
                        }
                    }
                }

                .modal-footer {
                    height: 40px;
                }
            }
        }
    }
</style>