<template>
    <div class="steps flex-sm-nowrap mb-3">
        <a v-for="(step, i) in steps" :key="i" @click.prevent="changeStep(i)" :disabled="stepNumber < i"
           class="step" :class="{ active: $route.name === step.routeName }" href="#">
            <h4 class="step-title">
                <i v-if="stepNumber > i" class="material-icons check_circle"></i>
                {{i + 1}}. {{step.name}}
            </h4>
        </a>
    </div>
</template>

<script>
export default {
    name: "CheckoutNav",
    data() {
        return {
            stepNumber: 0,
            steps: [
                {
                    name: this.$t("checkout.nav.address"),
                    routePath: "/checkout",
                    routeName: "checkoutBilling",
                },
                {
                    name: this.$t("checkout.nav.shipping"),
                    routePath: "/checkout/shipping",
                    routeName: "checkoutShipping",
                },
                {
                    name: this.$t("checkout.nav.review"),
                    routePath: "/checkout/review",
                    routeName: "checkoutReview",
                },
                {
                    name: this.$t("checkout.nav.payment"),
                    routePath: "/checkout/payment",
                    routeName: "checkoutPayment",
                },
            ],
        };
    },
    methods: {
        changeStep(stepNumber) {
            this.stepNumber = stepNumber;
            this.$router.push(this.steps[stepNumber].routePath);
        },
    },
};
</script>

<style scoped>

    .step[disabled] {
        pointer-events: none;
    }

</style>