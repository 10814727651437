<template>
    <div>
        <h3>
            <i class="fa fa-shopping-cart mr-2 text-primary"></i>
            {{ $t("HelpShop.shop") }}
        </h3>
        <div class="accordion" id="accordion-shop" role="tablist">
            <div class="card">
                <div class="card-header" role="tab">
                    <h6>
                        <a href="#search-book" data-toggle="collapse" class="collapsed" aria-expanded="false">
                            Comment puis-je rechercher facilement un ouvrage ?
                        </a>
                    </h6>
                </div>
                <div class="collapse" id="search-book" data-parent="#accordion-shop" role="tabpanel">
                    <div class="card-body">
                        <p>Pour une recherche rapide par titre, vous pouvez utilisez la <span>Recherche Rapide</span> avec l'icône <i class="material-icons search"></i> dans le menu en haut à droite.</p>
                        <p>Une recherche plus avancée par thème ou par collection par exemple est possible avec le bouton <span>Recherche Avancée</span> avec aussi un icône <i class="material-icons search"></i> dans le <router-link to="/shop">catalogue</router-link> à gauche.</p>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" role="tab">
                    <h6><a class="collapsed" href="#connected-to-buy" data-toggle="collapse">Dois-je être connecté pour remplir mon panier ?</a></h6>
                </div>
                <div class="collapse" id="connected-to-buy" data-parent="#accordion-shop" role="tabpanel">
                    <div class="card-body">
                        Oui, il est obligatoire de s'être préalablement inscrit et se connecter pour ajouter des produits à son panier et passer commande.
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" role="tab">
                    <h6><a class="collapsed" href="#price-not-displayed" data-toggle="collapse">Pourquoi le prix des livres n'est-il pas affiché ?</a></h6>
                </div>
                <div class="collapse" id="price-not-displayed" data-parent="#accordion-shop" role="tabpanel">
                    <div class="card-body">
                        <p>Si vous n'êtes pas connecté, les prix des livres ne sont pas affichés. Si le prix du livre n'est pas affiché, vous ne pouvez pas le commander.</p>
                        <p>Il arrive aussi que, même connecté, le prix de certains livres ne soient pas divulgué. C'est une volonté de l'éditeur, vous ne pouvez donc pas les commander pour le moment.</p>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" role="tab">
                    <h6><a class="collapsed" href="#price-displayed-cant-buy" data-toggle="collapse">Le prix du livre est affiché mais je ne peux pas le commander, pourquoi ?</a></h6>
                </div>
                <div class="collapse" id="price-displayed-cant-buy" data-parent="#accordion-shop" role="tabpanel">
                    <div class="card-body">
                        Le bouton <span>Ajouter au panier</span> est désactivé, vous ne pouvez donc pas commander ce livre. Pour connaître la raison, survolez simplement le bouton avec votre souris. Sur interface tactile, laissez appuyer sur le bouton avec votre doigt.
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" role="tab">
                    <h6><a class="collapsed" href="#change-currency" data-toggle="collapse">Comment changer la devise des produits ?</a></h6>
                </div>
                <div class="collapse" id="change-currency" data-parent="#accordion-shop" role="tabpanel">
                    <div class="card-body">
                        <p>Vous pouvez changer la devise des produits en choisissant celle que vous souhaitez dans la liste déroulante tout en bas de n'importe quelle page de la boutique.</p>
                    </div>
                </div>
            </div>
            <div v-if="userPublisherAgreements.length" class="card">
                <div class="card-header" role="tab">
                    <h6><a class="collapsed" href="#agreement-product" data-toggle="collapse">Qu'est-ce qu'un produit Accord ?</a></h6>
                </div>
                <div class="collapse" id="agreement-product" data-parent="#accordion-shop" role="tabpanel">
                    <div class="card-body">
                        <p>Un produit <span>Accord</span> signifie que vous avez un accord avec son éditeur. Le montant à payer pour ces livres en passant commande sera à définir avec le ou les éiteurs concernés.</p>
                        <p>
                            Les produits <span>Accord</span> possède ce logo
                            <span id="agreement-cart-icon" class="fa-stack fa-1x">
                                <i class="fas fa-certificate fa-stack-2x text-primary"></i>
                                <i data-v-741978ef="" class="far fa-handshake fa-stack-1x fa-inverse"></i>
                            </span> pour les identifier.
                        </p>
                    </div>
                </div>
            </div>
            <div v-if="userPublisherAgreements.length" class="card">
                <div class="card-header" role="tab">
                    <h6><a class="collapsed" href="#agreement-cart" data-toggle="collapse">Puis-je constituer un panier composé de produits Accord et de produits sans Accord ?</a></h6>
                </div>
                <div class="collapse" id="agreement-cart" data-parent="#accordion-shop" role="tabpanel">
                    <div class="card-body">
                        <p>Non, vous devez choisir entre un panier composé seulement de produits <span>Accord</span> ou de produits <span>sans Accord</span>.</p>
                        <p>Si vous voulez changer de type de panier, vous devez le vider complètement en supprimant un par un les produits ou en cliquant sur <span>Vider le panier</span> dans <router-link to="/cart">le détail de votre panier</router-link> en bas de la page.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "HelpShop",
    computed: {
        ...mapGetters("user", {
            userPublisherAgreements: "publisherAgreements",
        }),
    },
};
</script>

<style scoped>
    .card-body span {
        font-weight: 600;
    }
</style>