<template>
    <div class="container">
        <AboutShop/>
        <hr/>
        <AboutProduct/>
        <hr/>
        <AboutDelivery/>
        <hr/>
        <AboutPayment/>
    </div>
</template>

<script>
import AboutDelivery from "./DefaultAboutDelivery";
import AboutPayment from "./DefaultAboutPayment";
import AboutProduct from "./DefaultAboutProduct";
import AboutShop from "./DefaultAboutShop";
export default {
    name: "DefaultAbout",
    components: {AboutShop, AboutProduct, AboutPayment, AboutDelivery},
};
</script>

<style scoped>

</style>