import Address from "./Address";
import Product from "./Product";
import Carrier from "./Carrier";
import DiscountCode from "./DiscountCode";
import { hasProp } from "@/helpers/class";

class Order {
    constructor(order = null) {
        this._id = !hasProp(order, "_id") ? undefined : order._id;
        this.type = !hasProp(order, "type") ? undefined : order.type;
        this.user = !hasProp(order, "user") ? "0" : order.user;
        this.reference = !hasProp(order, "reference") ? "0" : order.reference;
        this.status = {
            payment: !hasProp(order, "status.payment") ? undefined : order.status.payment,
            production: !hasProp(order, "status.production") ? undefined : order.status.production,
        };
        this.createdAt = !hasProp(order, "createdAt") ? undefined : order.createdAt;
        this.updatedAt = !hasProp(order, "updatedAt") ? undefined : order.updatedAt;
        this.currency = !hasProp(order, "currency") ? "EUR" : order.currency;
        this.total = !hasProp(order, "total") ? 0 : order.total;
        this.totalWT = !hasProp(order, "totalWT") ? 0 : order.totalWT;
        this.billing = {
            to: !hasProp(order, "billing.to") ? "" : order.billing.to,
            method: !hasProp(order, "billing.method") ? undefined : order.billing.method,
            address: !hasProp(order, "billing.address") ? new Address() : new Address(order.billing.address),
            addressId: !hasProp(order, "billing.addressId") ? "0" : order.billing.addressId,
            EUVATReverseCharge: !hasProp(order, "billing.EUVATReverseCharge") ? undefined : order.billing.EUVATReverseCharge,
            reference: {
                proForma: !hasProp(order, "billing.reference.proForma") ? undefined : order.billing.reference.proForma,
                bill: !hasProp(order, "billing.reference.bill") ? undefined : order.billing.reference.bill,
                credit: !hasProp(order, "billing.reference.credit") ? undefined : order.billing.reference.credit,
            },
        };
        this.delivery = {
            to: !hasProp(order, "delivery.to") ? "" : order.delivery.to,
            trackingNumber: !hasProp(order, "delivery.trackingNumber") ? undefined : order.delivery.trackingNumber,
            method: !hasProp(order, "delivery.method") ? "" : order.delivery.method,
            address: !hasProp(order, "delivery.address") ? new Address() : new Address(order.delivery.address),
            addressId: !hasProp(order, "delivery.addressId") ? "0" : order.delivery.addressId,
            carrier: !hasProp(order, "delivery.carrier") ? new Carrier() : new Carrier(order.delivery.carrier),
            carrierId: !hasProp(order, "delivery.carrierId") ? 0 : order.delivery.carrierId,
            free: !hasProp(order, "delivery.free") ? false : order.delivery.free,
            price: !hasProp(order, "delivery.price") ? 0 : order.delivery.price,
            priceWT: !hasProp(order, "delivery.priceWT") ? 0 : order.delivery.priceWT,
            tax: {
                reduced: !hasProp(order, "delivery.tax.reduced") ? 0 : order.delivery.tax.reduced,
                percent: !hasProp(order, "delivery.tax.percent") ? 0 : order.delivery.tax.percent,
            },
            sendingDate: !hasProp(order, "delivery.sendingDate") ? undefined : order.delivery.sendingDate,
            carrierSobookId: !hasProp(order, "delivery.carrierSobookId") ? 0 : order.delivery.carrierSobookId,
        };
        this.products = !hasProp(order, "products") ? [] : order.products.map(product => new Product(product));
        this.comment = !hasProp(order, "comment") ? undefined : order.comment;
        this.paypal = {
            orderID: !hasProp(order, "paypal.orderID") ? "0" : order.paypal.orderID,
        };
        this.creditCard = {
            paymentIntentID: !hasProp(order, "creditCard.paymentIntentID") ? undefined : order.creditCard.paymentIntentID,
        };
        this.discountCode = !hasProp(order, "discountCode") ? new DiscountCode() : new DiscountCode(order.discountCode);
    }

    get selfPublishedProductsWithFilesToUpload() {
        return this.products.filter(product => product.source === "user" && product.files.corpus.status === "to-upload" && product.files.cover.status === "to-upload");
    }

    get selfPublishedProductsWithFilesToUploadOrToCheck() {
        return this.products.filter(product => product.source === "user" &&
            (product.files.corpus.status === "to-upload" && product.files.cover.status === "to-upload" ||
                product.files.corpus.status === "to-check" && product.files.cover.status === "to-check"));
    }

    static get paymentStatuses() {
        return ["waiting", "error", "accepted", "refunded", "none"];
    }
    static get productionStatuses() {
        return ["pending", "preparing", "shipping", "shipped", "delivered", "canceled"];
    }
}

export default Order;