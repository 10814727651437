<template>
    <div class="col-md-7 mb-30">
        <div class="card border-default bg-white pt-2 box-shadow">
            <div class="card-body">
                <span v-tooltip="$t('ProductInfos.hasPublisherAgreement')" v-if="hasPublisherAgreement"
                    id="agreement-icon" class="fa-stack fa-2x">
                    <i class="fas fa-certificate fa-stack-2x text-primary"></i>
                    <i class="far fa-handshake fa-stack-1x fa-inverse"></i>
                </span>
                <div class="row align-items-center">
                    <div class="col-8">
                        <h2 id="product-title" v-html='product.title'/>
                    </div>
                    <div class="col-4 text-right">
                        <ProductStarRate :product="product" @show-product-review-modal="$emit('show-product-review-modal')"/>
                    </div>
                </div>
                <div class="text-muted mt-1" v-if="product.subtitle" v-html="product.subtitle"/>
                <div class="font-italic mt-1" v-if="product.author" v-html="$t('ProductInfos.byAuthor', { author: product.author })"/>
                <hr class="mt-2"/>
                <div class="row align-items-center">
                    <div class="col-6 text-center">
                        <div v-if="this.product.hasDisplayedPrices(isUserLogged)">
                            <div v-if="this.product.discount !== 0">
                                <h3 class="text-normal d-flex align-items-center" id="price">
                                    <span class="d-flex flex-column">
                                        <span class="text-sm text-danger">{{this.product.discount}} %</span>
                                        <del class="text-muted text-sm">{{this.properPrice(product.priceWT, chosenCurrency, currencies)}}</del>
                                    </span>
                                    <i class="fa fa-arrow-right text-sm ml-2"></i>
                                    <span class="ml-2">{{this.properPrice(product.discountedPriceWT, chosenCurrency, currencies)}}</span>
                                </h3>
                            </div>
                            <div v-else>
                                <h3 class="text-normal" id="price">
                                    {{this.properPrice(product.priceWT, chosenCurrency, currencies)}}
                                </h3>
                            </div>
                        </div>
                        <div v-html="noPriceText" class="text-muted" v-else/>
                    </div>
                    <div class="col-6 text-center justify-content-center">
                        <AddToCartBtn classes="btn btn-primary btn-block" :text="$t('shared.product.addToCart')"
                                      :quantity="1" :currency="chosenCurrency" :product="product"/>
                    </div>
                </div>
                <hr class="mt-1 mb-3"/>
                <div v-if="product.description && product.description.trim()">
                    <h5>{{$t("shared.product.description")}}</h5>
                    <read-more class="text-sm" :more-str="$t('ReadMore.readMore')" :text="product.description"
                               :less-str="$t('ReadMore.readLess')" :max-chars="250"/>
                    <hr class="mt-3 mb-3"/>
                </div>
                <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" href="#product-general-info-tab" data-toggle="tab" role="tab"
                           aria-selected="true" v-html="$t('shared.product.informationsTitle')"/>
                    </li>
                    <li v-if="product.source === 'user'" class="nav-item">
                        <a class="nav-link" href="#product-components-info-tab" data-toggle="tab" role="tab"
                           aria-selected="false" v-html="$t('ProductInfos.productComposition')"/>
                    </li>
                    <li v-if="product.options.length" class="nav-item">
                        <a class="nav-link" href="#product-options-info-tab" data-toggle="tab" role="tab"
                           aria-selected="false">
                            <span v-html="$t('ProductInfos.options')"/>
                            <span class="badge badge-primary ml-2" v-html="product.options.length"/>
                        </a>
                    </li>
                </ul>
                <div class="row p-3">
                    <div class="col-12">
                        <div class="tab-content">
                            <div class="tab-pane fade active show" id="product-general-info-tab" role="tabpanel">
                                <ProductGeneralInfoList :product="product"/>
                            </div>
                            <div v-if="product.source === 'user'" class="tab-pane fade" id="product-components-info-tab" role="tabpanel">
                                <ProductComponentsInfoList :product="product"/>
                            </div>
                            <div v-if="product.options.length" class="tab-pane fade" id="product-options-info-tab" role="tabpanel">
                                <ProductOptionsList :product="product"/>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="mb-3"/>
                <div v-if="product.source === 'user'">
                    <div class="row">
                        <div class="col-8">
                            <h5>{{$t("shared.product.fileStatuses")}}</h5>
                        </div>
                        <div class="col-4 text-right">
                            <PrintableProductBadge :product="product"/>
                        </div>
                    </div>
                    <div class="row align-items-center justify-content-center">
                        <div class="col">
                            <ProductFileStatusBadge class="w-100" book-part="corpus" :product="product"/>
                        </div>
                        <div class="col">
                            <ProductFileStatusBadge class="w-100" book-part="cover" :product="product"/>
                        </div>
                    </div>
                    <ProductCreationWorkshopButton :product="product" class="btn-sm btn-block"
                                                   @show-product-creation-workshop-modal="$emit('show-product-creation-workshop-modal')">
                        <template v-slot:text>
                            <i class="fa fa-palette mr-2"/>
                            <span v-html="$t('ProductCreationWorkshopButton.createYourBook')"/>
                        </template>
                    </ProductCreationWorkshopButton>
                    <UploadProductFilesButton class="btn-block" :product="product"
                                              @show-upload-product-files-modal="$emit('show-upload-product-files-modal')">
                        <template #text>
                            <i class="fa fa-cloud-upload-alt mr-2"/>
                            <span v-html="$t('ProductInfos.uploadFiles')"/>
                        </template>
                    </UploadProductFilesButton>
                    <hr class="mt-3 mb-3"/>
                </div>
                <div class="row align-items-center">
                    <div class="col-sm-6">
                        <AddToCartBtn classes="btn btn-primary btn-block" :text="$t('shared.product.addToCart')"
                                      :quantity="1" :currency="chosenCurrency" :product="product"/>
                    </div>
                    <div class="col-sm-6">
                        <Alert type="info" size="xs" :text="$t('ProductInfos.inYourCart', { count: countInCart })">
                            <template v-slot:icon>
                                <i class="fa fa-shopping-bag"/>
                            </template>
                        </Alert>
                    </div>
                </div>
                <transition name="fade">
                    <div v-if="countInCart">
                        <hr class="mt-2 mb-2" />
                        <div class="row align-items-center form-group mb-0">
                            <div class="col-sm-6 text-center">
                                <label for="sp-quantity" v-html="$t('ProductInfos.updateQuantityInYourCart')"></label>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group mb-0 d-flex justify-content-center align-items-center">
                                    <button @click.prevent="decrementQuantity" class="btn btn-secondary mr-0 no-border-radius"
                                            :disabled="!product.canAddToCart(isUserLogged, doesShopShowOnlyPODProducts, cannotOrderUnprintableTwice) || loadingUpdate">
                                        <i class="fa fa-minus"></i>
                                    </button>
                                    <input type="number" min="1" v-model.number="countInCart" ref="changeQuantityInput"
                                           class="form-control text-center no-border-radius"
                                           :disabled="!product.canAddToCart(isUserLogged, doesShopShowOnlyPODProducts, cannotOrderUnprintableTwice) || loadingUpdate" id="sp-quantity"/>
                                    <button @click.prevent="incrementQuantity" class="btn btn-secondary no-border-radius"
                                            :disabled="!product.canAddToCart(isUserLogged, doesShopShowOnlyPODProducts, cannotOrderUnprintableTwice) || loadingUpdate">
                                        <i class="fa fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { properDate } from "@/helpers/date";
import { properPrice } from "@/helpers/price";
import Product from "@/classes/Product";
import AddToCartBtn from "@/components/shared/Product/AddToCartBtn";
import ProductStarRate from "@/components/shared/Product/ProductStarRate";
import Alert from "@/components/shared/Misc/Alert";
import ProductFileStatusBadge from "@/components/shared/Product/ProductFiles/ProductFileStatusBadge";
import PrintableProductBadge from "@/components/shared/Product/PrintableProductBadge";
import UploadProductFilesButton from "@/components/shared/Product/ProductFiles/UploadProductFiles/UploadProductFilesButton";
import ProductGeneralInfoList from "@/components/shared/Product/ProductInfoList/ProductGeneralInfoList";
import ProductComponentsInfoList from "@/components/shared/Product/ProductInfoList/ProductComponentsInfoList";
import ProductOptionsList from "@/components/shared/Product/ProductInfoList/ProductOptionsList";
import ProductCreationWorkshopButton
    from "@/components/shared/Product/ProductFiles/ProductCreationWorkshop/ProductCreationWorkshopButton";

export default {
    name: "ProductInfos",
    components: {
        ProductCreationWorkshopButton,
        ProductOptionsList,
        ProductComponentsInfoList,
        ProductGeneralInfoList,
        UploadProductFilesButton,
        PrintableProductBadge,
        ProductFileStatusBadge,
        Alert,
        ProductStarRate,
        AddToCartBtn,
    },
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
    data() {
        return { loadingUpdate: false };
    },
    computed: {
        ...mapGetters("currencies", {
            currencies: "currencies",
        }),
        ...mapGetters("countries", {
            countries: "countries",
        }),
        ...mapGetters("preferences", {
            chosenCurrency: "currency",
        }),
        ...mapGetters("user", {
            isUserLogged: "isLogged",
            userPublisherAgreements: "publisherAgreements",
            userCart: "cart",
            user: "user",
        }),
        ...mapGetters("config", {
            doesShopShowOnlyPODProducts: "doesShopShowOnlyPODProducts",
            cannotOrderUnprintableTwice: "cannotOrderUnprintableTwice",
        }),
        hasPublisherAgreement() {
            return this.userPublisherAgreements.includes(this.product.publisher._id);
        },
        countInCart: {
            get() {
                return this.userCart.reduce((acc, product) => product._id === this.product._id ? acc + product.quantity : acc, 0);
            },
            async set(quantity) {
                if (quantity) {
                    await this.updateCartProductQuantity(quantity);
                }
            },
        },
        noPriceText() {
            if (this.product) {
                if (!this.user.canSeePrices) {
                    return this.$t("ProductInfos.logInToSeePrice");
                } else if (!this.product.publisher.showPrice) {
                    return this.$t("ProductInfos.publisherDoesntWantToShowPrice");
                }
            }
            return null;
        },
    },
    methods: {
        properDate, properPrice,
        async updateCartProductQuantity(quantity) {
            try {
                this.loadingUpdate = true;
                const cartProduct = new Product(this.userCart.find(product => product._id === this.product._id));
                cartProduct.quantity = quantity;
                await this.$store.dispatch("user/updateToCart", cartProduct);
            } catch (err) {
                this.$error.displayError(err);
            } finally {
                this.loadingUpdate = false;
                setTimeout(() => this.$refs.changeQuantityInput.focus(), 30);
            }
        },
        incrementQuantity() {
            this.countInCart += 1;
        },
        decrementQuantity() {
            if (this.countInCart > 1) {
                this.countInCart -= 1;
            }
        },
    },
};
</script>

<style lang='scss' scoped>

    #sp-quantity {
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            /* display: none; <- Crashes Chrome on hover */
            -webkit-appearance: none;
            margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
        }

        -moz-appearance:textfield; /* Firefox */
    }

    #price {
        font-weight: 500 !important;
        font-size: 2rem;
        color: gray;
        margin-bottom: 0 !important;
    }

    #agreement-icon {
        position: absolute;
        top: -4%;
        left: -6%;
    }

    #product-title {
        margin-bottom: 0;
    }
</style>