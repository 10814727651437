<template>
    <button v-if="product.canUploadFiles && !product.getOption('product-creation-workshop')" class="btn btn-warning"
            v-tooltip="$t('UploadProductFilesButton.sendFiles')"
            @click="$emit('show-upload-product-files-modal', product)">
        <slot name="text"/>
    </button>
</template>

<script>
import Product from "@/classes/Product";

export default {
    name: "UploadProductFilesButton",
    props: {
        product: {
            type: Product,
            required: true,
        },
        showTooltip: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style scoped>

</style>