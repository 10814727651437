<template>
    <div id="printcorp-header">
        <Branding/>
        <nav class="site-menu">
            <ul class="text-center">
                <li :class="{ active: $route.name === 'home' }">
                    <router-link to="/">
                        <span class="fa fa-home fa-2x"/>
                    </router-link>
                </li>
                <li :class="{ active: inSelfPublishingFlow($route.name) }">
                    <router-link to="/self-publishing">
                        <span v-html="$t('PrintCorpHeader.printMyBook')"/>
                    </router-link>
                </li>
                <li :class="{ active: $route.name === 'selfPublishingBooks' }">
                    <router-link to="/self-publishing/books">
                        <span v-html="$t('PrintCorpHeader.myLibrary')"/>
                    </router-link>
                </li>
                <transition name="fade">
                    <li v-if="isUserLogged" :class="{ active: $route.name === 'profileOrders' }">
                        <router-link to="/profile/orders">
                            <span v-html="$t('PrintCorpHeader.myOrders')"/>
                        </router-link>
                    </li>
                </transition>
                <transition name="fade">
                    <li v-if="isUserLogged && user.isAdmin" :class="{ active: inAdministrationSection }">
                        <router-link to="/administration">
                            <span v-html="$t('PrintCorpHeader.administration')"/>
                        </router-link>
                    </li>
                </transition>
                <HelpTab/>
                <li :class="{ active: $route.name === 'contact' }">
                    <router-link to="/contact">
                        <span v-html="$t('PrintCorpHeader.contact')"/>
                    </router-link>
                </li>
                <li>
                    <a class="d-flex align-items-center" href="https://www.typolibris.fr/la-clairiere-des-livres/" target="_blank">
                        <img :src="IMGs.laClairiereDesLivres" alt="La Clairière des Livres" width="120"/>
                    </a>
                </li>
            </ul>
        </nav>
        <SideBarTabs @show-forgot-password-modal="$emit('show-forgot-password-modal')"/>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Branding from "@/components/Hermes/Header/Branding";
import { inAdministrationSection, inSelfPublishingFlow } from "@/helpers/section";
import HelpTab from "@/components/shared/Header/HelpTab";
import SideBarTabs from "@/components/shared/Header/SideBarTabs";
import laClairiereDesLivres from "@/assets/img/imprimer-vos-livres/custom-header/la-clairiere-des-livres.png";
import typolibris from "@/assets/img/imprimer-vos-livres/custom-header/typolibris.png";

export default {
    name: "PrintCorpHeader",
    components: {SideBarTabs, HelpTab, Branding },
    data() {
        return {
            IMGs: { laClairiereDesLivres, typolibris },
        };
    },
    computed: {
        ...mapGetters("user", {
            user: "user",
            isUserLogged: "isLogged",
            userCart: "cart",
        }),
        inAdministrationSection() {
            return inAdministrationSection(this.$route.name);
        },
    },
    methods: {
        inSelfPublishingFlow,
    },
};
</script>

<style lang="scss" scoped>
    #printcorp-header {
        background-color: #242021 !important;
        height: 100%;
        width: 100%;
    }

    ul {
        li {
            text-align: center;
            max-width: 150px;
        }
    }
</style>