<template>
    <tr>
        <td>
            <div class="product-item">
                <router-link class="product-thumb" target="_blank" :to="`/product/${product._id}`">
                    <img v-lazy="product.firstCoverPhotoUrl" alt="Product"/>
                </router-link>
                <div class="product-info">
                    <h4 class="product-title">
                        <router-link target="_blank" :to="`/product/${product._id}`">{{this.product.title}}</router-link>
                    </h4>
                    <span v-if="product.isbn"><em>{{$t("checkout.review.checkoutReviewCartDetailsLine.isbn")}}:</em> {{this.product.isbn}}</span>
                    <span v-if="product.publisher._id"><em>{{$t("checkout.review.checkoutReviewCartDetailsLine.publisher")}}:</em> {{this.product.publisher.name}}</span>
                    <span v-if="product.author"><em>{{$t("checkout.review.checkoutReviewCartDetailsLine.authors")}}:</em> {{this.product.author}}</span>
                    <hr class="my-2"/>
                    <span v-if="!!physicalPrintingApproval">
                        <em v-html="$t('checkout.review.checkoutReviewCartDetailsLine.physicalPrintingApprovalOptionTaken')"/>
                        <span v-html="`(+ ${properPrice(physicalPrintingApproval.charge.priceWT, physicalPrintingApproval.charge.currency, currencies)})`"/>
                    </span>
                    <span v-if="!!assistedProductCreationOption">
                        <em v-html="$t('checkout.review.checkoutReviewCartDetailsLine.assistedProductCreationOptionTaken')"/>
                        <span v-html="`(+ ${properPrice(assistedProductCreationOption.charge.priceWT, assistedProductCreationOption.charge.currency, currencies)})`"/>
                    </span>
<!--                    <span v-if="product.customization.dedication"><em>{{$t("checkout.review.checkoutReviewCartDetailsLine.dedication")}}:</em> {{this.product.customization.dedication}}</span>-->
                    <CustomizeProductBtn :product="product" class="btn btn-primary btn-xs mt-2" @show-customize-product-modal="showCustomizeProductModal"/>
                </div>
            </div>
        </td>
        <td class="text-center text-lg text-medium">
            <span v-if="productFromReview && productFromReview.priceWT !== product.priceWT">
                <del class="text-sm text-muted"
                     v-tooltip="$t('OrderReviewCartDetailsLine.lastPriceWTWasPriceWT', { priceWT: properPrice(product.priceWT, currency, currencies) })">
                    {{ properPrice(product.priceWT, currency, currencies) }}
                </del>
                <i class="fa fa-arrow-right text-muted ml-1 mr-1 text-sm"/>
            </span>
            <span v-html="productPriceWT"/>
        </td>
        <td class="text-center text-lg text-medium">
            <span v-if="productFromReview && productFromReview.discount !== product.discount">
                <del class="text-sm text-muted"
                     v-tooltip="$t('OrderReviewCartDetailsLine.lastDiscountWasDiscount', { discount: product.discount })">
                    {{ product.discount }} %
                </del>
                <i class="fa fa-arrow-right text-muted ml-1 mr-1 text-sm"/>
            </span>
            <span v-html="productDiscount"/>
        </td>
        <td class="text-center text-lg text-medium">
            <span v-if="productFromReview && productFromReview.discountedPriceWT !== product.discountedPriceWT">
                <del class="text-sm text-muted"
                     v-tooltip="$t('OrderReviewCartDetailsLine.lastDiscountedPriceWTWasDiscountedPriceWT', { discountedPriceWT: properPrice(getTotalWT(product), currency, currencies) })">
                    {{ properPrice(product.discountedPriceWT, currency, currencies) }}
                </del>
                <i class="fa fa-arrow-right text-muted ml-1 mr-1 text-sm"/>
            </span>
            <span v-html="productDiscountedPriceWT"/>
        </td>
        <td class="text-center text-lg text-medium">{{this.product.quantity}}</td>
        <td class="text-center text-lg text-medium white-space-no-wrap">
            <span v-if="productFromReview && productFromReview.totalWT !== getTotalWT(product)">
                <del class="text-sm text-muted"
                     v-tooltip="$t('OrderReviewCartDetailsLine.lastTotalWTWasTotalWT', { totalWT: properPrice(getTotalWT(product), currency, currencies) })">
                    {{ properPrice(getTotalWT(product), currency, currencies) }}
                </del>
                <i class="fa fa-arrow-right text-muted ml-1 mr-1 text-sm"/>
            </span>
            <span v-html="productTotalWT"/>
        </td>
    </tr>
</template>

<script>
import { mapGetters } from "vuex";
import { properPrice, getSubTotal } from "@/helpers/price";
import CustomizeProductBtn from "../../../Product/CustomizeProductBtn/CustomizeProductBtn";

export default {
    name: "OrderReviewCartDetailsLine",
    components: {CustomizeProductBtn},
    props: {
        product: {
            type: Object,
            required: true,
        },
        review: {
            type: Object,
            default: () => null,
        },
        currency: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapGetters("currencies", {
            currencies: "currencies",
        }),
        productFromReview() {
            return this.review ? this.review.products.find(product => product._id === this.product._id) : null;
        },
        productPriceWT() {
            const product = this.productFromReview ? this.productFromReview : this.product;
            return this.properPrice(product.priceWT, this.currency, this.currencies);
        },
        productDiscount() {
            const product = this.productFromReview ? this.productFromReview : this.product;
            return `${product.discount} %`;
        },
        productDiscountedPriceWT() {
            const product = this.productFromReview ? this.productFromReview : this.product;
            return this.properPrice(product.discountedPriceWT, this.currency, this.currencies);
        },
        productTotalWT() {
            const product = this.productFromReview ? this.productFromReview : this.product;
            const totalWT = this.getTotalWT(product);
            return this.properPrice(totalWT, this.currency, this.currencies);
        },
        assistedProductCreationOption() {
            return !this.product.alreadyOrdered && this.product.getOption("assisted-product-creation");
        },
        physicalPrintingApproval() {
            return !this.product.alreadyOrdered && this.product.getOption("physical-printing-approval");
        },
    },
    methods: {
        properPrice,
        getTotalWT(product) {
            return product.totalWT ? product.totalWT : getSubTotal([product], this.currencies);
        },
        showCustomizeProductModal(product) {
            this.$emit("show-customize-product-modal", product);
        },
    },
};
</script>

<style lang="scss" scoped>
</style>