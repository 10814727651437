<template>
    <div id="about">
        <PageTitle :title="aboutTitle">
            <template v-slot:left>
                <i class="fa fa-3x text-primary" :class="aboutIcon"/>
            </template>
        </PageTitle>
        <div v-if="!hermesAPIConfig.client === 'airvey-editions'" class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-sm-2">
                    <img alt="Questions" :src="questionsSVG">
                </div>
            </div>
        </div>
        <AboutILP v-if="hermesAPIConfig.client === 'ilp'"/>
        <AboutGutenberg v-else-if="hermesAPIConfig.client === 'gutenberg'"/>
        <AboutLaBoutiqueSoBook v-else-if="hermesAPIConfig.client === 'la-boutique-sobook'"/>
        <AboutImprimerVosLivres v-else-if="hermesAPIConfig.client === 'imprimer-vos-livres'"/>
        <AboutAirveyEditions v-else-if="hermesAPIConfig.client === 'airvey-editions'"/>
        <DefaultAbout v-else/>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import PageTitle from "@/components/shared/Misc/PageTitle";
import { importSVG } from "@/helpers/file";
import AboutILP from "./AboutILP/AboutILP";
import DefaultAbout from "./DefaultAbout/DefaultAbout";
import AboutGutenberg from "./AboutGutenberg/AboutGutenberg";
import AboutLaBoutiqueSoBook from "./AboutLaBoutiqueSoBook/AboutLaBoutiqueSoBook";
import AboutImprimerVosLivres from "@/components/Hermes/About/AboutImprimerVosLivres/AboutImprimerVosLivres";
import AboutAirveyEditions from "@/components/Hermes/About/AboutAirveyEditions/AboutAirveyEditions";

export default {
    name: "About",
    components: {
        AboutAirveyEditions,
        AboutImprimerVosLivres, AboutLaBoutiqueSoBook, AboutGutenberg, DefaultAbout, AboutILP, PageTitle},
    computed: {
        ...mapGetters("config", {
            hermesAPIConfig: "hermesAPI",
        }),
        questionsSVG() {
            return importSVG("questions", this.hermesAPIConfig.client);
        },
        aboutTitle() {
            return this.hermesAPIConfig.client === "airvey-editions" ? "Votre tapuscrit" : this.$t("about.title");
        },
        aboutIcon() {
            return this.hermesAPIConfig.client === "airvey-editions" ? "fa-book" : "fa-question-circle";
        },
    },
};

</script>

<style scoped>

</style>