import i18n from "@/i18n";
import { hasProp } from "@/helpers/class";

class Carrier {
    constructor(carrier = null) {
        this.id = !hasProp(carrier, "id") ? undefined : carrier.id;
        this.name = !hasProp(carrier, "name") ? undefined : carrier.name;
        this.currency = !hasProp(carrier, "currency") ? undefined : carrier.currency;
        this.chargesBy = !hasProp(carrier, "chargesBy") ? undefined : carrier.chargesBy;
        this.enabled = !hasProp(carrier, "enabled") ? true : carrier.enabled;
        this.enabledForProduction = !hasProp(carrier, "enabledForProduction") ? true : carrier.enabledForProduction;
        this.enabledForDistribution = !hasProp(carrier, "enabledForDistribution") ? true : carrier.enabledForDistribution;
        this.maxWeight = !hasProp(carrier, "maxWeight") ? undefined : carrier.maxWeight;
        this.needsGLN = !hasProp(carrier, "needsGLN") ? false : carrier.needsGLN;
        this.private = !hasProp(carrier, "private") ? false : carrier.private;
        this.request = {
            packageWeight: !hasProp(carrier, "request.packageWeight") ? undefined : carrier.request.packageWeight,
            packageProductQuantity: !hasProp(carrier, "request.packageProductQuantity") ? undefined : carrier.request.packageProductQuantity,
            deliveryCountry: !hasProp(carrier, "request.deliveryCountry") ? undefined : carrier.request.deliveryCountry,
            minDeliveryDays: !hasProp(carrier, "request.minDeliveryDays") ? undefined : carrier.request.minDeliveryDays,
            maxDeliveryDays: !hasProp(carrier, "request.maxDeliveryDays") ? undefined : carrier.request.maxDeliveryDays,
            reducedTax: !hasProp(carrier, "request.reducedTax") ? undefined : carrier.request.reducedTax,
            tax: {
                percent: !hasProp(carrier, "request.tax.percent") ? undefined : carrier.request.tax.percent,
                reduced: !hasProp(carrier, "request.tax.reduced") ? undefined : carrier.request.tax.reduced,
                fixed: !hasProp(carrier, "request.tax.fixed") ? undefined : carrier.request.tax.fixed,
            },
            price: !hasProp(carrier, "request.price") ? undefined : carrier.request.price,
            priceWT: !hasProp(carrier, "request.priceWT") ? undefined : carrier.request.priceWT,
        };
    }

    get deliveryDaysText() {
        const { minDeliveryDays, maxDeliveryDays } = this.request;
        if (minDeliveryDays && maxDeliveryDays) {
            return i18n.t("Class.Carrier.deliveryDays", { min: minDeliveryDays, max: maxDeliveryDays });
        } else {
            return i18n.t("Class.Carrier.unknownDeliveryDays");
        }
    }
}

export default Carrier;