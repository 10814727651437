import Vue from "vue";
import Publisher from "../../classes/Publisher";

const state = {
    publishers: [],
};

const getters = {
    publishers(state) {
        return state.publishers;
    },
};

const mutations = {
    setPublishers(state, publishers) {
        state.publishers = publishers;
    },
};

const actions = {
    async getPublishers({ commit, rootState }) {
        const search = { active: true };
        if (!rootState.user.user.isAdmin) {
            search["is-for-testing-purposes"] = false;
        }
        const response = await Vue.prototype.$hermesAPI.getPublishers(search);
        commit("setPublishers", response.data.filter(p => p.productCount !== 0).map(p => new Publisher(p)));
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};