<template>
    <div class="entry">
        <div class="entry-thumb">
            <router-link @click.native="$emit('product-selected')" :to="`/product/${product._id}`">
                <img v-lazy="product.firstCoverPhotoUrl" alt="Product">
            </router-link>
        </div>
        <div class="entry-content">
            <h4 class="entry-title">
                <router-link @click.native="$emit('product-selected')" v-html='this.product.title' :to="`/product/${product._id}`">
                </router-link>
                <small v-if="product.subtitle" v-html="product.subtitle"></small>
            </h4>
            <ProductStarRate :product="product" :show-add-review-btn="false" :show-no-review-text="false"/>
            <span class="entry-meta" v-if="product.hasDisplayedPrices(isUserLogged)">
                <span v-if="product.discount">
                    <del v-html='`${properPrice(product.priceWT, chosenCurrency, currencies)}`'></del>
                    →
                </span>
                <span class="text-less-bold" v-html='`${properPrice(product.discountedPriceWT, chosenCurrency, currencies)}`'></span>
            </span>
            <span v-if="product.isbn" class="entry-meta">
                <span class="text-less-bold">ISBN</span>: <span v-html="this.product.isbn"></span>
            </span>
            <div>
                <router-link @click.native="$emit('product-selected')" :to="`/product/${product._id}`" v-tooltip="$t('HeaderSearchResults.seeProduct')"
                             class="btn btn-xs btn-primary mr-1">
                    <i class="fa fa-eye"></i>
                </router-link>
                <AddToCartBtn :tooltip="$t('HeaderSearchResults.addToCart')" :product="product"
                              classes="btn btn-xs btn-info" text="<i class='fa fa-cart-plus'></i>"/>
            </div>
            <hr/>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import AddToCartBtn from "../../../shared/Product/AddToCartBtn";
import { properPrice } from "../../../../helpers/price";
import ProductStarRate from "../../../shared/Product/ProductStarRate";

export default {
    name: "HeaderSearchResults",
    components: {ProductStarRate, AddToCartBtn},
    props: {
        product: {
            type: Object,
        },
        search: {
            type: String,
        },
    },
    methods: {
        properPrice,
    },
    computed: {
        ...mapGetters("currencies", {
            currencies: "currencies",
        }),
        ...mapGetters("preferences", {
            chosenCurrency: "currency",
        }),
        ...mapGetters("user", {
            isUserLogged: "isLogged",
        }),
    },
};
</script>

<style scoped>
    .entry-meta {
        display: inline;
    }
</style>