<template>
    <div id="gutenberg-home">
        <GutenbergHomeDisclaimer/>
        <HomeNewProducts/>
    </div>
</template>

<script>
import GutenbergHomeDisclaimer from "./GutenbergHomeDisclaimer";
import HomeNewProducts from "../../HomeNewProducts/HomeNewProducts";

export default {
    name: "GutenbergHome",
    components: {HomeNewProducts, GutenbergHomeDisclaimer},
};
</script>

<style scoped>

</style>