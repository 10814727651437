<template>
    <div>
        <CheckoutStep :step="1" :text="$t('CheckoutBilling.fillBillRecipient')" icon="user"/>
        <div class="row">
            <div class="form-group col-md-3 col-xs-12">
                <ValidationProvider ref="recipientInput" v-slot="{ errors, classes }">
                    <label for="recipient" class="label">{{ $t("CheckoutBilling.billTo") }} <span>*</span></label>
                    <div class="input-group">
                            <span class="input-group-addon">
                                <i class="material-icons person"></i>
                            </span>
                        <input v-model="recipient" class="form-control" :class="classes" id="recipient"
                               :placeholder="$t('CheckoutShipping.required')" required/>
                    </div>
                    <div class="invalid-feedback">
                       {{ errors[0] }}
                    </div>
                </ValidationProvider>
            </div>
        </div>
        <CheckoutStep :step="2" :text="$t('checkout.address.chooseBillingAddress')" icon="map-pin"/>
        <AddressManager ref="addressManager" :canEdit="false" :default-address-id="order.billing.addressId"/>
        <div v-if="isStepValid">
            <div class="row">
                <div class="col-12 text-center">
                    <div class="custom-control custom-checkbox custom-control-inline">
                        <input v-model="saveAsFavoriteBillingAddress" class="custom-control-input c-pointer" type="checkbox" id="favorite-billing-address-id">
                        <label class="custom-control-label c-pointer" for="favorite-billing-address-id">
                            {{ $t("CheckoutBilling.favoriteBillingAddress") }}
                        </label>
                    </div>
                </div>
            </div>
            <RequiredForOneClickOrder/>
        </div>
        <div class="d-flex justify-content-end">
            <button @click="fillBilling" :disabled="!isStepValid" class="btn btn-primary m-0">
                {{$t('checkout.continue')}}
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import AddressManager from "../../shared/Address/AddressManager/AddressManager";
import RequiredForOneClickOrder from "../../shared/Order/RequiredForOneClickOrder";
import CheckoutStep from "../../shared/Order/CheckoutStep";

export default {
    name: "CheckoutBilling",
    components: {CheckoutStep, RequiredForOneClickOrder, AddressManager},
    props: {
        order: {
            required: true,
            type: Object,
        },
        preferences: {
            required: true,
            type: Object,
        },
    },
    data() {
        return {
            recipient: this.order.billing.to,
            saveAsFavoriteBillingAddress: !!this.preferences.billing.addressId,
        };
    },
    computed: {
        ...mapGetters("user", {
            user: "user",
            userSociety: "society",
            userAddresses: "addresses",
        }),
        isStepValid() {
            return this.userAddresses.length && this.recipient && this.recipient.trim();
        },
    },
    mounted() {
        if (this.recipient && this.recipient.trim()) {
            this.$refs.recipientInput.validate();
        }
    },
    methods: {
        fillBilling() {
            const selectedAddress = this.$refs.addressManager.getSelectedAddress();
            const billing = {
                method: this.order.billing.method,
                addressId: selectedAddress._id,
                address: selectedAddress,
                to: this.recipient,
                favoriteBillingAddress: this.saveAsFavoriteBillingAddress,
            };
            this.$emit("fill-billing", billing);
        },
    },
};
</script>

<style lang="scss" scoped>
    .form-group {
        label {
            span {
                color: red;
            }
        }
    }

    .custom-checkbox {
        .custom-control-label {
            font-size: 17px;
        }
    }
</style>