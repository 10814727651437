const state = {
    open: false,
    sections: {
        mobileNav: {
            active: false,
        },
        search: {
            active: false,
        },
        account: {
            active: false,
        },
        cart: {
            active: false,
        },
    },
};

const getters = {
    isMobileNavSectionActive(state) {
        return state.sections.mobileNav.active;
    },
    isSearchSectionActive(state) {
        return state.sections.search.active;
    },
    isAccountSectionActive(state) {
        return state.sections.account.active;
    },
    isCartSectionActive(state) {
        return state.sections.cart.active;
    },
    isSideBarOpen(state) {
        return state.open;
    },
};

const mutations = {
    setOpen(state, open) {
        state.open = open;
    },
    setSectionActive(state, { name, active }) {
        state.sections[name].active = active;
    },
};

const actions = {
    openSearchSection({ commit }) {
        commit("setSectionActive", { name: "mobileNav", active: false });
        commit("setSectionActive", { name: "search", active: true });
        commit("setSectionActive", { name: "account", active: false });
        commit("setSectionActive", { name: "cart", active: false });
        commit("setOpen", true);
    },
    openAccountSection({ commit }) {
        commit("setSectionActive", { name: "mobileNav", active: false });
        commit("setSectionActive", { name: "search", active: false });
        commit("setSectionActive", { name: "account", active: true });
        commit("setSectionActive", { name: "cart", active: false });
        commit("setOpen", true);
    },
    openCartSection({ commit }) {
        commit("setSectionActive", { name: "mobileNav", active: false });
        commit("setSectionActive", { name: "search", active: false });
        commit("setSectionActive", { name: "account", active: false });
        commit("setSectionActive", { name: "cart", active: true });
        commit("setOpen", true);
    },
    toggleSection({ commit, state }, section) {
        if (state.sections[section] !== undefined) {
            if (state.sections[section].active) {
                commit("setSectionActive", { name: section, active: false });
                commit("setOpen", false);
            } else {
                for (const s in state.sections) {
                    commit("setSectionActive", { name: s, active: s === section });
                }
                commit("setOpen", true);
            }
        }
    },
    hide({ commit }) {
        for (const s in state.sections) {
            commit("setSectionActive", { name: s, active: false });
        }
        commit("setOpen", false);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};