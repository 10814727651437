<template>
    <SubmitBtn :classes="classes" :tooltip-text="tooltipText" :loading="loading" :disabled="disabled"
               :disabled-tooltip-text="$t('DownloadBilling.cantDownloadBilling')" :text="text || defaultTextWithIcon"
               @click="getBilling"/>
</template>

<script>
import FileSaver from "file-saver";
import SubmitBtn from "../Misc/SubmitBtn";

export default {
    name: "DownloadBilling",
    components: {SubmitBtn},
    props: {
        text: {
            type: String,
            default: null,
        },
        forcedType: {
            type: String,
            default: null,
        },
        order: {
            type: Object,
            required: true,
        },
        classes: {
            type: String,
            required: true,
        },
        displayTooltip: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        type() {
            if (this.forcedType) {
                return this.forcedType;
            } else if (this.order.type === "quote") {
                return "quote";
            } else if (this.order.billing.reference.credit) {
                return "credit";
            } else if (this.order.billing.reference.bill) {
                return "bill";
            } else {
                return "proForma";
            }
        },
        fileName() {
            if (this.type === "quote") {
                return this.order.reference;
            } else if (this.type === "credit") {
                return `AV${this.order.billing.reference.credit}`;
            } else if (this.type === "bill") {
                return `FC${this.order.billing.reference.bill}`;
            } else {
                return `PF${this.order.billing.reference.proForma}`;
            }
        },
        defaultText() {
            return this.$t(`DownloadBilling.${this.type}`);
        },
        defaultTextWithIcon() {
            return `<i class="fa fa-2x fa-file-pdf mr-2"></i>${this.defaultText}`;
        },
        tooltipText() {
            return this.displayTooltip && this.defaultText || "";
        },
    },
    methods: {
        async getBilling() {
            try {
                this.loading = true;
                const response = await this.$hermesAPI.getOrderBilling(this.order._id);
                const blob = new Blob([response.data], { type: "application/pdf" });
                FileSaver.saveAs(blob, `${this.fileName}.pdf`);
            } catch (err) {
                this.$error.displayError(err);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped>

</style>