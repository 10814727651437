<template>
    <div>
        <h3>
            <i class="fa fa-user mr-2 text-primary"></i>
            {{ $t("HelpProfile.account") }}
        </h3>
        <div class="accordion" id="accordion-profile" role="tablist">
            <div class="card">
                <div class="card-header" role="tab">
                    <h6>
                        <a href="#how-login" data-toggle="collapse" class="collapsed" aria-expanded="false">
                            Comment puis-je m'enregistrer / me connecter ?
                        </a>
                    </h6>
                </div>
                <div class="collapse" id="how-login" data-parent="#accordion-profile" role="tabpanel">
                    <div class="card-body">
                        <p>Pour s'enregistrer ou se connecter, il vous suffit de cliquer sur l'icône <i class="material-icons person"></i> dans le menu en haut à droite.</p>
                        <p>Dans le panel ouvert, vous pouvez soit vous inscrire sur la boutique ou vous connecter.</p>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" role="tab">
                    <h6><a class="collapsed" href="#cant-login" data-toggle="collapse">Je n'arrive pas à me connecter, pourquoi ?</a></h6>
                </div>
                <div class="collapse" id="cant-login" data-parent="#accordion-profile" role="tabpanel">
                    <div class="card-body">
                        <p>Assurez-vous d'abord que votre email et votre mot de passe sont corrects. <span class="text-danger">Attention aux majuscules</span></p>
                        <p>Si vous ne pouvez toujours pas vous connecter, votre compte n'a sûrement pas encore été validé par l'administrateur.</p>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" role="tab">
                    <h6><a class="collapsed" href="#forgot-password" data-toggle="collapse">J'ai oublié mon mot de passe ! Comment le retrouver ?</a></h6>
                </div>
                <div class="collapse" id="forgot-password" data-parent="#accordion-profile" role="tabpanel">
                    <div class="card-body">
                        <p>Pas de panique, vous pouvez cliquer sur <span>Mot de passe oublié</span> dans l'espace de connexion pour définir un nouveau mot de passe.</p>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" role="tab">
                    <h6><a class="collapsed" href="#change-mail" data-toggle="collapse">Est-ce que je peux modifier mon adresse mail ?</a></h6>
                </div>
                <div class="collapse" id="change-mail" data-parent="#accordion-profile" role="tabpanel">
                    <div class="card-body">
                        <p>Non, vous ne pouvez pas changer votre adresse mail associée à votre compte.</p>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" role="tab">
                    <h6><a class="collapsed" href="#change-password" data-toggle="collapse">Est-ce que je peux modifier mon mot de passe une fois connecté ?</a></h6>
                </div>
                <div class="collapse" id="change-password" data-parent="#accordion-profile" role="tabpanel">
                    <div class="card-body">
                        <p>Oui, vous pouvez le faire en allant dans <span>Mon Profil > Changer mon mot de passe</span> ou directement <router-link to="/profile/change-password">sur ce lien</router-link>.</p>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" role="tab">
                    <h6><a class="collapsed" href="#manage-addresses" data-toggle="collapse">Où puis-je gérer mes adresses de facturation / livraison ?</a></h6>
                </div>
                <div class="collapse" id="manage-addresses" data-parent="#accordion-profile" role="tabpanel">
                    <div class="card-body">
                        <p>Pour ajouter, modifier ou supprimer une de vos adresses de facturation / livraison, vous devez vous rendre sur <span>Mon Profil > Mes adresses</span> ou directement <router-link to="/profile/addresses">sur ce lien</router-link>.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HelpProfile",
};
</script>

<style scoped>
    .card-body span {
        font-weight: 600;
    }
</style>