<template>
    <div id="loading" class="text-center">
        <OrbitSpinner id="icon" :animation-duration="1000" :size="iconSize" :color="shopPrimaryColor"/>
        <span v-if='text' :style="{fontSize: this.fontSize + 'px'}">{{text}}</span>
    </div>
</template>

<script>
import { OrbitSpinner } from "epic-spinners";
import { mapGetters } from "vuex";

export default {
    name: "APILoading",
    components: {OrbitSpinner},
    props: {
        text: {
            type: String,
            required: true,
        },
        iconSize: {
            type: Number,
            default: 50,
        },
        fontSize: {
            type: Number,
            default: 10,
        },
    },
    computed: {
        ...mapGetters("config", {
            shopPrimaryColor: "shopPrimaryColor",
        }),
    },
};
</script>

<style scoped>
    #loading {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #icon {
        margin: 5px;
    }
</style>