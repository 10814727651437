<template>
    <div>
        <div class="card-header text-lg" v-html="$t('CheckoutCompletePayBillByCreditCard.youPayedByCreditCard')"/>
        <div class="card-body">
            <div class="row align-items-center">
                <div class="col-1">
                    <i class="far fa-3x fa-credit-card text-info"/>
                </div>
                <div key="creditCard" class="col-10">
                    <h4 class="card-title" v-html="$t('CheckoutCompletePayBillByCreditCard.howByCreditCard')"/>
                </div>
            </div>
            <div class="row justify-content-center">
                <div class="col-8">
                    <StripePayment :order="order" :forced-loading="loading" @payment-intent="pay"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import StripePayment from "../../../shared/Order/StripePayment";

export default {
    name: "CheckoutCompletePayBillByCreditCard",
    components: {StripePayment},
    props: {
        order: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        ...mapGetters("config", {
            shopPrimaryColor: "shopPrimaryColor",
        }),
    },
    methods: {
        async pay(paymentIntent) {
            try {
                this.loading = true;
                await this.$hermesAPI.payOrder(this.order._id, {
                    billing: { method: "creditCard" },
                    creditCard: { paymentIntentID: paymentIntent.id },
                });
                this.loading = false;
                await Swal.fire({
                    icon: "success",
                    title: this.$t("CheckoutCompletePayBillByCreditCard.orderPayed"),
                    text: this.$t("CheckoutCompletePayBillByCreditCard.paymentAccepted"),
                    confirmButtonColor: this.shopPrimaryColor,
                    confirmButtonText: this.$t("CheckoutCompletePayBillByCreditCard.close"),
                    footer: `<span>${this.$t("CheckoutCompletePayBillByCreditCard.youWillBeRedirect")}</span>`,
                });
                await this.$router.push("/profile/orders");
            } catch (err) {
                this.$error.displayError(err);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped>

</style>