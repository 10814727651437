<template>
    <div class="modal fade" id="upload-product-files-modal" tabindex="-1" role="dialog" aria-hidden="true"
         data-backdrop="static" data-keyboard="false">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-html="$t('UploadProductFilesModal.title', product)"/>
                </div>
                <div class="modal-body">
                    <UploadProductFiles ref="uploadProductFiles" :product="product"
                                        @files-uploaded="filesUploaded" @uploading-files="loading = true"
                                        @upload-error="loading = false"/>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" :disabled="loading"
                            v-html="$t('UploadProductFilesModal.close')"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UploadProductFiles from "./UploadProductFiles";
import Product from "../../../../../classes/Product";

export default {
    name: "UploadProductFilesModal",
    components: { UploadProductFiles },
    data() {
        return {
            product: new Product(),
            loading: false,
        };
    },
    methods: {
        show(product) {
            this.product = new Product(product);
            this.$refs.uploadProductFiles.reset();
            // eslint-disable-next-line no-undef
            $("#upload-product-files-modal").modal("show");
        },
        filesUploaded(product) {
            this.loading = false;
            this.product = new Product(product);
            this.$emit("update-product", product);
        },
    },
};
</script>

<style scoped>

</style>