<template>
    <div>
        <div class="row">
            <div class="col-12">
                <h4>
                    <i class="fas fa-map-pin mr-2 text-primary"/>
                    <span v-html="address.name"/>
                </h4>
                <hr class="mt-1 mb-4"/>
            </div>
        </div>
        <div class="row justify-content-center">
            <div v-if="!isAddressValid && currentRoute.name == 'checkoutShipping'" class="col-12 text-center mt-4">
              <h4 class="isAddressValid">
                <i class="fas fa-exclamation-triangle text-warning"/>
                {{$t("AddressSection.missingAddressType")}}
                <router-link to="/profile/addresses">
                  <button type="button" class="btn btn-primary">{{$t("AddressSection.manageMyAddresses")}}</button>
                </router-link>
                </h4>
            </div>
            <div class="col-6">
                <h6>
                    <span class="fas fa-map-marked-alt mr-2 text-primary"/>
                    <span v-html="$t('AddressSection.generalInfo')"/>
                </h6>
                <ul class="list-unstyled text-sm">
                    <li v-if="addressCompany">
                      <span class='text-muted mr-1' v-html="`${$t('AddressSection.company')}:`"/>
                      <span v-html="addressCompany"/>
                    </li>
                    <li v-if="addressIndividual">
                      <span class='text-muted mr-1' v-html="`${$t('AddressSection.individual')}:`"/>
                      <span v-html="addressIndividual"/>
                    </li>
                    <li>
                        <span class='text-muted mr-1' v-html="`${$t('AddressSection.street')}:`"/>
                        <span v-html="addressStreet"/>
                    </li>
                    <li>
                        <span class='text-muted mr-1' v-html="`${$t('AddressSection.zipCode')}:`"/>
                        <span v-html="addressZipCode"/>
                    </li>
                    <li>
                        <span class='text-muted mr-1' v-html="`${$t('AddressSection.city')}:`"/>
                        <span v-html="address.city"/>
                    </li>
                    <li>
                        <span class='text-muted mr-1' v-html="`${$t('AddressSection.country')}:`"/>
                        <span v-html="address.country.name"/>
                    </li>
                </ul>
            </div>
            <div class="col-6">
                <h6>
                    <span class="fas fa-list-ul mr-2 text-primary"/>
                    <span v-html="$t('AddressSection.moreInfo')"/>
                </h6>
                <ul class="list-unstyled text-sm">
                    <li>
                        <span class='text-muted mr-1' v-html="`${$t('AddressSection.mobilePhone')}:`"/>
                        <span v-html="addressMobilePhone"/>
                    </li>
                    <li>
                        <span class='text-muted mr-1' v-html="`${$t('AddressSection.GLN')}:`"/>
                        <span v-html="addressGLN"/>
                    </li>
                    <li>
                        <span class='text-muted mr-1' v-html="`${$t('AddressSection.VATIN')}:`"/>
                        <span v-html="addressVATIN"/>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AddressSection",
    props: {
        address: {
            required: true,
            type: Object,
        },
    },
    computed: {
        addressStreet() {
            return this.address.streetBis ? `${this.address.street}, ${this.address.streetBis}` : this.address.street;
        },
        addressZipCode() {
            return this.address.zipCode ? this.address.zipCode : this.$t("AddressSection.none");
        },
        addressMobilePhone() {
            return this.address.mobilePhone ? this.address.mobilePhone : this.$t("AddressSection.none");
        },
        addressGLN() {
            return this.address.gln ? this.address.gln : this.$t("AddressSection.none");
        },
        addressVATIN() {
            return this.address.vatin ? this.address.vatin : this.$t("AddressSection.none");
        },
        addressCompany() {
            return this.address.company ? this.address.company : null;
        },
        addressIndividual() {
            if (this.address.firstname && this.address.lastname) {
                return `${this.address.firstname} ${this.address.lastname}`;
            } else {
                return null;
            }
        },
        isAddressValid() {
            return this.addressCompany || this.addressIndividual;
        },
        currentRoute() {
            return this.$route;
        },
    },
};
</script>

<style scoped>
  .isAddressValid {
    color: orangered;
  }
</style>