<template>
    <div class="container">
        <section class="row align-items-center padding-top-2x padding-bottom-2x text-justify">
            <p class="mb-4">Vous êtes sur le site de vente en ligne de Gutenberg & Co. Ce site fonctionne essentiellement en Print & Collect.</p>
            <p class="mb-4">Gutenberg & Co. est une entreprise issue du monde de l’imprimerie de livres, qui a conçu et maintenant commercialise et exploite le robot-imprimeur Gutenberg.one.</p>
            <p class="mb-4">Pour concilier la diversité croissante des fonds éditoriaux, et l’exigence de disponibilité immédiate des produits, nous avons choisi de relever le défi de l’impression à l’unité et en librairie.</p>
            <p class="mb-4">Nous avons conçu et fabriqué le robot Gutenberg One qui devient la plus petite imprimerie de livre au monde. Nous développons en collaboration avec les éditeurs le catalogue des souvrages disponibles à l’impression.</p>
            <p class="mb-4">En n’imprimant un livre qu’à la demande du lecteur, nous avons considérablement simplifié la logistique du livre à faible titage. Le choix d’imprimer exclusivement sur papier recyclé complète la démarche de transition écologique que nous avons.</p>
            <p class="mb-4 text-less-bold">Positionnée au carrefour des préoccupations des auteurs, des éditeurs, des libraires et des lecteurs, Gutenberg.one capitalise sur les apports des technologies numériques et robotiques pour offrir une gamme de services et de produits à la fois performants et éco- responsables.</p>
            <p class="mb-4 text-less-bold">Plus d’informations sur <a target="_blank" href="https://gutenbergandco.com">gutenbergandco.com</a></p>
        </section>
    </div>
</template>

<script>
export default {
    name: "AboutGutenberg",
};
</script>

<style scoped>

</style>