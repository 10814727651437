<template>
    <div class="page-title">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-1">
                    <slot name="left"/>
                </div>
                <div class="col-10">
                    <h1 class="title" v-html="this.title"/>
                </div>
                <div class="col-1">
                    <slot name="right"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PageTitle",
    props: {
        title: {
            type: String,
            required: true,
            default: "Page title",
        },
    },
};
</script>

<style scoped>
    .page-title {
        padding-top: 30px !important;
        padding-bottom: 30px !important;
    }

    .title {
        margin-bottom: 0 !important;
    }
</style>