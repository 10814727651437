<template>
    <v-tour name="mainTour" :steps="steps" :options="options" :callbacks="callbacks">
        <template slot-scope="tour">
            <transition name="fade">
                <v-step v-if="tour.steps[tour.currentStep]" :key="tour.currentStep" :step="tour.steps[tour.currentStep]"
                        :previous-step="tour.previousStep" :next-step="tour.nextStep" :stop="tour.stop"
                        :is-first="tour.isFirst" :is-last="tour.isLast" :labels="tour.labels">
                </v-step>
            </transition>
        </template>
    </v-tour>
</template>

<script>
import { mapGetters } from "vuex";
import { tourOptions } from "../../../helpers/v-tour";

export default {
    name: "MainTour",
    data() {
        return {
            options: tourOptions,
            callbacks: {
                onPreviousStep: this.onPreviousStep,
                onNextStep: this.onNextStep,
                onStop: this.onStop,
            },
        };
    },
    computed: {
        ...mapGetters("config", {
            shopName: "shopName",
            frontConfig: "front",
        }),
        ...mapGetters("user", {
            isUserLogged: "isLogged",
            userCart: "cart",
        }),
        steps() {
            const steps = [
                { target: "#logo", content: `${this.$t("MainTour.welcomeOnShop", { shop: this.shopName })}<br/>${this.$t("MainTour.youCanUseArrows")}` },
                { target: ".site-menu", content: this.$t("MainTour.theMainNav") },
                { target: "#main-nav-home", content: `<i class='fa fa-home text-center fa-3x mb-2'></i><br/>${this.$t("MainTour.youAreOnHomePage")}` },
                { target: "#main-nav-shop", content: `<i class='fa fa-book-open text-center fa-3x mb-2'></i><br/>${this.$t("MainTour.theShopPage")}` },
            ];
            if (this.isUserLogged) {
                steps.push({ target: "#main-nav-user-cart", content: `<i class='fa fa-shopping-bag text-center fa-3x mb-2'></i><br/>${this.$t("MainTour.theUserCartPage")}` });
                if (this.userCart && this.userCart.length) {
                    steps.push({ target: "#main-nav-checkout", content: `<i class='fa fa-shopping-cart text-center fa-3x mb-2'></i><br/>${this.$t("MainTour.theCheckoutPage")}` });
                }
                steps.push({ target: "#main-nav-profile", content: `<i class='fa fa-user text-center fa-3x mb-2'></i><br/>${this.$t("MainTour.theProfilePage")}` });
            }
            steps.push({ target: "#header-search", content: `<i class='fa fa-search text-center fa-3x mb-2'></i><br/>${this.$t("MainTour.theHeaderSearch")}` });
            steps.push({ target: "#header-account", content: `<i class='fa fa-user text-center fa-3x mb-2'></i><br/>${this.$t("MainTour.theHeaderAccount")}` });
            steps.push({ target: "#header-user-cart", content: `<i class='fa fa-shopping-bag text-center fa-3x mb-2'></i><br/>${this.$t("MainTour.theHeaderUserCart")}` });
            steps.push({ target: "#footer-change-currency", content: `<i class='fa fa-euro-sign text-center fa-3x mb-2'></i><br/>${this.$t("MainTour.theFooterChangeCurrency")}` });
            steps.push({ target: "#footer-payment-methods", content: `<i class='fa fa-credit-card text-center fa-3x mb-2'></i><br/>${this.$t("MainTour.theFooterPaymentMethods")}` });
            steps.push({ target: "#footer-delivery-methods", content: `<i class='fa fa-truck text-center fa-3x mb-2'></i><br/>${this.$t("MainTour.theFooterDeliveryMethods")}` });
            steps.push({ target: "#main-nav-help", content: `<i class='fa fa-question-circle text-center fa-3x mb-2'></i><br/>${this.$t("MainTour.theHelpPage")}` });
            return steps;
        },
    },
    methods: {
        onPreviousStep() {
            this.onCurrentStep(this.$tours.mainTour.currentStep - 1);
        },
        onNextStep() {
            this.onCurrentStep(this.$tours.mainTour.currentStep + 1);
        },
        onCurrentStep(stepIdx) {
            if (stepIdx < 0 || stepIdx >= this.steps.length) {
                return;
            }
            const step = this.steps[stepIdx];
            if (step.target === "#main-nav-shop") {
                this.$router.push("/shop");
            } else if (step.target === "#main-nav-user-cart") {
                this.$router.push("/cart");
            } else if (step.target === "#main-nav-checkout") {
                this.$router.push("/checkout");
            } else if (step.target === "#main-nav-profile") {
                this.$router.push("/profile");
            } else if (step.target === "#main-nav-help") {
                this.$router.push("/");
            }
        },
        onStop() {
            localStorage.setItem("mainTourDone", true);
        },
    },
};
</script>

<style scoped>

</style>