<template>
    <div id="self-publishing-flow-quote-footer-gutenberg">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <div class="card-header bg-primary text-white text-center">
                        <i class="fa fa-star mr-2"/>
                        <span>Prestations supplémentaires</span>
                    </div>
                    <div class="card-body bg-white">
                        <div class="row">
                            <div class="col-6">
                                <h6 class="text-primary">Prestations de l'édition</h6>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="custom-control custom-checkbox custom-control-inline c-pointer">
                                            <input class="custom-control-input" type="checkbox" id="custom-quote-isbn"/>
                                            <label class="custom-control-label c-pointer"
                                                   for="custom-quote-isbn">ISBN</label>
                                            <v-popover>
                                                <a class="text-sm font-italic ml-2" href="#" @click.prevent>
                                                    <i class="fa fa-question-circle mr-2"/>
                                                </a>
                                                <template slot="popover">
                                                    <div>Je suis une bulle d'aide pour cette option</div>
                                                </template>
                                            </v-popover>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="custom-control custom-checkbox custom-control-inline c-pointer">
                                            <input class="custom-control-input" type="checkbox" id="custom-quote-bnf"/>
                                            <label class="custom-control-label c-pointer" for="custom-quote-bnf">
                                                Dépôt BNF (et/ou Electre)
                                            </label>
                                            <v-popover>
                                                <a class="text-sm font-italic ml-2" href="#" @click.prevent>
                                                    <i class="fa fa-question-circle mr-2"/>
                                                </a>
                                                <template slot="popover">
                                                    <div>Je suis une bulle d'aide pour cette option</div>
                                                </template>
                                            </v-popover>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="custom-control custom-checkbox custom-control-inline c-pointer">
                                            <input class="custom-control-input" type="checkbox"
                                                   id="custom-quote-dilicom-indexing"/>
                                            <label class="custom-control-label c-pointer"
                                                   for="custom-quote-dilicom-indexing">
                                                Référencement Dilicom
                                            </label>
                                            <v-popover>
                                                <a class="text-sm font-italic ml-2" href="#" @click.prevent>
                                                    <i class="fa fa-question-circle mr-2"/>
                                                </a>
                                                <template slot="popover">
                                                    <div>Je suis une bulle d'aide pour cette option</div>
                                                </template>
                                            </v-popover>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="custom-control custom-checkbox custom-control-inline c-pointer">
                                            <input class="custom-control-input" type="checkbox"
                                                   id="custom-quote-shops-indexing"/>
                                            <label class="custom-control-label c-pointer"
                                                   for="custom-quote-shops-indexing">
                                                Référencement sur les boutiques
                                            </label>
                                            <v-popover>
                                                <a class="text-sm font-italic ml-2" href="#" @click.prevent>
                                                    <i class="fa fa-question-circle mr-2"/>
                                                </a>
                                                <template slot="popover">
                                                    <div>Je suis une bulle d'aide pour cette option</div>
                                                </template>
                                            </v-popover>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <h6 class="text-primary">Prestations techniques</h6>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="custom-control custom-checkbox custom-control-inline c-pointer">
                                            <input class="custom-control-input" type="checkbox"
                                                   id="custom-quote-content-control"/>
                                            <label class="custom-control-label c-pointer"
                                                   for="custom-quote-content-control">Contrôle du contenu</label>
                                            <v-popover>
                                                <a class="text-sm font-italic ml-2" href="#" @click.prevent>
                                                    <i class="fa fa-question-circle mr-2"/>
                                                </a>
                                                <template slot="popover">
                                                    <div>Je suis une bulle d'aide pour cette option</div>
                                                </template>
                                            </v-popover>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="custom-control custom-checkbox custom-control-inline c-pointer">
                                            <input class="custom-control-input" type="checkbox"
                                                   id="custom-quote-manual-layout"/>
                                            <label class="custom-control-label c-pointer"
                                                   for="custom-quote-manual-layout">Mise en page manuelle</label>
                                            <v-popover>
                                                <a class="text-sm font-italic ml-2" href="#" @click.prevent>
                                                    <i class="fa fa-question-circle mr-2"/>
                                                </a>
                                                <template slot="popover">
                                                    <div>Je suis une bulle d'aide pour cette option</div>
                                                </template>
                                            </v-popover>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="custom-control custom-checkbox custom-control-inline c-pointer">
                                            <input class="custom-control-input" type="checkbox"
                                                   id="custom-quote-text-corrections"/>
                                            <label class="custom-control-label c-pointer"
                                                   for="custom-quote-text-corrections">Corrections orthographiques et
                                                grammaticales</label>
                                            <v-popover>
                                                <a class="text-sm font-italic ml-2" href="#" @click.prevent>
                                                    <i class="fa fa-question-circle mr-2"/>
                                                </a>
                                                <template slot="popover">
                                                    <div>Je suis une bulle d'aide pour cette option</div>
                                                </template>
                                            </v-popover>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="custom-control custom-checkbox custom-control-inline c-pointer">
                                            <input class="custom-control-input" type="checkbox"
                                                   id="custom-quote-handmade-cover"/>
                                            <label class="custom-control-label c-pointer"
                                                   for="custom-quote-handmade-cover">1ère et 4eme de couverture</label>
                                            <v-popover>
                                                <a class="text-sm font-italic ml-2" href="#" @click.prevent>
                                                    <i class="fa fa-question-circle mr-2"/>
                                                </a>
                                                <template slot="popover">
                                                    <div>Je suis une bulle d'aide pour cette option</div>
                                                </template>
                                            </v-popover>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="custom-control custom-checkbox custom-control-inline c-pointer">
                                            <input class="custom-control-input" type="checkbox" id="custom-quote-epub"/>
                                            <label class="custom-control-label c-pointer" for="custom-quote-epub">Conversion
                                                possible en fichier epub</label>
                                            <v-popover>
                                                <a class="text-sm font-italic ml-2" href="#" @click.prevent>
                                                    <i class="fa fa-question-circle mr-2"/>
                                                </a>
                                                <template slot="popover">
                                                    <div>Je suis une bulle d'aide pour cette option</div>
                                                </template>
                                            </v-popover>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center mt-2">
                            <div class="col-6 align-items-center justify-content-center">
                                <router-link to="/contact" class="btn btn-primary btn-block">
                                    <span>Devis personnalisé</span>
                                    <i class="fa fa-chevron-right ml-3"/>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SelfPublishingFlowQuoteFooterGutenberg",
};
</script>

<style scoped>

</style>