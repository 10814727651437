<template>
    <div>
        <PageTitle id="shop-page-title" :title="shopTitle">
            <template v-slot:left>
                <i class="fa fa-book-open fa-3x text-primary"/>
            </template>
        </PageTitle>
        <div class="container padding-bottom-3x mb-1">
            <div class="row w-100">
                <div class="order-md-2" :class="shopClasses">
                    <transition name="fade" mode="out-in">
                        <div class="row justify-content-between mb-2 ml-1 mr-1 align-items-center"
                             v-if="this.products && this.products.length">
                            <div id="shop-total-result" class="pt-3 pb-1 pb-sm-3 text-sm text-center col-xs-12 text-sm-left">
                                <i>
                                    {{ $tc("shop.result", products.length, { displayed: products.length, count: pagination.total }) }}
                                    <a @click.prevent="showAdvancedSearchModal" class="text-primary text-less-bold" href="#">
                                        {{ $t("shop.accordingToYourResearch") }}
                                    </a>
                                    <br/>
                                    {{ $tc("shop.sortBySortOrder", products.length,  { sort: $t(`shop.${queries.sort}`), order: $t(`shop.${queries.order}`) }) }}
                                </i>
                            </div>
                            <select v-model="perPage" @change='changeQueryParam({ "per-page": perPage })'
                                    class="form-control col-lg-3 col-md-4 col-xs-12" id="shop-select-per-page">
                                <option v-for="pageOption in pageOptions" :key="pageOption.number" :value="pageOption.number">
                                    {{$t("shop.perPage", { number: pageOption.number })}}
                                </option>
                            </select>
                        </div>
                    </transition>
                    <ShopProductList :pagination="this.pagination" :products="this.products"
                                     @change-query-param="changeQueryParam" @show-advanced-search-modal="showAdvancedSearchModal"/>
                </div>
                <ShopMenu :queries="this.queries" :book-collections="bookCollections" :chosen-publisher="chosenPublisher"
                          @change-query-param="changeQueryParam" @show-advanced-search-modal="showAdvancedSearchModal"/>
            </div>
        </div>
        <ShopAdvancedSearchModal :queries="queries" :book-collections="bookCollections" ref="advancedSearchModal"
                                 @advanced-search="advancedSearch"/>
        <ShopTour/>
    </div>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import Config from "../../../../config";
import Product from "../../../classes/Product";
import PageTitle from "../../shared/Misc/PageTitle";
import ShopMenu from "./ShopMenu/ShopMenu";
import ShopProductList from "./ShopProductList/ShopProductList";
import ShopAdvancedSearchModal from "./ShopAdvancedSearchModal";
import ProductSearchQuery from "../../../classes/ProductSearchQuery";
import ShopTour from "./ShopTour";

export default {
    name: "Shop",
    components: {ShopTour, ShopAdvancedSearchModal, ShopProductList, PageTitle, ShopMenu},
    data() {
        return {
            products: null, pagination: null, perPage: 9,
            pageOptions: [
                { number: 15 },
                { number: 30 },
                { number: 90 },
                { number: 150 },
            ],
            bookCollections: null,
            queries: new ProductSearchQuery(),
        };
    },
    computed: {
        ...mapGetters("preferences", {
            chosenCurrency: "currency",
        }),
        ...mapGetters("user", {
            user: "user",
            isUserLogged: "isLogged",
            isUserAdmin: "isAdmin",
        }),
        ...mapGetters("publishers", {
            publishers: "publishers",
        }),
        ...mapGetters("config", {
            doesShopShowOnlyPODProducts: "doesShopShowOnlyPODProducts",
            frontConfig: "front",
        }),
        shopTitle() {
            if (this.publishers && this.publishers.length && this.queries.publisher) {
                return this.$t("shop.shopOfPublisher", { publisher: this.chosenPublisher.name });
            }
            return this.$t(`header.mainNav.${this.frontConfig.app.i18n.terms.shopSection}`);
        },
        chosenPublisher() {
            return this.publishers && this.publishers.length && this.queries.publisher ? this.publishers.find(publisher => publisher._id === this.queries.publisher) : null;
        },
        shopClasses() {
            return this.frontConfig.shop.products.areOnFullPage ? "col-xs-12" : "col-lg-9 col-md-8";
        },
    },
    async mounted() {
        if (!this.frontConfig.isShopSectionEnabled) {
            this.$toasted.error(this.$t("shop.youCantAccessThisPage"), { icon: "times" });
            return this.$router.push("/");
        }
        await this.getProducts();
        await this.getBookCollections();
    },
    methods: {
        async getBookCollections() {
            try {
                this.bookCollections = null;
                const { data } = await this.$hermesAPI.getBookCollections({ publisher: this.queries.publisher, active: true });
                this.bookCollections = data.bookCollections.filter(bookCollection => Config.bookCollections.showWithOneProduct || bookCollection.productCount > 1);
            } catch (err) {
                this.$error.displayError(err);
            }
        },
        async getProducts() {
            if (!this.chosenCurrency) {
                return;
            }
            this.initAndRetrieveQueryParams();
            try {
                this.queries.active = true;
                this.queries.currency = this.chosenCurrency;
                if (this.doesShopShowOnlyPODProducts) {
                    this.queries.pod = true;
                }
                this.queries.user = this.isUserLogged ? this.user._id : null;
                this.queries["is-publisher-for-testing-purposes"] = !this.isUserAdmin ? false : undefined;
                const query = JSON.parse(JSON.stringify(this.queries));
                for (const prop in query) {
                    if (query[prop] === undefined || query[prop] === "" || query[prop] === null) {
                        delete query[prop];
                    }
                }
                const response = await this.$hermesAPI.getProducts(query);
                this.products = response.data.products.map(product => new Product(product));
                this.pagination = response.data.pagination;
            } catch (err) {
                this.$error.displayError(err);
            }
        },
        changeQueryParam(queryParam) {
            if (queryParam["publisher"] && this.queries["publisher"] !== queryParam["publisher"]) {
                this.queries = new ProductSearchQuery();
            } else if (queryParam["per-page"] !== undefined) {
                queryParam.page = 1;
            }
            this.queries = _.merge({}, this.queries, queryParam);
            this.$router.replace({query: new ProductSearchQuery(this.queries, true)});
        },
        initAndRetrieveQueryParams() {
            this.products = null;
            this.pagination = null;
            this.queries = this.$route.query.search === "reset" ? _.merge({}, this.queries, this.$route.query) : _.merge({}, new ProductSearchQuery(), this.$route.query);
            this.queries.source = "publisher";
            this.perPage = this.queries["per-page"];
            if (this.$route.query.modal === "advanced-search") {
                this.showAdvancedSearchModal();
            }
            if (!this.chosenPublisher) {
                this.queries.publisher = null;
            }
            this.$router.replace({ query: new ProductSearchQuery(this.queries, true) });
        },
        addToCart(product) {
            this.$emit("add-to-cart", product);
        },
        showAdvancedSearchModal() {
            this.$refs.advancedSearchModal.show();
        },
        advancedSearch(query) {
            this.queries = new ProductSearchQuery(query);
            this.$router.replace({ query: new ProductSearchQuery(this.queries, true) });
        },
    },
    watch: {
        "$route.query"(current, previous) {
            if (current !== previous) {
                this.getProducts();
                if (current.publisher !== previous.publisher) {
                    this.getBookCollections();
                }
            }
        },
        chosenCurrency() {
            this.getProducts();
        },
        isUserLogged() {
            this.getProducts();
        },
    },
};
</script>

<style scoped>

</style>