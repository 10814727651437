<template>
    <div id="checkout-shipping-withdrawal-point" class="tab-pane fade"
         :class="{ show: chosenMethod, active: chosenMethod }" role="tabpanel">
        <CheckoutStep :step="3" :text="chooseWithdrawalPointText" icon="store"/>
        <div v-if="withdrawalPoints.length" class="table-responsive">
            <table class="table table-hover">
                <thead class="thead-default">
                <tr>
                    <th/>
                    <th v-html="withdrawalPointLocalisationText"/>
                    <th v-html="$t('checkout.shipping.priceWT')"/>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(withdrawalPoint, idx) in withdrawalPoints" class="withdrawal-point"
                    @click="selectWithdrawalPoint(withdrawalPoint)" :key="withdrawalPoint._id">
                    <td class="align-middle">
                        <div class="custom-control custom-radio mb-0">
                            <input :value="withdrawalPoint._id" :id="`withdrawal-point-${idx}`"
                                   class="custom-control-input" type="radio" name="withdrawalPoint"
                                   v-model="addressIdSelected">
                            <label class="custom-control-label" :for="`withdrawal-point-${idx}`"/>
                        </div>
                    </td>
                    <td class="align-middle">
                        <span class="text-medium" v-html="withdrawalPoint.name"/>
                        <br/>
                        <span class="text-muted text-sm">
                            {{withdrawalPoint.address.street}} {{withdrawalPoint.address.streetBis}},
                            {{withdrawalPoint.address.zipCode}} {{withdrawalPoint.address.city}} - {{withdrawalPoint.address.country.name}}
                        </span>
                    </td>
                    <td class="align-middle" v-html="getPrice(withdrawalPoint)"/>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="row mt-2" v-else>
            <div class="col-12">
                <h3 class="text-center text-muted">
                    <i class="fa fa-store mr-2"/>
                    <span v-html="noWithdrawalPointText"/>
                </h3>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { properPrice } from "../../../../helpers/price";
import CheckoutStep from "../../../shared/Order/CheckoutStep";

export default {
    name: "CheckoutShippingWithdrawalPoints",
    components: {CheckoutStep},
    props: {
        order: {
            type: Object,
            required: true,
        },
        withdrawalPoints: {
            required: true,
        },
        addressId: {
            required: true,
        },
    },
    data() {
        return {
            addressIdSelected: this.addressId,
            chosenMethod: this.order.delivery.method === "withdrawalPoint",
        };
    },
    computed: {
        ...mapGetters("currencies", {
            currencies: "currencies",
        }),
        ...mapGetters("preferences", {
            chosenCurrency: "currency",
        }),
        ...mapGetters("config", {
            frontConfig: "front",
        }),
        chooseWithdrawalPointText() {
            const withdrawalPointTerm = this.frontConfig.app.i18n.terms.withdrawalPoint;
            return withdrawalPointTerm === "withdrawalPoint" ? this.$t("checkout.shipping.chooseWithdrawalPoint") : this.$t("checkout.shipping.chooseProvisionPlace");
        },
        withdrawalPointLocalisationText() {
            const withdrawalPointTerm = this.frontConfig.app.i18n.terms.withdrawalPoint;
            return withdrawalPointTerm === "withdrawalPoint" ? this.$t("checkout.shipping.withdrawalPointLocalisation") : this.$t("checkout.shipping.provisionPlace");
        },
        noWithdrawalPointText() {
            const withdrawalPointTerm = this.frontConfig.app.i18n.terms.withdrawalPoint;
            return withdrawalPointTerm === "withdrawalPoint" ? this.$t("checkout.shipping.noWithdrawalPointYet") : this.$t("checkout.shipping.noProvisionPlaceYet");
        },
    },
    methods: {
        properPrice,
        getPrice(withdrawalPoint) {
            const chargedBy = withdrawalPoint.chargedBy === "order" ? this.$t("checkout.shipping.withdrawalPoints.perOrder") : this.$t("checkout.shipping.withdrawalPoints.perProduct");
            let price = this.properPrice(withdrawalPoint.priceWT, this.chosenCurrency, this.currencies);
            if (withdrawalPoint.priceWT) {
                price += ` ${chargedBy}`;
            }
            return price;
        },
        selectWithdrawalPoint(withdrawalPoint) {
            this.addressIdSelected = withdrawalPoint._id;
            this.$emit("set-withdrawal-point-address", withdrawalPoint);
        },
    },
    watch: {
        addressId(value) {
            this.addressIdSelected = value;
        },
    },
};
</script>

<style scoped>
    .withdrawal-point {
        cursor: pointer;
    }
</style>