<template>
    <div>
        <PageTitle :title="$t('cart.title')">
            <template v-slot:left>
                <i class="fa fa-shopping-bag fa-3x text-primary"/>
            </template>
        </PageTitle>
        <transition name="fade" mode="out-in">
            <div key="loadingCart" class="loading" v-if="userCart === null">
                <APILoading :text="$t('cart.loadingCart')" :font-size="24"/>
            </div>
            <CartDetails key="cart" v-else-if="userCart.length"/>
            <div class="row justify-content-center" key="emptyCart" v-else id="noProductContainer">
                <div class="col-12">
                    <h2>{{$t("header.headerShopCart.noProduct")}}</h2>
                </div>
                <div class="col-lg-3 col-md-8">
                    <img alt="Add to cart" id="add-to-cart-svg" :src="addToCartSVG">
                </div>
                <div class="col-12 m-3">
                    <router-link v-if="frontConfig.isShopSectionEnabled" class="btn btn-primary" to="/shop"
                                 v-html="$t('header.headerShopCart.goToShop')"/>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import PageTitle from "../../shared/Misc/PageTitle";
import CartDetails from "./CartDetails";
import APILoading from "../../shared/Misc/APILoading";
import { importSVG } from "../../../helpers/file";

export default {
    name: "Cart",
    components: {APILoading, CartDetails, PageTitle},
    computed: {
        ...mapGetters("user", {
            userCart: "cart",
        }),
        ...mapGetters("config", {
            hermesAPIConfig: "hermesAPI",
            frontConfig: "front",
        }),
        addToCartSVG() {
            return importSVG("add_to_cart", this.hermesAPIConfig.client);
        },
    },
};
</script>

<style scoped lang="scss">
    .loading {
        height: 70vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #noProductContainer {
        text-align: center;
    }
</style>