<template>
    <div id="product-components-info">
        <ul class="text-sm text-left">
            <li v-if="product.components.binding.name">
                <span class="text-less-bold mr-1" v-html="`${$t('ProductCreated.bookSummary.binding')}:`"/>
                <span v-html="product.components.binding.name"/>
            </li>
            <li v-if="product.components.cover.paper.name">
                <span class="text-less-bold mr-1" v-html="`${$t('ProductCreated.bookSummary.cover.paper')}:`"/>
                <span v-html="product.components.cover.paper.name"/>
            </li>
            <li v-if="product.components.cover.printing.bothSides !== undefined">
                <span class="text-less-bold mr-1" v-html="`${$t('ProductCreated.bookSummary.cover.printing.label')}:`"/>
                <span v-html="this.coverPrintingText"/>
            </li>
            <li v-if="product.components.cover.lamination.name">
                <span class="text-less-bold mr-1" v-html="`${$t('ProductCreated.bookSummary.lamination')}:`"/>
                <span v-html="product.components.cover.lamination.name"/>
            </li>
            <li v-if="product.components.corpus.paper.name">
                <span class="text-less-bold mr-1" v-html="`${$t('ProductCreated.bookSummary.corpus.paper')}:`"/>
                <span v-html="product.components.corpus.paper.name"/>
            </li>
            <li v-if="product.components.corpus.printing.name">
                <span class="text-less-bold mr-1" v-html="`${$t('ProductCreated.bookSummary.corpus.printing')}:`"/>
                <span v-html="product.components.corpus.printing.name"/>
            </li>
        </ul>
    </div>
</template>

<script>
import Product from "@/classes/Product";

export default {
    name: "ProductComponentsInfoList",
    props: {
        product: {
            type: Product,
            required: true,
        },
    },
    computed: {
        coverPrintingText() {
            if (this.product.components.cover.printing.bothSides) {
                return this.$t("ProductCreated.bookSummary.cover.printing.bothSides");
            } else if (this.product.components.cover.printing.bothSides === false) {
                return this.$t("ProductCreated.bookSummary.cover.printing.oneSide");
            } else {
                return "";
            }
        },
    },
};
</script>

<style scoped>

</style>