<template>
    <div id="ilp-home">
        <ILPHomeDisclaimer/>
        <HomeNewProducts/>
    </div>
</template>

<script>
import ILPHomeDisclaimer from "./ILPHomeDisclaimer";
import HomeNewProducts from "../../HomeNewProducts/HomeNewProducts";

export default {
    name: "ILPHome",
    components: { HomeNewProducts, ILPHomeDisclaimer },
};
</script>

<style scoped>

</style>