/**
 *
 * @param obj
 * @param properties Needs to be a string (e.g: "property1.property2.etc")
 * @returns {boolean} If object has property or not
 */
export function hasProp(obj, properties) {
    if (obj === null || obj === undefined) {
        return false;
    }
    properties = properties.split(".");
    for (const property of properties) {
        if (obj[property] === undefined) {
            return false;
        }
        obj = obj[property];
    }
    return true;
}

export function sanitize(obj) {
    if (obj === null || obj === undefined) {
        return null;
    }
    Object.keys(obj).forEach(key => {
        if (obj[key] && typeof obj[key] === "object") {
            sanitize(obj[key]);
        } else if (obj[key] === undefined) {
            delete obj[key];
        }
    });
    return obj;
}