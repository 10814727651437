<template>
    <div v-tooltip="productFileStatusBadgeTooltip" class="badge p-2" :class="productFileStatusBadgeClasses">
        <div class="text-center text-less-bold">
            <i class="mr-2" :class="productFileStatusBadgeIcon"/>
            <span v-html="$t(`ProductFilesStatusBadge.${bookPart}`)"/>
            <i class="fa fa-question-circle ml-2"/>
        </div>
        <hr class="my-1"/>
        <div v-html="productFileStatusBadgeText"/>
    </div>
</template>
<script>
import Product from "@/classes/Product";

export default {
    name: "ProductFileStatusBadge",
    props: {
        product: {
            type: Product,
            required: true,
        },
        bookPart: {
            type: String,
            required: true,
        },
    },
    computed: {
        statuses() {
            return {
                "to-upload": {
                    classes: "badge-warning",
                    text: this.$t("ProductFilesStatusBadge.toUpload"),
                    icon: "fa fa-cloud-upload-alt",
                    tooltip: this.$t("ProductFilesStatusBadge.youMustUploadFile"),
                },
                "uploaded": {
                    classes: "badge-info",
                    text: this.$t("ProductFilesStatusBadge.uploaded"),
                    icon: "fa fa-cart-arrow-down",
                    tooltip: this.$t("ProductFilesStatusBadge.youMustOrderThisProduct"),
                },
                "waiting-for-corpus": {
                    classes: "badge-warning",
                    text: this.$t("ProductFilesStatusBadge.waitingForCorpus"),
                    icon: "fa fa-clock",
                    tooltip: this.$t("ProductFilesStatusBadge.fileWillBeCheckedWhenCorpusReady"),
                },
                "to-check": {
                    classes: "badge-info",
                    text: this.$t("ProductFilesStatusBadge.toCheck"),
                    icon: "fa fa-clock",
                    tooltip: this.$t("ProductFilesStatusBadge.fileWillBeCheckedAnytimeSoon"),
                },
                "checking": {
                    classes: "badge-info",
                    text: this.$t("ProductFilesStatusBadge.checking"),
                    icon: "fa fa-sync-alt fa-spin",
                    tooltip: this.$t("ProductFilesStatusBadge.fileIsCurrentlyChecked"),
                },
                "errored": {
                    classes: "badge-danger",
                    text: this.$t("ProductFilesStatusBadge.errored"),
                    icon: "fa fa-times",
                    tooltip: this.$t("ProductFilesStatusBadge.fileIsErrored"),
                },
                "waiting-for-client-approval": {
                    classes: "badge-warning",
                    text: this.$t("ProductFilesStatusBadge.waitingForClientApproval"),
                    icon: "fa fa-file-signature",
                    tooltip: this.$t("ProductFilesStatusBadge.youMustCheckPrintingApproval"),
                },
                "fixing-by-operator": {
                    classes: "badge-info",
                    text: this.$t("ProductFilesStatusBadge.fixingByOperator"),
                    icon: "fa fa-sync-alt fa-spin",
                    tooltip: this.$t("ProductFilesStatusBadge.fileBeingFixedByOperator"),
                },
                "approved-by-client": {
                    classes: "badge-success",
                    text: this.$t("ProductFilesStatusBadge.approvedByClient"),
                    icon: "fa fa-check-circle",
                    tooltip: this.$t("ProductFilesStatusBadge.youApprovedPrinting"),
                },
                "printable": {
                    classes: "badge-success",
                    text: this.$t("ProductFilesStatusBadge.printable"),
                    icon: "fa fa-check-circle",
                    tooltip: this.product.getOption("physical-printing-approval") ? this.$t("ProductFilesStatusBadge.yourFileIsPrintableWhenYouApproved") : this.$t("ProductFilesStatusBadge.yourFileIsPrintable"),
                },
            };
        },
        productFileStatusBadgeText() {
            return this.statuses[this.productFileStatus].text;
        },
        productFileStatusBadgeClasses() {
            return this.statuses[this.productFileStatus].classes;
        },
        productFileStatusBadgeIcon() {
            return this.statuses[this.productFileStatus].icon;
        },
        productFileStatusBadgeTooltip() {
            return this.statuses[this.productFileStatus].tooltip;
        },
        productFileStatus() {
            if (this.bookPart === "cover" &&
                (this.product.files.cover.status === "to-check" || this.product.files.cover.status === "checking") &&
                this.product.files.corpus.status !== "approved-by-client" && this.product.files.corpus.status !== "printable") {
                return "waiting-for-corpus";
            } else {
                return this.product.files[this.bookPart].status;
            }
        },
    },
};
</script>

<style scoped>

</style>