<template>
    <div>
        <div class="card-header text-lg">
            {{$t("CheckoutCompletePayBillByPayPal.youPayedByPayPal")}}
        </div>
        <div class="card-body">
            <div class="row align-items-center">
                <div class="col-1">
                    <img :src="paypalLogo"/>
                </div>
                <div v-if="loading" key="loading" class="col-10">
                    <APILoading :font-size="24" :text="$t('CheckoutCompletePayBillByPayPal.loadingPayment')"/>
                </div>
                <div v-show="!loading" key="paypal" class="col-10">
                    <h4 class="card-title">{{$t("CheckoutCompletePayBillByPayPal.howByPayPal")}}</h4>
                    <div id="paypal-button-container"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import paypalLogo from "../../../../assets/img/payments/paypal.png";
import Swal from "sweetalert2";
import APILoading from "../../../shared/Misc/APILoading";

export default {
    name: "CheckoutCompletePayBillByPayPal",
    components: {APILoading},
    props: {
        order: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            paypalLogo,
            loading: false,
        };
    },
    computed: {
        ...mapGetters("config", {
            shopPrimaryColor: "shopPrimaryColor",
        }),
    },
    mounted() {
    // eslint-disable-next-line no-undef
        paypal.Buttons({
            createOrder: async() => {
                const response = await this.$hermesAPI.createPayPalPayment(this.order._id);
                return response.data.orderID;
            },
            onApprove: async data => {
                try {
                    this.loading = true;
                    const response = await this.$hermesAPI.payOrder(this.order._id, {
                        billing: { method: "paypal" },
                        paypal: { orderID: data.orderID },
                    });
                    this.warnUserAboutPaymentSuccess(response.data);
                } catch (err) {
                    this.$error.displayError(err);
                } finally {
                    this.loading = false;
                }
            },
        }).render("#paypal-button-container");
    },
    methods: {
        warnUserAboutPaymentSuccess(order) {
            if (order.status === "acceptedPayment") {
                Swal.fire({
                    icon: "success",
                    title: this.$t("CheckoutCompletePayBillByPayPal.orderPayed"),
                    text: this.$t("CheckoutCompletePayBillByPayPal.paymentAccepted"),
                    confirmButtonColor: this.shopPrimaryColor,
                    confirmButtonText: this.$t("CheckoutCompletePayBillByPayPal.close"),
                    footer: `<span>${this.$t("CheckoutCompletePayBillByPayPal.youWillBeRedirect")}</span>`,
                }).then(() => {
                    this.$router.push("/profile/orders");
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: this.$t("CheckoutCompletePayBillByPayPal.error"),
                    text: this.$t("CheckoutCompletePayBillByPayPal.paymentError"),
                    confirmButtonColor: this.shopPrimaryColor,
                    confirmButtonText: this.$t("CheckoutCompletePayBillByPayPal.close"),
                });
            }
        },
    },
};
</script>

<style scoped>

</style>