<template>
    <div>
        <h3>
            <i class="fa fa-shopping-bag mr-2 text-primary"></i>
            {{ $t("HelpOrders.orders") }}
        </h3>
        <div class="accordion" id="accordion-orders" role="tablist">
            <div class="card">
                <div class="card-header" role="tab">
                    <h6>
                        <a href="#how-checkout" data-toggle="collapse" class="collapsed" aria-expanded="false">
                            Où puis-je passer commande ?
                        </a>
                    </h6>
                </div>
                <div class="collapse" id="how-checkout" data-parent="#accordion-orders" role="tabpanel">
                    <div class="card-body">
                        <p>Pour passer commande, vous devez ouvrir le panel du panier avec l'icône <i class="material-icons shopping_basket"></i> dans le menu en haut à droite et cliquer sur le bouton <span>Commander</span>.</p>
                        <p>Vous pouvez aussi le faire en cliquant sur <router-link to="/checkout">ce lien</router-link>.</p>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" role="tab">
                    <h6>
                        <a href="#how-pay" data-toggle="collapse" class="collapsed" aria-expanded="false">
                            Quels sont les moyens de paiement disponibles ?
                        </a>
                    </h6>
                </div>
                <div class="collapse" id="how-pay" data-parent="#accordion-orders" role="tabpanel">
                    <div class="card-body">
                        <p>Les moyens de paiement disponibles sur cette boutique sont affichés tout en bas à droite de cette page dans l'encart <span>Méthodes de paiement</span>.</p>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" role="tab">
                    <h6>
                        <a href="#where-are-orders" data-toggle="collapse" class="collapsed" aria-expanded="false">
                            Où retrouver mes commandes ?
                        </a>
                    </h6>
                </div>
                <div class="collapse" id="where-are-orders" data-parent="#accordion-orders" role="tabpanel">
                    <div class="card-body">
                        <p>Vous pouvez retrouver la liste de toutes vos commandes en allant dans <span>Mon Profil > Mes Commandes</span> ou directement en cliquant sur <router-link to="/profile/orders">ce lien</router-link>.</p>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" role="tab">
                    <h6>
                        <a href="#how-pay-order" data-toggle="collapse" class="collapsed" aria-expanded="false">
                            Comment régler ma commande ?
                        </a>
                    </h6>
                </div>
                <div class="collapse" id="how-pay-order" data-parent="#accordion-orders" role="tabpanel">
                    <div class="card-body">
                        <p>Dans le cas où vous avez réglé en espèce par exemple, vous pouvez retrouver la procédure de paiement en cliquant sur l'icône <i class="fa fa-question-circle text-warning"></i> dans la ligne correspondante à votre commande dans <span>Mon Profil > Mes Commandes</span>.</p>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" role="tab">
                    <h6>
                        <a href="#change-payment-method" data-toggle="collapse" class="collapsed" aria-expanded="false">
                            Est-ce que je peux changer la méthode de paiement de ma commande ?
                        </a>
                    </h6>
                </div>
                <div class="collapse" id="change-payment-method" data-parent="#accordion-orders" role="tabpanel">
                    <div class="card-body">
                        <p>
                            Si votre commande est <span>En attente de paiement</span> ou <span>Erreur de paiement</span>, vous pouvez changer sa méthode de paiement en cliquant sur le bouton avec l'icône
                            <span class="fa-stack">
                                <i class="fas fa-dollar-sign fa-stack-1x"></i>
                                <i id="redo-icon" class="fas fa-redo-alt fa-stack-2x"></i>
                            </span>
                        dans la liste de vos commandes.</p>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" role="tab">
                    <h6>
                        <a href="#cancel-order" data-toggle="collapse" class="collapsed" aria-expanded="false">
                            Est-ce que je peux annuler ma commande ?
                        </a>
                    </h6>
                </div>
                <div class="collapse" id="cancel-order" data-parent="#accordion-orders" role="tabpanel">
                    <div class="card-body">
                        <p>Si votre commande est <span>En attente de paiement</span> ou <span>Erreur de paiement</span>, vous pouvez l'annuler en cliquant sur le bouton avec l'icône <i class="fa fa-list-ul"></i> dans la liste de vos commandes.</p>
                        <p>Dans la fenêtre qui vient de s'ouvrir, cliquez sur le bouton <span>Annuler ma commande</span>.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HelpOrders",
};
</script>

<style scoped>
    .card-body span {
        font-weight: 600;
    }
</style>