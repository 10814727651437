<template>
    <div data-backdrop="static" data-keyboard="false" class="modal fade" id="order-change-payment-modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <form @submit.prevent="updatePayment">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ $t("ProfileOrdersChangePaymentModal.changePaymentForReference", { reference: this.order.reference }) }}</h5>
                    </div>
                    <div class="modal-body">
                        <div class="row align-items-center">
                            <div class="col-1 d-none d-lg-block">
                                 <span class="fa-stack">
                                    <i class="fas fa-dollar-sign text-success fa-stack-1x"></i>
                                    <i id="redo-icon" class="fas fa-redo-alt fa-stack-2x text-success"></i>
                                </span>
                            </div>
                            <div class="col-lg-10 col-md-12">
                                <h4>{{ $t("ProfileOrdersChangePaymentModal.youCanChangePayment") }}</h4>
                            </div>
                        </div>
                        <hr class="mb-2"/>
                        <div class="row">
                            <div class="col-12 text-center">
                                <h4 v-html="`${$t('ProfileOrdersChangePaymentModal.currentPaymentMethod')} ${currentBillingMethod}`"></h4>
                            </div>
                        </div>
                        <hr class="mb-2"/>
                        <div class="row align-items-center">
                            <div class="col-6">
                                <label for="change-payment">{{ $t("ProfileOrdersChangePaymentModal.changePayment") }}</label>
                            </div>
                            <div class="col-6">
                                <select id="change-payment" v-model="order.billing.method" class="form-control">
                                    <option v-for="method in methods" :key="method" :value="method">
                                        {{ $t(`ProfileOrdersChangePaymentModal.${method}`) }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <SubmitBtn classes="btn btn-success" :loading="loading" :disabled="currentMethod === order.billing.method" :text="$t('ProfileOrdersChangePaymentModal.updatePayment')"/>
                        <button :disabled="loading" type="button" class="btn btn-secondary" data-dismiss="modal">{{$t("ProfileOrdersChangePaymentModal.close")}}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Order from "../../../../classes/Order";
import SubmitBtn from "../../../shared/Misc/SubmitBtn";

export default {
    name: "ProfileOrdersChangePaymentModal",
    components: {SubmitBtn},
    data() {
        return {
            order: new Order(),
            methods: [],
            currentMethod: undefined,
            loading: false,
        };
    },
    created() {
        this.methods = this.generateBillingMethods();
    },
    methods: {
        show(order) {
            this.order = new Order(order);
            this.currentMethod = this.order.billing.method;
            // eslint-disable-next-line no-undef
            $("#order-change-payment-modal").modal("show");
        },
        generateBillingMethods() {
            const methods = [];
            if (this.isCashPaymentEnabled) {
                methods.push("cash");
            }
            if (this.isCheckPaymentEnabled) {
                methods.push("check");
            }
            if (this.isBankTransferPaymentEnabled) {
                methods.push("bankTransfer");
            }
            if (this.isOrangeMoneyPaymentEnabled) {
                methods.push("orangeMoney");
            }
            if (this.isWariPaymentEnabled) {
                methods.push("wari");
            }
            if (this.isCreditCardPaymentEnabled) {
                methods.push("creditCard");
            }
            if (this.isPayPalPaymentEnabled) {
                methods.push("paypal");
            }
            return methods;
        },
        async updatePayment() {
            try {
                this.loading = true;
                const response = await this.$hermesAPI.updateOrder({ _id: this.order._id, billing: { method: this.order.billing.method } });
                this.order = new Order(response.data);
                this.$emit("update-order", this.order);
                // eslint-disable-next-line no-undef
                $("#order-change-payment-modal").modal("hide");
                this.$toasted.success(this.$t("ProfileOrdersChangePaymentModal.paymentUpdated"), { icon: "check" });
            } catch (err) {
                this.$error.displayError(err);
            } finally {
                this.loading = false;
            }
        },
    },
    computed: {
        ...mapGetters("config", {
            isCashPaymentEnabled: "isCashPaymentEnabled",
            isCheckPaymentEnabled: "isCheckPaymentEnabled",
            isBankTransferPaymentEnabled: "isBankTransferPaymentEnabled",
            isOrangeMoneyPaymentEnabled: "isOrangeMoneyPaymentEnabled",
            isWariPaymentEnabled: "isWariPaymentEnabled",
            isCreditCardPaymentEnabled: "isCreditCardPaymentEnabled",
            isPayPalPaymentEnabled: "isPayPalPaymentEnabled",
        }),
        currentBillingMethod() {
            if (this.currentMethod === undefined) {
                return "";
            } else {
                return `<span class="text-primary">${this.$t(`ProfileOrdersChangePaymentModal.${this.currentMethod}`)}</span>`;
            }
        },
    },
};
</script>

<style scoped>
    #redo-icon {
        margin-left: 1px;
    }
</style>