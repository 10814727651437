import { render, staticRenderFns } from "./APILoading.vue?vue&type=template&id=e060d45c&scoped=true&"
import script from "./APILoading.vue?vue&type=script&lang=js&"
export * from "./APILoading.vue?vue&type=script&lang=js&"
import style0 from "./APILoading.vue?vue&type=style&index=0&id=e060d45c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e060d45c",
  null
  
)

export default component.exports