<template>
    <span class="d-flex justify-content-between align-items-center">
        <span class="d-flex align-items-center">
            <i v-if="hasAgreement" v-tooltip="$t('ShopMenuPublisher.hasAgreement')"
               class="far fa-handshake text-primary mr-2"/>
            <a class="publishers" :class="{ chosen }"
               @click.prevent="changeQueryParam(publisher._id)">
                {{ publisherName }}
            </a>
        </span>
        <span v-tooltip="countTooltip" class="badge badge-default">
            {{ publisherCount }}
        </span>
    </span>
</template>

<script>
import { mapGetters } from "vuex";
import Publisher from "../../../../../classes/Publisher";

export default {
    name: "ShopMenuPublisher",
    props: {
        publisher: {
            type: Object,
            default: () => new Publisher(),
        },
        chosen: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        changeQueryParam(publisherId) {
            this.$emit("change-query-param", { publisher: publisherId });
        },
    },
    computed: {
        ...mapGetters("publishers", {
            publishers: "publishers",
        }),
        ...mapGetters("user", {
            userPublisherAgreements: "publisherAgreements",
        }),
        publisherName() {
            return this.publisher.name ? this.publisher.name : this.$t("shop.shopMenu.shopMenuPublisher.all");
        },
        publisherCount() {
            const reducer = (accumulator, publisher) => accumulator + publisher.productCount;
            return this.publisher.name ? this.publisher.productCount : this.publishers.reduce(reducer, 0);
        },
        countTooltip() {
            const publisherTooltip = this.$tc("ShopMenuPublisher.publisherHas", this.publisherCount, { productCount: this.publisherCount });
            const shopTooltip = this.$tc("ShopMenuPublisher.shopHas", this.publisherCount, { productCount: this.publisherCount });
            return this.publisher.name ? publisherTooltip : shopTooltip;
        },
        hasAgreement() {
            return this.userPublisherAgreements.find(publisherId => publisherId === this.publisher._id);
        },
    },
};
</script>

<style scoped>
    .publishers {
        font-size: 1.05rem;
        cursor: pointer;
    }

    .chosen {
        font-weight: 600;
        font-size: 1.1rem;
    }
</style>