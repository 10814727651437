<template>
    <transition v-if="frontConfig.isShopSectionEnabled" mode="out-in" name="fade">
        <div key="loading" v-if="!products" id="loading">
            <APILoading :font-size='30' :text="$t('shared.apiLoading.getProducts')"/>
        </div>
        <div key="home" v-else id="home">
            <HomeCarousel :products="products"/>
            <ProductList :products="products"/>
        </div>
    </transition>
</template>

<script>
import { mapGetters } from "vuex";
import Product from "../../../../classes/Product";
import APILoading from "../../../shared/Misc/APILoading";
import HomeCarousel from "../HomeCarousel";
import ProductList from "../../../shared/ProductList/ProductList";

export default {
    name: "HomeNewProducts",
    components: {ProductList, HomeCarousel, APILoading},
    data() {
        return {
            products: null,
        };
    },
    computed: {
        ...mapGetters("preferences", {
            chosenCurrency: "currency",
        }),
        ...mapGetters("user", {
            user: "user",
            isUserLogged: "isLogged",
            isUserAdmin: "isAdmin",
        }),
        ...mapGetters("config", {
            doesShopShowOnlyPODProducts: "doesShopShowOnlyPODProducts",
            hermesAPIConfig: "hermesAPI",
            frontConfig: "front",
        }),
    },
    created() {
        this.getProducts();
    },
    methods: {
        async getProducts() {
            if (!this.chosenCurrency) {
                return;
            }
            try {
                this.products = null;
                const queries = {
                    active: true,
                    user: this.isUserLogged ? this.user._id : undefined,
                    "per-page": 50,
                    sort: "release-date",
                    order: "desc",
                    "is-publisher-for-testing-purposes": !this.isUserAdmin ? false : undefined,
                    currency: this.chosenCurrency,
                };
                if (this.doesShopShowOnlyPODProducts) {
                    queries.pod = true;
                }
                const response = await this.$hermesAPI.getProducts(queries);
                this.products = response.data.products.map(product => new Product(product));
            } catch (err) {
                this.$error.displayError(err);
            }
        },
        addToCart(product) {
            this.$emit("add-to-cart", product);
        },
    },
    watch: {
        chosenCurrency() {
            this.getProducts();
        },
        isUserLogged() {
            this.getProducts();
        },
    },
};
</script>

<style scoped>
    #loading {
        height: 100vh;
    }
</style>