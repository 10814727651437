<template>
    <button :class="{ minified }" v-if="canReviewProduct" @click.prevent="$emit('show-product-review-modal')"
            v-html="btnText" v-tooltip="minified && this.$t('AddProductReviewBtn.review')">
    </button>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "AddProductReviewBtn",
    props: {
        product: {
            type: Object,
            required: true,
        },
        text: {
            type: String,
        },
        minified: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters("user", {
            user: "user",
            isUserLogged: "isLogged",
        }),
        ...mapGetters("config", {
            areProductReviewsEnabled: "areProductReviewsEnabled",
        }),
        canReviewProduct() {
            return this.isUserLogged && this.product.alreadyOrdered && this.areProductReviewsEnabled &&
                !this.product.reviews.find(review => review.user._id === this.user._id) ;
        },
        btnText() {
            return this.minified || !this.text ? "<i class='fas fa-star'></i>" : this.text;
        },
    },
};
</script>

<style scoped>
    .minified {
        line-height: 14px;
        height: 20px;
        border-radius: 20px;
        width: 20px;
        font-size: 10px;
        padding: 0 0 0 1px;
    }
</style>