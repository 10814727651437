<template>
    <transition mode="out-in" name="fade">
        <APILoading id="loading" key="loading" v-if="!bookCollections" :font-size='12' :icon-size='20'
                    :text="$t('ShopMenuBookCollectionsFilter.loadingCollections')"/>
        <section id="shop-book-collections-section" key="book-collection-list" v-else-if="bookCollections.length > 1"
                 class="widget">
            <h3 class="widget-title d-flex align-items-center justify-content-between">
                <span>
                    <i class="fa fa-bookmark mr-2 text-primary"/>
                    <span>
                        {{ $t("ShopMenuBookCollectionsFilter.collections") }}
                        <span v-if="chosenPublisher">
                            {{ chosenPublisher.name }}
                        </span>
                    </span>
                </span>
                <span v-tooltip="bookCollectionCountTooltip" class="badge float-right" :class="bookCollectionCountClass">
                    {{ this.displayedBookCollections.length }}
                </span>
            </h3>
            <div class="input-group mb-3">
                <div class="input-group-addon">
                    <span class="input-group-text">
                        <i class="fa fa-filter pt-2"/>
                    </span>
                </div>
                <input class="form-control form-control-sm" v-model="filter"
                       :placeholder="$t('ShopMenuBookCollectionsFilter.filterCollectionName')"/>
            </div>
            <div v-if="bookCollections.length" id="book-collection-list">
                <div class="custom-control">
                    <ShopMenuBookCollection :key="null" :book-collection="null" :chosen="isBookCollectionChosen('')"
                                            :book-collections="bookCollections" :chosen-publisher="chosenPublisher"
                                            @change-query-param="changeQueryParam"/>
                </div>
                <div v-for="bookCollection in this.displayedBookCollections" :key="bookCollection.name" class="custom-control">
                    <ShopMenuBookCollection @change-query-param="changeQueryParam" :book-collection="bookCollection"
                                            :book-collections="bookCollections" :chosen-publisher="chosenPublisher"
                                            :chosen="isBookCollectionChosen(bookCollection.name)"/>
                </div>
            </div>
        </section>
    </transition>
</template>

<script>
import APILoading from "../../../../shared/Misc/APILoading";
import ShopMenuBookCollection from "./ShopMenuBookCollection";

export default {
    name: "ShopMenuBookCollectionsFilter",
    components: {ShopMenuBookCollection, APILoading},
    props: {
        queries: {
            type: Object,
            required: true,
        },
        bookCollections: {
            required: true,
        },
        chosenPublisher: {
            required: true,
        },
    },
    data() {
        return {
            filter: "",
        };
    },
    computed: {
        displayedBookCollections() {
            return this.bookCollections ? this.bookCollections.filter(bookCollection => this.showBookCollection(bookCollection)) : [];
        },
        bookCollectionCountClass() {
            return this.bookCollections.length === this.displayedBookCollections.length ? "badge-primary" : "badge-warning";
        },
        bookCollectionCountTooltip() {
            if (!this.chosenPublisher) {
                const bookCollectionCountShop = this.$t("ShopMenuBookCollectionsFilter.bookCollectionCountShop", { count: this.bookCollections.length });
                const bookCollectionCountShopWithFilter = this.$t("ShopMenuBookCollectionsFilter.bookCollectionCountShopWithFilter", { count: this.displayedBookCollections.length });
                return this.bookCollections.length === this.displayedBookCollections.length ? bookCollectionCountShop : bookCollectionCountShopWithFilter;
            } else {
                const publisherBookCollectionCountShop = this.$t("ShopMenuBookCollectionsFilter.publisherBookCollectionCountShop", { count: this.bookCollections.length });
                const publisherBookCollectionCountShopWithFilter = this.$t("ShopMenuBookCollectionsFilter.publisherBookCollectionCountShopWithFilter", { count: this.displayedBookCollections.length });
                return this.bookCollections.length === this.displayedBookCollections.length ? publisherBookCollectionCountShop : publisherBookCollectionCountShopWithFilter;
            }
        },
    },
    methods: {
        changeQueryParam(queryParam) {
            this.$emit("change-query-param", queryParam);
        },
        showBookCollection(bookCollection) {
            const bookCollectionName = bookCollection.name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
            const regex = new RegExp(this.filter.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase());
            return bookCollectionName.match(regex, "ig");
        },
        isBookCollectionChosen(bookCollection) {
            return bookCollection === this.queries["book-collection"];
        },
    },
};
</script>

<style lang="scss" scoped>
    #book-collection-list {
        max-height: 25vh;
        overflow-y: auto;
        scrollbar-width: thin;
    }

    #book-collection-list::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    #book-collection-list::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
    }

    #book-collection-list::-webkit-scrollbar-thumb {
        background: #e1e1e1;
        border: 0px none #ffffff;
        border-radius: 50px;
    }

    #book-collection-list::-webkit-scrollbar-thumb:hover {
        background: grey;
    }

    #book-collection-list::-webkit-scrollbar-thumb:active {
        background: dimgrey;
    }

    #book-collection-list::-webkit-scrollbar-track {
        background: white;
        border: 0px none #ffffff;
        border-radius: 50px;
    }

    #book-collection-list::-webkit-scrollbar-track:hover {
        background: white;
    }

    #book-collection-list::-webkit-scrollbar-track:active {
        background: white;
    }

    #book-collection-list::-webkit-scrollbar-corner {
        background: transparent;
    }
</style>