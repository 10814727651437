<template>
    <div>
        <div class="card-header text-lg">
            {{$t("CheckoutCompletePayBillByOrangeMoney.youPayedByOrangeMoney")}}
        </div>
        <div class="card-body">
            <div class="row align-items-center">
                <div class="col-1">
                    <img alt="Orange Money" :src="orangeMoney" />
                </div>
                <div class="col-10">
                    <h4 class="card-title">{{$t("CheckoutCompletePayBillByOrangeMoney.howByOrangeMoney")}}</h4>
                </div>
            </div>
            <hr class="mb-4"/>
            <div class="row form-group">
                <label class="col-3 col-form-label text-muted" for="title">{{$t("CheckoutCompletePayBillByOrangeMoney.entitled")}}</label>
                <input v-tooltip="$t('CheckoutCompletePayBillByOrangeMoney.clickToCopy')" class="form-control col-6" readonly id="title"
                       @click="copy('title')" :value="paymentTitle"/>
            </div>
            <div class="row form-group">
                <label class="col-3 col-form-label text-muted" for="recipient">{{$t("CheckoutCompletePayBillByOrangeMoney.recipient")}}</label>
                <input v-tooltip="$t('CheckoutCompletePayBillByOrangeMoney.clickToCopy')" class="form-control col-6" readonly id="recipient"
                       @click="copy('recipient')" :value="shopPayments.orangeMoney.code"/>
            </div>
            <div class="row form-group">
                <label class="col-3 col-form-label text-muted" for="total">{{$t("CheckoutCompletePayBillByOrangeMoney.amount")}}</label>
                <input v-tooltip="$t('CheckoutCompletePayBillByOrangeMoney.clickToCopy')" class="form-control col-6" readonly id="total"
                       @click="copy('total')" :value="properPrice(this.order.totalWT, this.order.currency, currencies)"/>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import orangeMoney from "../../../../assets/img/payments/orangeMoney.png";
import { properPrice } from "../../../../helpers/price";

export default {
    name: "CheckoutCompletePayBillByOrangeMoney",
    props: {
        order: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            paymentTitle: this.$t("CheckoutCompletePayBillByOrangeMoney.transferTitle", { reference: this.order.reference }),
            orangeMoney,
        };
    },
    computed: {
        ...mapGetters("currencies", {
            currencies: "currencies",
        }),
        ...mapGetters("config", {
            shopPayments: "payments",
        }),
    },
    methods: {
        copy(id) {
            const copyText = document.getElementById(id);
            copyText.select();
            document.execCommand("copy");
            this.$toasted.success(this.$t("CheckoutCompletePayBillByOrangeMoney.copied"), { icon: "copy" });
        },
        properPrice,
    },
};
</script>

<style scoped>
    input[readonly="readonly"] {
        cursor: pointer;
        color: #606060;
    }
</style>