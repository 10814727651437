import Vue from "vue";
import i18n from "../../i18n";

const state = {
    preferences: {
        currency: undefined,
    },
};

const getters = {
    preferences(state) {
        return state.preferences;
    },
    currency(state) {
        return state.preferences.currency;
    },
};

const mutations = {
    setPreferences(state, preferences) {
        state.preferences = preferences;
    },
    setCurrency(state, currency) {
        state.preferences.currency = currency;
        localStorage.setItem("currency", currency);
    },
};

const actions = {
    setPreferences({ commit }, preferences) {
        commit("setPreferences", preferences);
    },
    setCurrency({ commit, state, rootState }, currencyISOCode) {
        if (state.preferences.currency !== undefined && state.preferences.currency !== currencyISOCode) {
            const currency = rootState.currencies.currencies ? rootState.currencies.currencies.find(c => c.alpha3ISO === currencyISOCode) : undefined;
            const currencySymbol = currency ? currency.symbol : "?";
            Vue.prototype.$toasted.info(i18n.t("SelectCurrency.pricesAreNowInCurrency", { currency: currencySymbol }), { icon: "coins" });
        }
        commit("setCurrency", currencyISOCode);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};