import { render, staticRenderFns } from "./SideBarTabs.vue?vue&type=template&id=03d02029&scoped=true&"
import script from "./SideBarTabs.vue?vue&type=script&lang=js&"
export * from "./SideBarTabs.vue?vue&type=script&lang=js&"
import style0 from "./SideBarTabs.vue?vue&type=style&index=0&id=03d02029&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03d02029",
  null
  
)

export default component.exports