export function isCartFullOfProductsWithAgreement(user) {
    if (!user.cart || user.cart.length === 0) {
        return false;
    }
    for (const product of user.cart) {
        if (!user.publishers.agreements.includes(product.publisher._id)) {
            return false;
        }
    }
    return true;
}