<template>
    <div id="airvey-editions-home">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 mt-3">
                    <AdCarousel/>
                </div>
            </div>
        </div>
        <div class="container py-3">
            <div class="row mt-2">
                <div class="col-12">
                    <h2 class="text-uppercase text-less-bold ml-2">
                        Nos univers
                    </h2>
                </div>
                <div class="col-12 rounded-panel p-4">
                    <div class="row">
                        <div class="col-3 text-center">
                            <router-link to="/shop?book-collection=Inven%27Terre%20Hauts-de-France" class="btn btn-outline-primary btn-half-rounded ">
                                Beaux-Livres
                            </router-link>
                        </div>
                        <div class="col-3 text-center">
                            <router-link to="/shop?book-collection=Noir%20polar" class="btn btn-outline-primary btn-half-rounded ">
                                Policiers & Thrillers
                            </router-link>
                        </div>
                        <div class="col-3 text-center">
                            <router-link to="/shop?book-collection=Airvey%20Jeunesse" class="btn btn-outline-primary btn-half-rounded ">
                                Les Plus Jeunes & Ados
                            </router-link>
                        </div>
                        <div class="col-3 text-center">
                            <router-link to="/shop?book-collection=Histoires%20courtes" class="btn btn-outline-primary btn-half-rounded ">
                                Collection textes courts
                            </router-link>
                        </div>
                    </div>
                    <div class="row align-items-center">
                        <div class="col-3">
                            <img class="img-fluid" :src="IMGs.InventerreLogo" alt="Inventerre"/>
                        </div>
                        <div class="col-3">
                            <img class="img-fluid" :src="IMGs.NoirPolarLogo" alt="NoirPolar"/>
                        </div>
                        <div class="col-3">
                            <img class="img-fluid" :src="IMGs.AirveyJeunesseLogo" alt="JeunesseAirvey"/>
                        </div>
                        <div class="col-3">
                            <img class="img-fluid" :src="IMGs.HistoireCourteLogo" alt="HistoireCourte"/>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-3 text-center">
                            <router-link to="/shop?book-collection=Hors%20collection" class="btn btn-outline-primary btn-half-rounded ">
                                Genres divers
                            </router-link>
                            <div class="text-uppercase text-less-bold">
                                [Hors collection]
                            </div>
                        </div>
                        <div class="col-3 text-center">
                            <router-link to="/shop?book-collection=Bonnes%20affaires" class="btn btn-outline-primary btn-half-rounded ">
                                Le côté +
                            </router-link>
                            <div class="text-uppercase text-less-bold">
                                Les bonnes affaires
                            </div>
                        </div>
                        <div class="col-3 text-center">
                            <router-link to="/shop" class="btn btn-primary font-weight-bold btn-block text-less-bold">
                                Découvrez nos livres
                                <span class="fa fa-chevron-right ml-2"/>
                            </router-link>
                        </div>
                    </div>
                    <img id="airvey-watermark" :src="IMGs.AirveyWatermark" alt="Airvey watermark">
                </div>
            </div>
            <!--
            <div id="free-shipping-section" class="row mt-4">
                <div class="col-12">
                    <h2 class="text-center text-less-bold">
                        <i class="fas fa-truck mr-2"/>
                        <span>
                            PORT GRATUIT en U. E. et en Suisse : Airvey éditions a choisi de vous livrer sans frais de port
                        </span>
                    </h2>
                </div>
            </div>
            -->
            <div class="row mt-4">
                <div class="col-12">
                    <h2 class="text-uppercase text-less-bold ml-2">
                        Nos nouveautés
                    </h2>
                </div>
                <div class="col-12 rounded-panel">
                    <transition mode="out-in" name="fade">
                        <APILoading v-if="loading" key="loading" :loading="loading" :text="'Chargement des nouveautés'" :font-size="20"/>
                        <div v-else class="row" key="products">
                            <ShopProductPreview v-for="product in products" :key="product._id" :product="product" class="col-3"/>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Product from "@/classes/Product";
import APILoading from "@/components/shared/Misc/APILoading";
import ShopProductPreview from "@/components/Hermes/Shop/ShopProductList/ShopProductPreview";
import AdCarousel from "@/components/shared/Home/AdCarousel";
import AirveyJeunesseLogo from "@/assets/img/airvey-editions/airvey-jeunesse-logo.png";
import AirveyWatermark from "@/assets/img/airvey-editions/airvey-watermark.png";
import HistoireCourteLogo from "@/assets/img/airvey-editions/histoire-courte-logo.png";
import InventerreLogo from "@/assets/img/airvey-editions/inventerre-logo.png";
import NoirPolarLogo from "@/assets/img/airvey-editions/noir-polar-logo.png";

export default {
    name: "AirveyEditionsHome",
    components: {AdCarousel, ShopProductPreview, APILoading },
    data() {
        return {
            products: null,
            loading: false,
            IMGs: { AirveyJeunesseLogo, AirveyWatermark, HistoireCourteLogo, InventerreLogo, NoirPolarLogo },
        };
    },
    created() {
        this.getNewProducts();
    },
    methods: {
        async getNewProducts() {
            try {
                this.loading = true;
                this.products = null;
                const queries = {
                    "active": true,
                    "per-page": 6,
                    "sort": "release-date",
                    "order": "desc",
                };
                const { data } = await this.$hermesAPI.getProducts(queries);
                this.products = data.products.map(product => new Product(product));
            } catch (err) {
                this.$error.displayError(err);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    #airvey-editions-home {
        background-color: #D4EBF8;
    }

    #airvey-watermark {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 300px;
        height: auto;
    }

    .rounded-panel {
        background-color: white;
        border-radius: 20px;

        .btn-half-rounded {
            font-weight: 600;
            border-radius: 20px 20px 0 0;
        }
    }

    .division-line {
        border-top-color: #8a8a8a !important;
    }
</style>