<template>
    <div data-backdrop="static" data-keyboard="false" class="modal fade" id="advancedSearchModal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <form @submit.prevent="search">
                    <div class="modal-header">
                        <h5 class="modal-title">{{$t("shop.shopAdvancedSearchModal.advancedSearch")}}</h5>
                    </div>
                    <div class="modal-body">
                        <div class="row align-items-center" id="notice">
                            <div class="col-1 hidden-sm-down">
                                <i class="fa fa-search fa-2x"/>
                            </div>
                            <div class="col-10">
                                <span>{{$t("shop.shopAdvancedSearchModal.notice")}}</span>
                            </div>
                            <div class="col-12">
                                <hr class="m-3"/>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-6">
                                <label class="text-muted"
                                       for="title">{{$t("shop.shopAdvancedSearchModal.title")}}</label>
                                <input class="form-control" type="text" ref="titleInput" id="title" v-model="localQueries.title"
                                       :placeholder="$t('shop.shopAdvancedSearchModal.title')">
                            </div>
                            <div class="col-6">
                                <label class="text-muted"
                                       for="subtitle">{{$t("shop.shopAdvancedSearchModal.subtitle")}}</label>
                                <input class="form-control" type="text" id="subtitle" v-model="localQueries.subtitle"
                                       :placeholder="$t('shop.shopAdvancedSearchModal.subtitle')">
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-6">
                                <label class="text-muted"
                                       for="author">{{$t("shop.shopAdvancedSearchModal.author")}}</label>
                                <input class="form-control" type="text" id="author" v-model="localQueries.author"
                                       :placeholder="$t('shop.shopAdvancedSearchModal.author')">
                            </div>
                            <div class="col-6">
                                <label class="text-muted" for="isbn">{{$t("shop.shopAdvancedSearchModal.isbn")}}</label>
                                <input class="form-control" type="text" id="isbn" v-model="localQueries.isbn" maxlength="13"
                                       pattern="^[0-9]+$" :placeholder="$t('shop.shopAdvancedSearchModal.isbn')">
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-6">
                                <label class="text-muted" for="book-collections">
                                    {{$t("shop.shopAdvancedSearchModal.collection")}}
                                </label>
                                <transition name="fade" mode="out-in">
                                    <APILoading id="loading" key="loading" v-if="!bookCollections" :font-size='12' :icon-size='20'
                                                :text="$t('shop.shopAdvancedSearchModal.loadingBookCollections')"/>
                                    <v-select v-else id="book-collections" @input="setSelectedBookCollection" :filter-by="filterBy"
                                              :placeholder='$t("shop.shopAdvancedSearchModal.chooseBookCollection")' key="book-collections"
                                              :options="formattedBookCollections" :value="formattedSelectedBookCollection">
                                        <template slot="option" slot-scope="option">
                                            {{ option.label }}
                                        </template>
                                    </v-select>
                                </transition>
                            </div>
                            <div class="col-6">
                                <label class="text-muted"
                                       for="description">{{$t("shop.shopAdvancedSearchModal.description")}}</label>
                                <input class="form-control" type="text" id="description"
                                       v-model="localQueries['description']"
                                       :placeholder="$t('shop.shopAdvancedSearchModal.description')">
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-6">
                                <label class="text-muted"
                                       for="clil">{{$t("shop.shopAdvancedSearchModal.publisher")}}</label>
                                <v-select id="publisher" @input="setSelectedPublisher" :filter-by="filterBy"
                                          :placeholder='$t("shop.shopAdvancedSearchModal.choosePublisher")'
                                          :options="formattedPublishers" :value="formattedSelectedPublisher">
                                    <template slot="option" slot-scope="option">
                                        {{ option.label }}
                                    </template>
                                </v-select>
                            </div>
                            <div class="col-6">
                                <label class="text-muted"
                                       for="clil">{{$t("shop.shopAdvancedSearchModal.country")}}</label>
                                <v-select id="country" @input="setSelectedCountry" :filter-by="filterBy"
                                          :placeholder='$t("shop.shopAdvancedSearchModal.chooseCountry")'
                                          :options="formattedCountries" :value="formattedSelectedCountry">
                                    <template slot="option" slot-scope="option">
                                        <img width="20" alt="flag" :src="option.flagURL"/> {{ option.label }}
                                    </template>
                                </v-select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-6">
                                <label class="text-muted"
                                       for="clil">{{$t("shop.shopAdvancedSearchModal.clil")}}</label>
                                <v-select id="clil" @input="setSelectedTheme" :filter-by="filterBy"
                                          :placeholder='$t("shop.shopAdvancedSearchModal.chooseTheme")'
                                          :options="themes" :value="formattedSelectedTheme">
                                    <template slot="option" slot-scope="option">
                                        <span :class="`ml-${option.lvl}`">{{ option.value }} - </span>
                                        {{ option.label }}
                                    </template>
                                </v-select>
                            </div>
                            <div class="col-6">
                                <label class="text-muted"
                                       for="geographical-area">{{$t("shop.shopAdvancedSearchModal.geographicalArea")}}</label>
                                <v-select id="geographical-area" @input="setSelectedGeographicalArea" :filter-by="filterBy"
                                          :placeholder='$t("shop.shopAdvancedSearchModal.chooseGeographicalArea")'
                                          :options="geographicalAreas" :value="formattedSelectedGeographicalArea">
                                    <template slot="option" slot-scope="option">
                                        {{ option.label }}
                                    </template>
                                </v-select>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-6">
                                <label class="text-muted" for="from-release-date">{{$t("shop.shopAdvancedSearchModal.releasedFrom")}}</label>
                                <input class="form-control" type="date" id="from-release-date"
                                       v-model="localQueries['from-release-date']"/>
                            </div>
                            <div class="col-6">
                                <label class="text-muted" for="to-release-date">{{$t("shop.shopAdvancedSearchModal.releasedTo")}}</label>
                                <input class="form-control" type="date" id="to-release-date"
                                       v-model="localQueries['to-release-date']"/>
                            </div>
                        </div>
                        <hr class="mb-3"/>
                        <div v-if="frontConfig.areShopSortersEnabled(user)" id="sort" class="form-group row">
                            <div class="col-md-4 col-sm-12">
                                <label class="text-muted">
                                    {{$t("shop.shopAdvancedSearchModal.sortResultsBy")}}
                                </label>
                            </div>
                            <div class="col-md-8 col-sm-12 row">
                                <div class="col-md-6 col-sm-12 mb-2">
                                    <select v-model="localQueries.sort" class="form-control">
                                        <option v-if="frontConfig.isShopSortByReleaseDateEnabled" value="release-date">
                                            {{$t("shop.shopAdvancedSearchModal.releaseDate")}}
                                        </option>
                                        <option v-if="frontConfig.isShopSortByPriceWTEnabled" value="price-w-t">{{$t("shop.shopAdvancedSearchModal.price")}}</option>
                                        <option v-if="frontConfig.isShopSortByTitleEnabled" value="title">{{$t("shop.shopAdvancedSearchModal.title")}}</option>
                                    </select>
                                </div>
                                <div class="col-md-6 col-sm-12">
                                    <select v-model="localQueries.order" class="form-control">
                                        <option value="desc">{{$t("shop.shopAdvancedSearchModal.desc")}}</option>
                                        <option value="asc">{{$t("shop.shopAdvancedSearchModal.asc")}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">
                            <span class="hidden-sm-down">
                                {{$t("shop.shopAdvancedSearchModal.close")}}
                            </span>
                            <span class="hidden-md-up fa fa-times">
                            </span>
                        </button>
                        <button type="submit" class="btn btn-primary">
                            <span class="hidden-sm-down">
                                {{$t("shop.shopAdvancedSearchModal.searchWithCriteria")}}
                            </span>
                            <span class="hidden-md-up fa fa-search"/>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import themes from "../../../plugins/clil";
import geographicalAreas from "../../../plugins/geographical_areas";
import ProductSearchQuery from "../../../classes/ProductSearchQuery";
import { filterBy } from "@/helpers/v-select";
import APILoading from "../../shared/Misc/APILoading";

export default {
    name: "ShopAdvancedSearchModal",
    components: {APILoading},
    props: {
        queries: {
            type: Object,
            required: true,
        },
        bookCollections: {
            required: true,
        },
    },
    data() {
        return {
            localQueries: new ProductSearchQuery(this.queries),
            selectedTheme: null,
            themes: [],
            geographicalAreas: [],
        };
    },
    computed: {
        ...mapGetters("publishers", {
            publishers: "publishers",
        }),
        ...mapGetters("countries", {
            countries: "countries",
        }),
        ...mapGetters("config", {
            frontConfig: "front",
        }),
        ...mapGetters("user", {
            user: "user",
        }),
        formattedPublishers() {
            return this.publishers ? this.publishers.map(publisher => ({ value: publisher._id, label: publisher.name })) : [];
        },
        formattedSelectedPublisher() {
            return this.publishers ? this.formattedPublishers.find(publisher => publisher.value === this.localQueries.publisher): {};
        },
        formattedSelectedGeographicalArea() {
            return this.geographicalAreas ? this.geographicalAreas.find(geographicalArea => geographicalArea.value === this.localQueries["geographical-area"]): {};
        },
        formattedSelectedTheme() {
            return this.themes ? this.themes.find(theme => theme.value === this.localQueries.clil): {};
        },
        formattedCountries() {
            return this.countries ? this.countries.map(country => ({ value: country.alpha3ISO, label: country.name, flagURL: country.flagURL })) : [];
        },
        formattedSelectedCountry() {
            return this.countries ? this.formattedCountries.find(country => country.value === this.localQueries.countries): {};
        },
        formattedBookCollections() {
            return this.bookCollections ? this.bookCollections.map(bookCollection => ({ value: bookCollection.name, label: bookCollection.name })) : [];
        },
        formattedSelectedBookCollection() {
            return this.bookCollections ? this.formattedBookCollections.find(bookCollection => bookCollection.value === this.localQueries["book-collection"]) : {};
        },
    },
    created() {
        this.generateThemes();
        this.generateGeographicalAreas();
    },
    methods: {
        filterBy,
        setSelectedPublisher(publisher) {
            this.localQueries.publisher = publisher ? publisher.value : null;
        },
        setSelectedGeographicalArea(geographicalArea) {
            this.localQueries["geographical-area"] = geographicalArea ? geographicalArea.value : null;
        },
        setSelectedTheme(theme) {
            this.localQueries.clil = theme ? theme.value : null;
        },
        setSelectedCountry(country) {
            this.localQueries.countries = country ? country.value : null;
        },
        setSelectedBookCollection(bookCollection) {
            this.localQueries["book-collection"] = bookCollection ? bookCollection.value : null;
        },
        show() {
            this.localQueries = new ProductSearchQuery(this.queries);
            // eslint-disable-next-line no-undef
            $("#advancedSearchModal").modal("show");
            setTimeout(() => {
                this.$refs.titleInput.focus();
            }, 500);
        },
        search() {
            this.localQueries.page = 1;
            this.$emit("advanced-search", this.localQueries);
            // eslint-disable-next-line no-undef
            $("#advancedSearchModal").modal("hide");
        },
        generateThemes() {
            let lvl1, lvl2, lvl3, lvl4;
            Object.keys(themes).forEach(key1 => {
                lvl1 = themes[key1];
                this.themes.push({
                    value: key1,
                    lvl: 1,
                    label: lvl1.label,
                });
                if (lvl1["subThemes"] !== undefined) {
                    Object.keys(lvl1.subThemes).forEach(key2 => {
                        lvl2 = lvl1.subThemes[key2];
                        this.themes.push({
                            value: key2,
                            lvl: 2,
                            label: lvl2.label,
                        });
                        if (lvl2["subThemes"] !== undefined) {
                            Object.keys(lvl2.subThemes).forEach(key3 => {
                                lvl3 = lvl2.subThemes[key3];
                                this.themes.push({
                                    value: key3,
                                    lvl: 3,
                                    label: lvl3.label,
                                });
                                if (lvl3["subThemes"] !== undefined) {
                                    Object.keys(lvl3.subThemes).forEach(key4 => {
                                        lvl4 = lvl3.subThemes[key4];
                                        this.themes.push({
                                            value: key4,
                                            lvl: 4,
                                            label: lvl4.label,
                                        });
                                    });
                                }
                            });
                        }
                    });
                }
            });
        },
        generateGeographicalAreas() {
            let lvl1, lvl2;
            Object.keys(geographicalAreas).forEach(key1 => {
                lvl1 = geographicalAreas[key1];
                this.geographicalAreas.push({
                    value: key1,
                    lvl: 1,
                    label: lvl1.label,
                });
                if (lvl1["subAreas"] !== undefined) {
                    Object.keys(lvl1.subAreas).forEach(key2 => {
                        lvl2 = lvl1.subAreas[key2];
                        this.geographicalAreas.push({
                            value: key2,
                            lvl: 2,
                            label: lvl2.label,
                        });
                    });
                }
            });
        },
    },
    watch: {
        "localQueries.isbn"(isbn, previous) {
            if (!/^\d*$/.test(isbn)) {
                this.localQueries.isbn = previous;
            }
        },
    },
};
</script>

<style scoped>
    #sort {
        display: flex;
        align-items: center;
    }
</style>