var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-8 text-center"},[_c('div',{staticClass:"padding-top-2x mt-2 hidden-lg-up"}),_c('ValidationObserver',{ref:"formObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit()}}},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-md-6 col-sm-12"},[_c('div',{staticClass:"row"},[_c('ValidationProvider',{staticClass:"col-12",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"actual-password"}},[_vm._v(" "+_vm._s(_vm.$t("ProfileChangePassword.actualPassword"))+" "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.oldPassword),expression:"oldPassword"}],staticClass:"form-control",class:classes,attrs:{"id":"actual-password","type":"password","required":"","minlength":"5","placeholder":_vm.$t('ProfileChangePassword.required')},domProps:{"value":(_vm.oldPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.oldPassword=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,true)})],1),_c('div',{staticClass:"row"},[_c('ValidationProvider',{staticClass:"col-12",attrs:{"vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"new-password"}},[_vm._v(" "+_vm._s(_vm.$t("ProfileChangePassword.newPassword"))+" "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control",class:classes,attrs:{"id":"new-password","type":"password","required":"","minlength":"5","placeholder":_vm.$t('ProfileChangePassword.required')},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,true)})],1),_c('div',{staticClass:"row"},[_c('ValidationProvider',{staticClass:"col-12",attrs:{"rules":"confirm_password:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"confirm-new-password"}},[_vm._v(" "+_vm._s(_vm.$t("ProfileChangePassword.confirmNewPassword"))+" "),_c('span',[_vm._v("*")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmPassword),expression:"confirmPassword"}],staticClass:"form-control",class:classes,attrs:{"id":"confirm-new-password","type":"password","required":"","minlength":"5","placeholder":_vm.$t('ProfileChangePassword.required')},domProps:{"value":(_vm.confirmPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.confirmPassword=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,true)})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('SubmitBtn',{attrs:{"classes":"btn btn-primary btn-block","text":_vm.$t('ProfileChangePassword.changePassword'),"loading":_vm.loading,"disabled":invalid}})],1)])]),_c('div',{staticClass:"col-md-6 hidden-sm-down"},[_c('img',{attrs:{"src":_vm.securitySVG,"alt":"Change password"}})])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }