<template>
    <div data-backdrop="static" data-keyboard="false" class="modal fade" id="address-modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <ValidationObserver ref="formObserver" v-slot="{ invalid }">
                    <form @submit.prevent>
                        <div class="modal-header">
                            <h5 class="modal-title">
                                {{$t("shared.address.addressModal.addAddress")}}
                            </h5>
                        </div>
                        <div class="modal-body">
                            <AddressForm @form-valid="isFormValid" ref="addressForm"/>
                        </div>
                        <div class="modal-footer">
                            <button type="button" :disabled="loading" class="btn btn-secondary" data-dismiss="modal">
                                {{$t("shared.address.addressModal.close")}}
                            </button>
                            <SubmitBtn :loading="loading" :disabled="!formValid || invalid" :text="$t('shared.address.addressModal.add')"
                                        :classes="'btn btn-primary'" @click="addAddress"/>
                        </div>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>

<script>
import AddressForm from "./AddressForm";
import SubmitBtn from "../../Misc/SubmitBtn";

export default {
    name: "AddressModal",
    components: {SubmitBtn, AddressForm},
    data() {
        return {
            formValid: false,
            loading: false,
        };
    },
    methods: {
        show() {
            // eslint-disable-next-line no-undef
            $("#address-modal").modal("show");
            this.$refs.addressForm.resetAddress();
            this.$refs.formObserver.reset();
        },
        isFormValid(valid) {
            this.formValid = valid;
        },
        async addAddress() {
            const address = this.$refs.addressForm.getAddress();
            if (address) {
                try {
                    this.loading = true;
                    const response = await this.$store.dispatch("user/addAddress", address);
                    this.$emit("add-address", response.data);
                    // eslint-disable-next-line no-undef
                    $("#address-modal").modal("hide");
                } catch (err) {
                    this.$error.displayError(err);
                } finally {
                    this.loading = false;
                }
            }
        },
    },
};
</script>

<style scoped>

</style>