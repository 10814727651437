<template>
    <div id="reset-password">
        <PageTitle :title="$t('ResetPassword.resetPassword')"/>
        <div class="container padding-bottom-3x mb-1">
            <ValidationObserver v-slot="{ invalid }" >
                <form @submit.prevent="submit">
                    <div class="row align-items-center justify-content-center">
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <img :src="securitySVG" alt="Security"/>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <ValidationProvider vid="confirmation" v-slot="{ errors, classes }">
                                <label for="new-password">
                                    {{ $t("ResetPassword.newPassword") }}
                                    <span>*</span>
                                </label>
                                <div class="form-group">
                                    <input type="password" v-model="password" id="new-password" minlength="5" required
                                           class="form-control mb-3" :placeholder="$t('ResetPassword.required')" :class="classes"/>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider rules="confirm_password:confirmation" v-slot="{ errors, classes }">
                                <label for="confirm-new-password">
                                    {{ $t("ResetPassword.confirmNewPassword") }}
                                    <span>*</span>
                                </label>
                                <div class="form-group">
                                    <input type="password" v-model="confirmPassword" id="confirm-new-password" minlength="5" required
                                           class="form-control" :placeholder="$t('ResetPassword.required')" :class="classes"/>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </div>
                            </ValidationProvider>
                            <SubmitBtn :classes="'btn btn-primary btn-block'" :text="$t('ResetPassword.changePassword')"
                                       :loading="loading" :disabled="invalid"/>
                        </div>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
import PageTitle from "../../shared/Misc/PageTitle";
import SubmitBtn from "../../shared/Misc/SubmitBtn";
import { mapGetters } from "vuex";
import { importSVG } from "../../../helpers/file";

export default {
    name: "ResetPassword",
    components: {SubmitBtn, PageTitle},
    props: {
        user: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            password: "",
            confirmPassword: "",
            loading: false,
        };
    },
    computed: {
        ...mapGetters("user", {
            isUserLogged: "isLogged",
        }),
        ...mapGetters("config", {
            hermesAPIConfig: "hermesAPI",
        }),
        securitySVG() {
            return importSVG("security", this.hermesAPIConfig.client);
        },
    },
    mounted() {
        if (this.isUserLogged) {
            this.$router.push({ path: "/" });
            this.$toasted.error(this.$t("ResetPassword.alreadyLogged"), { icon: "lock" });
        }
    },
    methods: {
        async submit() {
            try {
                if (this.password !== this.confirmPassword) {
                    return this.$toasted.error(this.$t("ResetPassword.passwordsDontMatch"), { icon: "lock" });
                }
                this.loading = true;
                await this.$hermesAPI.resetPassword(this.$route.query.token, this.password);
                this.$toasted.success(this.$t("ResetPassword.passwordChanged"), { icon: "check" });
                await this.$router.push({ path: "/" });
            } catch (err) {
                this.$toasted.error(this.$t("ResetPassword.badToken"), { icon: "lock" });
                await this.$router.push({ path: "/" });
            } finally {
                this.loading = false;
            }
        },
    },
    watch: {
        "user.logged"(logged) {
            if (logged) {
                this.$router.push({ path: "/" });
                this.$toasted.error(this.$t("ResetPassword.alreadyLogged"), { icon: "lock" });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    label {
        span {
            color: red;
        }
    }
</style>