<template>
    <div>
        <div class="card-header text-lg">
            {{$t("CheckoutCompletePayBillByCash.youPayedByCash")}}
        </div>
        <div class="card-body">
            <div class="row align-items-center">
                <div class="col-1">
                    <i class="fas fa-money-bill text-success fa-3x"></i>
                </div>
                <div class="col-10">
                    <h4 class="card-title">{{$t("CheckoutCompletePayBillByCash.howByCash")}}</h4>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CheckoutCompletePayBillByCash",
};
</script>

<style scoped>

</style>