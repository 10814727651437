import Address from "./Address";
import { hasProp } from "@/helpers/class";

class Publisher {
    constructor(publisher = null) {
        this._id = !hasProp(publisher, "_id") ? null : publisher._id;
        this.defaultRate = !hasProp(publisher, "defaultRate") ? 0 : publisher.defaultRate;
        this.groups = !hasProp(publisher, "groups") ? [] : publisher.groups;
        this.productCount = !hasProp(publisher, "productCount") ? 0 : publisher.productCount;
        this.name = !hasProp(publisher, "name") ? "" : publisher.name;
        this.address = !hasProp(publisher, "address") ? new Address() : new Address(publisher.address);
        this.showPrice = !hasProp(publisher, "showPrice") ? true : publisher.showPrice;
        this.sobookClientId = !hasProp(publisher, "sobookClientId") ? 0 : publisher.sobookClientId;
    }
}

export default Publisher;