<template>
    <SubmitBtn @click="deleteProductReview" :loading="loading" :classes="classes"
               :text="$t('DeleteProductReviewBtn.deleteProductReview')"/>
</template>

<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import SubmitBtn from "../../Misc/SubmitBtn";

export default {
    name: "DeleteProductReviewBtn",
    components: {SubmitBtn},
    props: {
        review: {
            type: Object,
            required: true,
        },
        classes: {
            type: String,
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        ...mapGetters("config", {
            shopPrimaryColor: "shopPrimaryColor",
        }),
    },
    methods: {
        async confirmDeleteProductReview() {
            const { value } = await Swal.fire({
                title: this.$t("DeleteProductReviewBtn.youSure"),
                text: this.$t("DeleteProductReviewBtn.youWillDelete"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: this.shopPrimaryColor,
                cancelButtonColor: "#D33",
                confirmButtonText: this.$t("DeleteProductReviewBtn.yesDelete"),
                cancelButtonText: this.$t("DeleteProductReviewBtn.cancel"),
            });
            return value;
        },
        async deleteProductReview() {
            try {
                if (!await this.confirmDeleteProductReview()) {
                    return;
                }
                this.loading = true;
                await this.$hermesAPI.deleteProductReview(this.review.product, this.review._id);
                this.$toasted.success(this.$t("DeleteProductReviewBtn.reviewDeleted"), { icon: "check" });
                this.$emit("delete-product-review", this.review._id);
            } catch (err) {
                this.$error.displayError(err);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped>

</style>