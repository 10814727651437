export function importSVG(filename, client) {
    let file;
    try {
        file = require(`../assets/svg/${client}/${filename}.svg`);
    } catch (e) {
        file = require(`../assets/svg/default/${filename}.svg`);
    }
    return file;
}

export function importPNG(filename, client) {
    let file;
    try {
        file = require(`../assets/img/${client}/${filename}.png`);
    } catch (e) {
        file = require(`../assets/img/default/${filename}.png`);
    }
    return file;
}