<template>
    <div class="col-md-5 mb-30">
        <div class="product-gallery">
            <div class="product-carousel owl-carousel gallery-wrapper">
                <div class="gallery-item">
                    <img data-zoomable v-lazy="photos[0]" alt="Product">
                </div>
                <div class="gallery-item">
                    <img data-zoomable v-lazy="photos[1]" alt="Product">
                </div>
            </div>
            <ul v-if="photos[0] && photos[1]" class="product-thumbnails">
                <li @click="selectThumbnail('firstCover')" :class="{active: selected === 'firstCover'}">
                    <a>
                        <img v-lazy="photos[0]" alt="Product">
                    </a>
                </li>
                <li @click="selectThumbnail('fourthCover')" :class="{active: selected === 'fourthCover'}">
                    <a>
                        <img v-lazy="photos[1]" alt="Product">
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
/* eslint-disable no-undef */
import mediumZoom from "medium-zoom";

export default {
    name: "ProductGallery",
    components: {},
    props: {
        photos: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            selected: "firstCover",
        };
    },
    mounted() {
        this.initCarousel();
        mediumZoom("[data-zoomable]");
    },
    methods: {
        initCarousel() {
            $(".product-carousel").owlCarousel({
                items: 1,
                loop: false,
                dots: false,
            });
        },
        selectThumbnail(thumbnail) {
            if (this.selected !== thumbnail) {
                this.selected = thumbnail;
                if (this.selected === "firstCover") {
                    $(".product-carousel").trigger("prev.owl.carousel");
                } else {
                    $(".product-carousel").trigger("next.owl.carousel");
                }
            }
        },
    },
};
</script>

<style scoped lang='scss'>

    .product-thumbnails {
        li {
            cursor: pointer;
        }
    }

    .gallery-item {
        display: flex !important;
        justify-content: center !important;
        align-items: center;
        height: 500px;

        img {
            cursor: zoom-in !important;
            max-height: 100% !important;
        }
    }

</style>