<template>
    <div class="col-lg-8 col-xs-12 text-center">
        <h3 class="widget-title text-center">
            <label for="currencies">{{$t("footer.selectCurrency.changeCurrency")}}</label>
            <i v-tooltip="$t('SelectCurrency.applyOnlyOnProducts', { currency: $t(`footer.selectCurrency.${shopCurrency}`)})" class="fas fa-question-circle ml-2 text-info"></i>
        </h3>
        <select v-model="localCurrency" id="currencies" class="form-control">
            <option v-for="currency in selectableCurrencies" :value="currency.alpha3ISO" :key="currency.alpha3ISO"
                    v-html="`${$t(`footer.selectCurrency.${currency.alpha3ISO}`)}`"/>
        </select>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "CurrenciesSelect",
    props: {
        selectableCurrencies: {
            type: Array,
            required: true,
        },
    },
    computed: {
        ...mapGetters("preferences", {
            currency: "currency",
        }),
        ...mapGetters("config", {
            shopCurrency: "shopCurrency",
        }),
        localCurrency: {
            get() {
                return this.currency;
            },
            set(currency) {
                this.setCurrency(currency);
            },
        },
    },
    methods: {
        ...mapActions("preferences", {
            setCurrency: "setCurrency",
        }),
    },
};
</script>

<style scoped>

</style>