<template>
    <div id="terms-and-conditions-airvey-editions">
        <h2>
            1. CHAMP D’APPLICATION DES CONDITIONS GENERALES DE VENTE
        </h2>
        <p>
            Les présentes Conditions Générales de Vente s’appliquent de plein droit à l’ensemble des
            produits ou services proposés à la vente sur son site internet www.airvey-editions.com par
            l’E. I. Hervé Mineur sous l’enseigne AIRVEY éditions immatriculée au RCS de PAU
            sous le numéro A 338 446 297 dont le siège social est sis 40 chemin de Seigne, 64300 ST-GIRONS-EN-BEARN
        </p>
        <p>
            Toute commande passée par le Client, personne physique ou morale, implique son acceptation
            totale et sans réserve de ces Conditions Générales de Vente. Toute condition contraire
            opposée par le Client lui sera donc, à défaut d’acceptation express d’Airvey éditions
            inopposable quel que soit le moment où elle aura pu être portée à sa connaissance. Ces
            Conditions Générales de Vente sont accessibles à tout moment sur le site et prévaudront, le
            cas échéant, sur toute autre version et sur les propres conditions d’achat ou autres du Client.
        </p>
        <h2>
            2. COMMANDE
        </h2>
        <p>
            Le Client passe commande selon le processus en vigueur sur le site et confirme le récapitulatif
            détaillé de celle-ci.
        </p>
        <p>
            Le Client est tenu de fournir toutes informations nécessaires à la livraison. Le Client est
            engagé par sa commande dès qu’il clique sur «Paiement par Carte bancaire», «Paiement par
            Chèque» «Paiement par PayPal» ou «Paiement par virement bancaire» dans la page
            «Panier» ou «Vous avez commandé» du site. Les données enregistrées sur le serveur de
            Airvey éditions constituent la preuve de l’ensemble des transactions passées entre Airvey
            éditions et ses Clients.
        </p>
        <p>
            Le paiement en ligne des produits par carte bancaire est réalisé par l’intermédiaire du Service
            Stripe. Les données enregistrées par le système de paiement de
            «Stripe» constituent la preuve des transactions financières réalisées par carte bancaire.
            PAYBOX Services ne transmet pas les informations relatives au paiement par carte bancaire à
            Airvey éditions sauf, si nécessaire, en cas de réclamation dûment exprimée portant sur une
            transaction particulière. La transmission des informations entre le client et Stripe est
            sécurisée, en fonction des logiciels intégrés dans le matériel du Client, par les protocoles
            existant sur Internet, au maximum autorisé par la législation française en vigueur.
        </p>
        <p>
            Les produits sont proposés à la vente jusqu’à épuisement du stock. Pour les produits n’étant
            pas en stock, les offres de Airvey éditions sont valables sous réserve de disponibilité chez nos
            fournisseurs. En cas de commande d’un produit indisponible, le client sera informé par
            courriel dans les meilleurs délais.
        </p>
        <h2>
            3. PRIX, MODE DE REGLEMENT ET SECURISATION
        </h2>
        <p>
            Les tarifs facturés sont exprimés en euros toutes taxes comprises et comprennent la participation légale obligatoire
            (3€ par commande) aux frais de port si la commande est inférieure à 35€. Il y a lieu de nous contacter en cas de commande
            égale ou supérieure  à 35€, afin que le total de la commande puisse être dispensé des frais de port.
            Il peut s'ajouter les frais éventuels de connexion qui resteraient à la charge du client.
            Airvey éditions se réserve le droit de reporter toute nouvelle taxe et augmentation de
            taux. Les prix indiqués s’entendent en euros. Le règlement des produits ou services se fait
            uniquement par chèque, PayPal ou par carte bancaire. Le Client doit régler la totalité de ses
            achats à la commande. Ces tarifs comprennent les frais de port.
        </p>
        <h3>
            3.1 Paiement par chèque
        </h3>
        <p>
            Le paiement par chèque ne peut se faire que par des chèques libellés en euros et tirés sur une
            banque française. Le chèque doit être établi à l’ordre de Airvey éditions et être envoyé à
            AIRVEY éditions, 40 chemin de Seigne, 64300 ST-GIRONS-EN-BEARN, avec mention du numéro de référence de la
            commande qui a été indiqué lors de l’enregistrement de la commandé et dans le mail de
            confirmation de la commande. Dans ce cas, la commande ne sera traitée qu’à réception du
            chèque.
        </p>
        <h3>
            3.2 Paiement par carte bancaire
        </h3>
        <p>
            Le débit sur la carte bancaire du Client sera effectué en euros lors de l’acceptation de la
            commande par Airvey éditions. La saisie des coordonnées bancaires du Client est sécurisée.
            La saisie du numéro de carte de crédit, ainsi que sa date d’expiration se fait sur le site au sein
            de la zone de paiement sécurisé dans laquelle les informations sont cryptées. Airvey éditions
            garantit la sécurité des transactions en ligne. Airvey éditions utilise l’un des systèmes
            sécurisés les plus fiables disponibles sur internet : SSL (Secure Socket Layers). Toutes les
            informations personnelles des Clients sont parfaitement protégées et cryptées avant
            transmission au centre de traitement des commandes.
        </p>
        <h2>
            4. RETRACTATION
        </h2>
        <p>
            Conformément aux dispositions des articles L.121-20 et suivants du Code de la
            consommation, si le Client est un particulier, il bénéficie d’un délai de sept (7) jours pour
            exercer son droit de rétractation, sans avoir à justifier de motifs ni à payer de pénalités à
            l’exception, des frais de retour. Le délai de sept jours court à compter de la date de réception
            des produits.
        </p>
        <p>
            Si le Client use de ce droit de rétractation, le retour des produits devra s’effectuer dans leur
            emballage d’origine, en parfait état et être adressé à l’adresse suivante en courrier
            recommandé avec accusé de réception : Airvey éditions, 40 chemin de Seigne, 64300 ST-GIRONS-EN-BEARN. Les
            articles retournés incomplets, abîmés, endommagés, utilisés ou salis ne pourront pas être
            repris. Les frais de retour sont à la charge du Client.
        </p>
        <p>
            Airvey éditions sera tenu au remboursement des sommes versées par le Client au titre des
            marchandises retournées, à l’exclusion des frais d’envoi. Le remboursement interviendra dans
            un délai maximum de trente jours à compter de la réception du retour. Le remboursement
            pourra se faire par crédit sur le compte bancaire de la carte utilisée pour le règlement ou par
            chèque bancaire libellé à l’ordre du Client
        </p>
        <h2>
            5. RESPONSABILITE
        </h2>
        <p>
            Airvey éditions met tout en œuvre pour offrir des produits de qualité.
        </p>
        <p>
            Si malgré toute son attention, des défectuosités étaient décelées, le Client est invité à formuler
            sa réclamation, par courrier recommandé ou par courriel à contact@airvey.fr, au plus
            tard 48 heures à compter de la réception des produits. Cette réclamation devra être
            accompagnée du motif de la réclamation. Après examen, Airvey éditions se réservera le droit
            de rembourser le prix payé par le Client ou de livrer un nouveau produit.
        </p>
        <p>
            A défaut de réclamation dans les délais et formes prescrites, le Client sera réputé avoir
            accepté sans réserves le produit, ce qui lui interdira tout recours contre Airvey éditions.
        </p>
        <p>
            Airvey éditions ne pourra être tenue à aucune autre indemnisation envers le Client notamment
            pour tout dommage direct ou indirect, que celui-ci pourrait prétendre avoir subi.
        </p>
        <p>
            Le produit voyagera aux risques et périls du Client. Les réclamations pour retard, avaries ou
            manquants devront donc être faites dès réception sur le bon de livraison ou après du
            transporteur. En signant le bordereau de livraison, le Client accepte les produits livrés en l’état
            et dès lors aucune réclamation relative à des dommages subis durant le transport ne sera
            acceptée. Il est de la responsabilité du Client d’effectuer toutes vérifications et de faire toutes
            réserves à l’arrivée du matériel et d’exercer, le cas échéant, tous recours contre le
            transporteur.
        </p>
        <h2>
            6. DROITS DE PROPRIETE INTELLECTUELLE
        </h2>
        <p>
            Les produits de Airvey éditions sont protégés par la législation sur le droit d’auteur et sur la
            propriété intellectuelle. Aucune mention ou utilisation des marques, noms commerciaux,
            sigles, logos, dessins ou photos figurant sur le Site ou sur ses produits ne pourra être faite sans
            accord écrit préalable de Airvey éditions. Toute reproduction ou utilisation non préalablement
            et expressément autorisée par Airvey éditions, par courrier écrit, sera constitutive du délit de
            contrefaçon et passible de poursuites pénales.
        </p>
        <h2>
            7. INFORMATIQUES ET LIBERTE
        </h2>
        <p>
            Airvey éditions constitue un fichier comportant des informations sur tous ses Clients. La
            collecte de ces informations a pour finalité de permettre à Airvey éditions de traiter les
            commandes, livrer les produits, communiquer avec le Client sur les commandes, les produits,
            les services, conserver et mettre à jour ses fichiers et ainsi les comptes Client. Airvey éditions
            oeuvre pour protéger les informations personnelles de ces Clients lors des transferts et utilise
            des logiciels SSL (Secure Sockets Layer Software) qui cryptent les informations avant
            qu’elles ne lui soient envoyées. [Le cas échéant, Airvey éditions se réserve le droit de
            communiquer et d’exploiter ces statistiques auprès de tous tiers, de façon non-nominative
            dans les conditions prévues par la loi. Le Client donne son consentement exprès à la
            constitution de ce fichier, comportant toutes les informations que Airvey éditions peut obtenir
            relatives à l’utilisation du site et des produits. Le Client accepte expressément que Airvey
            éditions puisse traiter les données personnelles liées aux présentes conditions générales, à des
            fins de marketing direct ou aux fins mentionnées aux présentes sous réserve de l’application
            des lois et règlements en vigueur]
        </p>
        <p>
            En application de la loi n° 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et
            aux libertés, chaque Client dispose des droits d’opposition (art. 26 de la loi), d’accès (art. 34 à
            38 de la loi) et de rectification (art. 36 de la loi) des données le concernant fournies par lui
            dans le cadre de l’ouverture de son compte sur le site ou de l’achat de produits. Ainsi, chaque
            Client peut exiger que soient rectifiées, complétées, clarifiées, mises à jour ou effacées les
            informations le concernant qui sont inexactes, incomplètes, équivoques ou périmées. Pour
            exercer l’un de ces droits, le Client doit s’adresser à Airvey éditions.
        </p>
        <p>
            Un ou plusieurs «cookies» sont susceptibles d’être placés sur le disque dur de l’ordinateur de
            chaque Client, afin notamment de permettre à Airvey éditions de reconnaître le Client
            lorsqu’il se connecte au Site, de faciliter la gestion de son compte et de générer les statistiques
            correspondantes sur le Site. Chaque Client autorise la mise en place de «cookies» et leur
            utilisation par Airvey éditions. Les Clients sont informés de la possibilité de s’opposer à

            l’installation de «cookies», notamment avec des navigateurs tels que Internet Explorer et
            Netscape Navigator.
        </p>
        <h2>
            8. CLAUSE DE RESERVE DE PROPRIETE
        </h2>
        <p>
            Les produits de Airvey éditions sont vendus sous réserve de propriété. Airvey éditions
            conservera ainsi, conformément à l’article L 621-122 du Code de Commerce, la propriété du
            produit, jusqu’au paiement complet et effectif du prix en principal par le Client en ce compris,
            les frais et accessoires et les éventuelles pénalités de retard.
        </p>
        <h2>
            9. INVALIDITE, FORCE MAJEURE, DROIT APPLICABLE, TRIBUNAUX
            COMPETENTS
        </h2>
        <p>
            Si pour une quelconque raison une des présentes conditions générales devait être déclarée
            inapplicable, cette inapplicabilité n’affectera pas l’application des autres dispositions des
            présentes Conditions générales ; celle jugée inapplicable étant alors remplacée par la
            disposition la plus proche possible.
        </p>
        <p>
            Ni Airvey éditions, ni le Client ne sauraient être tenus responsables de toute inexécution qui
            aurait pour origine un cas de force majeure, échappant à leur contrôle, incluant notamment,
            sans que cela soit limitatif, les cas de guerre, d’émeute, d’insurrection, d’interruption de
            transport, de problème d’importation ou d’exportation, de grève, de lock-out, de pénurie,
            d’incendie, de tremblement de terre, de tempête, d’inondations.
        </p>
        <p>
            SI LE CLIENT AGIT EN QUALITE DE COMMERÇANT, LE TRIBUNAL DE
            COMMERCE DE PAU (64000) SERA SEUL COMPETENT EN CAS
            D’EVENTUEL LITIGE OU DE DIFFEREND, CE QUI EST EXPRESSEMENT ACCEPTE
            PAR LE CLIENT.
        </p>
        <h2>
            10. POUR TOUS RENSEIGNEMENTS
        </h2>
        <p>
            Pour tout renseignement, et notamment pour recevoir le texte des présentes conditions
            générales de vente, le Client devra écrire à Airvey éditions à l’adresse suivante : Airvey
            éditions, 40 chemin de Seigne, 64300 ST-GIRONS-EN-BEARN.
        </p>
        <ul>
            <li>Propriétaire de la boutique : Hervé MINEUR</li>
            <li>Adresse postale : 40 chemin de Seigne, 64300 ST-GIRONS-EN-BEARN</li>
            <li>Téléphone : 06 03 97 37 01</li>
            <li>SIRET : 338 446 297 00040</li>
            <li>TVA : FR36 338 446 297</li>
            <li>Airvey Éditions ne pratique aucune collecte de données</li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "TermsAndConditionsAirveyEditions",
};
</script>

<style scoped>

</style>