<template>
    <div id="product-general-info">
        <ul class="text-sm text-left">
            <li v-if="product.title">
                <span class="text-less-bold mr-1" v-html="`${$t('ProductCreated.bookSummary.title')}:`"/>
                <span v-html="product.title"/>
            </li>
            <li v-if="product.subtitle">
                <span class="text-less-bold mr-1" v-html="`${$t('shared.product.subtitle')}:`"/>
                <span v-html="product.subtitle"/>
            </li>
            <li v-if="product.isbn">
                <span class="text-less-bold mr-1" v-html="`${$t('shared.product.isbn')}:`"/>
                <span v-html="product.isbn"/>
            </li>
            <li v-if="product.author">
                <span class="text-less-bold mr-1" v-html="`${$t('ProductCreated.bookSummary.author')}:`"/>
                <span v-html="product.author"/>
            </li>
            <li v-if="product.publisher && product.publisher.name">
                <span class="text-less-bold mr-1" v-html="`${$t('shared.product.publisher')}:`"/>
                <span v-html="product.publisher.name"/>
            </li>
            <li v-if="product.dimensions.width && product.dimensions.height">
                <span class="text-less-bold mr-1" v-html="`${$t('ProductCreated.bookSummary.format')}:`"/>
                <span v-html="product.formatInMM"/>
            </li>
            <li v-if="isProductOpenFormatShown && product.openBookDimensions.width">
                <span class="text-less-bold mr-1" v-html="`${$t('ProductGeneralInfoList.openFormat')}:`"/>
                <span v-html="`${product.openBookDimensions.width} mm x ${product.openBookDimensions.height} mm`"/>
            </li>
            <li v-if="product.dimensions.thickness">
                <span class="text-less-bold mr-1" v-html="`${$t('shared.product.thickness')}:`"/>
                <span v-html="`${product.dimensions.thickness.toFixed(2)} mm`"/>
            </li>
            <li v-if="isProductWeightShown && product.weight">
                <span class="text-less-bold mr-1" v-html="`${$t('shared.product.weight')}:`"/>
                <span v-html="`${product.weight} g`"/>
            </li>
            <li v-if="product.paging.total">
                <span class="text-less-bold mr-1" v-html="`${$t('ProductCreated.bookSummary.paging.label')}:`"/>
                <span v-html="productPagingText"/>
            </li>
            <li v-if="product.priceWT">
                <span class="text-less-bold mr-1" v-html="`${$t('ProductCreated.bookSummary.priceWT')}:`"/>
                <span v-html="properPrice(product.priceWT, product.currency, currencies)"/>
            </li>
            <li v-if="product.bookCollection">
                <span class="text-less-bold mr-1" v-html="`${$t('shared.product.collection')}:`"/>
                <span v-html="product.bookCollection"/>
            </li>
            <li v-if="productCLIL">
                <span class="text-less-bold mr-1" v-html="`${$t('shared.product.theme')}:`"/>
                <span v-html="productCLIL"/>
            </li>
            <li v-if="productGeographicalAreas">
                <span class="text-less-bold mr-1" v-html="`${$t('shared.product.geographicalArea')}:`"/>
                <span v-html="productGeographicalAreas"/>
            </li>
            <li v-if="product.countries.length">
                <span class="text-less-bold mr-1" v-html="`${$tc('ProductInfos.linkedCountries', product.countries.length)}:`"/>
                <span v-html="productCountries"/>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { properPrice } from "@/helpers/price";
import Product from "@/classes/Product";
import geographicalAreas from "@/plugins/geographical_areas.json";
import themes from "@/plugins/clil.json";

export default {
    name: "ProductGeneralInfoList",
    props: {
        product: {
            type: Product,
            required: true,
        },
    },
    computed: {
        ...mapGetters("currencies", {
            currencies: "currencies",
        }),
        ...mapGetters("config", {
            isProductWeightShown: "isProductWeightShown",
            isProductOpenFormatShown: "isProductOpenFormatShown",
        }),
        productCountries() {
            let productCountries = "";
            for (let i = 0; i < this.product.countries.length; i++) {
                const productCountry = this.countries.find(country => country.alpha3ISO === this.product.countries[i]);
                productCountries += productCountry ? productCountry.name : "Unknown";
                if (i + 1 !== this.product.countries.length) {
                    productCountries += ", ";
                }
            }
            return productCountries;
        },
        productGeographicalAreas() {
            let productGeographicalAreas = "";
            for (let i = 0; i < this.product.geographicalAreas.length; i++) {
                this.findProductGeographicalArea(geographicalAreas, this.product.geographicalAreas[i]);
                if (i + 1 !== this.product.geographicalAreas.length) {
                    productGeographicalAreas += ", ";
                }
            }
            return productGeographicalAreas;
        },
        productCLIL() {
            return this.product.clil ? this.findProductCLIL(themes, this.product.clil) : "";
        },
        productPagingText() {
            const productPagingTextField = this.product.paging.color ? "descriptionWithColorPages" : "description";
            return this.$t(`ProductCreated.bookSummary.paging.${productPagingTextField}`, this.product.paging);
        },
    },
    methods: {
        properPrice,
        findProductGeographicalArea(obj, code) {
            Object.keys(obj).forEach(key => {
                if (obj[key] && typeof obj[key] === "object") {
                    if (parseInt(key) === code) {
                        return `${obj[key].label}`;
                    } else {
                        return this.findProductGeographicalArea(obj[key], code);
                    }
                } else if (parseInt(key) === code) {
                    return `${obj[key].label}`;
                }
            });
        },
        findProductCLIL(obj, code) {
            Object.keys(obj).forEach(key => {
                if (obj[key] && typeof obj[key] === "object") {
                    if (parseInt(key) === code) {
                        return obj[key].label;
                    } else {
                        return this.findProductCLIL(obj[key], code);
                    }
                } else if (parseInt(key) === code) {
                    return obj[key].label;
                }
            });
        },
    },
};
</script>

<style scoped>

</style>