const env = process.env;

class Config {
    constructor() {
        this.app = {
            baseURL: env.BASE_URL,
            nodeEnv: env.NODE_ENV,
            style: {
                default: { primaryColor: "#DC9814" },
                "sobook-distribution": { primaryColor: "#6DC5D1" },
                humensis: { primaryColor: "#E32131" },
                gutenberg: { primaryColor: "#DC9814" },
                ilp: { primaryColor: "#622181" },
                "imprimer-vos-livres": { primaryColor: "#7DBE31" },
                "la-boutique-sobook": { primaryColor: "#6DC5C1" },
            },
            i18n: {
                language: env.VUE_APP_SHOP_LANGUAGE || "fr",
                terms: {
                    withdrawalPoint: env.VUE_APP_WITHDRAWAL_POINT_TERM || "withdrawalPoint",
                    shopSection: env.VUE_APP_SHOP_SECTION_TERM || "catalog",
                },
            },
            debug: {
                forcedClient: env.VUE_APP_FORCED_CLIENT,
            },
        };
        this.nav = {
            sections: env.VUE_APP_NAV_SECTIONS ? env.VUE_APP_NAV_SECTIONS.split(",") : [],
            showShopSubmenu: env.VUE_APP_SHOW_SHOP_SUBMENU === "true",
        };
        this.shop = {
            sorters: env.VUE_APP_SHOP_SORTERS ? env.VUE_APP_SHOP_SORTERS.split(",") : [],
            sideFilters: env.VUE_APP_SHOP_SIDE_FILTERS ? env.VUE_APP_SHOP_SIDE_FILTERS.split(",") : [],
            products: {
                areOnFullPage: env.VUE_APP_SHOP_ARE_PRODUCTS_ON_FULL_PAGE === "true",
                isOpenFormatShown: env.VUE_APP_SHOW_PRODUCTS_OPEN_FORMAT === "true",
                isWeightShown: env.VUE_APP_SHOW_PRODUCTS_WEIGHT === "true",
            },
        };
        this.products = {
            showPriceNotLogged: env.VUE_APP_SHOW_PRICES_NOT_LOGGED === "true",
            canAddToCartNotLogged: env.VUE_APP_CAN_ADD_TO_CARD_NOT_LOGGED === "true",
        };
        this.bookCollections = {
            showWithOneProduct: env.VUE_APP_SHOW_BOOK_COLLECTIONS_WITH_ONE_PRODUCT === "true",
        };
        this.currencies = {
            selectables: env.VUE_APP_SHOP_SELECTABLE_CURRENCIES ? env.VUE_APP_SHOP_SELECTABLE_CURRENCIES.split(",") : [],
            canChangeCurrencyNotLogged: env.VUE_APP_CAN_CHANGE_CURRENCY_NOT_LOGGED === "true",
        };
        this.hermesAPI = {
            baseURL: env.VUE_APP_HERMES_API_URL,
        };
        this.soBookQuotationAPI = {
            baseURL: env.VUE_APP_SOBOOK_QUOTATION_API_URL,
        };
        this.diceAPI = {
            baseURL: env.VUE_APP_DICE_API_URL,
            basicAuth: {
                username: env.VUE_APP_DICE_API_USERNAME,
                password: env.VUE_APP_DICE_API_PASSWORD,
            },
        };
        this.monSoBookAPI = {
            baseURL: env.VUE_APP_MONSOBOOK_API_URL,
            digestAuth: {
                username: env.VUE_APP_MONSOBOOK_API_USERNAME,
                password: env.VUE_APP_MONSOBOOK_API_PASSWORD,
            },
            selfPublishingClientId: env.VUE_APP_MONSOBOOK_SELF_PUBLISHING_CLIENT_ID,
        };
        this.ohMyMailAPI = {
            baseURL: env.VUE_APP_OH_MY_MAIL_API_URL,
            basicAuth: {
                username: env.VUE_APP_OH_MY_MAIL_API_USERNAME,
                password: env.VUE_APP_OH_MY_MAIL_API_PASSWORD,
            },
        };
        this.oneYearOneBookEditor = {
            baseURL: env.VUE_APP_ONE_YEAR_ONE_BOOK_EDITOR_URL,
        };
        this.sentry = {
            enabled: env.VUE_APP_SENTRY_ENABLED === "true",
            key: env.VUE_APP_SENTRY_KEY,
            projectId: env.VUE_APP_SENTRY_PROJECT_ID,
        };
        this.stripe = {
            keys: {
                public: env.VUE_APP_STRIPE_PUBLIC_KEY,
            },
        };
        this.googleAnalytics = {
            enabled: env.VUE_APP_GOOGLE_ANALYTICS_ENABLED === "true",
            id: env.VUE_APP_GOOGLE_ANALYTICS_ID,
        };
    }

    get isShopSortByTitleEnabled() {
        return this.shop.sorters.includes("title");
    }

    get isShopSortByReleaseDateEnabled() {
        return this.shop.sorters.includes("releaseDate");
    }

    get isShopSortByPriceWTEnabled() {
        return this.shop.sorters.includes("priceWT");
    }

    areShopSortersEnabled(user) {
        return this.isShopSortByTitleEnabled || this.isShopSortByReleaseDateEnabled ||
            this.isShopSortByPriceWTEnabled && user.canSeePrices;
    }

    get isShopPublishersSideFilterEnabled() {
        return this.shop.sideFilters.includes("publishers");
    }

    get isShopBookCollectionsSideFilterEnabled() {
        return this.shop.sideFilters.includes("bookCollections");
    }

    get isHomeSectionEnabled() {
        return this.nav.sections.includes("home");
    }

    get isShopSectionEnabled() {
        return this.nav.sections.includes("shop");
    }

    get isSelfPublishingSectionEnabled() {
        return this.nav.sections.includes("selfPublishing");
    }

    get isCartSectionEnabled() {
        return this.nav.sections.includes("cart");
    }

    get isCheckoutSectionEnabled() {
        return this.nav.sections.includes("checkout");
    }

    get isProfileSectionEnabled() {
        return this.nav.sections.includes("profile");
    }

    get isHelpSectionEnabled() {
        return this.nav.sections.includes("help");
    }
}

export default Config;