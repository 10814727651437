<template>
    <div id="product-reviews">
        <transition name="fade" mode="out-in">
            <div v-if="product.reviews.length" key="reviews" class="row mt-30">
                <div class="col-md-4 mb-4">
                    <ProductRatesSummary :product="product" @delete-product-review="deleteProductReview"
                                         @show-product-review-modal="showProductReviewModal"/>
                </div>
                <div class="col-md-8">
                    <h3 class="padding-bottom-1x">{{ $t("ProductReviews.latestReviews") }} ({{ product.reviews.length }})</h3>
                    <ProductReview v-for="review in product.reviews" :key="review._id" :review="review"
                                   @edit-product-review="editProductReview"/>
                </div>
            </div>
            <div class="row mt-30" key="no-review" v-else>
                <div class="col-12 text-center">
                    <h3>{{ $t("ProductReviews.noReviewYet") }}</h3>
                </div>
                <div class="col-12 text-center">
                    <AddProductReviewBtn class="btn btn-primary" :product="product" :text="this.$t('ProductReviews.beFirstToReview')"
                                         @show-product-review-modal="$emit('show-product-review-modal')" @edit-product-review="editProductReview"/>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import ProductReview from "./ProductReview/ProductReview";
import ProductRatesSummary from "./ProductRatesSummary";
import AddProductReviewBtn from "../../../shared/Product/ProductReview/AddProductReviewBtn";

export default {
    name: "ProductReviews",
    components: {AddProductReviewBtn, ProductRatesSummary, ProductReview},
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
    methods: {
        showProductReviewModal(review) {
            this.$emit("show-product-review-modal", review);
        },
        deleteProductReview(reviewId) {
            this.$emit("delete-product-review", reviewId);
        },
        editProductReview(review) {
            this.$emit("edit-product-review", review);
        },
    },
};
</script>

<style scoped>

</style>