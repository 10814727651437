<template>
    <toggle-button :value="product.active.value" :sync="true" :labels="$t('AdministrationProductActiveSwitch.switch')" :width="60"
                   @change="toggleProductActiveness"/>
</template>

<script>
import Product from "@/classes/Product";

export default {
    name: "AdministrationProductActiveSwitch",
    props: {
        product: {
            type: Product,
            required: true,
        },
    },
    methods: {
        async toggleProductActiveness() {
            try {
                const { data } = await this.$hermesAPI.updateProduct({ _id: this.product._id, active: { value: !this.product.active.value } });
                this.$emit("product-updated", data);
                this.$toasted.success(this.$t("AdministrationProductActiveSwitch.productActivenessChanged"), { icon: "check" });
            } catch (err) {
                this.$error.displayError(err);
            }
        },
    },
};
</script>

<style scoped>

</style>