<template>
    <div class="col-lg-8 text-center">
        <div class="padding-top-2x mt-2 hidden-lg-up"></div>
        <AddressManager/>
    </div>
</template>

<script>
import AddressManager from "../../shared/Address/AddressManager/AddressManager";
export default {
    name: "ProfileAddresses",
    components: {AddressManager},
};
</script>

<style scoped>

</style>