<template>
    <div>
        <PageTitle :title="$t('CheckoutCompletePayBill.howToPayMyBill')"/>
        <div class="row justify-content-center mb-2">
            <div class="col-lg-6 col-md-8">
                <div class="card bg-secondary text-center">
                    <CheckoutCompletePayBillByCash v-if="order.billing.method === 'cash'"/>
                    <CheckoutCompletePayBillByCheck :order="order" v-else-if="order.billing.method === 'check'"/>
                    <CheckoutCompletePayBillByBankTransfer :order="order" v-else-if="order.billing.method === 'bankTransfer'"/>
                    <CheckoutCompletePayBillByOrangeMoney :order="order" v-else-if="order.billing.method === 'orangeMoney'"/>
                    <CheckoutCompletePayBillByWari :order="order" v-else-if="order.billing.method === 'wari'"/>
                    <CheckoutCompletePayBillByCreditCard :order="order" v-else-if="order.billing.method === 'creditCard'"/>
                    <CheckoutCompletePayBillByPayPal :order="order" v-else-if="order.billing.method === 'paypal'"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PageTitle from "../../../shared/Misc/PageTitle";
import CheckoutCompletePayBillByCash from "./CheckoutCompletePayBillByCash";
import CheckoutCompletePayBillByBankTransfer from "./CheckoutCompletePayBillByBankTransfer";
import CheckoutCompletePayBillByOrangeMoney from "./CheckoutCompletePayBillByOrangeMoney";
import CheckoutCompletePayBillByWari from "./CheckoutCompletePayBillByWari";
import CheckoutCompletePayBillByPayPal from "./CheckoutCompletePayBillByPayPal";
import CheckoutCompletePayBillByCheck from "./CheckoutCompletePayBillByCheck";
import CheckoutCompletePayBillByCreditCard from "./CheckoutCompletePayBillByCreditCard";

export default {
    name: "CheckoutCompletePayBill",
    components: {
        CheckoutCompletePayBillByCreditCard,
        CheckoutCompletePayBillByCheck,
        CheckoutCompletePayBillByPayPal,
        CheckoutCompletePayBillByWari,
        CheckoutCompletePayBillByOrangeMoney,
        CheckoutCompletePayBillByBankTransfer,
        CheckoutCompletePayBillByCash,
        PageTitle},
    props: {
        order: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>

</style>