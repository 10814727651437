<template>
    <div>
        <PageTitle :title="$t('checkout.title')">
            <template v-slot:left>
                <i class="fa fa-shopping-cart fa-3x text-primary"/>
            </template>
        </PageTitle>
        <div class="container padding-bottom-3x mb-2">
            <transition name="fadein" mode="out-in">
                <div key="loading" v-if="!isUserLogged || loadingFillOrder">
                    <APILoading :font-size="30" :text="$t('shared.apiLoading.getOrder')"/>
                </div>
                <div key="loadingOrder" class="row align-items-center justify-content-center" id="loading-order" v-else-if="loadingOrder">
                    <APILoading :font-size="30" :text="$t('shared.apiLoading.createYourOrder')"/>
                </div>
                <div v-else key="checkout" class="row">
                    <div class="col-xl-12 col-lg-8">
                        <CheckoutNav ref="checkoutNav"/>
                        <router-view @fill-billing="fillBilling" @fill-delivery="fillDelivery" @set-billing-method="setBillingMethod"
                                     @change-step="changeStep" @confirm-review="confirmReview" @create-order="createOrder"
                                     @update-preferences="updatePreferences" @apply-discount-code="applyDiscountCode"
                                     @edit-order-product="editOrderProduct" :order="order" :preferences="preferences" class="fadeIn"/>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PageTitle from "../../shared/Misc/PageTitle";
import CheckoutNav from "./CheckoutNav";
import Order from "../../../classes/Order";
import APILoading from "../../shared/Misc/APILoading";
import Product from "../../../classes/Product";
import { isCartFullOfProductsWithAgreement } from "@/helpers/user";
import DiscountCode from "../../../classes/DiscountCode";
import { decodeJWTToken } from "@/helpers/API";

export default {
    name: "Checkout",
    components: { APILoading, CheckoutNav, PageTitle },
    data() {
        return {
            order: new Order(),
            preferences: {},
            loadingOrder: false,
            loadingFillOrder: true,
        };
    },
    computed: {
        ...mapGetters("user", {
            user: "user",
            userAddresses: "addresses",
            userFullName: "fullName",
            userSociety: "society",
            userCart: "cart",
            isUserLogged: "isLogged",
        }),
        ...mapGetters("config", {
            uniqueDeliveryMethod: "uniqueDeliveryMethod",
            uniquePaymentMethod: "uniquePaymentMethod",
        }),
    },
    async created() {
        if (this.$route.name !== "checkoutBilling") {
            await this.$router.push("/checkout");
        }
        await this.checkUserAndFillOrder();
        if (this.$route.query.mode === "one-click") {
            await this.oneClickOrder();
        }
    },
    methods: {
        ...mapActions("user", {
            checkUserAuthentication: "checkUserAuthentication",
        }),
        fillBilling(billing) {
            this.order.billing = billing;
            this.preferences.billing.addressId = billing.favoriteBillingAddress ? billing.addressId : null;
            if (isCartFullOfProductsWithAgreement(this.user)) {
                this.order.billing.method = "agreement";
            }
            this.changeStep(1);
        },
        fillDelivery(delivery) {
            this.order.delivery = delivery;
            if (delivery.favoriteDelivery) {
                this.preferences.delivery.method = delivery.method;
                this.preferences.delivery.addressId = delivery.addressId;
                this.preferences.delivery.carrierId = delivery.method === "home" ? delivery.carrierId : null;
            } else {
                this.preferences.delivery = {
                    method: null,
                    addressId: null,
                    carrierId: null,
                };
            }
            this.changeStep(2);
        },
        changeStep(stepNumber) {
            this.$refs.checkoutNav.changeStep(stepNumber);
        },
        async checkOneClickOrderDeliveryData() {
            try {
                if (!this.order.delivery.to) {
                    return { success: false, step: 1, message: this.$t("Checkout.missingDeliveryRecipientForOneClickOrder") };
                } else if (!this.order.delivery.method) {
                    return { success: false, step: 1, message: this.$t("Checkout.missingDeliveryMethodForOneClickOrder") };
                } else if (!this.order.delivery.addressId ||
                    this.order.delivery.method === "home" && !this.user.addresses.find(address => address._id === this.order.billing.addressId)) {
                    return { success: false, step: 1, message: this.$t("Checkout.missingDeliveryAddressForOneClickOrder") };
                } else if (this.order.delivery.method === "home") {
                    // TODO: Check si transporteur dispo
                    if (!this.order.delivery.carrierId) {
                        return { success: false, step: 1, message: this.$t("Checkout.missingDeliveryCarrierForOneClickOrder") };
                    }
                } else if (this.order.delivery.method === "withdrawalPoint") {
                    await this.$hermesAPI.getWithdrawalPoint(this.order.delivery.addressId);
                }
                return null;
            } catch (e) {
                if (this.order.delivery.method === "withdrawalPoint") {
                    return { success: false, step: 1, message: this.$t("Checkout.missingWithdrawalPointForOneClickOrder") };
                }
            }
        },
        async checkOneClickOrder() {
            if (!this.order.billing.to) {
                return { success: false, step: 0, message: this.$t("Checkout.missingBillingRecipientForOneClickOrder") };
            } else if (!this.order.billing.addressId || !this.user.addresses.find(address => address._id === this.order.billing.addressId)) {
                return { success: false, step: 0, message: this.$t("Checkout.missingBillingAddressForOneClickOrder") };
            }
            const invalidDeliveryData = await this.checkOneClickOrderDeliveryData();
            if (invalidDeliveryData) {
                return invalidDeliveryData;
            } else if (!this.order.billing.method) {
                return { success: false, step: 3, message: this.$t("Checkout.missingBillingMethodForOneClickOrder") };
            } else if (this.order.billing.method === "paypal") {
                return { success: false, step: 3, message: this.$t("Checkout.youMustProvidePayPalCredentialsForOneClickOrder") };
            } else if (this.order.billing.method === "creditCard") {
                return { success: false, step: 3, message: this.$t("Checkout.youMustProvideCreditCardInfoForOneClickOrder") };
            }
            return { success: true };
        },
        async oneClickOrder() {
            try {
                this.loadingOrder = true;
                const result = await this.checkOneClickOrder();
                if (!result.success) {
                    setTimeout(() => {
                        this.changeStep(result.step);
                        this.$toasted.error(result.message, { icon: "times" });
                    }, 500);
                } else {
                    await this.$hermesAPI.testOrder(this.order);
                    await this.createOrder();
                }
            } catch (e) {
                setTimeout(() => {
                    this.changeStep(0);
                    this.$toasted.error(this.$t("Checkout.cantOneClickOrder"), { icon: "times" });
                }, 500);
            } finally {
                this.loadingOrder = false;
            }
        },
        fillOrderBasedOnUniqueMethods() {
            if (this.uniqueDeliveryMethod) {
                this.order.delivery.method = this.uniqueDeliveryMethod;
            }
            if (this.uniquePaymentMethod && this.order.billing.method !== "agreement") {
                this.order.billing.method = this.uniquePaymentMethod;
            }
        },
        async fillOrderBasedOnDeliveryPreferences() {
            this.order.delivery.method = this.user.preferences.delivery.method;
            if (this.user.preferences.delivery.addressId) {
                this.order.delivery.addressId = this.user.preferences.delivery.addressId;
                if (this.user.preferences.delivery.method === "home") {
                    const deliveryAddress = this.userAddresses.find(address => address._id === this.user.preferences.delivery.addressId);
                    if (deliveryAddress) {
                        this.order.delivery.address = deliveryAddress;
                    }
                } else if (this.user.preferences.delivery.method === "withdrawalPoint") {
                    try {
                        const { data: withdrawalPoint } = await this.$hermesAPI.getWithdrawalPoint(this.user.preferences.delivery.addressId);
                        this.order.delivery.address = withdrawalPoint.address;
                    } catch (err) {
                        console.log(err);
                    }
                }
            }
            if (this.user.preferences.delivery.method === "home" && this.user.preferences.delivery.carrierId) {
                this.order.delivery.carrierId = this.user.preferences.delivery.carrierId;
            }
        },
        async fillOrderBasedOnPreferences() {
            if (this.user.preferences.billing.addressId) {
                this.order.billing.addressId = this.user.preferences.billing.addressId;
                const billingAddress = this.userAddresses.find(address => address._id === this.user.preferences.billing.addressId);
                if (billingAddress) {
                    this.order.billing.address = billingAddress;
                }
            }
            if (this.user.preferences.delivery.method) {
                this.fillOrderBasedOnDeliveryPreferences();
            }
            if (this.user.preferences.billing.method) {
                this.order.billing.method = isCartFullOfProductsWithAgreement(this.user) ? "agreement" : this.user.preferences.billing.method;
            }
        },
        async checkUserAndFillOrder() {
            if (this.isUserLogged === undefined) {
                return;
            }
            await this.checkUserAuthentication();
            if (this.isUserLogged) {
                this.loadingFillOrder = true;
                this.order.user = this.user._id;
                this.preferences = JSON.parse(JSON.stringify(this.user.preferences));
                this.order.billing.to = this.user.displayedName;
                this.order.delivery.to = this.user.displayedName;
                this.order.products = [];
                if (this.userCart && !this.userCart.length) {
                    return await this.$router.push("/cart");
                }
                if (this.userCart) {
                    this.order.products = this.userCart.map(p => new Product(p));
                }
                await this.fillOrderBasedOnPreferences();
                this.fillOrderBasedOnUniqueMethods();
                this.loadingFillOrder = false;
            }
        },
        async setBillingMethod(billing) {
            this.order.billing.method = billing.method;
            if (billing.paymentIntent) {
                this.order.creditCard.paymentIntentID = billing.paymentIntent.id;
            }
            // TODO: Faire la même chose pour PayPal
            if (billing.favoritePaymentMethod) {
                this.preferences.billing.method = billing.method;
            }
            await this.createOrder();
        },
        async createOrder() {
            try {
                this.loadingOrder = true;
                await this.$store.dispatch("user/updateUser", { user: { preferences: this.preferences }, options: { toasted: false } });
                const { userId } = decodeJWTToken();
                const response = await this.$hermesAPI.createOrder({ ...this.order, user: userId });
                this.$store.commit("user/setCart", []);
                await this.$router.push(`/checkout-complete?order=${response.data._id}`);
            } catch (err) {
                this.$error.displayError(err);
            } finally {
                this.loadingOrder = false;
            }
        },
        confirmReview(data) {
            this.order.totalWT = data.review.base.total.priceWT;
            this.order.currency = data.review.base.currency;
            if (data.comment) {
                this.order.comment = data.comment;
            }
            this.changeStep(3);
        },
        updatePreferences(preferences) {
            this.preferences = preferences;
        },
        applyDiscountCode(discountCode) {
            this.order.discountCode = new DiscountCode(discountCode);
        },
        editOrderProduct(product) {
            const idx = this.order.products.findIndex(p => p._id === product._id);
            if (idx !== -1) {
                this.order.products.splice(idx, 1, new Product(product));
            }
        },
    },
    watch: {
        "$route.query": {
            async handler() {
                if (this.$route.query.mode === "one-click") {
                    await this.fillOrderBasedOnPreferences();
                    await this.oneClickOrder();
                }
            },
            deep: true,
        },
        userCart() {
            // if (this.userCart && !this.userCart.length) {
            //     return this.$router.push("/cart");
            // } else if (this.userCart) {
            //     const savedOrderProducts = JSON.parse(JSON.stringify(this.order.products));
            //     this.order.products = this.userCart.map(p => new Product(p));
            //     // for (const savedProduct of savedOrderProducts) {
            //     //     const idx = this.order.products.findIndex(p => p._id === savedProduct._id);
            //     //     if (idx !== -1 && savedProduct.customization.dedication) {
            //     //         this.order.products[idx].customization.dedication = savedProduct.customization.dedication;
            //     //     }
            //     // }
            // }
        },
    },
};
</script>

<style scoped lang="scss">
    #loading-order {
        height: 30vh;
    }
</style>