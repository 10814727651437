<template>
    <div id="administration-products">
        <transition name="fade" mode="out-in">
            <div key="loading" v-if="!products">
                <APILoading :font-size="30" :text="$t('AdministrationProducts.loading')"/>
            </div>
            <div key="products" v-else class="row">
                <div class="col-12">
                    <v-client-table id="products" :data="products" :columns="columns" :options="options">
                        <SubmitBtn slot="afterFilter" :loading="isLoadingCSV" classes="btn btn-info" :text="$t('AdministrationProducts.export')"
                                   @click="downloadCSV"/>
                        <button v-if="hermesAPIConfig.admin.products.canAdd" class="btn btn-primary pull-right" slot="afterFilter"
                                @click.prevent="showAdministrationProductModal()">
                            <i class="fa fa-plus-circle mr-1"/>
                            <span v-html="$t('AdministrationProducts.addProduct')"/>
                        </button>
                        <div slot="title" slot-scope="props">
                            <router-link :to="`/product/${props.row._id}`" target="_blank" class="d-flex align-items-center">
                                <img width="50px" class="box-shadow mr-2" v-lazy="props.row.firstCoverPhotoUrl" alt="Product"/>
                                <span v-html="props.row.title"/>
                            </router-link>
                        </div>
                        <div slot="isbn" slot-scope="props" v-html="displayedProductISBN(props.row)"/>
                        <div slot="createdBy" slot-scope="props" v-html="displayedProductCreator(props.row)"/>
                        <div slot="status" slot-scope="props" class="text-center">
                            <AdministrationProductActiveSwitch :product="props.row" @product-updated="updateProduct"/>
                            <div v-if="props.row.source === 'user'" class="mt-2">
                                <ProductFileStatusBadge :product="props.row" book-part="corpus" class="mb-2 w-100"/>
                                <ProductFileStatusBadge :product="props.row" book-part="cover" class="w-100"/>
                            </div>
                        </div>
                        <div slot="createdAt" slot-scope="props" v-html="properDate(props.row.createdAt)"/>
                        <div slot="actions" slot-scope="props">
                            <router-link :to="`/product/${props.row._id}`" target="_blank" class="btn btn-xs btn-primary btn-block my-0">
                                <i class="fa fa-eye mr-2"/>
                                <span v-html="$t('AdministrationProducts.see')"/>
                            </router-link>
                            <UpdateProductBtn :product="props.row" @show-administration-product-modal="showAdministrationProductModal" class="my-1"/>
                            <DeleteProductBtn :product="props.row" classes="btn btn-danger btn-block btn-xs my-0" @delete-product="deleteProduct"/>
                        </div>
                    </v-client-table>
                </div>
            </div>
        </transition>
        <AdministrationProductModal ref="administrationProductModal" @product-added="addProduct" @product-updated="updateProduct"/>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import FileSaver from "file-saver";
import APILoading from "@/components/shared/Misc/APILoading";
import Product from "@/classes/Product";
import ProductFileStatusBadge from "@/components/shared/Product/ProductFiles/ProductFileStatusBadge";
import DeleteProductBtn from "@/components/Hermes/SelfPublishing/SelfPublishingBooks/DeleteProductBtn";
import { properDate } from "@/helpers/date";
import AdministrationProductModal from "@/components/Administration/AdministrationProducts/AdministrationProductModal";
import AdministrationProductActiveSwitch
    from "@/components/Administration/AdministrationProducts/AdministrationProductActiveSwitch";
import UpdateProductBtn from "@/components/Hermes/SelfPublishing/SelfPublishingBooks/UpdateProductBtn";
import SubmitBtn from "@/components/shared/Misc/SubmitBtn";

export default {
    name: "AdministrationProducts",
    components: {
        SubmitBtn,
        UpdateProductBtn,
        AdministrationProductActiveSwitch,
        AdministrationProductModal, DeleteProductBtn, ProductFileStatusBadge, APILoading },
    data() {
        return {
            products: null,
            columns: ["title", "isbn", "createdBy", "status", "createdAt", "actions"],
            options: {
                headings: this.$t("AdministrationProducts.headings"),
                perPage: 10,
                filterable: ["title", "isbn", "createdBy", "status", "createdAt"],
                sortable: ["title", "isbn", "createdBy", "status", "createdAt"],
                orderBy: {
                    ascending: false,
                    column: "createdAt",
                },
                filterAlgorithm: {
                    createdBy(row, query) {
                        query = query.toLowerCase();
                        const creator = this.displayedProductCreator(row);
                        return creator ? creator.toLowerCase().includes(query) : undefined;
                    },
                },
            },
            isLoadingCSV: false,
        };
    },
    computed: {
        ...mapGetters("config", {
            hermesAPIConfig: "hermesAPI",
        }),
    },
    async created() {
        await this.getProducts();
    },
    methods: {
        async getProducts() {
            try {
                const query = { "per-page": 0, fields: "title,isbn,source,publisher,selfPublisher,files,active,createdAt,firstCoverPhotoUrl" };
                const { data } = await this.$hermesAPI.getProducts(query);
                this.products = data.products.map(product => new Product(product));
            } catch (err) {
                this.$error.displayError(err);
            }
        },
        displayedProductISBN(product) {
            return product.isbn ? product.isbn : this.$t("VueClientTable.undefined");
        },
        displayedProductCreator(product) {
            if (product.source === "publisher") {
                return product.publisher.name;
            }
            return product.selfPublisher.isProfessional ? product.selfPublisher.society.name : product.selfPublisher.fullName;
        },
        addProduct(product) {
            this.products.push(new Product(product));
        },
        updateProduct(product) {
            const idx = this.products.findIndex(({ _id }) => _id === product._id);
            if (idx !== -1) {
                this.products.splice(idx, 1, new Product(product));
            }
        },
        deleteProduct(product) {
            const deletedProductIdx = this.products.findIndex(({ _id }) => _id === product._id);
            if (deletedProductIdx !== -1) {
                this.products.splice(deletedProductIdx, 1);
            }
        },
        showAdministrationProductModal(product) {
            this.$refs.administrationProductModal.show(product);
        },
        async downloadCSV() {
            try {
                this.isLoadingCSV = true;
                const response = await this.$hermesAPI.getProducts({ "response-format": "CSV", "per-page": 0 });
                const blob = new Blob([response.data], { type: "text/csv" });
                FileSaver.saveAs(blob, `products.csv`);
            } catch (err) {
                this.$error.displayError(err);
            } finally {
                this.isLoadingCSV = false;
            }
        },
        properDate,
    },
};
</script>

<style scoped>

</style>