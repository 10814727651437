import Axios from "axios";
import Config from "../../config";

const OhMyMailAPI = {
    // eslint-disable-next-line no-unused-vars
    install(Vue) {
        const axios = Axios.create({
            baseURL: Config.ohMyMailAPI.baseURL,
            timeout: 0,
            headers: {
                "Content-Type": "application/json",
            },
            auth: {
                username: Config.ohMyMailAPI.basicAuth.username,
                password: Config.ohMyMailAPI.basicAuth.password,
            },
        });

        Vue.prototype.$ohMyMailAPI = {};

        Vue.prototype.$ohMyMailAPI.sendMail = data => axios.post("/mails/default", data);

    },
};

export default OhMyMailAPI;