<template>
    <div id="footer">
        <div v-if="hermesAPIConfig.client === 'imprimer-vos-livres'">
            <PrintCorpFooter/>
            <div class="d-flex justify-content-between">
                <img id="typolibris-logo" :src="IMGs.typolibrisService" alt="Un service Typolibris"/>
                <div class="d-flex align-items-center mr-2">
                    <span class="text-less-bold" v-html="`© ${currentYear} imprimervoslivres.com`"/>
                    <span class="mx-2" v-html="'-'"/>
                    <router-link v-html="'CGV'" to="/terms-and-conditions"/>
                    <a id="facebook-button" class="ml-2" href="https://www.facebook.com/Typolibris/" target="_blank">
                        <i class="fab fa-2x fa-facebook-square"/>
                    </a>
                    <a id="instagram-button" class="ml-2" href="https://www.instagram.com/laclairieredeslivres/" target="_blank">
                        <i class="fab fa-2x fa-instagram-square"/>
                    </a>
                </div>
            </div>
        </div>
        <DefaultFooter v-else/>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import DefaultFooter from "./DefaultFooter/DefaultFooter";
import PrintCorpFooter from "./CustomFooter/PrintCorpFooter/PrintCorpFooter";
import typolibrisService from "@/assets/img/imprimer-vos-livres/custom-footer/typolibris-service.png";

export default {
    name: "Footer",
    components: {PrintCorpFooter, DefaultFooter},
    computed: {
        ...mapGetters("config", {
            hermesAPIConfig: "hermesAPI",
        }),
        currentYear() {
            return new Date().getFullYear();
        },
    },
    data() {
        return {
            IMGs: { typolibrisService },
        };
    },
};
</script>

<style lang="scss" scoped>

    #typolibris-logo {
        width: 200px;
        height: 30px;
        margin-left: 5px;
    }

    #facebook-button {
        color: #0088FF;
    }

    #instagram-button {
        color: #ff0074;
    }

</style>