<template>
    <div id="terms-and-conditions">
        <PageTitle :title="$t('termsAndConditions.title')">
            <template v-slot:left>
                <i class="fa fa-handshake fa-3x text-primary"/>
            </template>
        </PageTitle>
        <div class="row justify-content-center">
            <div class="col-sm-2">
                <img :src="agreementSVG" alt="Agreement"/>
            </div>
        </div>
        <div class="container">
            <section class="row align-items-center padding-top-2x padding-bottom-2x">
                <TermsAndConditionsILP v-if="hermesAPIConfig.client === 'ilp'"/>
                <TermsAndConditionsBoutiqueSoBook v-else-if="hermesAPIConfig.client === 'la-boutique-sobook'"/>
                <TermsAndConditionsImprimerVosLivres v-else-if="hermesAPIConfig.client === 'imprimer-vos-livres'"/>
                <TermsAndConditionsAirveyEditions v-else-if="hermesAPIConfig.client === 'airvey-editions'"/>
            </section>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { importSVG } from "@/helpers/file";
import PageTitle from "../../shared/Misc/PageTitle";
import TermsAndConditionsILP from "./TermsAndConditionsILP";
import TermsAndConditionsBoutiqueSoBook from "./TermsAndConditionsBoutiqueSoBook";
import TermsAndConditionsImprimerVosLivres from "./TermsAndConditionsImprimerVosLivres";
import TermsAndConditionsAirveyEditions from "@/components/Hermes/TermsAndConditions/TermsAndConditionsAirveyEditions";

export default {
    name: "TermsAndConditions",
    components: {
        TermsAndConditionsAirveyEditions,
        TermsAndConditionsImprimerVosLivres, TermsAndConditionsBoutiqueSoBook, TermsAndConditionsILP, PageTitle},
    computed: {
        ...mapGetters("config", {
            hermesAPIConfig: "hermesAPI",
        }),
        agreementSVG() {
            return importSVG("agreement", this.hermesAPIConfig.client);
        },
    },
};
</script>

<style scoped>

</style>