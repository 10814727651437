<template>
    <span class="d-flex justify-content-between align-items-center">
        <a class="book-collection" :class="{ chosen }" @click.prevent="changeQueryParam(bookCollection)">
            {{ bookCollectionName }}
        </a>
        <span v-tooltip="countTooltip" class="badge badge-default">
            {{ bookCollectionCount }}
        </span>
    </span>
</template>

<script>
export default {
    name: "ShopMenuBookCollection",
    props: {
        bookCollections: {
            required: true,
        },
        bookCollection: {
            required: true,
        },
        chosen: {
            type: Boolean,
            default: false,
        },
        chosenPublisher: {
            required: true,
        },
    },
    methods: {
        changeQueryParam(bookCollection) {
            this.$emit("change-query-param", { "book-collection": bookCollection ? bookCollection.name : null, page: 1 });
        },
    },
    computed: {
        bookCollectionName() {
            return this.bookCollection ? this.bookCollection.name : this.$t("ShopMenuBookCollection.all");
        },
        bookCollectionCount() {
            const reducer = (accumulator, bookCollection) => accumulator + bookCollection.productCount;
            return this.bookCollection ? this.bookCollection.productCount : this.bookCollections.reduce(reducer, 0);
        },
        countTooltip() {
            const bookCollectionTooltip = this.$tc("ShopMenuBookCollection.bookCollectionHas", this.bookCollectionCount, { productCount: this.bookCollectionCount });
            const shopTooltip = this.$tc("ShopMenuBookCollection.shopHas", this.bookCollectionCount, { productCount: this.bookCollectionCount });
            const publisherTooltip = this.$tc("ShopMenuBookCollection.publisherHas", this.bookCollectionCount, { productCount: this.bookCollectionCount });
            if (this.bookCollection) {
                return bookCollectionTooltip;
            } else {
                return this.chosenPublisher ? publisherTooltip : shopTooltip;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .book-collection {
        font-size: 1.05rem;
        cursor: pointer;
    }

    .chosen {
        font-weight: 600;
        font-size: 1.1rem;
    }
</style>