<template>
    <div>
        <h6 class="text-muted text-normal text-uppercase mb-4">
            <span class="fas fa-map-marked-alt mr-1 text-primary"/>
            {{ $t('shared.address.addressForm.generalInfo') }}
        </h6>
        <hr class="margin-bottom-1x">
        <div class="row">
            <div class="col-md-6">
                <ValidationProvider v-slot="{ errors, classes }">
                    <div class="form-group">
                        <label :for="HTMLIds.address.name">
                            {{$t("shared.address.addressForm.name")}}
                            <span>*</span>
                        </label>
                        <input :placeholder="$t('shared.address.addressForm.requiredName')" :disabled="disabled" :class="classes"
                               class="form-control" v-model="localAddress.name" type="text" :id="HTMLIds.address.name" required>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                    </div>
                </ValidationProvider>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label :for="HTMLIds.address.country">
                        {{$t("shared.address.addressForm.country")}}
                        <span>*</span>
                    </label>
                    <v-select :id="HTMLIds.address.country" @input="setSelectedCountry" :clearable="false" :disabled="disabled"
                              :value="formattedCountrySelected" :options="formattedCountries" :filter-by="filterBy">
                        <template slot="option" slot-scope="option">
                            <img width="20" alt="flag" :src="option.flagURL"/> {{ option.label }}
                        </template>
                    </v-select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label :for="HTMLIds.address.addressType">
                        {{$t("shared.address.addressForm.addressType")}}
                        <span>*</span>
                    </label>
                    <v-select v-model="localAddress.addressType" :id="HTMLIds.address.addressType" @input="setSelectedAddressType" :clearable="false"
                              :placeholder="$t('shared.address.addressForm.required')" :value="formattedAddressTypeSelected" :options="formattedAddressType">
                        <template slot="option" slot-scope="option">
                            {{ option.label }}
                        </template>
                    </v-select>
                </div>
            </div>
            <div class="col-md-6"></div>
        </div>
        <div class="row" v-if="localAddress.addressType === 'Professionnel'">
            <div class="col-md-6">
                <ValidationProvider v-slot="{ errors, classes }">
                    <div class="form-group">
                        <label :for="HTMLIds.address.company">
                            {{ $t("shared.address.addressForm.company") }}
                            <span>*</span>
                        </label>
                        <input :placeholder="$t('shared.address.addressForm.required')" :disabled="disabled" :class="classes"
                               class="form-control" v-model="localAddress.company" type="text" :id="HTMLIds.address.company" required>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                    </div>
                </ValidationProvider>
            </div>
            <div class="col-md-6"></div>
        </div>
        <div class="row" v-if="localAddress.addressType === 'Particulier'">
            <div class="col-md-6">
                <ValidationProvider v-slot="{ errors, classes }">
                    <div class="form-group">
                        <label :for="HTMLIds.address.firstname">
                            {{$t("shared.address.addressForm.firstname")}}
                            <span>*</span>
                        </label>
                        <input :placeholder="$t('shared.address.addressForm.required')" :disabled="disabled" :class="classes"
                               class="form-control" v-model="localAddress.firstname" type="text" :id="HTMLIds.address.firstname" required>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                    </div>
                </ValidationProvider>
            </div>
            <div class="col-md-6">
                <ValidationProvider v-slot="{ errors, classes }">
                    <div class="form-group">
                        <label :for="HTMLIds.address.lastname">
                            {{$t("shared.address.addressForm.lastname")}}
                            <span>*</span>
                        </label>
                        <input :placeholder="$t('shared.address.addressForm.required')" :disabled="disabled" :class="classes"
                               class="form-control" v-model="localAddress.lastname" type="text" :id="HTMLIds.address.lastname" required>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                    </div>
                </ValidationProvider>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <ValidationProvider v-slot="{ errors, classes }">
                    <div class="form-group">
                        <label :for="HTMLIds.address.street">
                            {{$t("shared.address.addressForm.address")}}
                            <span>*</span>
                        </label>
                        <input :placeholder="$t('shared.address.addressForm.required')" class="form-control" :class="classes"
                               type="text" :id="HTMLIds.address.street" v-model="localAddress.street" :disabled="disabled"
                               required>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                    </div>
                </ValidationProvider>
            </div>
            <div class="col-md-6">
                <ValidationProvider v-slot="{ classes }">
                    <div class="form-group">
                        <label :for="HTMLIds.address.streetBis">
                            {{$t("shared.address.addressForm.addressBis")}}
                        </label>
                        <input class="form-control" type="text" v-model="localAddress.streetBis" :disabled="disabled"
                                :placeholder="!disabled && $t('shared.address.addressForm.optional')" :id="HTMLIds.address.streetBis"
                                :class="classes">
                    </div>
                </ValidationProvider>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <ValidationProvider rules="zip_code" v-slot="{ errors, classes }">
                    <div class="form-group">
                        <label :for="HTMLIds.address.zipCode">
                            {{$t("shared.address.addressForm.zipCode")}}
                            <span v-if="!isZipCodeOptional">*</span>
                        </label>
                        <input :placeholder="!isZipCodeOptional ? $t('shared.address.addressForm.required') : $t('shared.address.addressForm.optional')"
                               class="form-control" type="text" :id="HTMLIds.address.zipCode" v-model="localAddress.zipCode"
                               :disabled="disabled"
                               :required="!isZipCodeOptional" :class="classes">
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                    </div>
                </ValidationProvider>
            </div>
            <div class="col-md-6">
                <ValidationProvider v-slot="{ errors, classes }">
                    <div class="form-group">
                        <label :for="HTMLIds.address.city">
                            {{ $t("shared.address.addressForm.city")  }}
                            <span>*</span>
                        </label>
                        <input :placeholder="$t('shared.address.addressForm.required')" :disabled="disabled" :class="classes"
                               class="form-control" v-model="localAddress.city" type="text" :id="HTMLIds.address.city" required>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                    </div>
                </ValidationProvider>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <ValidationProvider rules="mobile_phone" v-slot="{ errors, classes }">
                    <div class="form-group">
                        <label :for="HTMLIds.address.mobilePhone">
                            {{ $t("shared.address.addressForm.mobilePhone") }}
                            <span v-if="isMobilePhoneRequiredForDelivery">*</span>
                        </label>
                        <input :disabled="disabled" class="form-control" :required="isMobilePhoneRequiredForDelivery"
                               v-model="localAddress.mobilePhone" type="text" :id="HTMLIds.address.mobilePhone" :class="classes">
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                    </div>
                </ValidationProvider>
            </div>
            <div class="col-md-6"></div>
        </div>
        <div v-if="isUserProfessional">
            <hr>
            <h6 class="text-muted text-normal text-uppercase padding-top-1x mb-4">
                <i class="fas fa-building text-primary mr-1"/>
                    {{ $t("shared.address.addressForm.societyInfo") }}
            </h6>
            <hr class="mb-4">
            <div class="row">
                <div class="col-md-6">
                    <ValidationProvider v-slot="{ classes }">
                        <div class="form-group">
                            <label :for="HTMLIds.address.GLN">
                                {{$t("shared.address.addressForm.gln")}}
                            </label>
                            <input :placeholder="!disabled && $t('shared.address.addressForm.optional')" :disabled="disabled"
                                   class="form-control" v-model="localAddress.gln" type="text" :id="HTMLIds.address.GLN"
                                   :class="classes"/>
                        </div>
                    </ValidationProvider>
                </div>
                <div class="col-md-6">
                    <ValidationProvider v-slot="{ classes }">
                        <div class="form-group">
                            <label :for="HTMLIds.address.VATIN">
                                {{$t("shared.address.addressForm.vatin")}}
                            </label>
                            <input :placeholder="!disabled && $t('shared.address.addressForm.optional')" :disabled="disabled"
                                   class="form-control" v-model="localAddress.vatin" type="text" :id="HTMLIds.address.VATIN"
                                   :class="classes"/>
                        </div>
                    </ValidationProvider>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import uniqid from "uniqid";
import { mapGetters } from "vuex";
import Address from "@/classes/Address";
import { filterBy } from "@/helpers/v-select";

export default {
    name: "AddressForm",
    props: {
        address: {
            type: Object,
            default: () => new Address(),
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            localAddress: new Address(this.address),
            HTMLIds: {
                address: {
                    name: uniqid("address-name-"),
                    firstname: uniqid("address-firstname-"),
                    lastname: uniqid("address-lastname-"),
                    company: uniqid("address-company-"),
                    street: uniqid("address-street-"),
                    streetBis: uniqid("address-street-bis-"),
                    zipCode: uniqid("address-zip-code-"),
                    city: uniqid("address-city-"),
                    country: uniqid("address-country-"),
                    mobilePhone: uniqid("address-mobile-phone-"),
                    VATIN: uniqid("address-vatin-"),
                    GLN: uniqid("address-gln-"),
                    addressType: uniqid("address-address-type-"),
                },
            },
        };
    },
    computed: {
        ...mapGetters("countries", {
            countries: "countries",
        }),
        ...mapGetters("config", {
            shop: "shop",
        }),
        ...mapGetters("config", {
            isZipCodeOptional: "isZipCodeOptional",
            isMobilePhoneRequiredForDelivery: "isMobilePhoneRequiredForDelivery",
        }),
        ...mapGetters("user", {
            user: "user",
            isUserProfessional: "isProfessional",
        }),
        isFormValid() {
            return !(!this.localAddress || !this.localAddress.country.iso || !this.countries || !this.localAddress.addressType);
        },
        formattedCountries() {
            return this.countries ? this.countries.map(country => ({ value: country.alpha3ISO, label: country.name, flagURL: country.flagURL })) : [];
        },
        formattedCountrySelected() {
            return this.countries ? this.formattedCountries.find(country => country.value === this.localAddress.country.iso): {};
        },
        formattedAddressType() {
            const addressTypes = [
                { label: "Particulier", value: "Particulier" },
                { label: "Professionnel", value: "Professionnel" },
            ];
            return addressTypes;
        },
        formattedAddressTypeSelected() {
            return this.addressTypes ? this.formattedAddressType.find(addressType => addressType.value === this.localAddress.addressType): {};
        },
    },
    methods: {
        filterBy,
        resetAddress() {
            this.localAddress = new Address();
            if (this.shop.address.country.iso) {
                this.localAddress.country.iso = this.shop.address.country.iso;
                this.setSelectedCountry(this.formattedCountrySelected);
            }
        },
        getAddress() {
            if (this.isFormValid) {
                this.localAddress.sanitize(this.user);

                // Empty content (Sobook Express need firstname/lastname OR company)
                if (this.localAddress.addressType === "Professionnel") {
                    this.localAddress.firstname = "";
                    this.localAddress.lastname = "";
                } else {
                    this.localAddress.company = "";
                }
                return this.localAddress;
            }
            return null;
        },
        setSelectedCountry(country) {
            if (!country) {
                return;
            }
            this.localAddress.country.iso = country.value;
            this.localAddress.country.name = country.label;
        },
        setSelectedAddressType(addressType) {
            if (!addressType) {
                return ;
            }
            this.localAddress.addressType = addressType.value;
        },
    },
    watch: {
        isFormValid(valid) {
            this.$emit("form-valid", valid);
        },
        address: {
            deep: true,
            handler(newAddress) {
                this.localAddress = new Address(newAddress);
                this.$emit("form-valid", this.isFormValid);
            },
        },
    },
};
</script>

<style lang="scss" scoped>
    .form-group {
        label {
            span {
                color: red;
            }
        }
    }
</style>