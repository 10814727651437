<template>
    <div id="terms-and-conditions-boutique-sobook">
        <h2>Opposabilité</h2>
        <p>Le fait de valider une commande sur le site de SoBook implique l'adhésion entière et sans réserve à
            ces présentes Conditions Générales de Vente. Toute condition contraire, à défaut d'acceptation
            expresse et écrite, sera inopposable à SoBook.</p>
        <hr class="mt-3 mb-3"/>
        <h2>Propriété intellectuelle</h2>
        <p>L’éditeur, quel que soit le statut de ce dernier, garanti à SoBook qu’il dispose de tous les droits
            nécessaires permettant d’éditer autrui et qu’il respecte la propriété intellectuelle des auteurs dont il fait
            imprimer les livres par SoBook.</p>
        <hr class="mt-3 mb-3"/>
        <h2>Devis - barèmes</h2>
        <p>Les devis et barèmes seront établis sur demande et présentation du travail à réaliser. Ils sont valables
            pour un délai d'un mois. Toutefois, pour des travaux à caractère répétitif, le même devis ou barème
            pourra, suivant accord écrit, avoir une durée de validité plus longue et concerner plusieurs opérations
            successives. Le devis ou barème ne pourra être considéré comme définitif que le jour où un exemplaire
            de chaque document (fichier, papier …) aura été remis à SoBook.
        </p>
        <p>Toute livraison de documents, acomptes ou provisions, avec ou sans confirmation écrite de commande
            vaut acceptation du devis ou barème au prix fixé. En cas de différence entre les documents fournis
            pour l'élaboration du devis et ceux remis effectivement, le devis pourra être majoré.</p>
        <hr class="mt-3 mb-3"/>
        <h2>Commandes</h2>
        <p>Au préalable de toute commande, le client devra s’inscrire sur le site pour remplir la fiche client
            correspondant à son statut et valider son acceptation des conditions générales de vente et d’usage du
            site internet.</p>
        <p>Suite à cette inscription, si toutes les lignes ont été correctement renseignées, il lui sera envoyé par
            mail un accès à son compte client qui est une application personnelle lui permettant de faire imprimer
            en toute autonomie et de saisir les coordonnées d’expéditions à ses clients, si cette option de
            prestation optionnelle a été prévue lors de l’établissement de son devis.</p>
        <p>Le client saisit lui-même sa commande et joint tous les documents nécessaires.
            Toute demande de modification de commande en cours devra être écrite et engendrera une révision du
            devis. Les prix sont fixés pour une prestation continue et ininterrompue. SoBook se réserve le droit de
            refuser une commande, notamment en cas d'incident de paiement antérieur ou pour des raisons
            techniques. Sauf stipulation expresse du client, SoBook pourra sous traiter tout ou partie des
            commandes.</p>
        <hr class="mt-3 mb-3"/>
        <h2>Conditions de règlement</h2>
        <p>Les prestations sont facturées au prix indiqué sur le devis. Le client devra payer le tarif postal en
            vigueur au moment de l'affranchissement, le montant réel des frais d'expédition sera réajusté sur la
            base des informations de l'administration Postale.</p>
        <ol>
            <li>
                Pour les professionnels immatriculés au registre du commerce :
                Le montant de la prestation sera payable comptant ou dans un délai maximum de 30 jours suivants la
                date d’envoi de la facture. Aucun escompte ne sera accordé.
                Le taux d'intérêt des pénalités de retard exigibles le jour suivant la date de règlement figurant sur la
                facture est fixé à 11% l’an.
                Indemnité forfaitaire de recouvrement est fixée à 40€
                Les pénalités de retard et l’indemnité forfaitaire de recouvrement sont exigibles dès le premier jour de
                retard de règlement et cela sans qu'un rappel soit nécessaire.
            </li>
            <li>
                Pour les auteurs auto-édités ayant un numéro de SIRET et pour les particuliers :
                Le payement se fait en même temps que la commande. Aucun délai de payement n’est accordé.
                Aucune commande ne sera envoyée en production tant que le payement n’aura pas été préalablement
                encaissé
            </li>
        </ol>
        <hr class="mt-3 mb-3"/>
        <h2>Minimum de commande pour les livres</h2>
        <p>La première commande devra comporter un minimum de 10 exemplaires d’un même titre. Les
            commandes suivantes pouvant se faire ensuite à l’unité.</p>
        <hr class="mt-3 mb-3"/>
        <h2>Prestations optionnelles</h2>
        <p>SoBook peut expédier directement vos produits imprimés chez nous à vos clients, avec facture, bon de
            livraison, choix et suivi d’expédition.</p>
        <p>Le client saisit lui-même ses commandes et les coordonnées postales de ses clients et joint tous les
            documents nécessaires via son application personnelle sur le site de SoBook.</p>
        <hr class="mt-3 mb-3"/>
        <h2>Documents</h2>
        <p>Le contenu des livres et documents expédiés par voie postale doit être conforme à la législation en
            vigueur tant dans le pays expéditeur que dans le pays de destination et est sous l'entière responsabilité
            du client prescripteur.</p>
        <hr class="mt-3 mb-3"/>
        <h2>Délais d’exécution</h2>
        <p>Les délais ne sont donnés qu'à titre indicatif. Ils ne pourront être respectés que dans la mesure où la
            remise des documents intervient dans les conditions fixées initialement et que les bons à tirer soit
            acceptés par le client suivant le délai convenu. SoBook mettra tout en œuvre pour respecter les délais
            indiqués mais ne pourra être tenu responsable en cas de retard lié à un évènement de force majeure et
            notamment en cas de grève des services postaux, incendie, inondation...</p>
        <hr class="mt-3 mb-3"/>
        <h2>Responsabilités</h2>
        <p>Le bon à tirer ou le bon à rouler signé par le client dégage la responsabilité de SoBook quant à tout ce
            qui n’a pas été signalé avant la signature du bon à tirer ou du bon à rouler par le client. SoBook est
            tenue par une obligation de moyen non de résultat. Toute réclamation doit être effectuée par écrit dans
            un délai de 15 jours après réalisation de la prestation.</p>
        <hr class="mt-3 mb-3"/>
        <h2>Modifications des C.V.G.</h2>
        <p>Les termes de la présente rubrique étant susceptibles d'évoluer, les utilisateurs sont priés de s'y référer
            régulièrement.</p>
        <hr class="mt-3 mb-3"/>
        <h2>Droit applicable</h2>
        <p>Quel que soit le pays d’où vous vous connectez à ce site, quel que soit le pays où vous résidez ou
            travaillez, le site Internet et ses conditions d'accès et d'utilisation et ses conditions de vente sont régis
            par le droit français.</p>
        <hr class="mt-3 mb-3"/>
        <h2>Attribution de compétence</h2>
        <p>En utilisant le présent site Web, vous acceptez les conditions d'utilisation décrites ci-avant, sans
            préjudice de tous recours de nature contractuelle ou délictuelle pouvant être exercés par SoBook.
            Tout litige portant sur l'interprétation ou l'exécution d'un engagement contractuel prévu dans les
            présentes conditions générales de vente et d’utilisation du site Web sera de la compétence exclusive
            des Tribunaux de Roubaix-Tourcoing, y compris en cas de référé, de pluralité de défendeurs et quels
            que soient le mode et les modalités de payement.</p>
    </div>
</template>

<script>
export default {
    name: "TermsAndConditionsBoutiqueSoBook",
};
</script>

<style scoped>

</style>