<template>
    <div>
        <transition name="fade" mode="out-in">
            <div v-if="notFound" key="notFound" class="m-3">
                <div class="row">
                    <div class="col-12 text-center">
                        <h2 v-html="$t('Product.productNotFound')"/>
                        <router-link class="btn btn-primary" to="/shop" v-html="$t('Product.goBack')"/>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md-8 text-center">
                        <img alt="Not Found" :src="takenSVG"/>
                    </div>
                </div>
            </div>
            <aside v-else-if="product._id" key="product">
                <div>
                    <div class="bg-secondary pb-4 padding-top-1x">
                        <a @click.prevent="$router.back()" class="ml-4 font-italic" href="#">
                            <i class="fa fa-chevron-circle-left mr-1"/>
                            <span v-html="$t('Product.goBack')"/>
                        </a>
                        <div class="container">
                            <Alert v-if="productUnavailable" type="warning" class="text-center mb-4"
                                   :text="$t('Product.productNotAvailable')">
                                <template v-slot:icon>
                                    <i class="fa fa-2x fa-exclamation-triangle"/>
                                </template>
                            </Alert>
                            <div class="row mt-3">
                                <ProductGallery :photos="[product.firstCoverPhotoUrl, product.fourthCoverPhotoUrl]"/>
                                <ProductInfos :product="product" @show-product-review-modal="showProductReviewModal"
                                              @show-upload-product-files-modal="showUploadProductFilesModal"
                                              @show-product-creation-workshop-modal="showProductCreationWorkshopModal"/>
                            </div>
                        </div>
                    </div>
                    <div v-if="areProductReviewsEnabled" class="container padding-bottom-3x mb-1">
                        <ProductReviews :product="product" @show-product-review-modal="showProductReviewModal"
                                        @delete-product-review="deleteProductReview" @edit-product-review="editProductReview"/>
                        <ProductReviewModal ref="ProductReviewModal" :product="product"
                                            @add-review="addReview" @edit-product-review="editProductReview"/>
                    </div>
                    <div v-if="product.source === 'user'">
                        <ProductCreationWorkshopModal v-if="product.getOption('product-creation-workshop')"
                                                      ref="productCreationWorkshopModal" @update-product="updateProduct"/>
                        <UploadProductFilesModal v-else ref="uploadProductFilesModal" @update-product="updateProduct"/>
                    </div>
                </div>
            </aside>
            <div id="loading" key="loading" v-else>
                <APILoading :font-size='40' :icon-size='80' :text="$t('shared.apiLoading.getProduct')"/>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Product from "../../../classes/Product";
import ProductGallery from "./ProductGallery";
import ProductInfos from "./ProductInfos/ProductInfos";
import APILoading from "../../shared/Misc/APILoading";
import { importSVG } from "@/helpers/file";
import ProductReviews from "./ProductReviews/ProductReviews";
import ProductReviewModal from "./ProductReviews/ProductReviewModal";
import ProductReview from "../../../classes/ProductReview";
import Alert from "../../shared/Misc/Alert";
import UploadProductFilesModal from "@/components/shared/Product/ProductFiles/UploadProductFiles/UploadProductFilesModal";
import ProductCreationWorkshopModal
    from "@/components/shared/Product/ProductFiles/ProductCreationWorkshop/ProductCreationWorkshopModal";

export default {
    name: "Product",
    components: {
        ProductCreationWorkshopModal,
        UploadProductFilesModal,
        Alert,
        ProductReviewModal,
        ProductReviews,
        ProductInfos,
        ProductGallery,
        APILoading,
    },
    data() {
        return {
            product: new Product(),
            notFound: false,
        };
    },
    computed: {
        ...mapGetters("preferences", {
            chosenCurrency: "currency",
        }),
        ...mapGetters("user", {
            user: "user",
            isUserLogged: "isLogged",
        }),
        ...mapGetters("config", {
            areProductReviewsEnabled: "areProductReviewsEnabled",
            doesShopShowOnlyPODProducts: "doesShopShowOnlyPODProducts",
            hermesAPIConfig: "hermesAPI",
        }),
        takenSVG() {
            return importSVG("taken", this.hermesAPIConfig.client);
        },
        productUnavailable() {
            return this.product.active.value === false || this.doesShopShowOnlyPODProducts && !this.product.pod;
        },
    },
    created() {
        this.getProduct();
    },
    methods: {
        async getProduct() {
            try {
                if (!this.chosenCurrency) {
                    return;
                }
                this.notFound = false;
                this.product = new Product();
                const user = this.isUserLogged ? this.user._id : null;
                const response = await this.$hermesAPI.getProduct(this.$route.params.id, { currency: this.chosenCurrency, user });
                this.product = new Product(response.data);
                this.scrollToElement();
                await this.$router.replace({ params: { title: `${this.product.title.replace(/\s+|, ?|: ?|' /g, "-").toLowerCase()}` } });
            } catch (err) {
                if (err.response && err.response.status === 404) {
                    this.notFound = true;
                } else {
                    this.$error.displayError(err);
                }
            }
        },
        scrollToElement() {
            if (this.$route.query.scroll) {
                const scroll = this.$route.query.scroll;
                setTimeout(() => {
                    this.$scrollTo(`#${scroll}`);
                }, 1000);
            }
        },
        showProductReviewModal(review) {
            this.$refs.ProductReviewModal.show(review);
        },
        addReview(review) {
            this.product.reviews.unshift(new ProductReview(review));
        },
        editProductReview(updatedReview) {
            const idx = this.product.reviews.findIndex(review => review._id === updatedReview._id);
            if (idx !== -1) {
                this.product.reviews.splice(idx, 1, new ProductReview(updatedReview));
            }
        },
        deleteProductReview(reviewId) {
            const idx = this.product.reviews.findIndex(review => review._id === reviewId);
            if (idx !== -1) {
                this.product.reviews.splice(idx, 1);
            }
        },
        showUploadProductFilesModal() {
            this.$refs.uploadProductFilesModal.show(this.product);
        },
        showProductCreationWorkshopModal() {
            this.$refs.productCreationWorkshopModal.show(this.product);
        },
        updateProduct(updatedProduct) {
            this.product = new Product(updatedProduct);
        },
    },
    watch: {
        "$route.params"() {
            if (!this.product || this.$route.params.id !== this.product._id) {
                this.getProduct();
            }
        },
        chosenCurrency() {
            this.getProduct();
        },
        isUserLogged() {
            this.getProduct();
        },
    },
};
</script>

<style scoped>
    #loading {
        height: 80vh;
    }
</style>