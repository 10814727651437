<template>
    <div id="product-created" class="container p-3">
        <transition mode="out-in" name="fade">
            <div v-if="loading" id="loading" key="loading" class="d-flex justify-content-center align-items-center">
                <APILoading :loading="loading" :text="$t('ProductCreated.loadingProduct')" :font-size="24"/>
            </div>
            <div v-else id="product" key="product">
                <div class="row">
                    <div class="col-12">
                        <h2 class="text-center">
                            <i class="fa fa-check-circle text-success mr-3"/>
                            <span v-html="$t('ProductCreated.congratulationsYouSelfPublished')"/>
                        </h2>
                    </div>
                </div>
                <div class="row justify-content-center align-items-center">
                    <div class="col-10">
                        <div class="card shadow-lg">
                            <div class="card-header bg-primary text-white text-lg">
                                <i class="fa fa-book mr-2"/>
                                <span v-html="`${$t('ProductCreated.bookSummary.header')}`"/>
                            </div>
                            <div class="card-body bg-white">
                                <div class="row">
                                    <div class="col-6">
                                        <p class="text-less-bold text-sm text-primary"
                                           v-html="$t('ProductCreated.bookSummary.generalInfo')"/>
                                        <hr class="mt-3 mb-3"/>
                                        <ProductGeneralInfoList :product="product"/>
                                    </div>
                                    <div class="col-6">
                                        <p class="text-less-bold text-sm text-primary"
                                           v-html="$t('ProductCreated.bookSummary.technicalInfo')"/>
                                        <hr class="mt-3 mb-3"/>
                                        <ProductComponentsInfoList :product="product"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="mt-4 mb-4"/>
                <transition mode="out-in" name="fade">
                    <div v-if="!product.bothFilesUploaded" class="row">
                        <div class="col-12 text-center">
                            <h3 class="mb-1">
                                <i class="fa fa-cloud-upload-alt mr-2 text-success"/>
                                <span v-html="$t('ProductCreated.downloadYourFiles')"/>
                            </h3>
                            <UploadProductFilesButton :product="product" class="btn-lg" @show-upload-product-files-modal="showUploadProductFilesModal">
                                <template v-slot:text>
                                    <i class="fa fa-cloud-upload-alt mr-2"/>
                                    <span v-html="$t('UploadProductFilesButton.sendFiles')"/>
                                </template>
                            </UploadProductFilesButton>
                            <ProductCreationWorkshopButton :product="product" @show-product-creation-workshop-modal="showProductCreationWorkshopModal">
                                <template v-slot:text>
                                    <i class="fa fa-palette mr-2"/>
                                    <span v-html="$t('ProductCreationWorkshopButton.createYourBook')"/>
                                </template>
                            </ProductCreationWorkshopButton>
                        </div>
                    </div>
                    <div v-else class="row">
                        <div class="col-12 text-center">
                            <h3 class="mb-1">
                                <i class="far fa-smile mr-2 text-success"/>
                                <span v-html="$t('ProductCreated.yourFilesHaveBeenDownloaded')"/>
                            </h3>
                        </div>
                    </div>
                </transition>
                <hr class="mt-4 mb-4"/>
                <div class="row">
                    <div class="col-12 d-flex justify-content-around">
                        <router-link to="/self-publishing/create-book" class="btn btn-secondary">
                            <i class="fa fa-plus mr-2"/>
                            <span v-html="$t('ProductCreated.createAnotherBook')"/>
                        </router-link>
                        <router-link v-if="userCart.length" class="btn btn-primary btn-lg" to="/checkout">
                            <i class="fa fa-shopping-cart mr-2"/>
                            <span v-html="$t('ProductCreated.finalizeOrder')"/>
                        </router-link>
                    </div>
                </div>
                <UploadProductFilesModal ref="uploadProductFilesModal" @update-product="updateProduct"/>
                <ProductCreationWorkshopModal ref="productCreationWorkshopModal" @update-product="updateProduct"/>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import UploadProductFilesModal from "../../shared/Product/ProductFiles/UploadProductFiles/UploadProductFilesModal";
import UploadProductFilesButton from "../../shared/Product/ProductFiles/UploadProductFiles/UploadProductFilesButton";
import Product from "../../../classes/Product";
import APILoading from "../../shared/Misc/APILoading";
import ProductGeneralInfoList from "@/components/shared/Product/ProductInfoList/ProductGeneralInfoList";
import ProductComponentsInfoList from "@/components/shared/Product/ProductInfoList/ProductComponentsInfoList";
import ProductCreationWorkshopButton from "@/components/shared/Product/ProductFiles/ProductCreationWorkshop/ProductCreationWorkshopButton";
import ProductCreationWorkshopModal
    from "@/components/shared/Product/ProductFiles/ProductCreationWorkshop/ProductCreationWorkshopModal";

export default {
    name: "SelfPublishingProductCreated",
    components: {
        ProductCreationWorkshopModal,
        ProductCreationWorkshopButton,
        ProductGeneralInfoList,
        ProductComponentsInfoList,
        APILoading,
        UploadProductFilesButton,
        UploadProductFilesModal,
    },
    data() {
        return {
            product: new Product(),
            loading: true,
        };
    },
    computed: {
        ...mapGetters("config", {
            hermesAPIConfig: "hermesAPI",
        }),
        ...mapGetters("user", {
            user: "user",
            userCart: "cart",
            isUserLogged: "isLogged",
        }),
    },
    async created() {
        if (this.$route.params.id === undefined) {
            return this.$router.push("/");
        }
        return !this.isUserLogged ? await this.checkUserAuthentication() : await this.getProduct();
    },
    methods: {
        ...mapActions("user", {
            checkUserAuthentication: "checkUserAuthentication",
        }),
        async getProduct() {
            try {
                this.loading = true;
                const user = this.isUserLogged ? this.user._id : null;
                const { data } = await this.$hermesAPI.getProduct(this.$route.params.id, { user });
                this.product = new Product(data);
            } catch (err) {
                this.$error.displayError(err);
            } finally {
                this.loading = false;
            }
        },
        showUploadProductFilesModal(product) {
            this.$refs.uploadProductFilesModal.show(product);
        },
        showProductCreationWorkshopModal(product) {
            this.$refs.productCreationWorkshopModal.show(product);
        },
        updateProduct(product) {
            this.product = new Product(product);
        },
    },
};
</script>

<style scoped>
    #loading {
        height: 50vh;
    }
</style>