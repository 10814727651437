<template>
    <div id="default-header">
        <Branding/>
        <MainNav/>
        <SideBarTabs @show-forgot-password-modal="$emit('show-forgot-password-modal')"/>
    </div>
</template>

<script>
import MainNav from "../MainNav";
import Branding from "../Branding";
import SideBarTabs from "@/components/shared/Header/SideBarTabs";

export default {
    name: "DefaultHeader",
    components: {SideBarTabs, Branding, MainNav},
};
</script>

<style scoped>
    #default-header {
        border-bottom: 1px solid lightgrey;
    }
</style>