<template>
    <section class="row align-items-center padding-bottom-2x">
        <div class="col-md-5"><img class="d-block w-270 m-auto" :src="logoAboutProduct" alt="Online Shopping"></div>
        <div class="col-md-7 text-md-left text-center">
            <div class="mt-30 hidden-md-up"></div>
            <h2>{{$t("aboutProduct.title")}}</h2>
            <p class="text-sm">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam id purus at risus
                pellentesque faucibus a quis eros. In eu fermentum leo. Integer ut eros lacus. Proin ut accumsan leo.
                Morbi vitae est eget dolor consequat aliquam eget quis dolor. Mauris rutrum fermentum erat, at euismod
                lorem pharetra nec. Duis erat lectus, ultrices euismod sagittis at, pharetra eu nisl. Phasellus id ante
                at velit tincidunt hendrerit.</p><a class="text-decoration-none" href="shop-grid-ls.html">View Products&nbsp;<i
                class="material-icons keyboard_arrow_right lead"></i></a>
        </div>
    </section>
</template>

<script>
import logoAboutProduct from "../../../../assets/img/features/01.jpg";

export default {
    name: "AboutProduct",
    data() {
        return {
            logoAboutProduct,
        };
    },
};

</script>

<style scoped>

</style>