<template>
    <div id="default-home">
        <HomeNewProducts/>
    </div>
</template>

<script>
import HomeNewProducts from "../HomeNewProducts/HomeNewProducts";
export default {
    name: "DefaultHome",
    components: { HomeNewProducts },
};
</script>

<style scoped>

</style>