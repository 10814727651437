import { hasProp } from "../helpers/class";

class Country {
    constructor(country = null) {
        this.id = !hasProp(country, "id") ? undefined : country.id;
        this.name = !hasProp(country, "name") ? undefined : country.name;
        this.alpha2ISO = !hasProp(country, "alpha2ISO") ? undefined : country.alpha2ISO;
        this.alpha3ISO = !hasProp(country, "alpha3ISO") ? undefined : country.alpha3ISO;
        this.numericISO = !hasProp(country, "numericISO") ? undefined : country.numericISO;
        this.flagURL = !hasProp(country, "flagURL") ? undefined : country.flagURL;
    }
}

export default Country;