<template>
    <span v-tooltip="$t('AdminBadge.optionAvailableBcYouReAdmin')" class="badge badge-danger">
        <i class="fa fa-user-shield"></i>
        Admin
    </span>
</template>

<script>
export default {
    name: "AdminBadge",
};
</script>

<style scoped>

</style>