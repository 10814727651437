import request from "request";
import Config from "../../config";
import { decodeJWTToken } from "@/helpers/API";

const generateProductCode = ({ components }) => `${components.binding.id}_0_0_${components.cover.paper.id}_${components.corpus.paper.id}`;

const MonSoBookAPI = {
    // eslint-disable-next-line no-unused-vars
    install(Vue) {
        const auth = {
            user: Config.monSoBookAPI.digestAuth.username,
            pass: Config.monSoBookAPI.digestAuth.password,
            sendImmediately: false,
        };

        Vue.prototype.$monSoBookAPI = {};

        Vue.prototype.$monSoBookAPI.createProduct = ({ book, shop }) =>
            new Promise((resolve, reject) => {
                const { userId } = decodeJWTToken();
                const options = book.options.length ? book.options : undefined;
                const body = {
                    fromShop: true,
                    clientsobook: Config.monSoBookAPI.selfPublishingClientId,
                    client: shop,
                    titre: book.title,
                    nbrePage: book.paging.total,
                    // eslint-disable-next-line camelcase
                    type_produit: generateProductCode(book),
                    // eslint-disable-next-line camelcase
                    type_iso: "FR",
                    nbPageCoul: book.paging.color,
                    // eslint-disable-next-line camelcase
                    L_unit: book.dimensions.width,
                    // eslint-disable-next-line camelcase
                    H_unit: book.dimensions.height,
                    // eslint-disable-next-line camelcase
                    couv_rv: book.components.cover.printing.bothSides,
                    createdBy: userId,
                    // eslint-disable-next-line camelcase
                    marges_perdues: 0,
                    Auteurs: book.author,
                    Description: book.description,
                    source: "user",
                    lamination: book.components.cover.lamination.id,
                    reducedTax: book.reducedTax,
                    options,
                };
                const requestOptions = { uri: `${Config.monSoBookAPI.baseURL}Hub_express_api/createFicheMiseEnBase/`, auth, json: true, body };
                request.post(requestOptions, (error, response, body) => {
                    if (error) {
                        return reject(error);
                    } else if (response.statusCode !== 200) {
                        return reject(body);
                    }
                    const { shopResponse } = body;
                    return shopResponse && shopResponse._id ? resolve(shopResponse) : reject(shopResponse);
                });
            });

        Vue.prototype.$monSoBookAPI.updateProduct = (productId, data) =>
            new Promise((resolve, reject) => {
                const requestOptions = { uri: `${Config.monSoBookAPI.baseURL}Hub_express_api/updateFicheMiseEnBase/${productId}`, auth, json: true, body: data };
                request.patch(requestOptions, (error, response, body) => {
                    if (error) {
                        return reject(error);
                    } else if (response.statusCode !== 200) {
                        return reject(body);
                    }
                    return resolve(body);
                });
            });
    },
};

export default MonSoBookAPI;