<template>
    <div id="not-available" class="d-flex justify-content-center align-items-center flex-column">
        <h2 class="text-center" v-html="$t('HermesNotAvailable.notAvailable')"></h2>
        <img id="server-down-svg" alt="Server down" :src="importSVG('server_down')"/>
        <h3 class="mt-3">{{ $t("HermesNotAvailable.pleaseTryAgainLater") }}</h3>
        <button onclick="location.reload()" class="btn btn-primary btn-lg">
            <i class="fa fa-redo-alt"></i>
            {{ $t("HermesNotAvailable.refresh") }}
        </button>
    </div>
</template>

<script>
import { importSVG } from "@/helpers/file";
import "../../assets/scss/default/styles.scss";

export default {
    name: "HermesNotAvailable",
    methods: {
        importSVG,
    },
};
</script>

<style>
    #app {
        height: 100vh;
    }

    #not-available {
        height: 100%;
    }

    #server-down-svg {
        height: 40%;
    }
</style>