<template>
    <div class="alert mb-1" :class="`alert alert-${type} alert-${size}`">
        <div class="row align-items-center">
            <div class="col-1">
                <slot name="icon"/>
            </div>
            <span class="col-10" v-html="text"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "Alert",
    props: {
        type: {
            type: String,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        size: {
            type: String,
            default: "md",
        },
        noBorder: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped>
    .alert-xs {
        padding: 5px !important;
    }

    .alert-md {
        padding: 10px !important;
    }

    .border-0 {
        &:before {
            border: white 0 solid !important;
        }
    }
</style>