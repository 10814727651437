<template>
    <div id="la-boutique-sobook-home">
        <div id="start-to-print" class="row ml-0 mr-0 align-items-center">
            <div class="col-3">
                <img width="300" :src="IMGs.books" alt="Books">
            </div>
            <div class="col-9 text-center">
                <h1 id="print-text" class="mb-3" v-html="'IMPRIMEZ VOS LIVRES FACILEMENT ET RAPIDEMENT !'"/>
                <router-link id="start-to-print-btn" to="/self-publishing/create-book"
                             class="btn btn-lg shadow-lg mt-4 mb-4">
                    <i class="fa fa-chevron-right mr-3"/>
                    <span v-html="'J\'y vais !'"/>
                </router-link>
            </div>
        </div>
        <div id="sobook-helps-to-print" class="mt-5">
            <div class="d-flex flex-column align-items-center">
                <img :src="IMGs.logo" alt="Logo">
                <h3 class="mt-3 text-center" v-html="'VOUS AIDE GRÂCE À SA CALCULETTE DE DEVIS INTUITIVE,'"/>
                <h3 class="text-center" v-html="'À RÉALISER VOS PROJETS D\'IMPRESSION'"/>
            </div>
        </div>
    </div>
</template>

<script>
import { importPNG } from "../../../../../helpers/file";

export default {
    name: "LaBoutiqueSoBookHome",
    data() {
        return {
            IMGs: {
                logo: importPNG("logo", "la-boutique-sobook"),
                books: importPNG("home/books", "la-boutique-sobook"),
            },
        };
    },
};
</script>

<style lang="scss" scoped>
    #start-to-print {
        background-color: #91A4B1;
        min-height: 350px;

        #print-text {
            color: white;
        }

        #start-to-print-btn {
            font-size: 2rem;
            height: auto;
            border-radius: 2px;
            color: white;
            background-color: #494C4E;
            padding: 10px 40px;
        }
    }
</style>