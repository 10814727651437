<template>
    <div class="toolbar-section" :class="{ current: isCartSectionActive }" id="header-user-cart-panel">
        <transition name="fade" mode="out-in">
            <div key="notLogged" v-if="!frontConfig.products.canAddToCartNotLogged && !isUserLogged" id="notLogged">
                <img alt="Not logged" :src="queueSVG"/>
                <h5>{{$t("header.headerShopCart.notLogged")}}</h5>
                <button @click="$emit('click-login')" class="btn btn-primary">{{$t("header.headerShopCart.login")}}
                </button>
            </div>
            <div key="loading-cart" v-else-if="userCart === null">
                <APILoading :font-size="20" :text="$t('header.headerShopCart.loadingCart')"/>
            </div>
            <div key="cart" v-else-if="userCart.length">
                <div id='shop-cart-preview-container' class="table-responsive shopping-cart mb-0">
                    <table class="table">
                        <thead>
                        <tr>
                            <th colspan="2">
                                <div class="d-flex justify-content-between align-items-center">
                                    {{$t("header.headerShopCart.products")}}
                                    <router-link @click.native="hideSideBar" to='/cart' class="navi-link" text-uppercase><span class="text-xxs">
                                        {{$t("header.headerShopCart.expandCart")}}</span>
                                        <i class="material-icons keyboard_arrow_right"></i>
                                    </router-link>
                                </div>
                            </th>
                        </tr>
                        </thead>
                        <transition-group tag="tbody" name="shop-cart-line">
                            <HeaderShopCartLine v-for="product in userCart" class="shop-cart-line" :product="product" :key="product._id"/>
                        </transition-group>
                    </table>
                </div>

                <hr class="mb-3">
                <div id="shop-cart-preview-footer" class="d-flex flex-wrap justify-content-between align-items-center">
                    <div v-if="user.canSeePrices" class="pr-2 py-1 text-sm text-center">{{$t("header.headerShopCart.subtotal")}}:
                        <span class='text-dark text-medium'>{{this.properPrice(this.getSubTotal, chosenCurrency, currencies)}}</span>
                    </div>
                </div>
                <div class="text-center" v-tooltip="checkoutBtnTooltip">
                    <button @click="checkoutBtnAction" to="/checkout" id="checkout-btn" :disabled="!hasPaymentMethods"
                                 class="btn btn-lg btn-block btn-success mb-0 mr-0" v-html="checkoutBtnText"/>
                </div>
                <div v-if="user.canOneClickOrder" class="text-center" v-tooltip="oneClickOrderTooltip">
                    <a @click.prevent="oneClickOrder" href="#" :disabled="!hasPaymentMethods"
                                 class="btn btn-lg btn-block btn-info mb-0 mr-0">
                        {{ $t("HeaderShopCart.oneClickOrder") }}
                        <i class="fas fa-cart-arrow-down"></i>
                    </a>
                </div>
            </div>
            <div key="emptyCart" v-else id="noProductContainer">
                <img alt="Add to cart" :src="addToCartSVG">
                <h5 class="mt-2" v-html="$t('header.headerShopCart.noProduct')"/>
                <router-link v-if="frontConfig.isShopSectionEnabled" @click.native="hideSideBar"
                             class="btn btn-primary" to="/shop" v-html="$t('header.headerShopCart.goToShop')"/>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import HeaderShopCartLine from "./HeaderShopCartLine";
import APILoading from "../../../shared/Misc/APILoading";
import { importSVG } from "@/helpers/file";
import { properPrice } from "@/helpers/price";

export default {
    name: "HeaderShopCart",
    components: {APILoading, HeaderShopCartLine},
    computed: {
        ...mapGetters("user", {
            isUserLogged: "isLogged",
            userCart: "cart",
            user: "user",
        }),
        ...mapGetters("sideBarMenu", {
            isCartSectionActive: "isCartSectionActive",
        }),
        ...mapGetters("currencies", {
            currencies: "currencies",
        }),
        ...mapGetters("preferences", {
            chosenCurrency: "currency",
        }),
        ...mapGetters("config", {
            isCashPaymentEnabled: "isCashPaymentEnabled",
            isCheckPaymentEnabled: "isCheckPaymentEnabled",
            isBankTransferPaymentEnabled: "isBankTransferPaymentEnabled",
            isOrangeMoneyPaymentEnabled: "isOrangeMoneyPaymentEnabled",
            isWariPaymentEnabled: "isWariPaymentEnabled",
            isPayPalPaymentEnabled: "isPayPalPaymentEnabled",
            isCreditCardPaymentEnabled: "isCreditCardPaymentEnabled",
            frontConfig: "front",
            hermesAPIConfig: "hermesAPI",
        }),
        queueSVG() {
            return importSVG("queue", this.hermesAPIConfig.client);
        },
        addToCartSVG() {
            return importSVG("add_to_cart", this.hermesAPIConfig.client);
        },
        getSubTotal() {
            return this.userCart.reduce((acc, product) => acc + product.totalWT, 0);
        },
        hasPaymentMethods() {
            return this.isCashPaymentEnabled || this.isCheckPaymentEnabled || this.isBankTransferPaymentEnabled ||
                this.isOrangeMoneyPaymentEnabled || this.isWariPaymentEnabled || this.isPayPalPaymentEnabled ||
                this.isCreditCardPaymentEnabled;
        },
        checkoutBtnText() {
            return this.isUserLogged ? this.$t("header.headerShopCart.checkout") : this.$t("header.headerShopCart.logInToCheckout");
        },
        checkoutBtnTooltip() {
            if (!this.isUserLogged) {
                return "";
            } else if (this.hasPaymentMethods) {
                return this.$t("HeaderShopCart.checkoutInSomeClicks");
            } else {
                return this.$t("HeaderShopCart.shopDoesntHavePaymentMethodsYet");
            }
        },
        oneClickOrderTooltip() {
            return this.hasPaymentMethods ? this.$t("HeaderShopCart.checkoutInOneClick") : this.$t("HeaderShopCart.shopDoesntHavePaymentMethodsYet");
        },
    },
    methods: {
        ...mapActions("sideBarMenu", {
            hideSideBar: "hide",
            openAccountSection: "openAccountSection",
        }),
        properPrice,
        async oneClickOrder() {
            await this.hideSideBar();
            await this.$router.push("/checkout?mode=one-click");
        },
        async checkoutBtnAction() {
            if (this.isUserLogged) {
                await this.hideSideBar();
                await this.$router.push("/checkout");
            } else {
                await this.openAccountSection();
            }
        },
    },
};
</script>

<style scoped lang="scss">
    .shop-cart-line {
        transition: all .5s;
    }

    .shop-cart-line-enter, .shop-cart-line-leave-to {
        opacity: 0;
        transform: translateX(-500px);
    }

    .shop-cart-line-leave-active {
        position: absolute;
    }

    #notLogged {
        text-align: center;
    }

    #noProductContainer {
        text-align: center;
    }

    #banIcon {
        opacity: 0.6;
        color: Tomato;
    }

    #shop-cart-preview-container {
        overflow-x: hidden !important;
        overflow-y: hidden !important;

        tbody {
            overflow-y: auto !important;
            height: 50vh;
            display: block;
        }
    }

    #checkout-btn {
        font-size: 13px;
    }
</style>