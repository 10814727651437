<template>
    <div class="site-branding">
        <router-link class="site-logo hidden-xs-down" to="/">
            <img id="logo" :src="logo" alt="Logo">
        </router-link>
        <router-link class="site-logo logo-sm hidden-sm-up" to="/">
            <img :src="smallLogo" alt="Logo">
        </router-link>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { importPNG } from "@/helpers/file";

export default {
    name: "Branding",
    computed: {
        ...mapGetters("config", {
            hermesAPIConfig: "hermesAPI",
        }),
        ...mapGetters("user", {
            isUserLogged: "isLogged",
        }),
        logo() {
            if (this.hermesAPIConfig.client === "ilp") {
                return this.isUserLogged ? importPNG("logo-log-in", "ilp") : importPNG("logo-not-log-in", "ilp");
            }
            return importPNG("logo", this.hermesAPIConfig.client);
        },
        smallLogo() {
            if (this.hermesAPIConfig.client === "ilp") {
                return this.isUserLogged ? importPNG("logo-sm-log-in", "ilp") : importPNG("logo-sm-not-log-in", "ilp");
            }
            return importPNG("logo-sm", this.hermesAPIConfig.client);
        },
    },
};
</script>

<style lang="scss" scoped>

    .site-branding {
        padding-top: 5px;
        padding-bottom: 5px;
        display: flex;
        align-items: center;
        #logo {
            max-height: 60px;
            width: auto;
        }
    }
</style>