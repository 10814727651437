<template>
    <div class="container padding-top-3x padding-bottom-3x mb-1">
        <h1 class="display-404 text-center">404</h1>
        <div class="text-center">
            <h2>{{$t("notFound.pageNotFound")}}</h2>
            <p class="text-sm">{{$t("notFound.cantFind")}}
                <router-link to="/">{{$t("notFound.goBackHome")}}</router-link><br/>
                {{$t("notFound.orTrySearch")}}</p>
        </div>
    </div>
</template>

<script>
export default {
    name: "NotFound",
};
</script>

<style scoped>

</style>