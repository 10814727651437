class ProductSearchQuery {

    constructor(productSearchQuery = null, sanitized = false) {
        this["source"] = productSearchQuery ? productSearchQuery["source"] : "";
        this["title"] = productSearchQuery ? productSearchQuery["title"] : "";
        this["subtitle"] = productSearchQuery ? productSearchQuery["subtitle"] : "";
        this["isbn"] = productSearchQuery ? productSearchQuery["isbn"] : "";
        this["publisher"] = productSearchQuery ? productSearchQuery["publisher"] : null;
        this["selfPublisher"] = productSearchQuery ? productSearchQuery["selfPublisher"] : null;
        this["author"] = productSearchQuery ? productSearchQuery["author"] : "";
        this["price-w-t"] = productSearchQuery ? productSearchQuery["price-w-t"] : "";
        this["description"] = productSearchQuery ? productSearchQuery["description"] : "";
        this["book-collection"] = productSearchQuery ? productSearchQuery["book-collection"] : "";
        this["clil"] = productSearchQuery ? productSearchQuery["clil"] : null;
        this["countries"] = productSearchQuery ? productSearchQuery["countries"] : null;
        this["geographical-area"] = productSearchQuery ? productSearchQuery["geographical-area"] : null;
        this["pod"] = productSearchQuery ? productSearchQuery["pod"] : "";
        this["active"] = productSearchQuery ? productSearchQuery["active"] : true;
        this["page"] = productSearchQuery ? productSearchQuery["page"] : 1;
        this["per-page"] = productSearchQuery ? productSearchQuery["per-page"] : 15;
        this["from-release-date"] = productSearchQuery ? productSearchQuery["from-release-date"] : "";
        this["to-release-date"] = productSearchQuery ? productSearchQuery["to-release-date"] : "";
        this["sort"] = productSearchQuery ? productSearchQuery["sort"] : "release-date";
        this["order"] = productSearchQuery ? productSearchQuery["order"] : "desc";

        if (sanitized) {
            this.sanitizeQuery();
        }
    }

    sanitizeQuery() {
        for (const prop in this) {
            if (this[prop] !== undefined && this[prop] === null || typeof this[prop] === "string" && this[prop].trim() === "") {
                delete this[prop];
            }
        }
    }
}

export default ProductSearchQuery;