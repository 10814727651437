<template>
    <div>
        <span class="d-inline-block text-white text-xs p-1" :class="orderPaymentLabelClass">
            <router-link v-if="order.status.payment === 'waiting' || order.status.payment === 'error'"
                         class="pay-my-bill-help" v-tooltip="$t('OrderPaymentStatusLabel.howPayMyBill')"
                         target="_blank" :to="`/checkout-complete?order=${order._id}&scroll=how-to-pay-bill`">
                <i class="fa fa-question-circle text-info"></i>
            </router-link>
            <span v-html="orderPaymentLabel"></span>
            <div class="text-less-bold" v-if="order.status.payment !== 'none'">{{ properPrice(order.totalWT, order.currency, currencies) }}</div>
        </span>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { properPrice } from "../../../helpers/price";

export default {
    name: "OrderPaymentStatusLabel",
    props: {
        order: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            paymentStatuses: {
                waiting: {
                    class: "bg-warning",
                    label: this.$t(`OrderPaymentStatusLabel.waiting`),
                },
                error: {
                    class: "bg-danger",
                    label: this.$t(`OrderPaymentStatusLabel.error`),
                },
                accepted: {
                    class: "bg-success",
                    label: this.$t(`OrderPaymentStatusLabel.accepted`),
                },
                refunded: {
                    class: "bg-success",
                    label: this.$t(`OrderPaymentStatusLabel.refunded`),
                },
                none: {
                    class: "bg-success",
                    label: this.$t(`OrderPaymentStatusLabel.none`),
                },
            },
        };
    },
    computed: {
        ...mapGetters("currencies", {
            currencies: "currencies",
        }),
        orderPaymentLabelClass() {
            return this.paymentStatuses[this.order.status.payment] ? this.paymentStatuses[this.order.status.payment].class : "";
        },
        orderPaymentLabel() {
            return this.paymentStatuses[this.order.status.payment] ? this.paymentStatuses[this.order.status.payment].label : "";
        },
    },
    methods: {
        properPrice,
    },
};
</script>

<style lang="scss" scoped>
    span {
        position: relative;
        .pay-my-bill-help {
            position: absolute;
            top: -10px;
            left: -9px;
        }
    }
</style>