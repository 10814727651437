<template>
    <div>
        <div class="card-header text-lg">
            {{$t("CheckoutCompletePayBillByWari.youPayedByWari")}}
        </div>
        <div class="card-body">
            <div class="row align-items-center">
                <div class="col-1">
                    <img alt="Wari" :src="wari" />
                </div>
                <div class="col-10">
                    <h4 class="card-title">{{$t("CheckoutCompletePayBillByWari.howByWari")}}</h4>
                </div>
            </div>
            <hr class="mb-4"/>
            <div class="row form-group">
                <label class="col-3 col-form-label text-muted" for="title">{{$t("CheckoutCompletePayBillByWari.entitled")}}</label>
                <input v-tooltip="$t('CheckoutCompletePayBillByWari.clickToCopy')" class="form-control col-6" readonly id="title"
                       @click="copy('title')" :value="paymentTitle"/>
            </div>
            <div class="row form-group">
                <label class="col-3 col-form-label text-muted" for="recipient">{{$t("CheckoutCompletePayBillByWari.recipient")}}</label>
                <input v-tooltip="$t('CheckoutCompletePayBillByWari.clickToCopy')" class="form-control col-6" readonly id="recipient"
                       @click="copy('recipient')" :value="shopPayments.wari.code"/>
            </div>
            <div class="row form-group">
                <label class="col-3 col-form-label text-muted" for="total">{{$t("CheckoutCompletePayBillByWari.amount")}}</label>
                <input v-tooltip="$t('CheckoutCompletePayBillByWari.clickToCopy')" class="form-control col-6" readonly id="total"
                       @click="copy('total')" :value="properPrice(this.order.totalWT, this.order.currency, this.currencies)"/>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import wari from "../../../../assets/img/payments/wari.png";
import { properPrice } from "../../../../helpers/price";

export default {
    name: "CheckoutCompletePayBillByWari",
    props: {
        order: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            paymentTitle: this.$t("CheckoutCompletePayBillByWari.transferTitle", { reference: this.order.reference }),
            wari,
        };
    },
    computed: {
        ...mapGetters("currencies", {
            currencies: "currencies",
        }),
        ...mapGetters("config", {
            shopPayments: "payments",
        }),
    },
    methods: {
        properPrice,
        copy(id) {
            const copyText = document.getElementById(id);
            copyText.select();
            document.execCommand("copy");
            this.$toasted.success(this.$t("CheckoutCompletePayBillByWari.copied"), {icon: "copy"});
        },
    },
};
</script>

<style scoped>
    input[readonly="readonly"] {
        cursor: pointer;
        color: #606060;
    }
</style>