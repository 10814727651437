<template>
    <avatar :username="displayedName" :size="size"></avatar>
</template>

<script>
import Avatar from "vue-avatar";

export default {
    name: "UserAvatar",
    components: { "avatar": Avatar },
    props: {
        user: {
            type: Object,
            required: true,
        },
        size: {
            type: Number,
            default: 50,
        },
    },
    computed: {
        displayedName() {
            return this.user.displayedName;
        },
    },
};
</script>

<style>
    .vue-avatar--wrapper {
        display: inline-block !important;
    }
</style>