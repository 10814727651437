<template>
    <div class="tab-pane fade" id="signup" role="tabpanel">
        <ValidationObserver v-slot="{ invalid }">
            <form @submit.prevent='register' autocomplete="off" id="signup-form">
                <div class="mb-3">
                    <label for="email">
                        {{$t('header.account.registerPanel.email')}}
                        <RedAsterisk/>
                    </label>
                    <ValidationProvider v-slot="{ errors, classes }">
                        <div class="input-group">
                            <input class="form-control" :class="classes"
                                   type="email" required v-model='user.email' id="email" ref="email"
                                   :placeholder="$t('header.account.registerPanel.required')">
                            <span class="input-group-addon"><i class="material-icons mail"></i></span>
                        </div>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                    </ValidationProvider>
                </div>
                <ValidationObserver>
                    <div class="mb-3">
                        <label for="register-password">
                            {{$t('header.account.registerPanel.password')}}
                            <RedAsterisk/>
                        </label>
                        <ValidationProvider rules="min:5" vid="confirmation" v-slot="{ errors, classes }">
                            <div class="input-group">
                                <input class="form-control" id="register-password" type="password" v-model='user.password' minlength='5'
                                       :placeholder="$t('header.account.registerPanel.required')" required :class="classes">
                                <span class="input-group-addon"><i class="material-icons lock"></i></span>
                            </div>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="mb-3">
                        <label for="confirm-password">
                            {{$t('header.account.registerPanel.confirmPassword')}}
                            <RedAsterisk/>
                        </label>
                        <ValidationProvider rules="min:5|confirm_password:confirmation" v-slot="{ errors, classes }">
                            <div class="input-group">
                                <input class="form-control" id="confirm-password" type="password" v-model='confirmPassword' minlength='5'
                                       :placeholder="$t('header.account.registerPanel.required')" required :class="classes">
                                <span class="input-group-addon"><i class="material-icons lock"></i></span>
                            </div>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </ValidationProvider>
                    </div>
                </ValidationObserver>
                <div v-if="!shopType" class="custom-control custom-checkbox custom-control-inline c-pointer">
                    <input class="custom-control-input" type="checkbox" id="is-professional" v-model="user.isProfessional"/>
                    <label id="is-professional-label" class="custom-control-label text-primary c-pointer" for="is-professional">
                        <i class="fa fa-user-tie mr-2"/>
                        <span v-html="$t('header.account.registerPanel.IAmAProfessional')"/>
                    </label>
                </div>
                <hr class="mt-1 mb-2"/>
                <transition name="fade" mode="out-in">
                    <div class="mb-3" v-if="user.isProfessional" key="professional-user">
                        <label for="society-name">
                            {{$t('header.account.registerPanel.societyName')}}
                            <RedAsterisk/>
                        </label>
                        <ValidationProvider v-slot="{ errors, classes }">
                            <div class="input-group">
                                <input class="form-control" type="text" :placeholder="$t('header.account.registerPanel.required')"
                                       required :class="classes" v-model='user.society.name' id="society-name">
                                <span class="input-group-addon"><i class="material-icons business_center"></i></span>
                            </div>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </ValidationProvider>
                        <div v-if="shopType === 'B2B'" class="d-flex align-items-center font-italic text-muted">
                            <i class="fa fa-info-circle mr-2"/>
                            <span v-html="$t('header.account.registerPanel.shopIsForBusinessesOnly')"/>
                        </div>
                        <div v-if="isUserSocietySIRETEnabled">
                            <label for="society-SIRET">
                                {{$t('header.account.registerPanel.societySIRET')}}
                                <RedAsterisk/>
                            </label>
                            <ValidationProvider v-slot="{ errors, classes }">
                                <div class="input-group">
                                    <input class="form-control" type="text" :placeholder="$t('header.account.registerPanel.required')"
                                           required :class="classes" v-model='user.society.SIRET' id="society-SIRET"  pattern="[0-9]{14}"
                                           :title="$t('header.account.registerPanel.mustBeAValidSIRET')">
                                    <span class="input-group-addon"><i class="material-icons business"></i></span>
                                </div>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div v-else key="individual-user">
                        <div class="mb-3">
                            <label for="first-name">
                                {{$t('header.account.registerPanel.firstName')}}
                                <RedAsterisk/>
                            </label>
                            <ValidationProvider v-slot="{ errors, classes }">
                                <div class="input-group">
                                    <input class="form-control" type="text" :placeholder="$t('header.account.registerPanel.required')"
                                           required v-model='user.firstName' :class="classes" id="first-name">
                                    <span class="input-group-addon"><i class="material-icons person"></i></span>
                                </div>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="mb-3">
                            <label for="last-name">
                                {{$t('header.account.registerPanel.lastName')}}
                                <RedAsterisk/>
                            </label>
                            <ValidationProvider v-slot="{ errors, classes }">
                                <div class="input-group">
                                    <input class="form-control" type="text" :placeholder="$t('header.account.registerPanel.required')"
                                           required v-model='user.lastName' :class="classes" id="last-name">
                                    <span class="input-group-addon"><i class="material-icons person"></i></span>
                                </div>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </ValidationProvider>
                        </div>
                    </div>
                </transition>
                <div v-if="isUserMobilePhoneRequiredForRegistration">
                    <label for="user-mobile-phone">
                        {{$t('header.account.registerPanel.mobilePhone')}}
                        <RedAsterisk/>
                    </label>
                    <ValidationProvider v-slot="{ errors, classes }" rules="mobile_phone">
                        <div class="input-group">
                            <input class="form-control" type="tel" :placeholder="$t('header.account.registerPanel.required')"
                                   required :class="classes" v-model='user.mobilePhone' id="user-mobile-phone">
                            <span class="input-group-addon"><i class="material-icons phone"></i></span>
                        </div>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                    </ValidationProvider>
                </div>
                <hr class="my-2"/>
                <div class="custom-control custom-checkbox custom-control-inline c-pointer">
                    <input class="custom-control-input" type="checkbox" id="does-receive-newsletter" v-model="user.doesReceiveNewsletter"/>
                    <label id="does-receive-newsletter-label" class="custom-control-label text-primary c-pointer" for="does-receive-newsletter">
                        <span v-html="$t('header.account.registerPanel.doesReceiveNewsletter')"/>
                    </label>
                </div>
                <SubmitBtn :classes="'btn btn-primary btn-block'" :disabled="invalid" :loading="loading"
                            :text="$t('header.account.registerPanel.register')"/>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Swal from "sweetalert2";
import SubmitBtn from "../../../shared/Misc/SubmitBtn";
import User from "../../../../classes/User";
import RedAsterisk from "@/components/shared/Forms/RedAsterisk";

export default {
    name: "RegisterPanel",
    components: {RedAsterisk, SubmitBtn},
    data() {
        return {
            user: new User({ isProfessional: false }),
            confirmPassword: undefined,
            loading: false,
        };
    },
    computed: {
        ...mapGetters("config", {
            shopPrimaryColor: "shopPrimaryColor",
            shop: "shop",
            shopType: "shopType",
            isUserSocietySIRETEnabled: "isUserSocietySIRETEnabled",
            isUserMobilePhoneRequiredForRegistration: "isUserMobilePhoneRequiredForRegistration",
        }),
    },
    created() {
        if (this.shopType === "B2B") {
            this.user.isProfessional = true;
        }
    },
    methods: {
        ...mapActions("sideBarMenu", {
            hideSideBar: "hide",
        }),
        async register() {
            if (this.user.password !== this.confirmPassword) {
                return this.$toasted.error(this.$t("header.account.registerPanel.passwordsDontMatch"), { icon: "key" });
            }
            try {
                this.loading = true;
                const response = await this.$hermesAPI.register(this.user);
                this.showSwalMessage(response);
                this.resetForm();
            } catch (err) {
                this.handleRegisterError(err.response.data);
            } finally {
                this.loading = false;
            }
        },
        showSwalMessage(response) {
            if (response.data.method === "admin") {
                this.hideSideBar();
                Swal.fire({
                    icon: "success",
                    title: this.$t("header.account.registerPanel.registerSuccess"),
                    text: this.$t("header.account.registerPanel.waitForAdminToActivate"),
                    confirmButtonColor: this.shopPrimaryColor,
                    confirmButtonText: this.$t("header.account.registerPanel.gotIt"),
                    footer: `<span>${this.$t("header.account.registerPanel.dontForgetSpam")}</span>`,
                });
            } else if (response.data.method === "mail") {
                Swal.fire({
                    icon: "success",
                    title: this.$t("header.account.registerPanel.registerSuccess"),
                    text: this.$t("header.account.registerPanel.waitForActivationByMail"),
                    confirmButtonColor: this.shopPrimaryColor,
                    confirmButtonText: this.$t("header.account.registerPanel.gotIt"),
                    footer: `<span>${this.$t("header.account.registerPanel.dontForgetSpam")}</span>`,
                });
                this.$emit("open-login-tab");
            }
        },
        resetForm() {
            this.user = new User({ isProfessional: false });
            this.confirmPassword = "";
        },
        handleRegisterError(error) {
            if (error.code === 3) {
                this.$refs.email.focus();
                this.$toasted.error(this.$t("header.account.registerPanel.emailUsed"), { icon: "envelope" });
            } else {
                this.$toasted.error(this.$t("shared.toast.badRequest"), { icon: "server" });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    #signup-form {
        label {
            font-weight: 500;
        }
    }

    #is-professional-label {
        font-size: 1rem;
    }
</style>