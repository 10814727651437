<template>
    <section id="shop-sort-by-section" class="widget widget-icon-list">
        <h3 class="widget-title">{{ $t("shop.shopMenu.sortBy") }}</h3>
        <ul>
            <li v-if="frontConfig.isShopSortByReleaseDateEnabled" :class="{ active: this.queries.sort === 'release-date' }">
                <a href="#" v-scroll-to="'header'" @click.prevent="changeQueryParam({ sort: 'release-date' })">
                    <i class="far fa-star"/>
                    {{ $t("shop.shopMenu.releaseDate") }}
                </a>
            </li>
            <li v-if="frontConfig.isShopSortByPriceWTEnabled && user.canSeePrices" :class="{ active: this.queries.sort === 'price-w-t' }">
                <a href="#" v-scroll-to="'header'" @click.prevent="changeQueryParam({ sort: 'price-w-t' })">
                    <i class="material-icons attach_money"/>
                    {{ $t("shop.shopMenu.price") }}
                </a>
            </li>
            <li v-if="frontConfig.isShopSortByTitleEnabled" :class="{ active: this.queries.sort === 'title' }">
                <a href="#" v-scroll-to="'header'" @click.prevent="changeQueryParam({ sort: 'title' })">
                    <i class="material-icons sort_by_alpha"/>
                    {{ $t("shop.shopMenu.title") }}
                </a>
            </li>
        </ul>
        <button @click="changeQueryParam({ order: queries.order === 'desc' ? 'asc': 'desc' })"
                class="btn btn-sm btn-block btn-secondary" v-scroll-to="'header'">
            <span v-html="order"/>
            <i id="order" class="material-icons vertical_align_top fa-pull-right"
               :class="{ 'fa-rotate-180': this.queries.order === 'desc' }"/>
        </button>
    </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "ShopMenuSortBy",
    props: {
        queries: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters("config", {
            frontConfig: "front",
        }),
        ...mapGetters("user", {
            user: "user",
        }),
        order() {
            return this.queries.order === "asc" ? this.$t("shop.shopMenu.asc") : this.$t("shop.shopMenu.desc");
        },
    },
    methods: {
        changeQueryParam(queryParam) {
            this.$emit("change-query-param", queryParam);
        },
    },
};
</script>

<style scoped lang="scss">
    #order {
        transition: all 200ms ease;
        cursor: pointer;
        margin-top: 3px;
        font-weight: 500;
        font-size: 1.6rem;
    }

    #order-label {
        font-size: 0.8rem;
        margin-top: 1px;
        font-style: italic;
    }
</style>