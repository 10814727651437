import Vue from "vue";
import router from "../../router";

const state = {};

const getters = {};

const mutations = {};

const actions = {
    async startMainTour() {
        await router.push("/");
        Vue.prototype.$tours.mainTour.start();
    },
    async startShopTour() {
        await router.push("/shop");
        Vue.prototype.$tours.shopTour.start();
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};