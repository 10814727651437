<template>
    <v-tour name="shopTour" :steps="steps" :options="options" :callbacks="callbacks">
        <template slot-scope="tour">
            <transition name="fade">
                <v-step v-if="tour.steps[tour.currentStep]" :key="tour.currentStep" :step="tour.steps[tour.currentStep]"
                        :previous-step="tour.previousStep" :next-step="tour.nextStep" :stop="tour.stop"
                        :is-first="tour.isFirst" :is-last="tour.isLast" :labels="tour.labels">
                </v-step>
            </transition>
        </template>
    </v-tour>
</template>

<script>
import { tourOptions, tourStepOffset } from "../../../helpers/v-tour";
import {mapGetters} from "vuex";

export default {
    name: "ShopTour",
    data() {
        return {
            options: tourOptions,
            callbacks: {
                onPreviousStep: this.onPreviousStep,
                onNextStep: this.onNextStep,
            },
        };
    },
    computed: {
        ...mapGetters("user", {
            isUserLogged: "isLogged",
        }),
        steps() {
            return [{
                target: "#main-nav-shop",
                content: `<i class="fa fa-book-open fa-3x mb-2"></i><br/>${this.$t("ShopTour.welcomeOnShop")}<br/>${this.$t("ShopTour.youCanUseArrows")}`,
            }, {
                target: "#shop-product-list",
                content: `<i class="fa fa-book fa-3x mb-2"></i><br/>${this.productListStepText}`,
                params: { placement: "top" },
                offset: tourStepOffset - 200,
            }, {
                target: "#shop-total-result",
                content: `<i class="fa fa-search fa-3x mb-2"></i><br/>${this.$t("ShopTour.shopTotalResultBasedOnSearch")}`,
                params: { placement: "right" },
                offset: tourStepOffset,
            }, {
                target: "#shop-select-per-page",
                content: `<i class="fa fa-list-ol fa-3x mb-2"></i><br/>${this.$t("ShopTour.youCanChangePerPage")}`,
                offset: tourStepOffset,
            }, {
                target: "#shop-sort-by-section",
                content: `<i class="fa fa-sort-alpha-up fa-3x mb-2"></i><br/>${this.$t("ShopTour.youCanSortBy")}`,
                params: { placement: "right" },
                offset: tourStepOffset,
            }, {
                target: "#shop-publishers-section",
                content: `<i class="fa fa-book-open fa-3x mb-2"></i><br/>${this.$t("ShopTour.filterPublisherShop")}`,
                params: { placement: "right" },
                offset: tourStepOffset,
            }, {
                target: "#shop-book-collections-section",
                content: `<i class="fa fa-bookmark fa-3x mb-2"></i><br/>${this.$t("ShopTour.filterCollection")}`,
                params: { placement: "right" },
                offset: tourStepOffset,
            }, {
                target: "#advanced-search-btn",
                content: `<i class="fa fa-search fa-3x mb-2"></i><br/>${this.$t("ShopTour.youCanAdvancedSearch")}`,
                params: { placement: "right" },
                offset: tourStepOffset,
            }, {
                target: "#main-nav-help",
                content: `<i class='fa fa-question-circle text-center fa-3x mb-2'></i><br/>${this.$t("ShopTour.theHelpPage")}`,
            }];
        },
        productListStepText() {
            const label = this.$t("ShopTour.productList");
            return this.isUserLogged ? label : `${label}<br/> <i>(${this.$t("ShopTour.youMustBeLoggedToAddToCart")})</i>`;
        },
    },
    methods: {
        onPreviousStep() {
            this.onCurrentStep(this.$tours.shopTour.currentStep - 1);
        },
        onNextStep() {
            this.onCurrentStep(this.$tours.shopTour.currentStep + 1);
        },
        onCurrentStep(stepIdx) {
            if (stepIdx < 0 || stepIdx >= this.steps.length) {
                console.log("coucou");
            }
        },
    },
};
</script>

<style scoped>

</style>