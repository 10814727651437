<template>
    <SubmitBtn :text="text" @click.prevent.stop="addToCart" :tooltipText="tooltip"
               :classes="classes" :disabledTooltipText="disabledTooltipText"
               :loading="loading" :disabled="!canAddToCart"/>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Swal from "sweetalert2";
import SubmitBtn from "../Misc/SubmitBtn";
import { isCartFullOfProductsWithAgreement } from "@/helpers/user";

export default {
    name: "AddToCartBtn",
    components: {SubmitBtn},
    props: {
        classes: {
            type: String,
        },
        product: {
            type: Object,
            required: true,
        },
        quantity: {
            type: Number,
            default: 1,
        },
        text: {
            type: String,
            required: true,
        },
        tooltip: {
            type: String,
            default: null,
        },
        toCheckout: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        ...mapGetters("preferences", {
            chosenCurrency: "currency",
        }),
        ...mapGetters("user", {
            user: "user",
            isUserLogged: "isLogged",
            userCart: "cart",
            userPublisherAgreements: "publisherAgreements",
        }),
        ...mapGetters("config", {
            frontConfig: "front",
            shopPrimaryColor: "shopPrimaryColor",
            doesShopShowOnlyPODProducts: "doesShopShowOnlyPODProducts",
            cannotOrderUnprintableTwice: "cannotOrderUnprintableTwice",
        }),
        disabledTooltipText() {
            if (!this.frontConfig.products.canAddToCartNotLogged && !this.isUserLogged) {
                return this.$t("shared.submitBtn.cantBuyNotLogged");
            } else if (!this.product.publisher.showPrice) {
                return this.$t("shared.submitBtn.cantBuyPublisherHidePrice");
            } else if (this.product.active.value === false || this.doesShopShowOnlyPODProducts && !this.product.pod) {
                return this.$t("SubmitBtn.cantBuyProductIfInactive");
            } else if (!this.hasPublisherAgreement && isCartFullOfProductsWithAgreement(this.user)) {
                return this.$t("SubmitBtn.cantAddToAgreementCart");
            } else if (this.hasPublisherAgreement && !isCartFullOfProductsWithAgreement(this.user)) {
                return this.$t("SubmitBtn.cantAddToClassicCart");
            } else if (this.cannotOrderUnprintableTwice && this.product.alreadyOrdered && !this.product.pod) {
                return this.$t("SubmitBtn.makeProductFilesPrintable");
            }
            return "";
        },
        hasPublisherAgreement() {
            return this.userPublisherAgreements.includes(this.product.publisher._id);
        },
        canAddToCart() {
            return this.product.canAddToCart(this.isUserLogged, this.doesShopShowOnlyPODProducts, this.cannotOrderUnprintableTwice) &&
                this.checkCartHomogeneity;
        },
        checkCartHomogeneity() {
            return this.userCart.length === 0 ||
                this.hasPublisherAgreement && isCartFullOfProductsWithAgreement(this.user) ||
                !this.hasPublisherAgreement && !isCartFullOfProductsWithAgreement(this.user);
        },
    },
    methods: {
        ...mapActions("sideBarMenu", {
            hideSideBar: "hide",
            openAccountSection: "openAccountSection",
        }),
        async displayAddToCartToast(product) {
            const toastedMessage = `<img onerror="this.style.display='none'" src='${product.firstCoverPhotoUrl}'/>
                                    <span>${this.$t("shared.toast.addedToCart")}</span>`;
            const actions = [];
            if (this.isUserLogged) {
                actions.push({ text: this.$t("shared.toast.checkout"), push: { path: "/checkout" }, onClick: () => this.hideSideBar() });
            } else {
                actions.push({ text: this.$t("shared.toast.loginToCheckout"), onClick: () => this.openAccountSection() });
            }
            if (this.user.canOneClickOrder) {
                actions.push({ text: this.$t("shared.toast.oneClickOrder"), push: { path: "/checkout?mode=one-click" }, onClick: () => this.hideSideBar });
            }
            this.$toasted.show(toastedMessage, { position: "bottom-left", action: actions });
        },
        async confirmAddToCart() {
            return Swal.fire({
                title: this.$t("ShopProductPreview.youWillStartAnAgreementCart"),
                text: this.$t("ShopProductPreview.AgreementCartExplanations"),
                footer: `<center><i>${this.$t("ShopProductPreview.youCanRevertByCleaningCart")}</i></center>`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: this.shopPrimaryColor,
                cancelButtonColor: "#d33",
                confirmButtonText: `<i class="fa fa-handshake mr-2"></i> ${this.$t("ShopProductPreview.start")}`,
                cancelButtonText: this.$t("ShopProductPreview.cancel"),
            });
        },
        async addToCart() {
            try {
                if (this.hasPublisherAgreement && this.user.cart.length === 0) {
                    const { value: confirm } = await this.confirmAddToCart();
                    if (!confirm) {
                        return;
                    }
                }
                this.loading = true;
                const product = await this.$store.dispatch("user/addToCart", { productId: this.product._id, quantity: this.quantity });
                if (this.toCheckout) {
                    await this.$router.push("/checkout");
                } else {
                    await this.displayAddToCartToast(product);
                }
            } catch (err) {
                this.$error.displayError(err);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped>

</style>