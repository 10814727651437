<template>
    <div>
        <span v-tooltip="$tc('ProductReviewVotes.countUsersFoundThisUseful', upVoteCount, { count: upVoteCount })">
            {{ review.votes.up.length }}
        </span>
        <i class="far fa-thumbs-up mr-3 vote-btn" :class="{ 'text-success': userFoundItUseful }" @click="voteForReview(true)"
           v-tooltip="upVoteButtonTooltip">
        </i>
        <i class="far fa-thumbs-down vote-btn" :class="{ 'text-danger': userDoesntFoundItUseful }" @click="voteForReview(false)"
           v-tooltip="downVoteButtonTooltip">
        </i>
        <span v-tooltip="$tc('ProductReviewVotes.countUsersDoesntFoundThisUseful', downVoteCount, { count: downVoteCount })">
            {{ review.votes.down.length }}
        </span>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { decodeJWTToken } from "../../../../../helpers/API";

export default {
    name: "ProductReviewVotes",
    data() {
        return {
            loading: false,
        };
    },
    props: {
        review: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters("user", {
            user: "user",
        }),
        userFoundItUseful() {
            return this.review.votes.up.find(vote => vote === this.user._id);
        },
        userDoesntFoundItUseful() {
            return this.review.votes.down.find(vote => vote === this.user._id);
        },
        upVoteButtonTooltip() {
            return this.userFoundItUseful ? this.$t("ProductReviewVotes.clickToUnsetVote") : this.$t("ProductReviewVotes.clickToUpVote");
        },
        downVoteButtonTooltip() {
            return this.userDoesntFoundItUseful ? this.$t("ProductReviewVotes.clickToUnsetVote") : this.$t("ProductReviewVotes.clickToDownVote");
        },
        upVoteCount() {
            return this.review.votes.up.length;
        },
        downVoteCount() {
            return this.review.votes.down.length;
        },
    },
    methods: {
        async voteForReview(vote) {
            if (this.loading) {
                return;
            }
            try {
                this.loading = true;
                if (vote && this.userFoundItUseful || !vote && this.userDoesntFoundItUseful) {
                    const { userId } = decodeJWTToken();
                    const { data } = await this.$hermesAPI.unsetVoteProductReview(this.review.product, this.review._id, { user: userId });
                    this.$toasted.success(this.$t("ProductReviewVotes.voteCanceled"), { icon: "thumbs-up" });
                    this.$emit("edit-product-review", data);
                } else {
                    const { userId } = decodeJWTToken();
                    const { data } = await this.$hermesAPI.voteProductReview(this.review.product, this.review._id, { vote, user: userId });
                    this.$toasted.success(this.$t("ProductReviewVotes.voteAdded"), { icon: "thumbs-up" });
                    this.$emit("edit-product-review", data);
                }
            } catch (e) {
                this.$error.displayError(e);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .vote-btn {
        cursor: pointer;
        transition: all 0.2s ease-in-out;
    }
</style>