<template>
    <section class="hero-slider">
        <div class="owl-carousel large-controls dots-inside pb-4">
            <div v-for="product in highlightedProducts" :key="product._id" class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="pr-3 pt-5 pb-0 py-md-5 img-container">
                            <img width="400" class="d-block box-shadow carousel-img" v-lazy="product.firstCoverPhotoUrl"
                                 alt="Product">
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-6">
                        <div class="padding-top-3x padding-bottom-3x px-3 px-lg-5 text-md-left from-bottom">
                            <router-link :to="`/product/${product._id}`">
                                <h2 v-html="product.title"/>
                            </router-link>
                            <ProductStarRate :product="product" :show-no-review-text="false" :show-add-review-btn="false"/>
                            <p class="text-sm text-muted">{{product.description}}</p>
                            <div class="row">
                                <AddToCartBtn :product="product" classes="btn btn-primary" :text="$t('home.homeCarousel.addToCart')"/>
                                <router-link class="btn btn-outline-primary mx-0" :to="`product/${product._id}`" v-html="$t('home.homeCarousel.viewProduct')"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import AddToCartBtn from "../../shared/Product/AddToCartBtn";
import ProductStarRate from "../../shared/Product/ProductStarRate";

export default {
    name: "HomeCarousel",
    components: {ProductStarRate, AddToCartBtn},
    props: {
        products: {
            required: true,
        },
    },
    data() {
        return {
            loading: false,
            highlightedProducts: _.sampleSize(this.products, 5),
        };
    },
    mounted() {
    // eslint-disable-next-line no-undef
        $(".owl-carousel").owlCarousel({
            items: 1,
            loop: true,
            mouseDrag: false,
            touchDrag: false,
            dots: true,
            nav: true,
            navText: false,
            autoplay: true,
            autoplayTimeout: 8000,
        });
    },
    computed: {
        ...mapGetters("preferences", {
            chosenCurrency: "currency",
        }),
    },
};
</script>

<style lang="scss" scoped>
    .img-container {
        display: flex;
        justify-content: center;
        align-items: center;
        .carousel-img {
            max-height: 600px;
            width: auto;
        }
    }
</style>