<template>
    <span class="red-text ml-1" v-html="'*'"/>
</template>

<script>
export default {
    name: "RedAsterisk",
};
</script>

<style scoped>
</style>