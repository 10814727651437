<template>
    <footer id="imprimer-vos-livres-footer" class="site-footer">
        <div class="row w-100">
            <div class="d-flex justify-content-around col-7 align-items-center">
                <img :src="IMGs.madeInFrance" width="150" alt="Fabriqué en France"/>
                <img :src="IMGs.ecoResponsiblePrinting" width="150" alt="Imprimerie éco-responsable"/>
                <a href="https://www.typolibris.fr" target="_blank">
                    <img :src="IMGs.typolibris" width="150" alt="Imprimerie éco-responsable"/>
                </a>
            </div>
            <div class="d-flex align-items-center justify-content-around col-5">
                <div class="d-flex align-items-center flex-column">
                    <div>
                        <i class="fa fa-phone mr-2"/>
                        <span v-html="shop.phone"/>
                    </div>
                    <div class="mt-2">
                        <router-link to="/contact">
                            <i class="fa fa-envelope mr-2"/>
                            <span v-html="'Nous contacter'"/>
                        </router-link>
                    </div>
                </div>
                <div>
                    <div class="d-flex align-items-center justify-content-center">
                        <i class="fa fa-map-marker mr-2"/>
                        <div>
                            <div v-html="shop.address.street"/>
                            <div v-html="`${shop.address.zipCode} ${shop.address.city}`"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { mapGetters } from "vuex";
import madeInFrance from "@/assets/img/imprimer-vos-livres/custom-footer/made-in-france.png";
import ecoResponsiblePrinting from "@/assets/img/imprimer-vos-livres/custom-footer/eco-responsible-printing.png";
import typolibris from "@/assets/img/imprimer-vos-livres/custom-footer/typolibris.png";

export default {
    name: "PrintCorpFooter",
    data() {
        return {
            IMGs: { madeInFrance, ecoResponsiblePrinting, typolibris },
        };
    },
    computed: {
        ...mapGetters("config", {
            shop: "shop",
            shopDefaultMail: "shopDefaultMail",
        }),
    },
};
</script>

<style lang="scss" scoped>
    #imprimer-vos-livres-footer {
        border-top: 1px solid lightgray;
        border-bottom: 1px solid lightgray;
        background-color: #F4F4F4;
        height: 100px;
    }
</style>