import i18n from "../i18n";

const Error = {
    // eslint-disable-next-line no-unused-vars
    install(Vue, options) {
        Vue.prototype.$error = {};

        Vue.prototype.$error.displayError = error => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    Vue.prototype.$toasted.error(i18n.t("shared.toast.unauthorized"), { icon: "times" });
                    options.router.push("/");
                } else {
                    Vue.prototype.$toasted.error(i18n.t("shared.toast.badRequest"), { icon: "server" });
                }
            } else {
                throw error;
            }
        };

        Vue.prototype.$error.handleLoginError = (error, shop) => {
            if (!error || !error.response || !error.response.data) {
                return;
            }
            error = error.response.data;
            if (error.code === 10) {
                Vue.prototype.$toasted.error(i18n.t("header.account.loginPanel.loginFailed"), { icon: "lock" });
            } else if (error.code === 11) {
                const message = shop.type === "B2B" ? i18n.t("header.account.loginPanel.notActivatedYet.admin") : i18n.t("header.account.loginPanel.notActivatedYet.mail");
                Vue.prototype.$toasted.error(message, { icon: "lock" });
            } else if (error.code === 12) {
                Vue.prototype.$toasted.error(i18n.t("header.account.loginPanel.blocked"), { icon: "lock" });
            } else {
                Vue.prototype.$toasted.error(i18n.t("shared.toast.badRequest"), { icon: "server" });
            }
        };

        Vue.prototype.$error.handleStripeError = error => {
            if (!error || !error.response || !error.response.data) {
                return;
            }
            error = error.response.data;
            if (error.code === 24) {
                Vue.prototype.$toasted.error(error.message, { icon: "times" });
            } else {
                Vue.prototype.$toasted.error(i18n.t("shared.toast.badRequest"), { icon: "server" });
            }
        };
    },
};

export default Error;