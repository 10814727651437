import { hasProp } from "../helpers/class";

class Currency {
    constructor(currency = null) {
        this.id = !hasProp(currency, "id") ? undefined : currency.id;
        this.name = !hasProp(currency, "name") ? undefined : currency.name;
        this.alpha3ISO = !hasProp(currency, "alpha3ISO") ? undefined : currency.alpha3ISO;
        this.symbol = !hasProp(currency, "symbol") ? undefined : currency.symbol;
        this.localSymbol = !hasProp(currency, "localSymbol") ? undefined : currency.localSymbol;
        this.euroRate = !hasProp(currency, "euroRate") ? undefined : currency.euroRate;
        this.decimalDigits = !hasProp(currency, "decimalDigits") ? undefined : currency.decimalDigits;
    }
}

export default Currency;