<template>
    <div class="container padding-bottom-3x mb-1">
        <div class="table-responsive shopping-cart">
            <table class="table">
                <thead>
                <tr>
                    <th>{{$t("cartDetails.productName")}}</th>
                    <th v-if="user.canSeePrices" class="text-center" v-html='$t("cartDetails.priceUnit")'></th>
                    <th v-if="user.canSeePrices" class="text-center">{{$t("cartDetails.discount")}}</th>
                    <th v-if="user.canSeePrices" class="text-center" v-html='$t("cartDetails.priceReduce")'></th>
                    <th class="text-center">{{$t("cartDetails.quantity")}}</th>
                    <th v-if="user.canSeePrices" class="text-center" v-html='$t("cartDetails.priceTotalWT")'></th>
                    <th></th>
                </tr>
                </thead>
                <transition-group tag="tbody" name="shop-cart-line">
                    <CartDetailsLine v-for="product in userCart" :product="product" :key="product._id" class="shop-cart-line"/>
                </transition-group>
            </table>
        </div>
        <hr/>
        <div class="shopping-cart-footer d-flex justify-content-around align-items-center m-2">
            <SubmitBtn @click="confirmDeleteCart" :classes="'btn btn-sm btn-outline-danger'"
                        :text="$t('cartDetails.clearCart')" :loading="loadingDelete"/>
            <button class="btn btn-success" @click="checkoutBtnAction" v-html="checkoutBtnText"/>
            <div v-if="user.canSeePrices" class="column text-lg">
                {{$t("cartDetails.cartSubTotal")}}:
                <span class="text-medium text-dark">
                    {{this.properPrice(this.getSubTotal, chosenCurrency, currencies)}}
                </span>
            </div>
        </div>
        <hr/>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CartDetailsLine from "./CartDetailsLine";
import Swal from "sweetalert2";
import SubmitBtn from "../../shared/Misc/SubmitBtn";
import { properPrice, getSubTotal } from "../../../helpers/price";

export default {
    name: "CartDetails",
    components: {SubmitBtn, CartDetailsLine},
    data() {
        return {
            loadingDelete: false,
        };
    },
    computed: {
        ...mapGetters("currencies", {
            currencies: "currencies",
        }),
        ...mapGetters("preferences", {
            chosenCurrency: "currency",
        }),
        ...mapGetters("user", {
            isUserLogged: "isLogged",
            userCart: "cart",
            user: "user",
        }),
        ...mapGetters("config", {
            shopPrimaryColor: "shopPrimaryColor",
        }),
        getSubTotal() {
            return getSubTotal(this.userCart, this.currencies);
        },
        checkoutBtnText() {
            return this.isUserLogged ? this.$t("cartDetails.checkout") : this.$t("cartDetails.logInToCheckout");
        },
    },
    methods: {
        ...mapActions("sideBarMenu", {
            openAccountSection: "openAccountSection",
        }),
        properPrice,
        confirmDeleteCart() {
            Swal.fire({
                title: this.$t("cartDetails.youSure"),
                text: this.$t("cartDetails.youWillDelete"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: this.shopPrimaryColor,
                cancelButtonColor: "#D33",
                confirmButtonText: this.$t("cartDetails.yesDelete"),
                cancelButtonText: this.$t("cartDetails.cancel"),
            }).then(result => {
                if (result.value) {
                    this.deleteCart();
                }
            });
        },
        async deleteCart() {
            try {
                this.loadingDelete = true;
                await this.$store.dispatch("user/deleteCart");
            } catch (err) {
                this.$error.displayError(err);
            } finally {
                this.loadingDelete = false;
            }
        },
        async checkoutBtnAction() {
            if (this.isUserLogged) {
                await this.$router.push("/checkout");
            } else {
                await this.openAccountSection();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .table-responsive {
        overflow-y: hidden !important;
    }

    .shop-cart-line {
        transition: all .5s;
    }

    .shop-cart-line-enter, .shop-cart-line-leave-to {
        opacity: 0;
    }

    .shop-cart-line-leave-active {
        position: absolute;
    }

    .shopping-cart-footer {
        border-top: none;
    }
</style>