<template>
    <div id="profile">
        <PageTitle :title='profileTitle'>
            <template v-slot:left>
                <i class="fa text-primary fa-3x" :class="profileTitleIcon"></i>
            </template>
        </PageTitle>
        <div class="container padding-bottom-3x mb-2">
            <transition name="fadein" mode="out-in">
                <div key="loading" v-if="!isUserLogged">
                    <APILoading :font-size="30" :text="$t('shared.apiLoading.getProfile')"/>
                </div>
                <div key="profile" v-else class="row">
                    <div class="col-lg-4">
                        <aside class="user-info-wrapper">
                            <div class="user-cover" :style="`background-image: url(${defaultCoverPic})`"></div>
                            <div class="user-info">
                                <div class="user-custom-avatar">
                                    <UserAvatar :user="user"/>
                                </div>
                                <div class="user-data">
                                    <h5 v-html="user.displayedName"/>
                                    <span>{{$t("profile.joined", { date: properDate(userCreatedAt) })}}</span>
                                </div>
                            </div>
                        </aside>
                        <nav class="list-group">
                            <router-link to="/profile/orders" class="list-group-item"
                                         :class="{ active: $route.name === 'profileOrders' }">
                                <i class="fa fa-list-alt fa-pull-left"></i>{{$t("profile.orders.title")}}
                            </router-link>
                            <router-link to="/profile" class="list-group-item"
                                         :class="{ active: $route.name === 'me' }">
                                <i class="fa fa-user fa-pull-left"></i>{{$t("profile.me.title")}}
                            </router-link>
                            <router-link to="/profile/addresses" class="list-group-item with-badge"
                                         :class="{ active: $route.name === 'profileAddresses' }">
                                <i class="fa fa-map-pin fa-pull-left"></i>{{$t("profile.addresses.title")}}
                                <span class="badge badge-default badge-pill">{{userAddresses.length}}</span>
                            </router-link>
                            <router-link to="/profile/preferences" class="list-group-item"
                                         :class="{ active: $route.name === 'profilePreferences' }">
                                <i class="fa fa-cog fa-pull-left"></i>{{$t("Profile.preferences")}}
                            </router-link>
                            <router-link to="/profile/change-password" class="list-group-item"
                                         :class="{ active: $route.name === 'profileChangePassword' }">
                                <i class="fa fa-lock fa-pull-left"></i>{{$t("Profile.changePassword")}}
                            </router-link>
                        </nav>
                    </div>
                    <router-view :user="user" ref="profileView" class="fadeIn"/>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PageTitle from "../../shared/Misc/PageTitle";
import defaultProfilePic from "../../../assets/img/default_profile_pic.png";
import defaultCoverPic from "../../../assets/img/user-cover-img.jpg";
import APILoading from "../../shared/Misc/APILoading";
import { properDate } from "../../../helpers/date";
import UserAvatar from "../../shared/User/UserAvatar";

export default {
    name: "Profile",
    components: {UserAvatar, APILoading, PageTitle},
    data() {
        return {
            defaultProfilePic, defaultCoverPic,
        };
    },
    computed: {
        ...mapGetters("user", {
            user: "user",
            userFullName: "fullName",
            userAddresses: "addresses",
            userCreatedAt: "createdAt",
            isUserLogged: "isLogged",
        }),
        profileTitle() {
            const titles = {
                me: this.$t("profile.me.title"),
                profileOrders: this.$t("profile.orders.title"),
                profileAddresses: this.$t("profile.addresses.title"),
                profilePreferences: this.$t("Profile.preferences"),
                profileChangePassword: this.$t("Profile.changePassword"),
            };
            return titles[this.$route.name];
        },
        profileTitleIcon() {
            const icons = {
                me: "fa-user",
                profileOrders: "fa-list-alt",
                profileAddresses: "fa-map-pin",
                profilePreferences: "fa-cog",
                profileChangePassword: "fa-lock",
            };
            return icons[this.$route.name];
        },
    },
    async created() {
        await this.checkUserAuthentication();
    },
    methods: {
        properDate,
        ...mapActions("user", {
            checkUserAuthentication: "checkUserAuthentication",
        }),
    },
    watch: {
        user: {
            handler() {
                this.checkUserAuthentication();
            },
            deep: true,
        },
    },
};
</script>

<style scoped>

    .user-custom-avatar {
        padding-top: 48px;
    }

    .fa-pull-left {
        padding-top: 7px;
        padding-right: 25px;
        width: 5px;
    }

</style>