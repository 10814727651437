<template>
    <div v-html="$t('InputAvailableCharacters.countAvailableCharacters', { count: inputValueLength, max: maxLength })"/>
</template>

<script>
export default {
    name: "InputAvailableCharacters",
    props: {
        inputValue: {
            required: true,
        },
        maxLength: {
            type: Number,
            required: true,
        },
        trim: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        inputValueLength() {
            if (this.inputValue) {
                return this.trim ? this.inputValue.trim().length : this.inputValue.length;
            } else {
                return 0;
            }
        },
    },
};
</script>

<style scoped>

</style>