import i18n from "../i18n";

export const tourOptions = {
    labels: {
        buttonSkip: i18n.t("Tour.buttonSkip"),
        buttonPrevious: i18n.t("Tour.buttonPrevious"),
        buttonNext: i18n.t("Tour.buttonNext"),
        buttonStop: i18n.t("Tour.buttonStop"),
    },
};

export const tourStepOffset = -100;