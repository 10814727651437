<template>
    <div id="checkout-payment-paypal" class="tab-pane fade"
         :class="{ show: chosenMethod, active: chosenMethod }" role="tabpanel">
        <div class="row align-items-center">
            <div class="col-md-8 col-12">
                <div class="custom-control custom-checkbox">
                    <input class="custom-control-input c-pointer" v-model="accepted"
                           type="checkbox" id="accept-paypal">
                    <label v-html="$t('checkout.payment.acceptPayPal', generalInfos)"
                           class="custom-control-label c-pointer" for="accept-paypal"></label>
                </div>
            </div>
            <div class="col-md-4 col-12 text-center">
                <transition mode="out-in" name="fade">
                    <div v-show="accepted" id="paypal-button-container"></div>
                </transition>
            </div>
        </div>
        <transition name="fade">
            <div v-if="accepted" class="row">
                <div class="col-12 text-center">
                    <div class="custom-control custom-checkbox custom-control-inline">
                        <input v-model="saveAsFavoritePaymentMethod" class="custom-control-input c-pointer"
                               type="checkbox" id="favorite-payment-method-paypal">
                        <label class="custom-control-label c-pointer" for="favorite-payment-method-paypal">
                            {{ $t("CheckoutPayment.favoritePaymentMethod") }}
                        </label>
                    </div>
                    <RequiredForOneClickOrder/>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import paypalLogo from "../../../../assets/img/payments/paypal.png";
import Order from "../../../../classes/Order";
import RequiredForOneClickOrder from "../../../shared/Order/RequiredForOneClickOrder";

export default {
    name: "CheckoutPaymentPayPal",
    components: {RequiredForOneClickOrder},
    props: {
        generalInfos: {
            type: Object,
            required: true,
        },
        order: {
            type: Object,
            required: true,
        },
        preferences: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            paypalLogo,
            localOrder: new Order(this.order),
            accepted: false,
            saveAsFavoritePaymentMethod: this.preferences.billing.method === "paypal",
        };
    },
    computed: {
        chosenMethod() {
            return this.order.billing.method === "paypal";
        },
    },
    mounted() {
        this.localOrder.billing.method = "paypal";
        // eslint-disable-next-line no-undef
        paypal.Buttons({
            createOrder: async() => {
                const response = await this.$hermesAPI.createPayPalPayment(this.localOrder);
                return response.data.orderID;
            },
            onApprove: async data => {
                this.localOrder.paypal = {
                    orderID: data.orderID,
                };
                this.$emit("create-order", this.localOrder);
            },
        }).render("#paypal-button-container");
    },
    methods: {
        setBillingMethod() {
            this.$emit("set-billing-method", {
                method: "paypal",
                favoritePaymentMethod: this.saveAsFavoritePaymentMethod,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
</style>