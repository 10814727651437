<template>
    <div class="fadeIn" id="app">
        <div v-if="loading" class="loader"/>
        <HermesNotAvailable v-else-if="hermesAPIConfig === null" key="api-not-available"/>
        <Hermes v-else-if="hermesAPIConfig !== undefined" key="hermes"/>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import HermesNotAvailable from "./components/HermesNotAvailable/HermesNotAvailable";
import Hermes from "./components/Hermes/Hermes";

export default {
    components: {Hermes, HermesNotAvailable},
    data() {
        return {
            loading: true,
        };
    },
    computed: {
        ...mapGetters("config", {
            hermesAPIConfig: "hermesAPI",
        }),
    },
    async created() {
        await this.$store.dispatch("config/getHermesAPIConfig");
        this.loading = false;
    },
    methods: {
        ...mapActions("config", {
            getHermesAPIConfig: "getHermesAPIConfig",
        }),
    },
};
</script>

<style lang="scss">
</style>