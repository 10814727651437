import i18n from "../i18n";

export function properPrice(price, currencyISO, currencies) {
    if (!currencies) {
        return `${price} ?`;
    }
    const currency = currencies.find(c => c.alpha3ISO === currencyISO);
    const symbol = currency ? currency.symbol : "?";
    const decimalDigits = currency ? currency.decimalDigits : 2;
    price = price.toFixed(decimalDigits);
    return parseFloat(price) ? `${price} ${symbol}`.replace(".", ",") : i18n.t("shared.free");
}

export function getSubTotal(products, currencies) {
    let decimalDigits = 2;
    if (currencies) {
        const currency = currencies.find(c => c.alpha3ISO === products[0].currency);
        decimalDigits = currency ? currency.decimalDigits : 2;
    }
    return products.reduce((acc, product) => acc + parseFloat(product.discountedPriceWT.toFixed(decimalDigits)) * product.quantity, 0);
}

export function normalizeDecimal(value) {
    if (!value) {
        return "";
    }
    return value.toString().replace(",", ".");
}