import Axios from "axios";
import Config from "../../config";
import { stringify } from "qs";

const SoBookQuotationAPI = {
    // eslint-disable-next-line no-unused-vars
    install(Vue, options) {
        const axios = Axios.create({
            baseURL: Config.soBookQuotationAPI.baseURL,
            timeout: 0,
        });

        Vue.prototype.$soBookQuotationAPI = {};

        Vue.prototype.$soBookQuotationAPI.getBindings = () => axios.get(`/products/bindings`);

        Vue.prototype.$soBookQuotationAPI.getCoverPrintings = () => axios.get(`/products/cover-printings`);

        Vue.prototype.$soBookQuotationAPI.getCoverPapers = () => axios.get(`/products/cover-weights`);

        Vue.prototype.$soBookQuotationAPI.getLaminations = () => axios.get(`/products/filmings`);

        Vue.prototype.$soBookQuotationAPI.getCorpusPapers = () => axios.get(`/products/inner-weights`);

        Vue.prototype.$soBookQuotationAPI.getBookPrice = (book, quantity, priceListId) => {
            const { components, dimensions, paging } = book;
            const data = {
                binding: components.binding.id,
                width: dimensions.width,
                height: dimensions.height,
                orientation: dimensions.orientation === "portrait" ? "portrait" : "paysage",
                innerweight: components.corpus.paper.id,
                "cover-printing-both-sides": components.cover.paper.id,
                coverweight: components.cover.paper.id,
                filming: components.cover.lamination.id,
                nbpages: paging.total,
                nbcolorpages: paging.color,
                quantity,
                "sobook-client": priceListId,
            };
            return axios.get(`/price?${stringify(data)}`);
        };

        Vue.prototype.$soBookQuotationAPI.getBookDimensions = book => {
            const { components, dimensions, paging } = book;
            const data = {
                binding: components.binding.id,
                innerweight: components.corpus.paper.id,
                coverweight: components.cover.paper.id,
                filming: components.cover.lamination.id,
                width: dimensions.width,
                height: dimensions.height,
                orientation: dimensions.orientation === "portrait" ? "portrait" : "paysage",
                nbpages: paging.total,
                nbcolorpages: paging.color,
            };
            return axios.get(`/dimensions?${stringify(data)}`);
        };
    },
};

export default SoBookQuotationAPI;