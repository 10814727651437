<template>
    <div>
        <div class="row align-items-center mb-3">
            <div class="col-11">
                <h3 id="agreement-title" v-html="$t('CheckoutPaymentAgreement.youWillAgreementOrder')"></h3>
            </div>
            <div class="col-1 text-center">
                <span class="fa-stack fa-2x">
                    <i class="fas fa-certificate fa-stack-2x text-primary"></i>
                    <i class="far fa-handshake fa-stack-1x fa-inverse"></i>
                </span>
            </div>
        </div>
        <hr class="mb-3"/>
        <div class="row align-items-center">
            <div class="col-12 col-md-8">
                <div class="custom-control custom-checkbox">
                    <input class="custom-control-input" v-model="accepted"
                           type="checkbox" id="accept-agreement"/>
                    <label v-html="$t('CheckoutPaymentAgreement.acceptAgreement')"
                           class="custom-control-label" for="accept-agreement"></label>
                </div>
            </div>
            <div class="col-12 col-md-4 text-center">
                <button @click="setBillingMethod" class="btn btn-primary" :disabled="!accepted">
                    {{$t("CheckoutPaymentAgreement.payByAgreement")}}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CheckoutPaymentAgreement",
    data() {
        return {
            accepted: false,
        };
    },
    methods: {
        setBillingMethod() {
            this.$emit("set-billing-method", { method: "agreement" });
        },
    },
};
</script>

<style scoped>
    #agreement-title {
        margin-bottom: 0px;
    }
</style>