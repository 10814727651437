<template>
    <div id="self-publishing-flow-quote-footer-default">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <div class="card-header bg-primary text-white text-center">
                        <i class="fas fa-list-ul mr-2"/>
                        <span v-html="`${$t('SelfPublishingFlowQuoteFooterDefault.customQuote')}`"/>
                    </div>
                    <div class="card-body bg-white">
                        <div class="row">
                            <div class="col-1 text-right">
                                <i class="fa fa-headset fa-3x text-primary"/>
                            </div>
                            <div class="col-10 text-center">
                                <p v-html="$t('SelfPublishingFlowQuoteFooterDefault.needMoreOptions')"/>
                                <router-link to="/contact" v-html="$t('SelfPublishingFlowQuoteFooterDefault.contactUsForCustomQuote')"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SelfPublishingFlowQuoteFooterDefault",
};
</script>

<style scoped>

</style>