<template>
    <footer id="default-footer" class="site-footer">
        <div class="column d-flex align-items-center justify-content-center">
            <div class="text-center">
                <div class="text-sm mb-1">
                    <span v-html="$t('footer.needHelp')"/>
                    <span class="text-primary" v-html="shop.phone"/>
                </div>
                <div>
                    <router-link to="/terms-and-conditions" v-html="$t('footer.termsAndConditions')"/>
                </div>
                <div>
                    <router-link to="/about" v-html="aboutLinkText"/>
                </div>
                <div>
                    <router-link to="/contact" v-html="$t('footer.contact')"/>
                </div>
            </div>
        </div>
        <div v-if="selectableCurrencies.length > 1" id="footer-change-currency"
             class="column justify-content-center d-flex align-items-center">
            <CurrenciesSelect v-if="isCurrenciesSelectHiddenNotLogged" :selectable-currencies="selectableCurrencies"/>
            <div class="text-center" v-else v-html="$t('Footer.loginToChangeCurrency')"/>
        </div>
        <div id="footer-payment-methods" class="column">
            <h3 class="widget-title text-center">{{$t("footer.payment")}}
                <small v-html="$t('footer.paymentMethods')"/>
            </h3>
            <div class="footer-cards text-center d-flex align-items-center justify-content-center">
                <i v-tooltip="$t('footer.cash')" v-if="isCashPaymentEnabled" class="fas fa-2x fa-money-bill text-success"/>
                <i v-tooltip="$t('footer.check')" v-if="isCheckPaymentEnabled" class="fas fa-2x fa-money-check-alt ml-1"/>
                <i v-tooltip="$t('footer.bankTransfer')" v-if="isBankTransferPaymentEnabled" class="fas fa-2x fa-university text-info ml-1"/>
                <img alt="Orange Money" v-tooltip="$t('footer.orangeMoney')" v-if="isOrangeMoneyPaymentEnabled" width="28" :src="orangeMoney" class="ml-1"/>
                <img alt="Wari" class="ml-1" v-tooltip="$t('footer.wari')" v-if="isWariPaymentEnabled" width="28" :src="wari"/>
                <i v-tooltip="$t('footer.creditCard')" v-if="isCreditCardPaymentEnabled" class="far fa-2x fa-credit-card text-info ml-1"/>
                <img alt="PayPal" class="ml-1" v-tooltip="$t('footer.paypal')" v-if="isPayPalPaymentEnabled" width="28" :src="paypal"/>
            </div>
        </div>
        <div id="footer-delivery-methods" class="column">
            <h3 class="widget-title text-center">
                <span v-html="$t('footer.delivery')"/>
                <small v-html="$t('footer.deliveryMethods')"/>
            </h3>
            <div class="footer-cards text-center d-flex align-items-center justify-content-center">
                <i v-tooltip="withdrawalPointTooltip" v-if="isWithdrawalPointDeliveryEnabled" class="fas fa-2x fa-store text-success mr-1"/>
                <i v-tooltip="$t('footer.home')" v-if="isHomeDeliveryEnabled" class="fas fa-2x fa-home"/>
            </div>
        </div>
    </footer>
</template>

<script>
import { mapGetters } from "vuex";
import orangeMoney from "../../../../assets/img/payments/orangeMoney.png";
import wari from "../../../../assets/img/payments/wari.png";
import paypal from "../../../../assets/img/payments/paypal.png";
import CurrenciesSelect from "../CurrenciesSelect";

export default {
    name: "DefaultFooter",
    components: {CurrenciesSelect },
    data() {
        return {
            orangeMoney, wari, paypal,
        };
    },
    computed: {
        ...mapGetters("user", {
            isUserLogged: "isLogged",
        }),
        ...mapGetters("config", {
            shop: "shop",
            frontConfig: "front",
            isCashPaymentEnabled: "isCashPaymentEnabled",
            isCheckPaymentEnabled: "isCheckPaymentEnabled",
            isBankTransferPaymentEnabled: "isBankTransferPaymentEnabled",
            isOrangeMoneyPaymentEnabled: "isOrangeMoneyPaymentEnabled",
            isWariPaymentEnabled: "isWariPaymentEnabled",
            isPayPalPaymentEnabled: "isPayPalPaymentEnabled",
            isCreditCardPaymentEnabled: "isCreditCardPaymentEnabled",
            isWithdrawalPointDeliveryEnabled: "isWithdrawalPointDeliveryEnabled",
            isHomeDeliveryEnabled: "isHomeDeliveryEnabled",
            hermesAPIConfig: "hermesAPI",
        }),
        ...mapGetters("currencies", {
            currencies: "currencies",
        }),
        selectableCurrencies() {
            if (this.currencies && this.frontConfig.currencies.selectables) {
                return this.currencies.filter(currency => this.frontConfig.currencies.selectables.includes(currency.alpha3ISO));
            } else {
                return [];
            }
        },
        isCurrenciesSelectHiddenNotLogged() {
            const showPricesNotLogged = this.frontConfig.products.showPriceNotLogged;
            const canChangeCurrencyNotLogged = this.frontConfig.currencies.canChangeCurrencyNotLogged;
            return (showPricesNotLogged || this.isUserLogged) && (canChangeCurrencyNotLogged || this.isUserLogged);
        },
        withdrawalPointTooltip() {
            return this.$t(`footer.${this.frontConfig.app.i18n.terms.withdrawalPoint}`);
        },
        aboutLinkText() {
            return this.hermesAPIConfig.client === "airvey-editions" ? "Votre tapuscrit" : this.$t("footer.about");
        },
    },
};
</script>

<style scoped>

</style>