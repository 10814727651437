import Vue from "vue";
import Config from "../../../config";

const state = {
    front: Config,
    hermesAPI: undefined,
};

const getters = {
    front(state) {
        return state.front;
    },
    hermesAPI(state) {
        return state.hermesAPI;
    },
    isSandbox(state) {
        return state.hermesAPI ? state.hermesAPI.sandbox : undefined;
    },
    client(state) {
        return state.hermesAPI ? state.hermesAPI.client : undefined;
    },
    shop(state) {
        return state.hermesAPI ? state.hermesAPI.shop: undefined;
    },
    shopType(state) {
        return state.hermesAPI && state.hermesAPI.shop ? state.hermesAPI.shop.type: undefined;
    },
    shopName(state) {
        return state.hermesAPI && state.hermesAPI.shop ? state.hermesAPI.shop.displayedName : undefined;
    },
    shopPhone(state) {
        return state.hermesAPI && state.hermesAPI.shop ? state.hermesAPI.shop.phone : undefined;
    },
    shopDefaultMail(state) {
        return state.hermesAPI && state.hermesAPI.shop && state.hermesAPI.shop.mails ? state.hermesAPI.shop.mails.default : undefined;
    },
    shopProductsCreationMail(state) {
        if (state.hermesAPI && state.hermesAPI.shop && state.hermesAPI.shop.mails && state.hermesAPI.shop.mails.products) {
            return state.hermesAPI.shop.mails.products.creation;
        }
    },
    shopOrdersMail(state) {
        return state.hermesAPI && state.hermesAPI.shop && state.hermesAPI.shop.mails ? state.hermesAPI.shop.mails.orders : undefined;
    },
    shopITMail(state) {
        return state.hermesAPI && state.hermesAPI.shop && state.hermesAPI.shop.mails ? state.hermesAPI.shop.mails.IT : undefined;
    },
    shopCurrency(state) {
        return state.hermesAPI && state.hermesAPI.shop ? state.hermesAPI.shop.currency : undefined;
    },
    shopPrimaryColor(state) {
        return state.hermesAPI && state.hermesAPI.shop ? state.hermesAPI.shop.primaryColor : state.front.app.style.default.primaryColor;
    },
    products(state) {
        return state.hermesAPI ? state.hermesAPI.products : undefined;
    },
    areProductReviewsEnabled(state) {
        return state.hermesAPI && state.hermesAPI.products && state.hermesAPI.products.reviews ? state.hermesAPI.products.reviews.enabled : undefined;
    },
    publishers(state) {
        return state.hermesAPI ? state.hermesAPI.publishers : undefined;
    },
    doesShopShowOnlyPODProducts(state) {
        return state.hermesAPI && state.hermesAPI.publishers ? state.hermesAPI.publishers.isPODSensitiveForProductCount : undefined;
    },
    cannotOrderUnprintableTwice(state) {
        return state.hermesAPI && state.hermesAPI.orders && state.hermesAPI.orders.products ? state.hermesAPI.orders.products.cannotOrderUnprintableTwice : undefined;
    },
    payments(state) {
        return state.hermesAPI ? state.hermesAPI.payments : undefined;
    },
    deliveries(state) {
        return state.hermesAPI ? state.hermesAPI.deliveries : undefined;
    },
    addresses(state) {
        return state.hermesAPI ? state.hermesAPI.addresses : undefined;
    },
    discountCodes(state) {
        return state.hermesAPI ? state.hermesAPI.discountCodes : undefined;
    },
    isZipCodeOptional(state) {
        return state.hermesAPI && state.hermesAPI.addresses ? state.hermesAPI.addresses.optionalZipCode : undefined;
    },
    isMobilePhoneRequiredForDelivery(state) {
        return state.hermesAPI && state.hermesAPI.addresses && state.hermesAPI.addresses.isMobilePhoneRequired ? state.hermesAPI.addresses.isMobilePhoneRequired : undefined;
    },
    isCashPaymentEnabled(state) {
        return state.hermesAPI && state.hermesAPI.payments && state.hermesAPI.payments.cash ? state.hermesAPI.payments.cash.enabled : undefined;
    },
    isCheckPaymentEnabled(state) {
        return state.hermesAPI && state.hermesAPI.payments && state.hermesAPI.payments.check ? state.hermesAPI.payments.check.enabled : undefined;
    },
    isBankTransferPaymentEnabled(state) {
        return state.hermesAPI && state.hermesAPI.payments && state.hermesAPI.payments.bankTransfer ? state.hermesAPI.payments.bankTransfer.enabled : undefined;
    },
    isOrangeMoneyPaymentEnabled(state) {
        return state.hermesAPI && state.hermesAPI.payments && state.hermesAPI.payments.orangeMoney ? state.hermesAPI.payments.orangeMoney.enabled : undefined;
    },
    isWariPaymentEnabled(state) {
        return state.hermesAPI && state.hermesAPI.payments && state.hermesAPI.payments.wari ? state.hermesAPI.payments.wari.enabled : undefined;
    },
    isPayPalPaymentEnabled(state) {
        return state.hermesAPI && state.hermesAPI.payments && state.hermesAPI.payments.PayPal ? state.hermesAPI.payments.PayPal.enabled : undefined;
    },
    isCreditCardPaymentEnabled(state) {
        return state.hermesAPI && state.hermesAPI.payments && state.hermesAPI.payments.creditCard ? state.hermesAPI.payments.creditCard.enabled : undefined;
    },
    isWithdrawalPointDeliveryEnabled(state) {
        return state.hermesAPI && state.hermesAPI.deliveries && state.hermesAPI.deliveries.withdrawalPoint ? state.hermesAPI.deliveries.withdrawalPoint.enabled : undefined;
    },
    isHomeDeliveryEnabled(state) {
        return state.hermesAPI && state.hermesAPI.deliveries && state.hermesAPI.deliveries.home ? state.hermesAPI.deliveries.home.enabled : undefined;
    },
    uniquePaymentMethod(state, getters) {
        const paymentMethods = [
            { method: "paypal", enabled: getters.isPayPalPaymentEnabled },
            { method: "creditCard", enabled: getters.isCreditCardPaymentEnabled },
            { method: "orangeMoney", enabled: getters.isOrangeMoneyPaymentEnabled },
            { method: "wari", enabled: getters.isWariPaymentEnabled },
            { method: "bankTransfer", enabled: getters.isBankTransferPaymentEnabled },
            { method: "check", enabled: getters.isCheckPaymentEnabled },
            { method: "cash", enabled: getters.isCashPaymentEnabled },
        ];
        const enabledPaymentMethods = paymentMethods.filter(paymentMethod => paymentMethod.enabled);
        return enabledPaymentMethods.length === 1 ? enabledPaymentMethods[0].method : null;
    },
    uniqueDeliveryMethod(state, getters) {
        const deliveryMethods = [
            { method: "withdrawalPoint", enabled: getters.isWithdrawalPointDeliveryEnabled },
            { method: "home", enabled: getters.isHomeDeliveryEnabled },
        ];
        const enabledDeliveryMethods = deliveryMethods.filter(deliveryMethod => deliveryMethod.enabled);
        return enabledDeliveryMethods.length === 1 ? enabledDeliveryMethods[0].method : null;
    },
    isOrderProductCustomizationEnabled(state) {
        return state.hermesAPI && state.hermesAPI.orders && state.hermesAPI.orders.productCustomization ? state.hermesAPI.orders.productCustomization.enabled : undefined;
    },
    isDiscountCodeSystemEnabled(state) {
        return state.hermesAPI && state.hermesAPI.discountCodes && state.hermesAPI.discountCodes.enabled ? state.hermesAPI.discountCodes.enabled : undefined;
    },
    isSelfPublishingSystemEnabled(state) {
        return state.hermesAPI && state.hermesAPI.users ? state.hermesAPI.users.selfPublishing.enabled : undefined;
    },
    isUserSocietySIRETEnabled(state) {
        return state.hermesAPI && state.hermesAPI.users && state.hermesAPI.users.society && state.hermesAPI.users.society.SIRET ? state.hermesAPI.users.society.SIRET.enabled : undefined;
    },
    isUserMobilePhoneRequiredForRegistration(state) {
        return state.hermesAPI && state.hermesAPI.users && state.hermesAPI.users.registration && state.hermesAPI.users.registration.isMobilePhoneRequired ? state.hermesAPI.users.registration.isMobilePhoneRequired : undefined;
    },
    isProductCreationWorkshopEnabled(state) {
        return state.hermesAPI && state.hermesAPI.products ? state.hermesAPI.products.selfPublished.productCreationWorkshop.enabled : undefined;
    },
    selfPublishedProductsMinTotalPages(state) {
        return state.hermesAPI && state.hermesAPI.products ? state.hermesAPI.products.selfPublished.minTotalPages : undefined;
    },
    isProductWeightShown(state) {
        return state.hermesAPI && state.hermesAPI.products && state.hermesAPI.shop.products ? state.hermesAPI.products.isWeightShown : undefined;
    },
    isProductOpenFormatShown(state) {
        return state.hermesAPI && state.hermesAPI.products && state.hermesAPI.shop.products ? state.hermesAPI.products.isProductOpenFormatShown : undefined;
    },
};

const mutations = {
    setHermesAPIConfig(state, config) {
        state.hermesAPI = config;
    },
};

const actions = {
    async getHermesAPIConfig({ commit }) {
        try {
            const { data } = await Vue.prototype.$hermesAPI.getConfig();
            if (Config.app.debug.forcedClient) {
                data.config.client = Config.app.debug.forcedClient;
            }
            commit("setHermesAPIConfig", { ...data.config, sandbox: data.sandbox });
        } catch (e) {
            commit("setHermesAPIConfig", null);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};