<template>
    <div>
        <div class="table-responsive shopping-cart mb-0">
            <table class="table">
                <thead>
                <tr>
                    <th>{{$t('checkout.cart.productName')}}</th>
                    <th class="text-center hidden-sm-down" v-html='$t("checkout.cart.priceUnit")'/>
                    <th class="text-center hidden-sm-down">{{$t('checkout.cart.reducePercent')}}</th>
                    <th class="text-center hidden-sm-down" v-html='$t("checkout.cart.priceReduce")'/>
                    <th class="text-center">{{$t('checkout.cart.quantity')}}</th>
                    <th class="text-center" v-html='$t("checkout.cart.priceTotalWT")'/>
                </tr>
                </thead>
                <tbody>
                    <OrderReviewCartDetailsLine v-for="product in cart" :product="product" :key="product.id"
                                                :currency="currency ? currency : product.currency" :review="review"
                                                @show-customize-product-modal="showCustomizeProductModal"/>
                </tbody>
            </table>
        </div>
        <hr class="padding-bottom-1x">
<!--        <CustomizeProductModal @editOrderProduct="editOrderProduct" ref="CustomizeProductModal"/>-->
    </div>
</template>

<script>
import OrderReviewCartDetailsLine from "./OrderReviewCartDetailsLine";

export default {
    name: "OrderReviewCartDetails",
    components: {OrderReviewCartDetailsLine},
    props: {
        currency: {
            type: String,
            default: () => null,
        },
        review: {
            type: Object,
            default: () => null,
        },
        cart: {
            type: Array,
            required: true,
        },
    },
    methods: {
        showCustomizeProductModal(product) {
            this.$refs.CustomizeProductModal.show(product);
        },
        editOrderProduct(product) {
            this.$emit("edit-order-product", product);
        },
    },
};
</script>

<style scoped>

</style>