<template>
    <div class="container">
        <section class="row align-items-center padding-top-2x padding-bottom-2x text-justify">
            <div class="col-2">
                <img :src="IMGs.lightBulb" class="img-fluid" alt="Light Bulb"/>
            </div>
            <div class="col-10">
                <p>Sobook Boutique vous offre la possibilité d’imprimer
                    vos livres à la demande et de calculer vos coûts d’impression rapidement grâce à sa calculette de
                    devis intuitive.</p>
            </div>
        </section>
        <hr/>
        <section class="row align-items-center padding-top-2x padding-bottom-2x text-justify">
            <div class="col-10 text-right">
                <p>Sobook est spécialisé depuis sa création dans l’impression
                    à la demande. L’imprimerie répond aux besoins des auteurs et éditeurs qui souhaitent fonctionner
                    sans ou avec
                    un minimum de stock.</p>
                <p>Son atelier à forte capacité de production permet de réduire considérablement les délais de
                    fabrication et de créer des partenariats solides avec ses clients.</p>
            </div>
            <div class="col-2">
                <img :src="IMGs.heart" class="img-fluid" alt="Heart"/>
            </div>
        </section>
        <hr/>
        <section class="row align-items-center padding-top-2x padding-bottom-2x text-justify">
            <div class="col-2">
                <img :src="IMGs.mapPin"  class="img-fluid" alt="Map Pin"/>
            </div>
            <div class="col-10">
                <p class="text-lg">Vos ouvrages parcourent le monde grâce à Sobook Boutique ! Imprimez en France et envoyez vos ouvrages
                    en Europe et dans le monde entier.</p>
            </div>
        </section>
        <hr/>
        <section class="row align-items-center padding-top-2x padding-bottom-2x text-justify">
            <div class="col-10 text-right">
                <p>Notre démarche est éco-responsable !</p>
                <p>L’ensemble de nos papiers proviennent de forêts gérées durablement selon les normes en vigueurs et
                    les chutes de papiers sont recyclées.</p>
            </div>
            <div class="col-2">
                <img :src="IMGs.world" class="img-fluid" alt="World"/>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: "AboutLaBoutiqueSoBook",
    data() {
        return {
            IMGs: {
                world: require("../../../../assets/img/la-boutique-sobook/about/world.png"),
                lightBulb: require("../../../../assets/img/la-boutique-sobook/about/light-bulb.png"),
                mapPin: require("../../../../assets/img/la-boutique-sobook/about/map-pin.png"),
                heart: require("../../../../assets/img/la-boutique-sobook/about/heart.png"),
            },
        };
    },
};
</script>

<style scoped>
    p {
        font-size: 1.20rem;
    }
</style>