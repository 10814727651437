<template>
    <div class="mt-1 mb-2">
        <div class="row align-items-center mb-2">
            <div class="col-11">
                <h3 class="mb-0">
                    <span v-html="stepText"/>
                </h3>
            </div>
            <div class="col-1 text-center">
                <i :class="`fa fa-${icon} fa-3x text-primary`"/>
            </div>
        </div>
        <hr class="pb-1"/>
    </div>
</template>

<script>
export default {
    name: "CheckoutStep",
    props: {
        step: {
            type: Number,
        },
        text: {
            type: String,
            required: true,
        },
        icon: {
            type: String,
        },
    },
    computed: {
        stepText() {
            return this.step !== undefined ? `${this.step} - ${this.text}` : this.text;
        },
    },
};
</script>

<style scoped>

</style>