<template>
    <span class="badge" :class="badgeClasses" v-html="badgeLabel"/>
</template>

<script>
export default {
    name: "OneClickOrderBadge",
    props: {
        user: {
            required: true,
            type: Object,
        },
    },
    computed: {
        badgeClasses() {
            return this.user.canOneClickOrder ? "badge-success" : "badge-danger";
        },
        badgeLabel() {
            if (this.user.canOneClickOrder) {
                return `<i class='fa fa-check-circle mr-2'></i>${this.$t("OneClickOrderBadge.oneClickAvailable")}`;
            } else {
                return `<i class='fa fa-times mr-2'></i>${this.$t("OneClickOrderBadge.oneClickUnavailable")}`;
            }
        },
    },
};
</script>

<style scoped>

</style>