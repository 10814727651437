import { hasProp } from "../helpers/class";
import User from "./User";

class ProductReview {
    constructor(review = null) {
        this._id = !hasProp(review, "_id") ? undefined : review._id;
        this.user = !hasProp(review, "user") ? undefined : new User(review.user);
        this.product = !hasProp(review, "product") ? undefined : review.product;
        this.summary = !hasProp(review, "summary") ? undefined : review.summary;
        this.description = !hasProp(review, "description") ? undefined : review.description;
        this.rate = !hasProp(review, "rate") ? undefined : review.rate;
        this.votes = {
            up: !hasProp(review, "votes.up") ? [] : review.votes.up.map(vote => vote),
            down: !hasProp(review, "votes.down") ? [] : review.votes.down.map(vote => vote),
            count: !hasProp(review, "votes.count") ? undefined : review.votes.count,
        };
        this.createdAt = !hasProp(review, "createdAt") ? undefined : review.createdAt;
        this.updatedAt = !hasProp(review, "updatedAt") ? undefined : review.updatedAt;
    }
}

export default ProductReview;