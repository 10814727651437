import Axios from "axios";
import Config from "../../config";
import { generateQueryStringURL, generateValidQueryStrings } from "../helpers/API";

const DiceAPI = {
    // eslint-disable-next-line no-unused-vars
    install(Vue) {
        const axios = Axios.create({
            baseURL: Config.diceAPI.baseURL,
            timeout: 0,
            headers: {
                "Content-Type": "application/json",
            },
            auth: {
                username: Config.diceAPI.basicAuth.username,
                password: Config.diceAPI.basicAuth.password,
            },
        });

        Vue.prototype.$diceAPI = {};

        Vue.prototype.$diceAPI.generateValidQueryStrings = params => generateValidQueryStrings(params);

        Vue.prototype.$diceAPI.getCarriers = queryStrings => axios.get(generateQueryStringURL(`/carriers`, { ...queryStrings, "enabled-for-distribution": true }));

        Vue.prototype.$diceAPI.getCarrier = data => axios.get(generateQueryStringURL(`/carriers/${data.params.id}`, data.queries));

        Vue.prototype.$diceAPI.getCurrencies = queryStrings => axios.get(generateQueryStringURL(`/currencies`, queryStrings));

        Vue.prototype.$diceAPI.getCountries = queryStrings => axios.get(generateQueryStringURL(`/countries`, queryStrings));
    },
};

export default DiceAPI;