<template>
    <section class="container padding-top-3x pb-5">
        <h3 class="text-center mb-30">{{$t("shared.productList.news")}}</h3>
        <div class="row mb-2">
            <ShopProductPreview v-for="product in lastProducts" :key="product._id" :product="product"/>
        </div>
    </section>
</template>

<script>
import ShopProductPreview from "../../Hermes/Shop/ShopProductList/ShopProductPreview";

export default {
    name: "ProductList",
    components: {ShopProductPreview},
    props: {
        products: {
            required: true,
        },
    },
    computed: {
        lastProducts() {
            return this.products.slice(0, 9);
        },
    },
};
</script>

<style scoped>

</style>