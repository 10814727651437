<template>
    <section class="row align-items-center padding-top-2x padding-bottom-2x">
        <div class="col-md-5"><img class="d-block w-270 m-auto" :src="logoAboutPayment" alt="Payment"></div>
        <div class="col-md-7 text-md-left text-center">
            <div class="mt-30 hidden-md-up"></div>
            <h2>{{$t("aboutPayment.title")}}</h2>
            <p class="text-sm mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam id purus at risus
                pellentesque faucibus a quis eros. In eu fermentum leo. Integer ut eros lacus. Proin ut accumsan leo.
                Morbi vitae est eget dolor consequat aliquam.</p><a class="market-button apple-button" href="#"><span
                class="mb-subtitle">Download on the</span><span class="mb-title">App Store</span></a><a
                class="market-button google-button" href="#"><span class="mb-subtitle">Download on the</span><span
                class="mb-title">Google Play</span></a><a class="market-button windows-button" href="#"><span
                class="mb-subtitle">Download on the</span><span class="mb-title">Windows Store</span></a>
        </div>
    </section>
</template>

<script>
import logoAboutPayment from "../../../../assets/img/features/04.jpg";

export default {
    name: "AboutPayment",
    data() {
        return {
            logoAboutPayment,
        };
    },
};
</script>

<style scoped>

</style>