<template>
    <div class="comment">
        <div class="comment-author-ava">
            <UserAvatar :user="review.user"/>
        </div>
        <div class="comment-body">
            <div class="comment-header d-flex flex-wrap justify-content-between">
                <h4 class="comment-title">{{ review.summary }}</h4>
                <div class="mb-2">
                    <star-rating :rating="review.rate" :read-only="true" :star-size="15" :inline="true" :show-rating="false"/>
                </div>
            </div>
            <p class="comment-text" v-html="review.description"></p>
            <div class="comment-footer d-flex justify-content-between">
                <span class="comment-meta font-italic" v-html="$t('ProductReview.writtenByUserAtDateTime', {
                    user: review.user.displayedName,
                    date: this.properDate(review.createdAt),
                    time: this.properTime(review.createdAt),
                })">
                </span>
                <ProductReviewVotes :review="review" @edit-product-review="editProductReview"/>
            </div>
        </div>
    </div>
</template>

<script>
import UserAvatar from "../../../../shared/User/UserAvatar";
import { properDate, properTime } from "../../../../../helpers/date";
import ProductReviewVotes from "./ProductReviewVotes";

export default {
    name: "ProductReview",
    components: {ProductReviewVotes, UserAvatar},
    props: {
        review: {
            type: Object,
            required: true,
        },
    },
    methods: {
        properDate, properTime,
        editProductReview(review) {
            this.$emit("edit-product-review", review);
        },
    },
};
</script>

<style scoped>

</style>