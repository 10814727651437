import Vue from "vue";
import Country from "../../classes/Country";

const state = {
    countries: undefined,
};

const getters = {
    countries(state) {
        return state.countries;
    },
};

const mutations = {
    setCountries(state, countries) {
        state.countries = countries;
    },
};

const actions = {
    async getCountries({ commit }) {
        const { data } = await Vue.prototype.$diceAPI.getCountries();
        const countries = data.sort((a, b) => a.name.localeCompare(b.name));
        commit("setCountries", countries.map(country => new Country(country)));
    },
    setCountries({ commit }, countries) {
        commit("setCountries", countries);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};