import Vue from "vue";
import validator from "validator";
import { configure, extend, ValidationProvider, ValidationObserver } from "vee-validate";
import i18n from "../i18n";
import { email, required, min } from "vee-validate/dist/rules";

configure({ classes: { valid: "is-valid", invalid: "is-invalid" }});
configure({
    defaultMessage: (field, values) => i18n.t(`VeeValidate.${values._rule_}`, values),
});
extend("required", required);
extend("email", email);
extend("min", min);
extend("people_name", {
    validate: value => value.match(/^[^¨±!@£$%^&*_+§¡€#¢§¶•ªº°«/\\<>?:;|=.,0-9()]+$/),
});
extend("mobile_phone", {
    validate: value => validator.isMobilePhone(value.replace(/\s/g, "")),
});
extend("zip_code", {
    validate: value => validator.isPostalCode(value, "any"),
});
extend("confirm_password", {
    validate: (value, { other }) => value === other,
    params: [{ name: "other", isTarget: true }],
});
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);