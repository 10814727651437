<template>
    <div id="self-publishing-flow-quote-footer-imprimer-vos-livres">
        <div class="row">
            <div class="col-12">
                <div class="card shadow-lg">
                    <div class="card-header bg-primary text-white text-lg">
                        <i class="fas fa-cog mr-2"/>
                        <span v-html="`${$t('SelfPublishingFlowQuoteFooterImprimerVosLivres.customQuoteOptions')}`"/>
                    </div>
                    <div class="card-body bg-white">
                        <div class="row align-items-center">
                            <div class="col-4">
                                <div class="row mt-3">
                                    <div class="col-12">
                                        <div class="custom-control custom-checkbox custom-control-inline c-pointer">
                                            <input class="custom-control-input" type="checkbox" id="other-formats"/>
                                            <label class="custom-control-label c-pointer" for="other-formats"
                                                   v-html="$t('SelfPublishingFlowQuoteFooterImprimerVosLivres.otherFormats')"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="row justify-content-center">
                                    <div class="col-12">
                                        <div class="custom-control custom-checkbox custom-control-inline c-pointer">
                                            <input class="custom-control-input" type="checkbox" id="other-quantities"/>
                                            <label class="custom-control-label c-pointer" for="other-quantities"
                                                   v-html="$t('SelfPublishingFlowQuoteFooterImprimerVosLivres.otherQuantities')"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="row justify-content-center">
                                    <div class="col-12">
                                        <div class="custom-control custom-checkbox custom-control-inline c-pointer">
                                            <input class="custom-control-input" type="checkbox" id="other-options"/>
                                            <label class="custom-control-label c-pointer" for="other-options"
                                                   v-html="$t('SelfPublishingFlowQuoteFooterImprimerVosLivres.otherOptions')"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="custom-control custom-checkbox custom-control-inline c-pointer">
                                            <input class="custom-control-input" type="checkbox" id="custom-quote-multi-shipping-addresses" v-model="options.doesNeedMultiShippingAddresses"/>
                                            <label class="custom-control-label c-pointer" for="custom-quote-multi-shipping-addresses"
                                                   v-html="$t('SelfPublishingFlowQuoteFooterImprimerVosLivres.multiShippingAddresses')"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="custom-control custom-checkbox custom-control-inline c-pointer">
                                            <input class="custom-control-input" type="checkbox" id="custom-quote-flaps" v-model="options.doesNeedFlaps"/>
                                            <label class="custom-control-label c-pointer" for="custom-quote-flaps"
                                                   v-html="$t('SelfPublishingFlowQuoteFooterImprimerVosLivres.flaps')"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="custom-control custom-checkbox custom-control-inline c-pointer">
                                            <input class="custom-control-input" type="checkbox" id="custom-quote-bookmarks" v-model="options.doesNeedBookmarks"/>
                                            <label class="custom-control-label c-pointer" for="custom-quote-bookmarks"
                                                   v-html="$t('SelfPublishingFlowQuoteFooterImprimerVosLivres.bookmarks')"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="custom-control custom-checkbox custom-control-inline c-pointer">
                                            <input class="custom-control-input" type="checkbox" id="custom-quote-jacket" v-model="options.doesNeedJacket"/>
                                            <label class="custom-control-label c-pointer" for="custom-quote-jacket"
                                                   v-html="$t('SelfPublishingFlowQuoteFooterImprimerVosLivres.jacket')"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="custom-control custom-checkbox custom-control-inline c-pointer">
                                            <input class="custom-control-input" type="checkbox" id="custom-quote-others"/>
                                            <label class="custom-control-label c-pointer" for="custom-quote-others"
                                                   v-html="$t('SelfPublishingFlowQuoteFooterImprimerVosLivres.others')"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <img :src="IMGs.typolibris" width="200" alt="Typolibris Logo"/>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <a :href="typolibrisContactURL" target="_blank" class="btn btn-primary btn-sm">
                                            <span v-html="$t('SelfPublishingFlowQuoteFooterImprimerVosLivres.customQuote')"/>
                                            <i class="fa fa-chevron-right ml-2"/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { stringify } from "qs";
import { mapGetters } from "vuex";
import { importPNG } from "@/helpers/file";

export default {
    name: "SelfPublishingFlowQuoteFooterImprimerVosLivres",
    data() {
        return {
            IMGs: {
                typolibris: importPNG("custom-quote/typolibris", "imprimer-vos-livres"),
            },
            options: {
                doesNeedMultiShippingAddresses: false,
                doesNeedFlaps: false,
                doesNeedBookmarks: false,
                doesNeedJacket: false,
            },
        };
    },
    computed: {
        ...mapGetters("user", {
            user: "user",
            isUserLogged: "isLogged",
            userFullName: "fullName",
        }),
        optionsQueryStringParameters() {
            return {
                "does-need-multi-shipping-addresses": this.options.doesNeedMultiShippingAddresses,
                "does-need-flaps": this.options.doesNeedFlaps,
                "does-need-bookmarks": this.options.doesNeedBookmarks,
                "does-need-jacket": this.options.doesNeedJacket,
            };
        },
        typolibrisContactURL() {
            const queryStringParameters = {
                ...this.optionsQueryStringParameters,
            };
            if (this.isUserLogged) {
                queryStringParameters["customer-email"] = this.user.email;
                if (this.userFullName) {
                    queryStringParameters["customer-full-name"] = this.userFullName;
                }
                if (this.user.isProfessional) {
                    queryStringParameters["customer-society-name"] = this.user.society.name;
                    queryStringParameters["customer-society-siret"] = this.user.society.SIRET;
                }
            }
            return `https://www.typolibris.fr/nous-contacter?${stringify(queryStringParameters)}`;
        },
    },
};
</script>

<style scoped>

</style>