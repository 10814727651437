<template>
    <div v-if="areProductReviewsEnabled">
        <a href="#" id="product-reviews-count" @click.prevent="scrollToReviews" v-html="reviewsLengthText"
           v-if="showNoReviewText || product.reviews.length" v-tooltip="reviewsLengthTooltip" class="mr-1">
        </a>
        <star-rating v-if="product.reviews.length" :rating="averageRate" text-class="custom-rate-star-text"
                     :read-only="true" :star-size="15" :increment="0.01" :inline="true" :show-rating="showRating"
                     v-tooltip="$t('ProductStarRate.thisProductGotRateOutOfFive', { rate: averageRate })"/>
        <AddProductReviewBtn v-if="showAddReviewBtn" class="btn btn-warning btn-xs ml-2 btn-pulse" :product="product"
                             :minified="true" @show-product-review-modal="$emit('show-product-review-modal')"/>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import AddProductReviewBtn from "../../shared/Product/ProductReview/AddProductReviewBtn";

export default {
    name: "ProductStarRate",
    components: {AddProductReviewBtn},
    props: {
        product: {
            type: Object,
            required: true,
        },
        showNoReviewText: {
            type: Boolean,
            default: true,
        },
        showAddReviewBtn: {
            type: Boolean,
            default: true,
        },
        showRating: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        ...mapGetters("config", {
            areProductReviewsEnabled: "areProductReviewsEnabled",
        }),
        reviewsLengthText() {
            return this.product.reviews.length ? `(${this.$t("ProductStarRate.countReviews", { count: this.product.reviews.length })})` : this.$t("ProductStarRate.noReview");
        },
        reviewsLengthTooltip() {
            if (!this.product.reviews.length) {
                return;
            }
            return this.$tc("ProductStarRate.usersHaveReviewed", this.product.reviews.length, { count: this.product.reviews.length });
        },
        averageRate() {
            return this.product.averageRate();
        },
    },
    methods: {
        async scrollToReviews() {
            if (this.$route.name !== "product") {
                await this.$router.push(`/product/${this.product._id}?scroll=product-reviews`);
            } else {
                this.$scrollTo("#product-reviews");
            }
        },
    },
};
</script>

<style lang="scss">
    #product-reviews-count {
        font-size: 0.75rem;
    }

    .custom-rate-star-text {
        font-size: 0.90rem;
        border: 1px solid #cfcfcf;
        padding-left: 5px;
        padding-right: 5px;
        border-radius: 5px;
        color: #999;
        background: #fff;
        margin-left: 5px !important;
        margin-top: -4px !important;
        &:after {
            content: "/5";
        }
    }
</style>