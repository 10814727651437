<template>
    <div id="self-publishing-create-book">
        <div class="padding-top-2x mt-2 hidden-lg-up"/>
        <SelfPublishingFlow :self-published-book="selfPublishedBook" @update-self-published-book="updateSelfPublishedBook"/>
    </div>
</template>

<script>
import Product from "../../../../classes/Product";
import SelfPublishingFlow from "./SelfPublishingFlow/SelfPublishingFlow";

const baseBook = {
    components: {
        cover: {
            printing: { bothSides: false },
            paper: { id: 15 },
            lamination: { id: 2 },
        },
        corpus: {
            printing: { id: 1 },
            paper: { id: 30 },
        },
        binding: { id: 1 },
    },
    paging: { black: 200, color: 0 },
    dimensions: { orientation: "portrait", height: 210, width: 148 },
};

export default {
    name: "SelfPublishingCreateBook",
    components: { SelfPublishingFlow },
    data() {
        return {
            selfPublishedBook: new Product(baseBook),
        };
    },
    methods: {
        updateSelfPublishedBook(book) {
            this.selfPublishedBook = new Product(book);
        },
    },
};
</script>

<style scoped>
    #general-info-panel {
        border-right: 1px solid lightgray;
    }
</style>