<template>
    <div class="steps flex-sm-nowrap mb-3">
        <a v-for="(step, i) in steps" :key="i" @click.prevent="changeStep(i)" :disabled="stepNumber < i"
           class="step" :class="{ active: $route.name === step.routeName }" href="#">
            <h4 class="step-title">
                <i v-if="stepNumber > i" class="material-icons check_circle"/>
                <span v-html="`${i + 1}. ${step.name}`"/>
            </h4>
        </a>
    </div>
</template>

<script>
export default {
    name: "SelfPublishingFlowNav",
    data() {
        return {
            stepNumber: 0,
            steps: [
                {
                    name: this.$t("SelfPublishingFlowNav.buildQuote"),
                    routePath: "/self-publishing/create-book",
                    routeName: "selfPublishingFlowQuote",
                },
                {
                    name: this.$t("SelfPublishingFlowNav.finalizeProject"),
                    routePath: "/self-publishing/create-book/metadata",
                    routeName: "selfPublishingFlowMetadata",
                },
            ],
        };
    },
    methods: {
        changeStep(stepNumber) {
            this.stepNumber = stepNumber;
            this.$router.push(this.steps[stepNumber].routePath);
        },
    },
};
</script>

<style scoped>

    .step[disabled] {
        pointer-events: none;
    }

</style>