import Vue from "vue";
import Vuex from "vuex";
import config from "./modules/config";
import sideBarMenu from "./modules/sideBarMenu";
import publishers from "./modules/publishers";
import countries from "./modules/countries";
import currencies from "./modules/currencies";
import preferences from "./modules/preferences";
import user from "./modules/user";
import tours from "./modules/tours";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        config, sideBarMenu, publishers, countries, currencies, preferences, user, tours,
    },
});