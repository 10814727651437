import Address from "./Address";
import { hasProp } from "../helpers/class";

class WithdrawalPoint {
    constructor(withdrawalPoint = null) {
        this._id = !hasProp(withdrawalPoint, "_id") ? 0 : withdrawalPoint._id;
        this.name = !hasProp(withdrawalPoint, "name") ? "" : withdrawalPoint.name;
        this.address = !hasProp(withdrawalPoint, "address") ? new Address() : new Address(withdrawalPoint.address);
        this.priceWT = !hasProp(withdrawalPoint, "priceWT") ? 0 : withdrawalPoint.priceWT;
        this.currency = !hasProp(withdrawalPoint, "currency") ? 0 : withdrawalPoint.currency;
        this.chargedBy = !hasProp(withdrawalPoint, "chargedBy") ? "order" : withdrawalPoint.chargedBy;
    }

    toString() {
        return `${this.name}: ${this.address.toString()}`;
    }
}

export default WithdrawalPoint;