<template>
    <div class="tab-pane fade show active" id="login" role="tabpanel">
        <ValidationObserver v-slot="{ invalid }">
            <form @submit.prevent="login" id="login-form">
                <div class="mb-3">
                    <label>
                        {{$t('header.account.loginPanel.email')}}
                        <span>*</span>
                    </label>
                    <ValidationProvider ref="emailInput" v-slot="{ errors, classes }">
                        <div class="input-group">
                            <input class="form-control" type="email" v-model="email" :class="classes"
                                   :placeholder="$t('header.account.loginPanel.required')" required>
                            <span class="input-group-addon"><i class="material-icons mail"></i></span>
                        </div>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                    </ValidationProvider>
                </div>
                <div class="mb-3">
                    <label for="password">
                        {{$t('header.account.loginPanel.password')}}
                        <span>*</span>
                    </label>
                    <ValidationProvider v-slot="{ errors, classes }">
                        <div class="input-group">
                            <input id="password" class="form-control" v-model="password" type="password" minlength="5"
                                   :placeholder="$t('header.account.loginPanel.required')" required :class="classes">
                            <span class="input-group-addon"><i class="material-icons lock"></i></span>
                        </div>
                        <div class="invalid-feedback">
                            {{ errors[0] }}
                        </div>
                    </ValidationProvider>
                </div>
                <div class="custom-control custom-checkbox" v-tooltip="$t('LoginPanel.rememberMeExplanations')">
                    <input class="custom-control-input" type="checkbox" id="remember-me" v-model="rememberMe">
                    <label class="custom-control-label" for="remember-me">{{$t('LoginPanel.rememberMe')}}</label>
                </div>
                <div class="text-center">
                    <a href="#" class="text-primary" @click.prevent="$emit('show-forgot-password-modal')">
                        {{ $t("LoginPanel.forgotPassword") }}
                    </a>
                </div>
                <SubmitBtn :classes="'btn btn-primary btn-block'" :loading="loading" :disabled="invalid"
                            :text="$t('header.account.loginPanel.login')"/>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import SubmitBtn from "../../../../shared/Misc/SubmitBtn";

export default {
    name: "LoginPanel",
    components: {SubmitBtn},
    data() {
        return {
            email: "",
            password: "",
            loading: false,
            rememberMe: false,
        };
    },
    computed: {
        ...mapGetters("user", {
            isUserLogged: "isLogged",
        }),
        ...mapGetters("config", {
            shop: "shop",
        }),
    },
    mounted() {
        const lastEmailUsed = localStorage.getItem("last-email-used");
        if (lastEmailUsed) {
            this.rememberMe = true;
            this.email = lastEmailUsed;
            this.$nextTick(() => {
                this.$refs.emailInput.validate();
            });
        }
    },
    methods: {
        async login() {
            this.loading = true;
            try {
                await this.$store.dispatch("user/login", { email: this.email, password: this.password });
                if (this.rememberMe) {
                    localStorage.setItem("last-email-used", this.email);
                } else {
                    localStorage.removeItem("last-email-used");
                }
            } catch (err) {
                this.password = "";
                this.$error.handleLoginError(err, this.shop);
            } finally {
                this.loading = false;
            }
        },
    },
    watch: {
        isUserLogged(isUserLogged) {
            if (isUserLogged === true) {
                this.loading = false;
                this.password = "";
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    #login-form {
        label {
            font-weight: 500;
            span {
                font-size: 0.9rem;
                color: red;
            }
        }
    }
</style>