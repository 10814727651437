<template>
    <div class="card border-default">
        <div class="card-body">
            <div class="text-center">
                <h4 class="mb-1">Notes des acheteurs</h4>
                <div class="d-inline align-baseline display-2 mr-1" v-html="averageRate"></div>
                <star-rating :rating="averageRate" :read-only="true" :star-size="20" :increment="0.01" :inline="true" :show-rating="false"/>
            </div>
            <div class="pt-3">
                <label class="text-medium text-sm">
                    {{$tc("ProductRatesSummary.star", 5, { count: 5 })}}
                    <span class="text-muted">- {{ rateSummary.fiveStars.count }}</span>
                </label>
                <div class="progress margin-bottom-1x">
                    <div class="progress-bar bg-warning" role="progressbar" :style="`width: ${rateSummary.fiveStars.percent}%; height: 2px;`"
                         aria-valuenow="75" aria-valuemin="0" aria-valuemax="100">
                    </div>
                </div>
                <label class="text-medium text-sm">
                    {{$tc("ProductRatesSummary.star", 4, { count: 4 })}}
                    <span class="text-muted">- {{ rateSummary.fourStars.count }}</span>
                </label>
                <div class="progress margin-bottom-1x">
                    <div class="progress-bar bg-warning" role="progressbar" :style="`width: ${rateSummary.fourStars.percent}%; height: 2px;`"
                         aria-valuenow="20" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <label class="text-medium text-sm">
                    {{$tc("ProductRatesSummary.star", 3, { count: 3 })}}
                    <span class="text-muted">- {{ rateSummary.threeStars.count }}</span>
                </label>
                <div class="progress margin-bottom-1x">
                    <div class="progress-bar bg-warning" role="progressbar" :style="`width: ${rateSummary.threeStars.percent}%; height: 2px;`"
                         aria-valuenow="7" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <label class="text-medium text-sm">
                    {{$tc("ProductRatesSummary.star", 2, { count: 2 })}}
                    <span class="text-muted">- {{ rateSummary.twoStars.count }}</span>
                </label>
                <div class="progress margin-bottom-1x">
                    <div class="progress-bar bg-warning" role="progressbar" :style="`width: ${rateSummary.twoStars.percent}%; height: 2px;`"
                         aria-valuenow="3" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
                <label class="text-medium text-sm">
                    {{$tc("ProductRatesSummary.star", 1, { count: 1 })}}
                    <span class="text-muted">- {{ rateSummary.oneStar.count }}</span>
                </label>
                <div class="progress mb-2">
                    <div class="progress-bar bg-warning" role="progressbar" :style="`width: ${rateSummary.oneStar.percent}%; height: 2px;`"
                         aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>
            <div v-if="isUserLogged && product.alreadyOrdered" class="pt-2">
                <transition mode="out-in" name="fade">
                    <AddProductReviewBtn v-if="!isAlreadyReviewed" :product="product" class="btn btn-warning btn-block"
                                         :text="$t('ProductRatesSummary.leaveReview')"
                                         @show-product-review-modal="$emit('show-product-review-modal')"/>
                    <div class="text-center" v-else>
                        <h4 class="mb-1" v-html="$t('ProductRatesSummary.yourRate')"></h4>
                        <star-rating :rating="getUserProductReview.rate" :read-only="true" :star-size="20"
                                     :inline="true" text-class="custom-rate-star-text"/>
                        <button class="btn btn-warning btn-block" v-html="$t('ProductRatesSummary.editReview')"
                                @click="$emit('show-product-review-modal', getUserProductReview)">
                        </button>
                        <DeleteProductReviewBtn classes="btn btn-danger btn-block" :review="getUserProductReview"
                                                @delete-product-review="deleteProductReview"/>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import DeleteProductReviewBtn from "../../../shared/Product/ProductReview/DeleteProductReviewBtn";
import AddProductReviewBtn from "../../../shared/Product/ProductReview/AddProductReviewBtn";

export default {
    name: "ProductRatesSummary",
    components: {AddProductReviewBtn, DeleteProductReviewBtn},
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters("user", {
            user: "user",
            isUserLogged: "isLogged",
        }),
        averageRate() {
            return this.product.averageRate();
        },
        isAlreadyReviewed() {
            return this.product.isAlreadyReviewed(this.user);
        },
        rateSummary() {
            const summary = {
                oneStar: {
                    count: this.product.reviews.filter(review => review.rate === 1).length,
                },
                twoStars: {
                    count: this.product.reviews.filter(review => review.rate === 2).length,
                },
                threeStars: {
                    count: this.product.reviews.filter(review => review.rate === 3).length,
                },
                fourStars: {
                    count: this.product.reviews.filter(review => review.rate === 4).length,
                },
                fiveStars: {
                    count: this.product.reviews.filter(review => review.rate === 5).length,
                },
            };
            for (const rate in summary) {
                summary[rate].percent = summary[rate].count * 100 / this.product.reviews.length;
            }
            return summary;
        },
        getUserProductReview() {
            if (!this.isUserLogged || !this.isAlreadyReviewed) {
                return null;
            }
            return this.product.reviews.find(review => review.user._id === this.user._id);
        },
    },
    methods: {
        deleteProductReview(reviewId) {
            this.$emit("delete-product-review", reviewId);
        },
    },
};
</script>

<style scoped>

</style>