<template>
    <div class="badge" :class="badgeClasses">
        <i class="fa mr-2" :class="badgeIcon"/>
        <span v-html="badgeText"/>
    </div>
</template>

<script>
import Product from "@/classes/Product";

export default {
    name: "PrintableProductBadge",
    props: {
        product: {
            type: Product,
            required: true,
        },
    },
    computed: {
        badgeClasses() {
            return this.product.pod ? "badge-success" : "badge-danger";
        },
        badgeText() {
            return this.product.pod ? this.$t("PrintableProductBadge.printable") : this.$t("PrintableProductBadge.notPrintableYet");
        },
        badgeIcon() {
            return this.product.pod ? "fa-check-circle" : "fa-times-circle";
        },
    },
};
</script>

<style scoped>

</style>