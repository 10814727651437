<template>
    <div class="col-lg-4 col-sm-6">
        <router-link class="product-preview" :to='`/product/${product._id}`'>
            <div class="product-card mb-30 text-center">
                <div class="product-card-thumb">
                    <div class="product-thumbnail-wrapper d-flex justify-content-center align-items-center">
                        <div class="product-thumbnail d-flex align-items-center">
                            <div v-if="hasPublisherAgreement" v-tooltip="$t('ShopProductPreview.hasPublisherAgreement')"
                                 class="agreement-icon">
                                <span class="fa-stack fa-1x">
                                    <i class="fas fa-certificate fa-stack-2x text-primary"/>
                                    <i class="far fa-handshake fa-stack-1x fa-inverse"/>
                                </span>
                            </div>
                            <div class="product-discount"
                                 v-if="this.product.discount !== 0 && this.product.hasDisplayedPrices(isUserLogged)">
                                <span class="product-badge text-danger">{{this.product.discount}} %</span>
                            </div>
                            <div class="product-description" v-html="truncatedDescription"/>
                            <img :id="`product-thumbnail-${product._id}`" ref="productImage" v-lazy="product.firstCoverPhotoUrl" alt="Product"/>
                        </div>
                    </div>
                    <div class="product-card-buttons">
                        <AddToCartBtn :text="$t('shop.shopProductList.shopProductPreview.addToCart')"
                                      classes="btn btn-primary btn-block" :product="product"/>
                    </div>
                </div>
                <div class="product-card-details justify-content-center">
                    <h3 class="product-card-title">
                        <a class="text-primary" href="#">
                            {{truncateTitle}}
                        </a>
                    </h3>
                </div>
                <div v-if="this.product.hasDisplayedPrices(isUserLogged) === true">
                    <h4 class="product-card-price text-center">
                        {{this.properPrice(product.discountedPriceWT, chosenCurrency, currencies)}}
                    </h4>
                </div>
                <ProductStarRate :product="product" :show-rating="false"
                                 :show-add-review-btn="false" :show-no-review-text="false"/>
            </div>
        </router-link>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import AddToCartBtn from "../../../shared/Product/AddToCartBtn";
import ProductStarRate from "../../../shared/Product/ProductStarRate";
import { properPrice } from "@/helpers/price";

export default {
    name: "ShopProductPreview",
    components: {ProductStarRate, AddToCartBtn},
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
    computed: {
        ...mapGetters("currencies", {
            currencies: "currencies",
        }),
        ...mapGetters("preferences", {
            chosenCurrency: "currency",
        }),
        ...mapGetters("user", {
            user: "user",
            isUserLogged: "isLogged",
        }),
        truncateTitle() {
            const end = this.product.title.length > 25 ? "..." : "";
            return `${this.product.title.substr(0, 25)}${end}`;
        },
        truncatedDescription() {
            if (!this.product.description || !this.product.description.trim()) {
                return null;
            }
            const end = this.product.description.length > 350 ? `... (${this.$t("ShopProductPreview.clickForMore")})` : "";
            return `${this.product.description.substr(0, 350)}${end}`;
        },
        hasPublisherAgreement() {
            return this.user.publishers.agreements.includes(this.product.publisher._id);
        },
    },
    methods: {
        properPrice,
    },
};
</script>

<style lang="scss" scoped>
    .product-preview:hover {
        .product-description {
            opacity: 100;
        }
        img {
            filter: brightness(0.30);
        }
    }

    .product-card-details {
        padding-top: 10px !important;
    }

    .product-card-thumb {
        background-color: white !important;
    }

    .product-thumbnail-wrapper {
        height: 400px;
        transition: all 0.30s ease;
        position: relative;
    }

    .product-thumbnail {
        position: relative;
    }

    .product-discount {
        position: absolute;
        top: 1px;
        z-index: 999;
        left: -3px;
        width: 100%;
    }

    .agreement-icon {
        position: absolute;
        top: 5px;
        z-index: 999;
        right: 3px;
    }

    .product-description {
        opacity: 0;
        position: absolute;
        padding: 10px 10px 10px 10px;
        text-align: justify;
        z-index: 999;
        font-size: 0.75rem;
        color: white;
        text-shadow: 0 0 10px black;
        transition: all 0.30s ease;
    }

    img {
        max-width: 99%;
        max-height: 400px;
        transition: all 0.30s ease;
    }

    .product-card-buttons {
        width: 85% !important;
        margin: 0 auto !important;
        bottom: 8% !important;
        right: 20px !important;
    }
</style>