<template>
    <div class="toolbar-section" :class="{ current: isAccountSectionActive }" id="header-account-panel">
        <transition name="fadein" mode="out-in">
            <div key="loading" v-if="isUserLogged === undefined">
                <APILoading :font-size="30" :text="$t('header.account.loadingProfile')"/>
            </div>
            <div key="notLogged" v-else-if="isUserLogged === false">
                <ul class="nav nav-tabs nav-justified" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" ref="loginTab" href="#login" data-toggle="tab" role="tab">
                            {{$t("header.account.login")}}
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="#signup" data-toggle="tab" role="tab">
                            {{$t("header.account.register")}}
                        </a>
                    </li>
                </ul>
                <div class="tab-content">
                    <LoginPanel @show-forgot-password-modal="$emit('show-forgot-password-modal')"/>
                    <RegisterPanel @open-login-tab="openLoginTab"/>
                </div>
            </div>
            <div key="logged" id="connected" v-else-if="isUserLogged">
                <Connected/>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoginPanel from "./LoginPanel/LoginPanel";
import RegisterPanel from "./RegisterPanel";
import APILoading from "../../../shared/Misc/APILoading";
import Connected from "./Connected";

export default {
    name: "Account",
    components: {Connected, APILoading, RegisterPanel, LoginPanel},
    computed: {
        ...mapGetters("sideBarMenu", {
            isAccountSectionActive: "isAccountSectionActive",
        }),
        ...mapGetters("user", {
            isUserLogged: "isLogged",
        }),
    },
    methods: {
        openLoginTab() {
            this.$refs.loginTab.click();
        },
    },
};
</script>

<style lang="scss" scoped>
    #connected {
        text-align: center;
        img {
            width: 80%;
        }
        span {
            padding-top: 10px;
            font-style: italic;
            color: dimgrey;
        }
    }
</style>