<template>
  <transition mode="out-in" name="fade">
    <APILoading v-if="carriers === null || this.addressSelected._id === 0" key="loading" :font-size="24"
                :text="$t('CheckoutShippingHome.loadingDeliveryCost')"/>
    <div v-else-if="carriers.length === 0" key="no-carriers" class="text-center">
      <h4 class="text-center text-muted">{{ $t("DeliveryCost.noDeliveryYourOrderYet") }}</h4>
      <h5 class="text-center text-muted">{{ $t("DeliveryCost.contactOurCustomerService") }}</h5>
      <router-link class="btn btn-primary" target="_blank" to="/contact">
        {{ $t("DeliveryCost.contact") }}
      </router-link>
    </div>
    <div v-else key="carriers" class="table-responsive">
      <table class="table table-hover" v-if="carriers.length > 1">
        <thead class="thead-default">
        <tr>
          <th/>
          <th>{{ $t('DeliveryCost.description') }}</th>
          <th>{{ $t('checkout.shipping.priceWT') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(carrier, idx) in carriers" :key="carrier.id" v-tooltip="carrierTooltip(carrier)" class="carrier"
            @click="selectCarrier(carrier)">
          <td class="align-middle">
            <div class="custom-control custom-radio mb-0">
              <input :id="`carrier-${idx}`" :checked="false" :value="carrier.id"
                     class="custom-control-input" name="carrier" type="radio">
              <label :for="`carrier-${idx}`" class="custom-control-label"/>
            </div>
          </td>
          <td class="align-middle">
            <i class="mr-2 fa fa-handshake text-primary"/>
            <span class="text-medium">{{ carrier.name }}</span>
            <div class="text-muted font-italic" v-html="carrier.deliveryDaysText"/>
          </td>
          <td class="align-middle">{{ getPrice(carrier) }}</td>
        </tr>
        </tbody>
      </table>
      <div v-else class="single-carrier">
        <div class="carrier-info">
          <i class="mr-2 fa fa-shipping-fast text-primary"/>
          <span class="text-medium">{{ carriers[0].name }}</span>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {mapGetters} from "vuex";
import Carrier from "../../../../../classes/Carrier";
import APILoading from "../../../../shared/Misc/APILoading";
import {getSubTotal, properPrice} from "@/helpers/price";

export default {
    name: "DeliveryCost",
    components: {APILoading},
    props: {
        addressSelected: {
            required: true,
        },
        carrierIdSelected: {
            required: true,
        },
        order: {
            required: true,
            type: Object,
        },
    },
    data() {
        return {
            carriers: null,
        };
    },
    computed: {
        ...mapGetters("config", {
            hermesAPIConfig: "hermesAPI",
        }),
        ...mapGetters("currencies", {
            currencies: "currencies",
        }),
        ...mapGetters("preferences", {
            currency: "currency",
        }),
        ...mapGetters("user", {
            userCart: "cart",
            userCarrierAgreements: "carrierAgreements",
            userPrivateCarriers: "privateCarriers",
        }),
    },
    methods: {
        properPrice,
        hasDeliveryReducedTax() {
            const productsWithReducedTax = this.order.products.filter(product => product.tax.reduced === true);
            const productsWithFullTax = this.order.products.filter(product => product.tax.reduced === false);
            const totalProductsWithReducedTax = productsWithReducedTax.reduce((acc, product) => acc + product.priceWT, 0);
            const totalProductsWithFullTax = productsWithFullTax.reduce((acc, product) => acc + product.priceWT, 0);
            return totalProductsWithFullTax < totalProductsWithReducedTax;
        },
        getSubTotal() {
            return getSubTotal(this.userCart, this.currencies);
        },
        async getDeliveryCost() {
            const response = await this.$hermesAPI.getCarriers();
            return response.data.map(carrier => new Carrier(carrier));
        },
        async getDeliveryCosts() {
            try {
                this.carriers = null;
                let carriers = await this.getDeliveryCost();
                if (this.getSubTotal() <= parseFloat(this.hermesAPIConfig.deliveries.lawDeliveryCost.freeShippingFrom)) {
                    carriers = carriers.filter(carrier => carrier.id === parseInt(this.hermesAPIConfig.deliveries.lawDeliveryCost.price.default));
                } else {
                    carriers = carriers.filter(carrier => carrier.id === parseInt(this.hermesAPIConfig.deliveries.lawDeliveryCost.price.minimal));
                }

                this.carriers = carriers;
                if (this.carriers.length === 1) {
                    this.carrierIdAloneSelected = carriers[0].id;
                }
                if (this.order.delivery.method === "home" && this.carrierId !== 0) {
                    const selectedCarrier = this.carriers.find(carrier => carrier.id === this.carrierIdSelected || carrier.id === this.carrierIdAloneSelected);
                    if (selectedCarrier) {
                        this.selectCarrier(selectedCarrier);
                    } else {
                        this.$emit("reset-carrier-id");
                    }
                }
            } catch (err) {
                this.$error.displayError(err);
            }
        },
        isCarrierAgreement(carrier) {
            return this.userCarrierAgreements.includes(carrier.id);
        },
        carrierTooltip(carrier) {
            if (this.cantSelectCarrierBcOfGLN(carrier)) {
                return this.$t("CheckoutShippingHome.youNeedGLNToSelectCarrier");
            } else if (this.isCarrierAgreement(carrier)) {
                return this.$t("CheckoutShippingHome.youGotCarrierAgreement");
            } else {
                return null;
            }
        },
        getPrice(carrier) {
            if (this.isCarrierAgreement(carrier)) {
                return this.$t("CheckoutShippingHome.offered");
            }
            return this.properPrice(carrier.request.priceWT, carrier.currency, this.currencies);
        },
        selectCarrier(carrier) {
            if (!this.cantSelectCarrierBcOfGLN(carrier)) {
                this.$emit("select-carrier", carrier);
            }
        },
        cantSelectCarrierBcOfGLN(carrier) {
            return carrier && carrier.needsGLN && (!this.addressSelected.gln || this.addressSelected.gln.trim() === "");
        },
    },
    watch: {
        addressSelected() {
            this.getDeliveryCosts();
        },
        userCart() {
            if (this.userCart && this.userCart.length) {
                this.getDeliveryCosts();
            }
        },
    },
};
</script>

<style scoped>
.carrier {
  cursor: pointer;
}
</style>