<template>
    <div id="terms-and-conditions-imprimer-vos-livres">
        <h2>PRÉAMBULE</h2>
        <p>Nos rapports avec les clients sont régis par les usages professionnels et conditions généra- les de vente
            des industries graphiques. Sauf accord contraire déterminé par écrit entre notre société et ses clients,
            les dispositions suivantes s’appliquent d’autorité à toutes les prestations, ces dispositions déterminent
            les droits et les obligations de chacun.</p>
        <p>Nos obligations contractuelles seront suspendues de plein droit et sans formalité dégagée en cas de
            survenance d’évènements tels que :</p>
        <ul>
            <li>incendie, inondation, bris de machine dans nos ateliers...</li>
            <li>guerre, émeute, réquisition, réduction autoritaire des importations, accident ou retard de fabrication
                chez nos fournisseurs, retard dans les transports de marchandises ainsi qu’en survenance de tout
                évènement indépendant de notre volonté.</li>
        </ul>
        <h2>PRISE DE COMMANDE ET MARCHÉS</h2>
        <p>Le fait de passer commande implique l’adhésion entière et sans réserve du client aux présentes
            conditions générales de vente.</p>
        <p>Aucune condition particulière ne peut, sauf acceptation formelle et écrite de notre société, prévaloir
            contre ces conditions générales.</p>
        <p>Les offres faites par nos commerciaux ou téléphoniquement, ne constituent un engagement de notre
            part qu’autant qu’elles auront été confirmées par écrit par l’entreprise; toute commande de prestations
            devra obligatoirement faire l’objet d’une confirmation écrite de notre client. La remise de documents
            vaudra confirmation écrite.</p>
        <p>Le bon de commande doit préciser l’adresse de livraison et l’adresse de facturation si elles sont
            différentes. En cas de modification, le client devra impérativement nous en avertir par courrier ou
            télécopie avant la fin du mois courant. Au-delà de cette date, aucune adresse de facturation ne pourra
            être modifiée.</p>
        <h2>PRIX</h2>
        <p>Les prix sont établis suivant les tarifs ou devis. Toute modification (par exemple de format, de quantité
            etc.) donne lieu à l’établissement de nouveaux prix. Ces derniers s’entendent nets hors taxes départ
            lieu de fabrication sauf stipulation contraire. Nous nous réservons le droit de réviser nos prix à tout
            moment si les conditions de main-d’œuvre, de matières premières, de transport etc, venaient à être
            modifiées.</p>
        <h2>MODIFICATION DE LA COMMANDE</h2>
        <p>Toute modification de la commande ou résolution de commande demandée par le client n’est
            susceptible d’être prise en considération que si elle est parvenue par écrit avant le début d’exécution
            des travaux. Il peut être décidé d’un commun accord avec notre client d’apporter des modifications à
            l’exécution des travaux pouvant entraîner des plus-values sur les prix, sous réserve d’établir un
            nouveau bon de commande, notamment dans le cas de prestations supplémentaires non prévues
            initialement.</p>
        <p>Les corrections ou modifications demandées après l’exécution du travail sont facturées en supplément,
            sauf le cas de faute de l’exécutant. Une soumission d’épreuve restée sans réponse pendant une semaine
            s’interprète comme acceptée et nous autorise à débiter le travail effectué. Toute réclamation doit être
            effectuée dans la huitaine de réflexion.</p>
        <h2>EXÉCUTION DES TRAVAUX / QUANTITÉ</h2>
        <p>Les travaux sont exécutés à réception d’un bon de commande signé par le client.
            Le bon de commande doit être impérativement accompagné des sorties laser correspondantes et doit
            comporter les renseignements précis nécessaires à la bonne exécution des travaux à savoir :</p>
        <ul>
            <li>les supports informatiques avec la description des logiciels et les problèmes particuliers se rattachant
                à leur utilisation;</li>
            <li>les polices de caractères utilisées dans le document, les photos, logotypes, etc.</li>
        </ul>
        <p>Nous nous réservons le droit de confier à nos co-traitants ou sous-traitants, l’exécution totale ou
            partielle de la commande.</p>
        <p>Nous nous réservons le droit de refuser tous travaux si :</p>
        <ul>
            <li>les fichiers fournis sont inexploitables;</li>
            <li>les documents fournis (ektas, photos ou similis) sont de mauvaise qualité;</li>
            <li>les explications mentionnées sont insuffisantes, etc.</li>
        </ul>
        <p>Cependant, ces travaux pourront être effectués à des prix différents en accord avec notre client.</p>
        <p>Les conventions des Industries Graphiques autorisent un écart entre les quantités commandées et les
            quantités livrées sur la base de:</p>
        <ul>
            <li>Quantité commandée inférieure à 2 000 exemplaires ...........................+ ou - 10 %</li>
            <li>Quantité commandée comprise entre 2 001 et 10 000 exemplaires ......... + ou - 8 %</li>
            <li>Quantité commandée comprise entre 10 001 et 20 000 exemplaires ........ + ou - 6,5 %</li>
            <li>Quantité commandée supérieure à 20 001 exemplaires........................ + ou - 5,5 %</li>
        </ul>
        <h2>EXÉCUTION DES TRAVAUX / QUALITÉ</h2>
        <p>Tout B.A.T. daté et signé du client dégage entièrement notre responsabilité en cas d’erreur ou
            d’omission. Nous ne sommes tenus qu’à exécuter la correction nécessaire sans qu’il puisse ne nous
            être réclamé aucune indemnité pour retard ou tirage défectueux.</p>
        <p>Les délais de fabrication des produits millésimés ne sauront être inférieurs à six semaines à réception
            des fichiers complets.</p>
        <p>Les délais de livraison, sauf convention spéciale, ne sont qu’indicatifs et leur non observation ne peut
            motiver un laissé pour compte, rabais ou indemnité.</p>
        <p>Lors de la livraison, il appartient à l’acheteur, en cas d’avarie ou de perte, de faire les réserves
            nécessaires sur le bon de livraison contresigné par le transporteur ou son préposé. Ces réserves doivent
            être confirmées par lettre recommandée dans les trois jours qui suivent celui de la réception (non
            compris les jours fériés). Au cas où ces formalités n’auraient pas été accomplies, aucune réclamation
            ne sera admise pour avarie ou perte.</p>
        <p>Suite à la livraison, l’acheteur dispose d’un délai de huit jours pour contester la qualité des produits
            livrés. Tout défaut doit être notifié par lettre recommandée avec mention du lieu où les produits
            défectueux sont visibles et accessibles. A défaut, aucun refus de paiement ne pourra être justifié par le
            client en raison de la qualité des produits livrés. Toute erreur ou malfaçon de notre part ne peut donner
            lieu qu’au remplacement du produit défectueux sans autre dédommagement.</p>
        <h2>PROJETS</h2>
        <p>Tous projets et maquettes établis par nos soins demeurent notre propriété exclusive. Ils ne peuvent en
            aucun cas être reproduits sans notre accord sous quelque forme que ce soit. Tous travaux préparatoires
            sont toujours à la charge du client, même quand aucune suite n’est donnée dans un délai de deux mois
            à compter de leur présentation, et donnent lieu à l’établissement d’une facture.</p>
        <p>Même s’ils ont été facturés, les clichés et outillage payés par nos clients restent la propriété exclusive
            de notre société.</p>
        <p>Toute réclamation de ces outillages et clichés entraîne un complément de facturation de 30% en
            remboursement des frais d’études et de réalisation non compris dans les frais établis. Ils sont stockés
            et entretenus en nos locaux 24 mois à dater de la dernière commande, ceci garantissant leur parfait état
            pour les futures fabrications. Au-delà de cette période de 24 mois, en l’absence de fabrication, nous
            nous réservons le droit de les détruire sans préavis particulier.</p>
        <h2>PROPRIETE INTELLECTUELLE</h2>
        <p>La passation d’une commande portant sur la reproduction d’un objet qui bénéficie de la protection des
            lois sur la propriété artistique implique, de la part du client, l’affirmation de l’existence d’un droit de
            reproduction à son profit. Le client doit donc donner toute garantie pour toute contestation, dont ce
            droit de reproduction pourrait être l’objet.</p>
        <p>Dans le cas de travaux impliquant sous quelque forme que ce soit une activité créatrice au sens de la
            législation sur la propriété artistique, les droits d’auteur découlant de cette création, et notamment les
            droits de reproduction, restent appartenir à notre société et ne sont transférés au client que moyennant
            une convention en ce sens. Cette convention de cession et notamment des droits de reproduction doit
            être expresse : elle ne saurait résulter, ni du fait que l’activité créatrice ait été prévue dans la commande,
            ni du fait qu’elle fasse l’objet d’une rémunération spéciale, ni enfin du fait que la propriété du support
            matériel du droit d’auteur soit transférée au client. Sauf convention spéciale d’exclusivité, la création
            artistique réalisée peut être à nouveau utilisée.</p>
        <h2>TRANSPORT</h2>
        <p>Les frais d’emballage, de port, et de routage sont toujours à la charge du client. Lorsqu’elles sont
            expédiées, les marchandises voyagent toujours aux risques et périls du destinataire.</p>
        <p>Les transports s’effectuent à notre convenance, par nos soins, par services postaux ou par transporteurs
            privés. Dans les second et troisième modes de transport énoncés ci-dessus, nous ne saurions être tenus
            responsables des réserves prévues par l’article L 133-3 du code du commerce, lesquelles doivent être
            opposables au transporteur, ni même de la carence à agir en vertu de l’article L 133-4 du même code,
            le tout à peine de dommages et intérêts, si une action était diligentée à notre encontre.</p>
        <p>Dans le cas de dépôt de document originaux et/ou de valeur, notre client nous décharge expressément
            de toute responsabilité en cas de perte, vol ou détérioration, le dépôt étant effectué à ses risques et périls
            exclusifs.</p>
        <h2>CONDITIONS DE RÈGLEMENTS</h2>
        <p>En aucun cas une commande ne sera considérée comme définitive si elle n’est pas accompagnée d’un
            bon de commande signé ou du devis accepté ainsi que d’un acompte de 30 % du montant total TTC.
            Le solde de la prestation doit être réglé au plus tard à 60 jours nets.</p>
        <p>Dans tous les cas, pas d’escompte pour paiement comptant.</p>
        <p>Tout défaut de paiement à l’échéance convenu entraine :</p>
        <p>a) l’exigibilité de plein droit d’un intérêt de retard le jour suivant la date de règlement. Le taux d’intérêt
            sera égal au taux d’intérêt appliqué par la Banque Centrale Européenne à son opération de
            refinancement la plus récente, majoré de 10 points de pourcentage. Les pénalités de retard sont
            exigibles sans qu’un rappel soit nécessaire.</p>
        <p>b) Une indemnité forfaitaire de 40 euros sera exigible en cas de retard de paiement afin de couvrir les
            frais de recouvrement exposés par la société.</p>
        <h2>EXIGENCE DE GARANTIES OU RÈGLEMENT PRÉALABLE</h2>
        <p>Nous nous réservons le droit, dans le cours d’un marché, de demander le cas échéant, les garanties de
            paiement que nous jugeons nécessaires et à défaut desquelles le contrat pourra être annulé et résilié
            sans mise en demeure, sommation ou résiliation par justice, sur simple lettre recommandée et ce, sans
            indemnité ; nous aurons le même droit si l’acheteur fait défaut de paiement à l’une des échéances.</p>
        <p>Toute détérioration du crédit du client pourra justifier l’exigence de garanties ou d’un règlement
            comptant avant l’exécution des commandes reçues ou avant déchargement de la marchandise en cours
            de livraison.</p>
        <p>Ce sera notamment le cas si une modification dans la capacité du débiteur, sa situation juridique, son
            activité professionnelle a un effet défavorable sur son crédit.</p>
        <p>Le client s’engage à fournir à ses frais, la garantie réclamée dans un délai de 48 heures, ou à régler
            immédiatement les sommes dues et à consentir dans ce délai à notre société toute délégation ou cession
            de créance qu’elle pourra réclamer. À défaut, la commande pourrait être réputée résiliée à l’initiative
            de l’acheteur.</p>
        <h2>LIVRAISONS HORS-MÉTROPOLE</h2>
        <p>Toute livraison en dehors du territoire métropolitain est suspendue au règlement intégral du prix.</p>
        <h2>CLAUSE DE RÉSERVE DE PROPRIÉTÉ</h2>
        <p>De convention expresse et sans qu’il soit possible d’y déroger, nous demeurons propriétaire de nos
            supports et de leur contenu jusqu’à parfait et intégral paiement. De convention expresse, notre client
            nous autorise à faire pratiquer rétention des travaux achevés chez le prestataire chargé de la fabrication,
            en cas de non-paiement et ce jusqu’à parfait et intégral paiement. En cas de revendication, les acomptes
            déjà versés restent acquis au vendeur à titre de dédommagement.</p>
        <h2>ACCÈS INFORMATIQUE - LOI DU 6 JANVIER 1978</h2>
        <p>Toutes les données informatiques entrées et relatives à notre clientèle, leur donnent en application de
            la loi susvisée, un droit d’accès pour rectification. Le droit d’accès s’exerce par simple lettre.</p>
        <h2>CLAUSE RÉSOLUTOIRE</h2>
        <p>En cas de non-respect de l’une des clauses et conditions du présent contrat, notre société pourra si bon
            lui semble résilier ledit contrat sur simple notification adressée à notre client. Tous les acomptes versés
            resteront acquis à notre société à titre d’indemnités sans préjudice de tous dommages et intérêts.</p>
        <h2>CLAUSE PÉNALE</h2>
        <p>À défaut de paiement à l’une quelconque des échéances, les autres échéances deviendront
            immédiatement exigibles, même si elles ont donné lieu à des traites. S’ajouteront les éventuels frais
            d’impayés. De plus à titre de clause pénale, et en application des dispositions légales, l’acheteur sera
            de plein droit redevable et sans mise en demeure, d’une indemnité forfaitaire équivalente à 10% des
            sommes impayées.</p>
        <h2>ATTRIBUTION DE COMPÉTENCE</h2>
        <p>Tous les litiges auxquels le présent contrat pourrait donner lieu, concernant tant sa validité, son
            interprétation, son exécution, sa résiliation, leurs conséquences et leurs suites seront soumis au tribunal
            de commerce de Saint Brieuc même en cas de pluralité de défendeurs.</p>
    </div>
</template>

<script>
export default {
    name: "TermsAndConditionsImprimerVosLivres",
};
</script>

<style scoped>

</style>