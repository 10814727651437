<template>
    <div class="toolbar-section" :class="{ current: isMobileNavSectionActive }" id="mobileMenu">
        <!-- Slideable (Mobile) Menu-->
        <nav class="slideable-menu mt-4">
            <ul class="menu">
                <li :class="{active: $route.name === 'home'}">
                    <span>
                        <router-link @click.native="hideSideBar" to='/'>
                            <span>{{$t("header.mobileNav.home")}}</span>
                        </router-link>
                    </span>
                </li>
                <li class="has-children">
                    <span>
                        <router-link @click.native="hideSideBar" to="/shop">
                            <span>{{$t("header.mobileNav.catalog")}}</span>
                        </router-link>
                        <span class="sub-menu-toggle"></span>
                    </span>
                    <ul v-if="this.publishers" class="slideable-submenu">
                        <li v-for="publisher in this.publishers" :key="publisher._id">
                            <router-link @click.native="hideSideBar" :to="`/shop?publisher=${publisher._id}`">
                                {{publisher.name}}
                            </router-link>
                        </li>
                    </ul>
                    <ul class="slideable-submenu" v-else>
                        <li>
                            <APILoading :icon-size="30" :font-size='12' :text="$t('shared.apiLoading.getPublishers')"/>
                        </li>
                    </ul>
                </li>
                <li v-if="isUserLogged" :class="{active: inProfileSection}">
                    <span>
                        <router-link @click.native="hideSideBar" to='/profile'>
                            <span>{{$t("header.mobileNav.profile")}}</span>
                        </router-link>
                    </span>
                </li>
                <li v-if="isUserLogged && user.isAdmin" :class="{ active: inAdministrationSection }">
                    <router-link @click.native="hideSideBar" to="/administration">
                        <span>{{$t('header.mainNav.administration')}}</span>
                    </router-link>
                </li>
                <li :class="{active: this.inHelpSection}">
                    <span>
                        <router-link @click.native="hideSideBar" to='/help'>
                            <span>{{$t("header.mobileNav.help")}}</span>
                        </router-link>
                    </span>
                </li>
                <li :class="{active: $route.name === 'about'}">
                    <span>
                        <router-link @click.native="hideSideBar" to='/about'>
                            <span>{{$t("header.mobileNav.about")}}</span>
                        </router-link>
                    </span>
                </li>
                <li :class="{active: $route.name === 'contact'}">
                    <span>
                        <router-link @click.native="hideSideBar" to='/contact'>
                            <span>{{$t("header.mobileNav.contact")}}</span>
                        </router-link>
                    </span>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import APILoading from "../../shared/Misc/APILoading";
import { inHelpSection, inProfileSection, inAdministrationSection } from "@/helpers/section";

export default {
    name: "MobileNav",
    components: {APILoading},
    computed: {
        ...mapGetters("publishers", {
            publishers: "publishers",
        }),
        ...mapGetters("sideBarMenu", [
            "isMobileNavSectionActive",
        ]),
        ...mapGetters("user", {
            isUserLogged: "isLogged",
            user: "user",
        }),
        inHelpSection() {
            return inHelpSection(this.$route.name);
        },
        inProfileSection() {
            return inProfileSection(this.$route.name);
        },
        inAdministrationSection() {
            return inAdministrationSection(this.$route.name);
        },
    },
    methods: {
        ...mapActions("sideBarMenu", {
            hideSideBar: "hide",
        }),
    },
};
</script>

<style scoped>

</style>