<template>
    <div>
        <h3>
            <i class="fa fa-pen-fancy mr-2 text-primary"></i>
            {{ $t("HelpSelfPublishing.selfPublishing") }}
        </h3>
        <div class="accordion" id="accordion-self-publishing" role="tablist">
            <div class="card">
                <div class="card-header" role="tab">
                    <h6>
                        <a href="#how-works-ivl" data-toggle="collapse" class="collapsed" aria-expanded="false">
                            Comment fonctionne Imprimer Vos Livres ?
                        </a>
                    </h6>
                </div>
                <div class="collapse" id="how-works-ivl" data-parent="#accordion-self-publishing" role="tabpanel">
                    <div class="card-body">
                        <img :src="IVLSchema" class="img-responsive"/>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" role="tab">
                    <h6>
                        <a href="#what-are-dimensions" data-toggle="collapse" class="collapsed" aria-expanded="false">
                            Quelles sont les dimensions du fichier couverture ?
                        </a>
                    </h6>
                </div>
                <div class="collapse" id="what-are-dimensions" data-parent="#accordion-self-publishing" role="tabpanel">
                    <div class="card-body">
                        <p>Le format du livre ouvert est indiqué dans les informations générales. Il s’agit de la largeur du livre comprenant l’épaisseur du dos par la hauteur du livre.</p>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" role="tab">
                    <h6>
                        <a href="#bleed-box-for-cover-file" data-toggle="collapse" class="collapsed" aria-expanded="false">
                            Doit-on prévoir un fond perdu sur le fichier de couverture ?
                        </a>
                    </h6>
                </div>
                <div class="collapse" id="bleed-box-for-cover-file" data-parent="#accordion-self-publishing" role="tabpanel">
                    <div class="card-body">
                        <p>Si la couverture de votre livre comprend un aplat de couleur ou une image dépassant le format du livre en fond, vous devez ajouter un fond perdu de 5mm sans modifier les dimensions de votre fichier de couverture.</p>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" role="tab">
                    <h6>
                        <a href="#trim-box-for-files" data-toggle="collapse" class="collapsed" aria-expanded="false">
                            Faut-il prévoir les traits de coupe sur les fichiers ?
                        </a>
                    </h6>
                </div>
                <div class="collapse" id="trim-box-for-files" data-parent="#accordion-self-publishing" role="tabpanel">
                    <div class="card-body">
                        <p>Il est conseillé d'avoir les traits de coupe sur vos fichiers d’intérieur et de couverture de vos ouvrages. Si vous envoyez des fichiers sans traits de coupe, l’équipe pré-presse les rajoutera automatiquement.</p>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" role="tab">
                    <h6>
                        <a href="#files-format" data-toggle="collapse" class="collapsed" aria-expanded="false">
                            Quel est le format des fichiers ?
                        </a>
                    </h6>
                </div>
                <div class="collapse" id="files-format" data-parent="#accordion-self-publishing" role="tabpanel">
                    <div class="card-body">
                        <ul>
                            <li>Le fichier intérieur doit être monté page par page dans le sens de la lecture sur un seul fichier.</li>
                            <li>Le fichier de couverture doit être composé d’une seule page et rassemble de gauche à droite : 4ème de couverture, le dos puis 1ère de couverture.</li>
                        </ul>
                        <p>Le format des fichiers doit être en PDF.</p>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" role="tab">
                    <h6>
                        <a href="#color-profile" data-toggle="collapse" class="collapsed" aria-expanded="false">
                            Quel est le profil colorimétrique à respecter ?
                        </a>
                    </h6>
                </div>
                <div class="collapse" id="color-profile" data-parent="#accordion-self-publishing" role="tabpanel">
                    <div class="card-body">
                        <p>ISOcoated v2 300% ou Fogra39</p>
                        <p>Mode colorimétrie CMJN</p>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" role="tab">
                    <h6>
                        <a href="#images-resolution" data-toggle="collapse" class="collapsed" aria-expanded="false">
                            Quelle résolution d’image choisir ?
                        </a>
                    </h6>
                </div>
                <div class="collapse" id="images-resolution" data-parent="#accordion-self-publishing" role="tabpanel">
                    <div class="card-body">
                        <p>Minimum 300 dpi (sinon la qualité des images ne sera pas optimale sur le livre imprimé).</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IVLSchema from "@/assets/img/imprimer-vos-livres/schema.png";

export default {
    name: "HelpSelfPublishing",
    data() {
        return { IVLSchema };
    },
};
</script>

<style scoped>

</style>