<template>
    <div id="carousel">
        <section class="hero-slider">
            <div class="owl-carousel large-controls dots-inside">
                <router-link to="/shop?book-collection=Airvey%20Jeunesse">
                    <img class="carousel-img" :src="IMGs.AirveyJeunesseLogo" alt="Slide 1"/>
                </router-link>
                <router-link to="/shop?book-collection=Histoires%20courtes" >
                    <img class="carousel-img" :src="IMGs.HistoireCourteLogo" alt="Slide 2"/>
                </router-link>
                <router-link to="/shop?book-collection=Inven%27Terre%20Hauts-de-France">
                    <img class="carousel-img" :src="IMGs.InventerreLogo" alt="Slide 3"/>
                </router-link>
                <router-link to="/shop?book-collection=Noir%20polar">
                    <img class="carousel-img" :src="IMGs.NoirPolarLogo" alt="Slide 4"/>
                </router-link>
            </div>
        </section>
    </div>
</template>

<script>
import AirveyJeunesseLogo from "@/assets/img/airvey-editions/airvey-jeunesse-logo.png";
import AirveyWatermark from "@/assets/img/airvey-editions/airvey-watermark.png";
import HistoireCourteLogo from "@/assets/img/airvey-editions/histoire-courte-logo.png";
import InventerreLogo from "@/assets/img/airvey-editions/inventerre-logo.png";
import NoirPolarLogo from "@/assets/img/airvey-editions/noir-polar-logo.png";

export default {
    name: "AdCarousel",
    data() {
        return {
            IMGs: { AirveyJeunesseLogo, AirveyWatermark, HistoireCourteLogo, InventerreLogo, NoirPolarLogo },
        };
    },
    mounted() {
        // eslint-disable-next-line no-undef
        $(".owl-carousel").owlCarousel({
            items: 1,
            loop: true,
            mouseDrag: false,
            touchDrag: false,
            dots: true,
            nav: true,
            navText: false,
            autoplay: true,
            autoplayTimeout: 4000,
            height: 200,
        });
    },
};
</script>

<style lang="scss">

    .carousel-img {
        max-height: 400px;
    }

    .owl-item {
        text-align: center;
    }

    .owl-loaded::before {
        background-color: #D4EAF8 !important;
    }
</style>