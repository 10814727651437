import { hasProp } from "../helpers/class";

class DiscountCode {
    constructor(discountCode = null) {
        this._id = !hasProp(discountCode, "_id") ? undefined : discountCode._id;
        this.code = !hasProp(discountCode, "code") ? undefined : discountCode.code;
        this.limit = {
            per: !hasProp(discountCode, "limit.per") ? undefined : discountCode.limit.per,
            useCount: !hasProp(discountCode, "limit.useCount") ? undefined : discountCode.limit.useCount,
        };
        this.discount = {
            type: !hasProp(discountCode, "discount.type") ? undefined : discountCode.discount.type,
            on: !hasProp(discountCode, "discount.on") ? undefined : discountCode.discount.on,
            value: !hasProp(discountCode, "discount.value") ? undefined : discountCode.discount.value,
            currency: !hasProp(discountCode, "discount.currency") ? undefined : discountCode.discount.currency,
        };
        this.startsAt = !hasProp(discountCode, "startsAt") ? undefined : discountCode.startsAt;
        this.expiresAt = !hasProp(discountCode, "expiresAt") ? undefined : discountCode.expiresAt;
        this.eligibleUsers = !hasProp(discountCode, "eligibleUsers") ? undefined : discountCode.eligibleUsers;
        this.createdBy = !hasProp(discountCode, "createdBy") ? undefined : discountCode.createdBy;
    }
}

export default DiscountCode;