var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"reset-password"}},[_c('PageTitle',{attrs:{"title":_vm.$t('ResetPassword.resetPassword')}}),_c('div',{staticClass:"container padding-bottom-3x mb-1"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('div',{staticClass:"row align-items-center justify-content-center"},[_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-12"},[_c('img',{attrs:{"src":_vm.securitySVG,"alt":"Security"}})]),_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-12"},[_c('ValidationProvider',{attrs:{"vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('label',{attrs:{"for":"new-password"}},[_vm._v(" "+_vm._s(_vm.$t("ResetPassword.newPassword"))+" "),_c('span',[_vm._v("*")])]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],staticClass:"form-control mb-3",class:classes,attrs:{"type":"password","id":"new-password","minlength":"5","required":"","placeholder":_vm.$t('ResetPassword.required')},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"confirm_password:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('label',{attrs:{"for":"confirm-new-password"}},[_vm._v(" "+_vm._s(_vm.$t("ResetPassword.confirmNewPassword"))+" "),_c('span',[_vm._v("*")])]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.confirmPassword),expression:"confirmPassword"}],staticClass:"form-control",class:classes,attrs:{"type":"password","id":"confirm-new-password","minlength":"5","required":"","placeholder":_vm.$t('ResetPassword.required')},domProps:{"value":(_vm.confirmPassword)},on:{"input":function($event){if($event.target.composing){ return; }_vm.confirmPassword=$event.target.value}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])])]}}],null,true)}),_c('SubmitBtn',{attrs:{"classes":'btn btn-primary btn-block',"text":_vm.$t('ResetPassword.changePassword'),"loading":_vm.loading,"disabled":invalid}})],1)])])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }