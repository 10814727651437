<template>
    <div data-backdrop="static" data-keyboard="false" class="modal fade" id="order-details-modal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 v-if="order" class="modal-title" v-html="$t('ProfileOrdersDetailsModal.orderReview', { reference: this.order.reference })"/>
                </div>
                <div class="modal-body">
                    <transition>
                        <div key="loading" v-if="!order">
                            <APILoading :font-size="30" :text="$t('ProfileOrdersDetailsModal.loading')"/>
                        </div>
                        <div v-else key="order">
                            <div class="row text-center align-items-center">
                                <div class="col-4">
                                    <h4 v-html="$t('ProfileOrdersDetailsModal.reference')"/>
                                </div>
                                <div class="col-4">
                                    <h4 v-html="$t('ProfileOrdersDetailsModal.status')"/>
                                </div>
                                <div class="col-4">
                                    <h4 v-html="$t('ProfileOrdersDetailsModal.payment')"/>
                                </div>
                            </div>
                            <div class="row text-center align-items-center">
                                <div class="col-4" v-html="`#${order.reference}`"/>
                                <div class="col-4">
                                    <OrderProductionStatusLabel :order="order"/>
                                </div>
                                <div class="col-4">
                                    <OrderPaymentStatusLabel :order="order"/>
                                </div>
                            </div>
                            <Alert v-if="order.discountCode._id" type="success" :text="discountCodeDisclaimer">
                                <template v-slot:icon>
                                    <i class="fa fa-tag fa-2x mr-2 col-1"/>
                                </template>
                            </Alert>
                            <hr class="mt-3"/>
                            <OrderReviewHeader :order="order"/>
                            <OrderReviewCartDetails :cart="order.products"/>
                        </div>
                    </transition>
                </div>
                <div v-if="order" class="modal-footer">
                    <button v-if="showCancelOrderButton" class="btn btn-danger" :disabled="loading" @click="confirmCancelOrder">
                        <span class="hidden-md-down" v-html="$t('ProfileOrdersDetailsModal.cancelOrder')"/>
                        <i class="hidden-lg-up fa fa-ban"/>
                    </button>
                    <router-link v-if="showHowToPayMyBillButton" target="_blank"
                                 :to="`/checkout-complete?order=${order._id}&scroll=how-to-pay-bill`"
                                 class="btn btn-warning" :disabled="loading">
                        <span class="hidden-md-down">
                            {{ $t("ProfileOrdersDetailsModal.howPayMyBill") }}
                        </span>
                        <span class="hidden-lg-up">
                            <i class="fa fa-dollar-sign"/>
                            <i class="fa fa-question"/>
                        </span>
                    </router-link>
                    <DownloadBilling v-if="showDownloadCreditOrderButton" :order="order" type="credit" :display-tooltip="false"
                                     classes="btn btn-success" :disabled="loading"/>
                    <DownloadBilling v-if="order.billing.method !== 'agreement'" :order="order" classes="btn btn-danger"
                                     :forced-type="defaultOrderBillingType" :disabled="loading" :display-tooltip="false"/>
                    <button type="button" :disabled="loading" class="btn btn-secondary" data-dismiss="modal">
                        <span class="hidden-sm-down">
                            {{$t("ProfileOrdersDetailsModal.close")}}
                        </span>
                        <i class="hidden-md-up fa fa-times"/>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import Order from "../../../../classes/Order";
import OrderReviewHeader from "../../../shared/Order/OrderReview/OrderReviewHeader";
import OrderProductionStatusLabel from "../../../shared/Order/OrderProductionStatusLabel";
import DownloadBilling from "../../../shared/Order/DownloadBilling";
import OrderReviewCartDetails from "../../../shared/Order/OrderReview/OrderReviewCartDetails/OrderReviewCartDetails";
import OrderPaymentStatusLabel from "../../../shared/Order/OrderPaymentStatusLabel";
import { discountCodeDisclaimer } from "@/helpers/discount-code";
import Alert from "../../../shared/Misc/Alert";
import APILoading from "@/components/shared/Misc/APILoading";

export default {
    name: "ProfileOrdersDetailsModal",
    components: {
        APILoading,
        Alert,
        OrderPaymentStatusLabel,
        OrderProductionStatusLabel,
        OrderReviewCartDetails,
        DownloadBilling,
        OrderReviewHeader},
    data() {
        return {
            order: null,
            loading: false,
        };
    },
    computed: {
        ...mapGetters("config", {
            shopPrimaryColor: "shopPrimaryColor",
        }),
        ...mapGetters("currencies", {
            currencies: "currencies",
        }),
        showCancelOrderButton() {
            return (this.order.status.payment === "waiting" || this.order.status.payment === "error") && this.order.status.production === "pending";
        },
        showDownloadCreditOrderButton() {
            return this.order.status.payment === "refunded" && this.order.billing.method !== "agreement";
        },
        showHowToPayMyBillButton() {
            return this.order.status.payment === "waiting" || this.order.status.payment === "error";
        },
        defaultOrderBillingType() {
            return this.order.billing.reference.bill ? "bill" : "proForma";
        },
        discountCodeDisclaimer() {
            return discountCodeDisclaimer(this.order.discountCode, this.currencies);
        },
    },
    methods: {
        async show(order) {
            await this.getOrder(order._id);
            // eslint-disable-next-line no-undef
            $("#order-details-modal").modal("show");
        },
        async getOrder(orderId) {
            try {
                this.order = null;
                const { data } = await this.$hermesAPI.getOrder(orderId);
                this.order = new Order(data);
            } catch (err) {
                this.$error.displayError(err);
            } finally {
                this.loading = false;
            }
        },
        confirmCancelOrder() {
            Swal.fire({
                title: this.$t("ProfileOrdersDetailsModal.youSure"),
                text: this.$t("ProfileOrdersDetailsModal.cantBeUndo"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: this.shopPrimaryColor,
                cancelButtonColor: "#D33",
                confirmButtonText: this.$t("ProfileOrdersDetailsModal.yesCancel"),
                cancelButtonText: this.$t("ProfileOrdersDetailsModal.no"),
            }).then(result => {
                if (result.value) {
                    this.cancelOrder();
                }
            });
        },
        async cancelOrder() {
            try {
                this.loading = true;
                const response = await this.$hermesAPI.updateOrder({ _id: this.order._id, status: { production: "canceled" } });
                this.$emit("update-order", response.data);
                // eslint-disable-next-line no-undef
                $("#order-details-modal").modal("hide");
                this.$toasted.success(this.$t("ProfileOrdersDetailsModal.orderCanceled"), { icon: "check" });
            } catch (err) {
                this.$error.displayError(err);
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style scoped>
    @media screen and (min-width: 640px) {
        .modal-lg {
            width: 80vw;
            max-width: none;
        }
    }

    #discount-code-disclaimer > span {
        font-size: 1.2rem;
    }
</style>