<template>
    <div id="upload-product-thumbnails">
        <transition mode="out-in" name="fade">
            <div v-if="status === 'pending'" key="thumbnail-inputs">
                <form @submit.prevent="sendThumbnails">
                    <div class="row align-items-center">
                        <div class="col-2 text-center">
                            <img class="w-50" v-lazy="product.firstCoverPhotoUrl"/>
                        </div>
                        <div class="col-10">
                            <label class="text-muted" for="book-first-thumbnail-input" v-html="$t('UploadProductThumbnails.firstCoverThumbnail')"/>
                            <div class="custom-file">
                                <input ref="FirstThumbnailInput" @change="checkThumbnailInput('first')"
                                       class="custom-file-input" type="file" accept="image/jpeg,image/png" id="book-first-thumbnail-input"
                                       v-tooltip="$t('UploadProductThumbnails.thumbnailFileMustBeImage')"/>
                                <label class="custom-file-label" :class="{ 'custom-file-label-success': !!thumbnails.first }" for="book-first-thumbnail-input"
                                       v-html="this.firstThumbnailInputLabel"/>
                            </div>
                        </div>
                    </div>
                    <div class="row align-items-center mt-3">
                        <div class="col-2 text-center">
                            <img class="w-50" v-lazy="product.fourthCoverPhotoUrl"/>
                        </div>
                        <div class="col-10">
                            <label class="text-muted" for="book-fourth-thumbnail-input" v-html="$t('UploadProductThumbnails.fourthCoverThumbnail')"/>
                            <div class="custom-file">
                                <input ref="FourthThumbnailInput" @change="checkThumbnailInput('fourth')"
                                       class="custom-file-input" type="file" accept="image/jpeg,image/png" id="book-fourth-thumbnail-input"
                                       v-tooltip="$t('UploadProductThumbnails.thumbnailFileMustBeImage')"/>
                                <label class="custom-file-label" :class="{ 'custom-file-label-success': !!thumbnails.fourth }" for="book-fourth-thumbnail-input"
                                       v-html="this.fourthThumbnailInputLabel"/>
                            </div>
                        </div>
                    </div>
                    <hr class="mt-4 mb-4"/>
                    <div class="row justify-content-center">
                        <SubmitBtn classes="btn btn-primary" :loading="false" :disabled="!thumbnails.first && !thumbnails.fourth"
                                   :text="$t('UploadProductThumbnails.uploadThumbnails')"/>
                    </div>
                </form>
            </div>
            <div v-else-if="status === 'uploading'" id="upload-progress-bar" key="upload-progress-bar" class="col-12">
                <h3 class="text-center">
                    <img width="100" alt="Uploading..." :src="GIFs.uploadToCloud"/>
                    <span v-html="$t('UploadProductThumbnails.uploadingThumbnails')"/>
                </h3>
                <div class="progress">
                    <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
                         :aria-valuenow="progress.percent" aria-valuemin="0" aria-valuemax="100"
                         :style="`width: ${progress.percent}%`"/>
                </div>
                <h4 class="text-center text-muted mt-3 text-monospace" v-html="progressText"/>
            </div>
            <div v-if="status === 'done'" key="thumbnails-uploaded" class="col-12">
                <div class="row align-items-center">
                    <div class="col-5 text-center">
                        <img class="w-25 mr-2" v-lazy="product.firstCoverPhotoUrl"/>
                        <img class="w-25" v-lazy="product.fourthCoverPhotoUrl"/>
                    </div>
                    <div class="col-7 align-items-center text-left">
                        <h5 class="m-0 text-center font-italic">
                            <i class="fa fa-check-circle text-success mr-2"/>
                            <span v-html="$t('UploadProductThumbnails.yourThumbnailsHaveBeenSent')"/>
                        </h5>
                        <div class="text-center">
                            <button type="button" class="btn btn-secondary" @click="reset">
                                <i class="fa fa-chevron-circle-left mr-2"/>
                                <span v-html="$t('UploadProductThumbnails.backToUpload')"/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Product from "@/classes/Product";
import SubmitBtn from "@/components/shared/Misc/SubmitBtn";
import uploadToCloud from "@/assets/gif/upload-to-cloud.gif";

export default {
    name: "UploadProductThumbnails",
    components: { SubmitBtn },
    props: {
        product: {
            type: Product,
            required: true,
        },
    },
    data() {
        return {
            thumbnails: {
                first: null,
                fourth: null,
            },
            status: "pending",
            progress: { percent: 0 },
            GIFs: { uploadToCloud },
        };
    },
    computed: {
        ...mapGetters("config", {
            hermesAPIConfig: "hermesAPI",
        }),
        firstThumbnailInputLabel() {
            return this.thumbnails.first ? `<i class='fa fa-check text-success mr-2'></i>${this.thumbnails.first.name}` : this.$t("UploadProductThumbnails.chooseFile");
        },
        fourthThumbnailInputLabel() {
            return this.thumbnails.fourth ? `<i class='fa fa-check text-success mr-2'></i>${this.thumbnails.fourth.name}` : this.$t("UploadProductThumbnails.chooseFile");
        },
        progressText() {
            return this.progress.percent !== 100 ? `${this.progress.percent}%` : this.$t("UploadProductThumbnails.finalizing");
        },
    },
    methods: {
        reset() {
            this.thumbnails.first = null;
            this.thumbnails.fourth = null;
            this.status = "pending";
            this.progress.percent = 0;
        },
        checkThumbnailInput(thumbnailType) {
            const refInput = thumbnailType === "first" ? "FirstThumbnailInput" : "FourthThumbnailInput";
            const { files } = this.$refs[refInput];
            if (!files.length) {
                return this.thumbnails[thumbnailType] = null;
            } else {
                const file = files[0];
                try {
                    if (file.type !== "image/png" && file.type !== "image/jpeg") {
                        throw this.$t("UploadProductThumbnails.chosenFileNotImage");
                    } else if (file.size > 1048576) {
                        throw this.$t("UploadProductThumbnails.chosenFileTooBig");
                    }
                    this.thumbnails[thumbnailType] = file;
                } catch (e) {
                    this.$toasted.error(e, { icon: "times" });
                    this.thumbnails[thumbnailType] = null;
                }
            }
        },
        async sendThumbnails() {
            try {
                this.status = "uploading";
                this.$emit("uploading-thumbnails");
                const { data } = await this.$hermesAPI.sendThumbnailsProductFiles(this.product._id, this.thumbnails, this.progress);
                this.status = "done";
                this.$emit("thumbnails-uploaded", data);
                this.$toasted.success(this.$t("UploadProductThumbnails.thumbnailsUploaded"), { icon: "check" });
            } catch (e) {
                this.$error.displayError(e);
                this.$emit("upload-error");
                this.status = "errored";
            }
        },
    },
};
</script>

<style lang="scss" scoped>

    .custom-file-label {
        cursor: pointer;
    }

    .custom-file-label-success {
        border-color: #6fb07d;
    }
</style>