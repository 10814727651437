<template>
    <div id="checkout-payment-credit-card" class="tab-pane fade"
         :class="{ show: chosenMethod, active: chosenMethod }" role="tabpanel">
        <div class="row align-items-center">
            <div class="col-12">
                <div class="custom-control custom-checkbox">
                    <input class="custom-control-input c-pointer" v-model="accepted"
                           type="checkbox" id="accept-credit-card">
                    <label v-html="$t('checkout.payment.acceptCreditCard', generalInfos)"
                           class="custom-control-label c-pointer" for="accept-credit-card"/>
                </div>
            </div>
        </div>
        <transition mode="out-in" name="fade">
            <div v-if="accepted" class="row justify-content-center mb-4">
                <StripePayment class="col-md-5" :order="order" @payment-intent="setBillingMethod"/>
            </div>
        </transition>
        <transition name="fade">
            <div v-if="accepted" class="row">
                <div class="col-12 text-center">
                    <div class="custom-control custom-checkbox custom-control-inline">
                        <input v-model="saveAsFavoritePaymentMethod" class="custom-control-input c-pointer"
                               type="checkbox" id="favorite-payment-method-credit-card">
                        <label v-html="$t('CheckoutPayment.favoritePaymentMethod')" class="custom-control-label c-pointer"
                               for="favorite-payment-method-credit-card"/>
                    </div>
                    <RequiredForOneClickOrder/>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import RequiredForOneClickOrder from "../../../shared/Order/RequiredForOneClickOrder";
import StripePayment from "../../../shared/Order/StripePayment";

export default {
    name: "CheckoutPaymentCreditCard",
    components: {StripePayment, RequiredForOneClickOrder},
    props: {
        generalInfos: {
            type: Object,
            required: true,
        },
        order: {
            type: Object,
            required: true,
        },
        preferences: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            accepted: false,
            saveAsFavoritePaymentMethod: this.preferences.billing.method === "creditCard",
        };
    },
    computed: {
        chosenMethod() {
            return this.order.billing.method === "creditCard";
        },
    },
    methods: {
        setBillingMethod(paymentIntent) {
            this.$emit("set-billing-method", {
                method: "creditCard",
                favoritePaymentMethod: this.saveAsFavoritePaymentMethod,
                paymentIntent,
            });
        },
    },
};
</script>

<style lang="scss">
</style>