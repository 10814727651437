<template>
    <div>
        <h3>
            <i class="fa fa-question mr-2 text-primary"></i>
            {{ $t("HelpOther.other") }}
        </h3>
        <div class="accordion" id="accordion-other" role="tablist">
            <div class="card">
                <div class="card-header" role="tab">
                    <h6>
                        <a href="#how-contact" data-toggle="collapse" class="collapsed" aria-expanded="false">
                            Comment puis-je vous contacter ?
                        </a>
                    </h6>
                </div>
                <div class="collapse" id="how-contact" data-parent="#accordion-other" role="tabpanel">
                    <div class="card-body">
                        <p>Toutes les informations de contact sont disponibles sur <router-link to="/contact">cette page</router-link>.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HelpOther",
};
</script>

<style scoped>

</style>