<template>
    <button class="btn btn-info btn-xs btn-block" @click="showAdministrationProductModal">
        <i class="fa fa-pen mr-2"/>
        <span v-html="$t('UpdateProductBtn.update')"/>
    </button>
</template>

<script>
import Product from "@/classes/Product";

export default {
    name: "UpdateProductBtn",
    props: {
        product: {
            type: Product,
            required: true,
        },
    },
    methods: {
        showAdministrationProductModal() {
            this.$emit("show-administration-product-modal", this.product);
        },
    },
};
</script>

<style scoped>

</style>