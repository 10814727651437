<template>
    <div id="checkout-payment-check" class="tab-pane fade"
         :class="{ show: chosenMethod, active: chosenMethod }" role="tabpanel">
        <div class="row align-items-center">
            <div class="col-12 col-md-8">
                <div class="custom-control custom-checkbox">
                    <input class="custom-control-input c-pointer" v-model="accepted"
                           type="checkbox" id="accept-check"/>
                    <label v-html="$t('checkout.payment.acceptCheck', generalInfos)"
                           class="custom-control-label c-pointer" for="accept-check"></label>
                </div>
            </div>
            <div class="col-12 col-md-4 text-center">
                <button @click="setBillingMethod" class="btn btn-primary" :disabled="!accepted">
                    {{$t("checkout.payment.payByCheck")}}
                </button>
            </div>
        </div>
        <transition name="fade">
            <div v-if="accepted" class="row">
                <div class="col-12 text-center">
                    <div class="custom-control custom-checkbox custom-control-inline">
                        <input v-model="saveAsFavoritePaymentMethod" class="custom-control-input c-pointer" type="checkbox" id="favorite-payment-method-check">
                        <label class="custom-control-label c-pointer" for="favorite-payment-method-check">
                            {{ $t("CheckoutPayment.favoritePaymentMethod") }}
                        </label>
                    </div>
                    <RequiredForOneClickOrder/>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>

import RequiredForOneClickOrder from "../../../shared/Order/RequiredForOneClickOrder";
export default {
    name: "CheckoutPaymentCheck",
    components: {RequiredForOneClickOrder},
    props: {
        generalInfos: {
            type: Object,
            required: true,
        },
        preferences: {
            type: Object,
            required: true,
        },
        order: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            accepted: false,
            saveAsFavoritePaymentMethod: this.preferences.billing.method === "check",
        };
    },
    computed: {
        chosenMethod() {
            return this.order.billing.method === "check";
        },
    },
    methods: {
        setBillingMethod() {
            this.$emit("set-billing-method", {
                method: "check",
                favoritePaymentMethod: this.saveAsFavoritePaymentMethod,
            });
        },
    },
};
</script>

<style scoped>

</style>