<template>
    <div class="container">
        <section class="row align-items-center padding-top-2x padding-bottom-2x">
            <p>
            ILP Distri est LA boutique en ligne dédiée aux libraires dans laquelle vous trouverez en quelques clics tous les titres qu'ILP Groupe peut imprimer à la demande.
            </p>
            <p>
            Ces livres, édités par des éditeurs français ou internationaux, clients d'ILP Groupe sont imprimés à Dakar et mis à votre disposition en 48h à notre comptoir de Dakar.
            </p>
            <p>
            Nous disposons d'une organisation logistique nous permettant de coliser et d'acheminer vos livres en 8 à 10 jours dans les comptoirs ILP Groupe à Abidjan, Bamako, Conakry, Lomé ou Ouagadougou.
            </p>
            <p>
            Pour tout question, n'hésitez pas à contacter notre équipe par email : contact@ilpexpress.com
            </p>
        </section>
    </div>
</template>

<script>
export default {
    name: "AboutILP",
};
</script>

<style scoped>

</style>