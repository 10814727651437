<template>
    <div class="col-lg-8">
        <hr class="margin-bottom-1x">
        <h6 class="text-muted text-normal text-center text-uppercase mb-4">
            <span class="fas fa-shopping-cart mr-1 text-primary"/>
            <span v-html="$t('ProfilePreferences.checkoutPreferences')"/>
        </h6>
        <hr class="margin-bottom-1x">
        <div class="row">
            <div class="col-12 text-right">
                <OneClickOrderBadge :user="localUser"/>
                <i v-if="!localUser.canOneClickOrder"
                   v-tooltip="$t('ProfilePreferences.toEnableOneClickOrderSetFavorite')"
                   class="fa fa-question-circle text-info ml-2"/>
            </div>
        </div>
        <ValidationObserver ref="formObserver" v-slot="{ invalid }">
            <form @submit.prevent="updateUser">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label v-html="$t('ProfilePreferences.favoriteBillingAddress')"/>
                            <v-select @input="setFavoriteBillingAddress" :value="formattedFavoriteBillingAddressSelected"
                                      :placeholder="$t('ProfilePreferences.noFavoriteBillingAddress')"
                                      :options="formattedAddresses">
                                <template slot="option" slot-scope="option" v-html="option.label"/>
                            </v-select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="favorite-delivery-method" v-html="$t('ProfilePreferences.favoriteDeliveryMethod')"/>
                            <select id="favorite-delivery-method" class="form-control" @change="setFavoriteDeliveryAddress(null)" v-model="localUser.preferences.delivery.method">
                                <option :value="null" v-html="$t('ProfilePreferences.none')"/>
                                <option v-if="isWithdrawalPointDeliveryEnabled" value="withdrawalPoint" v-html="withdrawalPointLabel"/>
                                <option v-if="isHomeDeliveryEnabled" value="home" v-html="$t('ProfilePreferences.home')"/>
                            </select>
                        </div>
                    </div>
                </div>
                <transition mode="out-in" name="fadein">
                    <div key="favorite-withdrawal-point" v-if="localUser.preferences.delivery.method === 'withdrawalPoint'" class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label v-html="favoriteWithdrawalPointLabel"/>
                                <v-select @input="setFavoriteDeliveryAddress" :value="formattedFavoriteWithdrawalPointSelected"
                                          :placeholder="noFavoriteWithdrawalPointLabel"
                                          :options="formattedWithdrawalPoints">
                                    <template slot="option" slot-scope="option" v-html="option.label"/>
                                </v-select>
                            </div>
                        </div>
                    </div>
                    <div key="favorite-home" v-else-if="localUser.preferences.delivery.method === 'home'" class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label v-html="$t('ProfilePreferences.favoriteDeliveryAddress')"/>
                                <v-select @input="setFavoriteDeliveryAddress" :value="formattedFavoriteDeliveryAddressSelected"
                                          :placeholder="$t('ProfilePreferences.noFavoriteDeliveryAddress')"
                                          :options="formattedAddresses">
                                    <template slot="option" slot-scope="option" v-html="option.label"/>
                                </v-select>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label v-html="$t('ProfilePreferences.favoriteCarrier')"/>
                                <div v-if="localUser.preferences.delivery.method === 'home'" class="badge badge-info mb-1">
                                    <i class="fa fa-info-circle mr-1"/>
                                    <span v-html="$t('ProfilePreferences.youMustOrderToChangeFavoriteCarrier')"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="favorite-billing-method" v-html="$t('ProfilePreferences.favoriteBillingMethod')"/>
                            <select id="favorite-billing-method" class="form-control" v-model="localUser.preferences.billing.method">
                                <option :value="null" v-html="$t('ProfilePreferences.none')"/>
                                <option v-if="isCashPaymentEnabled" value="cash" v-html="$t('ProfilePreferences.cash')"/>
                                <option v-if="isCheckPaymentEnabled" value="check" v-html="$t('ProfilePreferences.check')"/>
                                <option v-if="isBankTransferPaymentEnabled" value="bankTransfer" v-html="$t('ProfilePreferences.bankTransfer')"/>
                                <option v-if="isOrangeMoneyPaymentEnabled" value="orangeMoney" v-html="$t('ProfilePreferences.orangeMoney')"/>
                                <option v-if="isWariPaymentEnabled" value="wari" v-html="$t('ProfilePreferences.wari')"/>
                                <option v-if="isCreditCardPaymentEnabled" value="creditCard" v-html="$t('ProfilePreferences.creditCard')"/>
                                <option v-if="isPayPalPaymentEnabled" value="paypal" v-html="$t('ProfilePreferences.paypal')"/>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <Alert v-if="localUser.preferences.billing.method === 'paypal'" type="info"
                               :text="$t('ProfilePreferences.youMustProvidePayPalCredentials')">
                            <template v-slot:icon>
                                <i class="fa fa-2x fa-info-circle"/>
                            </template>
                        </Alert>
                        <Alert v-else-if="localUser.preferences.billing.method === 'creditCard'" type="info"
                               :text="$t('ProfilePreferences.youMustProvideCreditCardInfo')">
                            <template v-slot:icon>
                                <i class="fa fa-2x fa-info-circle"/>
                            </template>
                        </Alert>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <hr class="mt-2 mb-3">
                        <div class="d-flex flex-wrap justify-content-between align-items-center">
                            <SubmitBtn :disabled="invalid" :classes="'btn btn-primary margin-right-none'" :loading="loading"
                                       :text="$t('ProfilePreferences.update')"/>
                        </div>
                    </div>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import SubmitBtn from "../../../shared/Misc/SubmitBtn";
import User from "../../../../classes/User";
import WithdrawalPoint from "../../../../classes/WithdrawalPoint";
import OneClickOrderBadge from "./OneClickOrderBadge";
import Alert from "../../../shared/Misc/Alert";

export default {
    name: "ProfilePreferences",
    components: {Alert, OneClickOrderBadge, SubmitBtn },
    props: {
        user: {
            required: true,
            type: Object,
        },
    },
    data() {
        return {
            loading: false,
            localUser: new User(this.user),
            withdrawalPoints: null,
        };
    },
    computed: {
        ...mapGetters("user", {
            userAddresses: "addresses",
        }),
        ...mapGetters("config", {
            frontConfig: "front",
            isCashPaymentEnabled: "isCashPaymentEnabled",
            isCheckPaymentEnabled: "isCheckPaymentEnabled",
            isBankTransferPaymentEnabled: "isBankTransferPaymentEnabled",
            isOrangeMoneyPaymentEnabled: "isOrangeMoneyPaymentEnabled",
            isWariPaymentEnabled: "isWariPaymentEnabled",
            isCreditCardPaymentEnabled: "isCreditCardPaymentEnabled",
            isPayPalPaymentEnabled: "isPayPalPaymentEnabled",
            isWithdrawalPointDeliveryEnabled: "isWithdrawalPointDeliveryEnabled",
            isHomeDeliveryEnabled: "isHomeDeliveryEnabled",
        }),
        formattedAddresses() {
            return this.userAddresses.map(address => ({ value: address._id, label: address.toString() }));
        },
        formattedWithdrawalPoints() {
            return this.withdrawalPoints ? this.withdrawalPoints.map(withdrawalPoint => ({ value: withdrawalPoint._id, label: withdrawalPoint.toString() })) : [];
        },
        formattedFavoriteWithdrawalPointSelected() {
            if (this.localUser.preferences.delivery.method === "withdrawalPoint" && this.localUser.preferences.delivery.addressId) {
                return this.formattedWithdrawalPoints.find(withdrawalPoint => withdrawalPoint.value === this.localUser.preferences.delivery.addressId);
            }
            return null;
        },
        formattedFavoriteBillingAddressSelected() {
            if (this.localUser.preferences.billing.addressId) {
                return this.formattedAddresses.find(address => address.value === this.localUser.preferences.billing.addressId);
            }
            return null;
        },
        formattedFavoriteDeliveryAddressSelected() {
            if (this.localUser.preferences.delivery.addressId) {
                return this.formattedAddresses.find(address => address.value === this.localUser.preferences.delivery.addressId);
            }
            return null;
        },
        withdrawalPointLabel() {
            const withdrawalPointTerm = this.frontConfig.app.i18n.terms.withdrawalPoint;
            return withdrawalPointTerm === "withdrawalPoint" ? this.$t("ProfilePreferences.withdrawalPoint") : this.$t("ProfilePreferences.provisionPlace");
        },
        favoriteWithdrawalPointLabel() {
            const withdrawalPointTerm = this.frontConfig.app.i18n.terms.withdrawalPoint;
            return withdrawalPointTerm === "withdrawalPoint" ? this.$t("ProfilePreferences.favoriteWithdrawalPoint") : this.$t("ProfilePreferences.favoriteProvisionPlace");
        },
        noFavoriteWithdrawalPointLabel() {
            const withdrawalPointTerm = this.frontConfig.app.i18n.terms.withdrawalPoint;
            return withdrawalPointTerm === "withdrawalPoint" ? this.$t("ProfilePreferences.noFavoriteWithdrawalPoint") : this.$t("ProfilePreferences.noFavoriteProvisionPlace");
        },
    },
    async created() {
        if (this.isWithdrawalPointDeliveryEnabled) {
            await this.getWithdrawalPoints();
        }
    },
    mounted() {
        this.$refs.formObserver.validate();
    },
    methods: {
        async updateUser() {
            try {
                this.loading = true;
                await this.$store.dispatch("user/updateUser", { user: this.localUser });
            } catch (err) {
                this.$error.displayError(err);
            } finally {
                this.loading = false;
            }
        },
        setFavoriteBillingAddress(address) {
            this.localUser.preferences.billing.addressId = address ? address.value : null;
        },
        async getWithdrawalPoints() {
            try {
                const response = await this.$hermesAPI.getWithdrawalPoints({ currency: this.chosenCurrency });
                this.withdrawalPoints = response.data.map(w => new WithdrawalPoint(w));
            } catch (e) {
                this.$error.displayError(e);
            }
        },
        setFavoriteDeliveryAddress(address) {
            this.localUser.preferences.delivery.addressId = address ? address.value : null;
        },
    },
};
</script>

<style lang="scss" scoped>
    .form-group {
        label {
            span {
                color: red;
            }
        }
    }
</style>