import Vue from "vue";
import JWT from "jsonwebtoken";
import Swal from "sweetalert2";
import User from "../../classes/User";
import Address from "../../classes/Address";
import Product from "../../classes/Product";
import i18n from "../../i18n";
import router from "../../router";

function setLocalStorageCartIfNotLogged(state) {
    if (!state.user.logged) {
        localStorage.setItem("cart", JSON.stringify(state.user.cart));
    }
}

function getLocalStorageCart() {
    try {
        if (localStorage.getItem("cart")) {
            const localCart = JSON.parse(localStorage.getItem("cart"));
            return Array.isArray(localCart) ? localCart : [];
        } else {
            return [];
        }
    } catch {
        localStorage.setItem("cart", JSON.stringify([]));
        return [];
    }
}

const state = {
    user: new User({
        cart: getLocalStorageCart(),
    }),
};

const getters = {
    user(state) {
        return state.user;
    },
    society(state) {
        return state.user.society;
    },
    lastName(state) {
        return state.user.lastName;
    },
    fullName(state) {
        let fullName = "";
        const firstName = state.user.firstName ? state.user.firstName.trim() : null;
        const lastName = state.user.lastName ? state.user.lastName.trim() : null;
        if (firstName) {
            fullName += firstName;
        }
        if (lastName) {
            fullName += ` ${lastName.toUpperCase()}`;
        }
        return fullName.trim();
    },
    createdAt(state) {
        return state.user.createdAt;
    },
    isLogged(state) {
        return state.user.logged;
    },
    isAdmin(state) {
        return state.user.isAdmin;
    },
    addresses(state) {
        return state.user.addresses;
    },
    cart(state) {
        return state.user.cart;
    },
    publisherAgreements(state) {
        return state.user.publishers.agreements;
    },
    carrierAgreements(state) {
        return state.user.carriers.agreements;
    },
    privateCarriers(state) {
        return state.user.carriers.privates;
    },
    isProfessional(state) {
        return state.user.isProfessional;
    },
};

const mutations = {
    setUser(state, user) {
        state.user = new User(user);
    },
    setLogged(state, logged) {
        state.user.logged = logged;
    },
    addAddress(state, address) {
        state.user.addresses.push(new Address(address));
    },
    updateAddress(state, newAddress) {
        const idx = state.user.addresses.findIndex(address => address._id === newAddress._id);
        if (idx !== -1) {
            state.user.addresses.splice(idx, 1, new Address(newAddress));
        }
    },
    deleteAddress(state, id) {
        const idx = state.user.addresses.findIndex(address => address._id === id);
        if (idx !== -1) {
            state.user.addresses.splice(idx, 1);
        }
    },
    setCart(state, cart) {
        state.user.cart = !cart ? cart : cart.map(p => new Product(p));
        setLocalStorageCartIfNotLogged(state);
    },
    addToCart(state, product) {
        const idx = state.user.cart.findIndex(p => p._id === product._id);
        if (idx !== -1) {
            state.user.cart.splice(idx, 1, new Product(product));
        } else {
            state.user.cart.push(new Product(product));
        }
        setLocalStorageCartIfNotLogged(state);
    },
    deleteToCart(state, id) {
        const idx = state.user.cart.findIndex(p => p._id === id);
        if (idx !== -1) {
            state.user.cart.splice(idx, 1);
        }
        setLocalStorageCartIfNotLogged(state);
    },
};

const actions = {
    async preConfirmFastLoginSwal({ dispatch }) {
        try {
            const email = document.getElementById("swal-input-email").value;
            const password = document.getElementById("swal-input-password").value;
            await dispatch("login", { email, password });
        } catch (e) {
            document.getElementById("swal-input-password").value = "";
            document.getElementById("swal-input-password").focus();
            Vue.prototype.$error.handleLoginError(e);
            return false;
        }
    },
    async fireFastLoginSwal({ rootGetters, dispatch }) {
        const lastEmailUsed = localStorage.getItem("last-email-used") || "";
        await Swal.fire({
            title: i18n.t("shared.logInSwal.logInToContinue"),
            html:
                `<hr class="mb-3"/>
                <label for="swal-input-email">${i18n.t("shared.logInSwal.email")}</label>
                <input placeholder="${i18n.t("shared.logInSwal.required")}" type="email" id="swal-input-email" class="form-control mb-3" value="${lastEmailUsed}">
                <label for="swal-input-password">${i18n.t("shared.logInSwal.password")}</label>
                <input placeholder="${i18n.t("shared.logInSwal.required")}" type="password" id="swal-input-password" class="form-control">`,
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonColor: rootGetters["config/shopPrimaryColor"],
            cancelButtonColor: "#D33",
            showLoaderOnConfirm: true,
            confirmButtonText: i18n.t("shared.logInSwal.logIn"),
            cancelButtonText: i18n.t("shared.logInSwal.cancel"),
            preConfirm: () => dispatch("preConfirmFastLoginSwal"),
        });
    },
    async checkUserAuthentication({ getters, dispatch }, needsToBeAdmin = false) {
        if (getters.isLogged === false) {
            await dispatch("fireFastLoginSwal");
            if (getters.isLogged === false) {
                Vue.prototype.$toasted.error(i18n.t("shared.toast.unauthorized"), { icon: "times" });
                await router.push("/");
            }
        } else if (getters.isLogged && needsToBeAdmin && !getters.isAdmin) {
            Vue.prototype.$toasted.error(i18n.t("shared.toast.unauthorized"), { icon: "times" });
            await router.push("/");
        }
    },
    async getUser({ commit, dispatch, rootState }) {
        const { data } = await Vue.prototype.$hermesAPI.getUser({ currency: rootState.preferences.preferences.currency });
        commit("setUser", new User({ ...data, logged: true }));
        await dispatch("publishers/getPublishers", {}, { root: true });
    },
    async login({ getters, commit, dispatch }, { email, password }) {
        const savedLocalCart = JSON.parse(JSON.stringify(getters.cart));
        const { data } = await Vue.prototype.$hermesAPI.login({ email, password });
        localStorage.setItem("token", data.token);
        Vue.prototype.$hermesAPI.setToken(data.token);
        await dispatch("getUser");
        commit("setLogged", true);
        for (const product of savedLocalCart) {
            try {
                await dispatch("addToCart", { productId: product._id, quantity: product.quantity });
            } catch {
                Vue.prototype.$toasted.error(i18n.t("shared.toast.cantTransferIntoCart", { title: product.title }), { icon: "lock-open" });
            }
        }
        localStorage.setItem("cart", JSON.stringify([]));
        Vue.prototype.$toasted.success(i18n.t("shared.toast.loginSuccess"), { icon: "lock-open" });
    },
    async logout({ commit, dispatch }) {
        localStorage.removeItem("token");
        Vue.prototype.$hermesAPI.setToken(null);
        Vue.prototype.$toasted.success(i18n.t("shared.toast.logoutSuccess"), { icon: "lock" });
        await router.push("/");
        commit("setUser", new User({ logged: false }));
        await dispatch("publishers/getPublishers", {}, { root: true });
    },
    async changeUser({ commit, dispatch }, userId) {
        localStorage.setItem("clientId", userId);
        commit("setUser", new User());
        await dispatch("checkTokenAndLogin");
        Vue.prototype.$toasted.success(i18n.t("shared.toast.userChanged"), { icon: "user" });
        Vue.prototype.$toasted.info(i18n.t("shared.toast.reload"), { icon: "sync-alt" });
    },
    async updateUser({ commit }, data) {
        const { user, options } = data;
        if (user) {
            delete user.isAdmin;
            delete user.active;
        }
        const response = await Vue.prototype.$hermesAPI.updateUser(user);
        if (!options || options && options.toasted !== false) {
            Vue.prototype.$toasted.success(i18n.t("profile.me.updated"), { icon: "user-circle" });
        }
        commit("setUser", { ...response.data, logged: true });
    },
    async checkTokenAndLogin({ commit, dispatch }) {
        const token = localStorage.getItem("token");
        if (token) {
            const decoded = JWT.decode(token);
            const now = Math.round(new Date().getTime() / 1000);
            if (decoded && decoded.exp && now < decoded.exp) {
                return await dispatch("getUser");
            }
        }
        commit("setLogged", false);
        await dispatch("publishers/getPublishers", {}, {root: true});
        localStorage.removeItem("token");
    },
    async addAddress({ commit }, address) {
        const response = await Vue.prototype.$hermesAPI.addAddress(address);
        Vue.prototype.$toasted.success(i18n.t("shared.address.addressModal.addressSaved"), { icon: "map-pin" });
        commit("addAddress", response.data);
        return response;
    },
    async updateAddress({ commit }, newAddress) {
        await Vue.prototype.$hermesAPI.updateAddress(newAddress);
        Vue.prototype.$toasted.success(i18n.t("shared.address.addressManager.addressUpdated"), { icon: "map-pin" });
        commit("updateAddress", newAddress);
    },
    async deleteAddress({ commit }, id) {
        await Vue.prototype.$hermesAPI.deleteAddress(id);
        Vue.prototype.$toasted.success(i18n.t("shared.address.addressManager.addressDeleted"), { icon: "map-pin" });
        commit("deleteAddress", id);
    },
    async getCart({ getters, commit, rootState }) {
        const savedLocalCart = JSON.parse(JSON.stringify(getters.cart));
        commit("setCart", null);
        if (getters.isLogged) {
            const response = await Vue.prototype.$hermesAPI.getCart({ currency: rootState.preferences.preferences.currency });
            commit("setCart", response.data);
        } else {
            const cart = [];
            for (const { _id, title, quantity } of savedLocalCart) {
                try {
                    const { data } = await Vue.prototype.$hermesAPI.getProduct(_id, { currency: rootState.preferences.preferences.currency });
                    const product = new Product({ ...data, quantity });
                    cart.push(product);
                } catch {
                    Vue.prototype.$toasted.error(i18n.t("shared.toast.productNotAvailableAnymore", { title }), { icon: "times" });
                }
            }
            commit("setCart", cart);
        }
    },
    async addToCart({ getters, commit, rootState }, product) {
        let response;
        if (getters.isLogged) {
            response = await Vue.prototype.$hermesAPI.addCartProduct(product, { currency: rootState.preferences.preferences.currency });
        } else {
            response = await Vue.prototype.$hermesAPI.getProduct(product.productId, { currency: rootState.preferences.preferences.currency });
            response.data.quantity = getters.cart.filter(p => p._id === product.productId).reduce((acc, product) => acc + product.quantity, 0) + 1;
        }
        product = new Product(response.data);
        commit("addToCart", product);
        return product;
    },
    async updateToCart({ getters, commit, rootState }, product) {
        let response;
        if (getters.isLogged) {
            response = await Vue.prototype.$hermesAPI.updateCartProduct(product, { currency: rootState.preferences.preferences.currency });
        } else {
            response = await Vue.prototype.$hermesAPI.getProduct(product._id, { currency: rootState.preferences.preferences.currency });
            response.data.quantity = product.quantity;
        }
        Vue.prototype.$toasted.success(i18n.t("shared.toast.cartEdited"), { icon: "shopping-cart", position: "bottom-left" });
        product = new Product(response.data);
        commit("addToCart", product);
    },
    async deleteToCart({ commit, getters }, id) {
        if (getters.isLogged) {
            await Vue.prototype.$hermesAPI.deleteCartProduct(id);
        }
        Vue.prototype.$toasted.success(i18n.t("shared.toast.deletedFromCart"), { icon: "shopping-cart" });
        commit("deleteToCart", id);
    },
    async deleteCart({ commit, getters }) {
        if (getters.isLogged) {
            await Vue.prototype.$hermesAPI.deleteCart();
        }
        Vue.prototype.$toasted.success(i18n.t("cartDetails.cartDeleted"), { icon: "shopping-cart" });
        commit("setCart", []);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};