<template>
    <div id="side-bar-tabs" class="toolbar">
        <div class="inner">
            <a @click.prevent="toggleSection('mobileNav')" ref="mobileMenuToggle"
               class="toolbar-toggle mobile-menu-toggle" :class="{ active: isMobileNavSectionActive }"
               href="#mobileMenu">
                <i class="material-icons menu"/>
            </a>
            <a v-if="frontConfig.isShopSectionEnabled" @click.prevent="toggleSection('search')" id="header-search"
               ref="searchToggle" v-tooltip="$t('Header.fastSearch')" class="toolbar-toggle search-toggle"
               href="#header-search-panel" :class="{ 'active': isSearchSectionActive }">
                <i class="material-icons search"/>
            </a>
            <a @click.prevent="toggleSection('account')" id="header-account" v-tooltip="$t('Header.myAccount')" ref="accountToggle"
               class="toolbar-toggle" :class="{ active: isAccountSectionActive }" href="#header-account-panel">
                <transition name="fade" mode="out-in">
                    <UserAvatar v-if="isUserLogged" class="material-icons" key="avatar" :user="user" :size="35"/>
                    <i v-else key="not-connected" class="material-icons person"/>
                </transition>
            </a>
            <a @click.prevent="toggleSection('cart')" id="header-user-cart" ref="cartToggle" v-tooltip="$t('Header.myCart')"
               class="toolbar-toggle" :class="{ active: isCartSectionActive }" href="#header-user-cart-panel" >
                <i>
                    <span class="material-icons shopping_basket"/>
                    <span v-tooltip="$t('Header.agreementCart')" v-if="isCartFullOfProductsWithAgreement(user)"
                          id="agreement-cart-icon" class="fa-stack fa-1x">
                            <i class="fas fa-certificate fa-stack-2x text-primary"/>
                            <i class="far fa-handshake fa-stack-1x fa-inverse"/>
                        </span>
                    <transition name="fade" mode="out-in">
                        <span v-if="user.isCartEnabled" class="count">{{cartProductQuantity}}</span>
                    </transition>
                </i>
            </a>
        </div>
        <div class="toolbar-dropdown" :class="{ open: isSideBarOpen }">
            <MobileNav/>
            <HeaderSearch/>
            <Account @show-forgot-password-modal="$emit('show-forgot-password-modal')"/>
            <HeaderShopCart @click-login="$refs.accountToggle.click()"/>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { isCartFullOfProductsWithAgreement } from "@/helpers/user";
import UserAvatar from "@/components/shared/User/UserAvatar";
import MobileNav from "@/components/Hermes/Header/MobileNav";
import HeaderSearch from "@/components/Hermes/Header/HeaderSearch/HeaderSearch";
import Account from "@/components/Hermes/Header/Account/Account";
import HeaderShopCart from "@/components/Hermes/Header/HeaderShopCart/HeaderShopCart";

export default {
    name: "SideBarTabs",
    components: {HeaderShopCart, Account, HeaderSearch, MobileNav, UserAvatar},
    computed: {
        ...mapGetters("sideBarMenu", {
            isSideBarOpen: "isSideBarOpen",
            isMobileNavSectionActive: "isMobileNavSectionActive",
            isSearchSectionActive: "isSearchSectionActive",
            isAccountSectionActive: "isAccountSectionActive",
            isCartSectionActive: "isCartSectionActive",
        }),
        ...mapGetters("user", {
            user: "user",
            userCart: "cart",
            isUserLogged: "isLogged",
        }),
        ...mapGetters("config", {
            frontConfig: "front",
        }),
        cartProductQuantity() {
            const count = this.userCart ? this.userCart.length : 0;
            return count < 100 ? count : `99+`;
        },
    },
    methods: {
        ...mapActions("sideBarMenu", {
            toggleSection: "toggleSection",
        }),
        isCartFullOfProductsWithAgreement,
    },
};
</script>

<style lang="scss" scoped>
    #agreement-cart-icon {
        position: fixed;
        right: 127%;
        top: -32%;
        font-size: 14px;
    }

    .count {
        top: -6px !important;
        right: -17px !important;
        width: 21px !important;
        height: 21px !important;
        font-size: 11px !important;
        line-height: 21px !important;
    }
</style>