import Vue from "vue";
import Currency from "../../classes/Currency";

const state = {
    currencies: undefined,
};

const getters = {
    currencies(state) {
        return state.currencies;
    },
};

const mutations = {
    setCurrencies(state, currencies) {
        state.currencies = currencies;
    },
};

const actions = {
    async getCurrencies({ commit }) {
        const response = await Vue.prototype.$diceAPI.getCurrencies();
        commit("setCurrencies", response.data.map(currency => new Currency(currency)));
    },
    setCurrencies({ commit }, currencies) {
        commit("setCurrencies", currencies);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};