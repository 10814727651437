<template>
    <div class="col-lg-8 text-center">
        <div class="padding-top-2x mt-2 hidden-lg-up"></div>
        <ValidationObserver ref="formObserver" v-slot="{ invalid }">
            <form @submit.prevent="submit()">
                <div class="row align-items-center">
                    <div class="col-md-6 col-sm-12">
                        <div class="row">
                            <ValidationProvider class="col-12" v-slot="{ errors, classes }">
                                <div class="form-group">
                                    <label for="actual-password">
                                        {{ $t("ProfileChangePassword.actualPassword") }}
                                        <span>*</span>
                                    </label>
                                    <input id="actual-password" class="form-control" type="password" required minlength="5"
                                           :placeholder="$t('ProfileChangePassword.required')" v-model="oldPassword" :class="classes"/>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="row">
                            <ValidationProvider vid="confirmation" class="col-12" v-slot="{ errors, classes }">
                                <div class="form-group">
                                    <label for="new-password">
                                        {{ $t("ProfileChangePassword.newPassword") }}
                                        <span>*</span>
                                    </label>
                                    <input id="new-password" class="form-control" type="password" required minlength="5"
                                           :placeholder="$t('ProfileChangePassword.required')" v-model="password" :class="classes"/>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="row">
                            <ValidationProvider rules="confirm_password:confirmation" v-slot="{ errors, classes }" class="col-12">
                                <div class="form-group">
                                    <label for="confirm-new-password">
                                        {{ $t("ProfileChangePassword.confirmNewPassword") }}
                                        <span>*</span>
                                    </label>
                                    <input id="confirm-new-password" class="form-control" type="password" required minlength="5"
                                           :placeholder="$t('ProfileChangePassword.required')" v-model="confirmPassword" :class="classes"/>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </div>
                            </ValidationProvider>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <SubmitBtn classes="btn btn-primary btn-block" :text="$t('ProfileChangePassword.changePassword')"
                                           :loading="loading" :disabled="invalid"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 hidden-sm-down">
                        <img :src="securitySVG" alt="Change password"/>
                    </div>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import SubmitBtn from "../../shared/Misc/SubmitBtn";
import { importSVG } from "../../../helpers/file";

export default {
    name: "ProfileChangePassword",
    components: {SubmitBtn},
    data() {
        return {
            oldPassword: "",
            password: "",
            confirmPassword: "",
            loading: false,
        };
    },
    computed: {
        ...mapGetters("config", {
            hermesAPIConfig: "hermesAPI",
        }),
        securitySVG() {
            return importSVG("security", this.hermesAPIConfig.client);
        },
    },
    methods: {
        async submit() {
            try {
                this.loading = true;
                if (this.password !== this.confirmPassword) {
                    return this.$toasted.error(this.$t("ProfileChangePassword.passwordsDontMatch"), { icon: "lock" });
                }
                await this.$hermesAPI.updatePassword({ oldPassword: this.oldPassword, password: this.password });
                this.$toasted.success(this.$t("ProfileChangePassword.passwordChanged"), { icon: "check" });
            } catch (err) {
                if (err.response && err.response.status === 401) {
                    return this.$toasted.error(this.$t("ProfileChangePassword.actualPasswordIncorrect"), { icon: "lock" });
                }
                this.$error.displayError(err);
            } finally {
                this.loading = false;
                this.oldPassword = "";
                this.password = "";
                this.confirmPassword = "";
                this.$refs.formObserver.reset();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    label {
        span {
            color: red;
        }
    }
</style>