<template>
    <header id="header" class="navbar navbar-sticky">
        <PrintCorpHeader v-if="hermesAPIConfig.client === 'imprimer-vos-livres'"
                         @show-forgot-password-modal="$emit('show-forgot-password-modal')"/>
        <DefaultHeader  v-else @show-forgot-password-modal="$emit('show-forgot-password-modal')"/>
    </header>
</template>

<script>
import { mapGetters } from "vuex";
import DefaultHeader from "@/components/Hermes/Header/DefaultHeader/DefaultHeader";
import PrintCorpHeader from "@/components/Hermes/Header/CustomHeader/PrintCorpHeader/PrintCorpHeader";

export default {
    name: "Header",
    components: {PrintCorpHeader, DefaultHeader},
    computed: {
        ...mapGetters("config", {
            hermesAPIConfig: "hermesAPI",
        }),
    },
};
</script>

<style scoped>

</style>