<template>
    <span ref="buttonContainer" id="btn-container" v-tooltip="tooltipOptions">
        <button ref="button" class="text-center" :class="[classes, {disabled: loading || disabled}]" @click="click"
                :disabled="loading || disabled" type="submit">
            <span class="d-flex align-items-center justify-content-center" v-html="text" v-if="!loading"/>
            <HalfCircleSpinner class="m-auto" :size="25" v-else :animation-duration="1000"/>
        </button>
    </span>
</template>

<script>
import { HalfCircleSpinner } from "epic-spinners";

export default {
    name: "SubmitBtn",
    components: {HalfCircleSpinner},
    data() {
        return {
            width: "",
            mounted: false,
        };
    },
    props: {
        classes: {
            type: String,
            default: "",
        },
        loading: {
            type: Boolean,
            required: true,
        },
        text: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        disabledTooltipText: {
            type: String,
            default: null,
        },
        tooltipText: {
            type: String,
            default: null,
        },
    },
    mounted() {
        this.mounted = true;
    },
    methods: {
        click(e) {
            this.$emit("click", e);
        },
    },
    computed: {
        containerButtonWidth() {
            if (this.mounted) {
                return window.getComputedStyle(this.$refs.button).width;
            } else {
                return "100%";
            }
        },
        tooltipOptions() {
            return {
                container: "body",
                offset: 9,
                content: this.disabled ? this.disabledTooltipText : this.tooltipText,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
    .disabled {
        cursor: default !important;
        pointer-events: none;
    }
</style>