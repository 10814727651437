<template>
    <div>
        <PageTitle :title="$t('contact.title')">
            <template v-slot:left>
                <i class="fa fa-phone fa-3x text-primary"/>
            </template>
        </PageTitle>
        <div v-if="hermesAPIConfig.client === 'ilp'" class="container padding-bottom-2x mb-2">
            <ContactAddressILP/>
        </div>
        <div v-else-if="hermesAPIConfig.client === 'airvey-editions'" class="container">
            <ContactAirveyEditions/>
        </div>
        <div v-else class="container">
            <ContactDefault/>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import PageTitle from "../../shared/Misc/PageTitle";
import ContactDefault from "./ContactDefault";
import ContactAddressILP from "./ContactAddressILP";
import ContactAirveyEditions from "./ContactAirveyEditions";

export default {
    name: "Contact",
    components: {ContactAirveyEditions, ContactAddressILP, PageTitle, ContactDefault},
    computed: {
        ...mapGetters("config", {
            hermesAPIConfig: "hermesAPI",
        }),
    },
};
</script>

<style scoped>

</style>