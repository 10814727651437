<template>
    <transition mode="out-in" name="fade">
        <APILoading id="loading" key="loading" v-if="!publishers" :font-size='12' :icon-size='20'
                    text="Chargement des éditeurs"/>
        <section key="publisher-list" id="shop-publishers-section" v-else-if="publishers.length > 1" class="widget">
            <h3 class="widget-title">
                <i class="fa fa-book-open mr-2 text-primary"/>
                {{$t("shop.shopMenu.publisherList")}}
                <span v-tooltip="publisherCountTooltip" class="badge float-right" :class="publisherCountClass">
                    {{ this.displayedPublishers.length }}
                </span>
            </h3>
            <div class="input-group mb-3">
                <div class="input-group-addon">
                    <span class="input-group-text">
                        <i class="fa fa-filter pt-2"/>
                    </span>
                </div>
                <input class="form-control form-control-sm" v-model="filter"
                       :placeholder="$t('ShopMenu.filterPublishersName')" />
            </div>
            <div id="publisher-list">
                <div class="custom-control">
                    <ShopMenuPublisher key="0" @change-query-param="changeQueryParam" :chosen="isPublisherChosen(null)"/>
                </div>
                <div v-for="publisher in this.displayedPublishers" :key="publisher._id" class="custom-control">
                    <ShopMenuPublisher @change-query-param="changeQueryParam" :publisher="publisher" :chosen="isPublisherChosen(publisher._id)"/>
                </div>
            </div>
        </section>
    </transition>
</template>

<script>
import { mapState } from "vuex";
import ShopMenuPublisher from "./ShopMenuPublisher";
import APILoading from "../../../../shared/Misc/APILoading";

export default {
    name: "ShopMenuPublishersFilter",
    components: {APILoading, ShopMenuPublisher},
    props: {
        queries: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            filter: "",
        };
    },
    computed: {
        ...mapState("publishers", {
            publishers: "publishers",
        }),
        displayedPublishers() {
            return this.publishers ? this.publishers.filter(publisher => this.showPublisher(publisher.name)) : [];
        },
        publisherCountClass() {
            return this.publishers.length === this.displayedPublishers.length ? "badge-primary" : "badge-warning";
        },
        publisherCountTooltip() {
            const publisherCountShop = this.$t("ShopMenu.publisherCountShop", { count: this.publishers.length });
            const publisherCountShopWithFilter = this.$t("ShopMenu.publisherCountShopWithFilter", { count: this.displayedPublishers.length });
            return this.publishers.length === this.displayedPublishers.length ? publisherCountShop : publisherCountShopWithFilter;
        },
    },
    methods: {
        changeQueryParam(queryParam) {
            this.$emit("change-query-param", queryParam);
        },
        showPublisher(name) {
            name = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
            const regex = new RegExp(this.filter.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase());
            return name.match(regex, "ig");
        },
        isPublisherChosen(publisherId) {
            return publisherId === this.queries["publisher"];
        },
    },
};
</script>

<style lang="scss" scoped>
    #publisher-list {
        max-height: 25vh;
        overflow-y: auto;
        scrollbar-width: thin;
    }

    #publisher-list::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    #publisher-list::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
    }

    #publisher-list::-webkit-scrollbar-thumb {
        background: #e1e1e1;
        border: 0px none #ffffff;
        border-radius: 50px;
    }

    #publisher-list::-webkit-scrollbar-thumb:hover {
        background: grey;
    }

    #publisher-list::-webkit-scrollbar-thumb:active {
        background: dimgrey;
    }

    #publisher-list::-webkit-scrollbar-track {
        background: white;
        border: 0px none #ffffff;
        border-radius: 50px;
    }

    #publisher-list::-webkit-scrollbar-track:hover {
        background: white;
    }

    #publisher-list::-webkit-scrollbar-track:active {
        background: white;
    }

    #publisher-list::-webkit-scrollbar-corner {
        background: transparent;
    }
</style>