<template>
    <tr>
        <td>
            <div class="product-item">
                <router-link class="product-thumb" :to="`/product/${product._id}`">
                    <img class="box-shadow" v-lazy="product.firstCoverPhotoUrl" alt="Product">
                </router-link>
                <div class="product-info">
                    <h4 class="product-title">
                        <router-link :to="`/product/${product._id}`">{{this.product.title}}</router-link>
                    </h4>
                    <span v-if="product.hasDisplayedPrices(isUserLogged)">
                        <em>{{$t("header.headerShopCart.headerShopCartLine.price")}}:</em>
                        {{ this.properPrice(product.totalWT, chosenCurrency, currencies) }}
                    </span>
                    <span class="d-flex align-items-center">
                        <em class="d-inline mr-2">{{$t("header.headerShopCart.headerShopCartLine.quantity")}}:</em>
                        <a @click.prevent="decrementQuantity" href="#" class="text-less-bold change-quantity-button"
                           :class="{ disabled: product.quantity === 1 || loading }" v-html="'-'"/>
                        <span class="d-inline ml-2 mr-2 text-monospace" v-html="product.quantity"/>
                        <a @click.prevent="incrementQuantity" href="#" class="text-less-bold change-quantity-button"
                           :class="{ disabled: loading }" v-html="'+'"/>
                    </span>
                </div>
            </div>
        </td>
        <td class="text-center">
            <a @click.prevent="confirmDeleteProduct" class="remove-from-cart" :class="{'c-default': loading}" href="#">
                <i v-if="!loading" class="material-icons icon_close"></i>
                <i v-else class="fas fa-circle-notch fa-spin text-warning"></i>
            </a>
        </td>
    </tr>
</template>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import { properPrice } from "../../../../helpers/price";

export default {
    name: "HeaderShopCartLine",
    props: {
        product: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        ...mapGetters("currencies", {
            currencies: "currencies",
        }),
        ...mapGetters("preferences", {
            chosenCurrency: "currency",
        }),
        ...mapGetters("config", {
            shopPrimaryColor: "shopPrimaryColor",
        }),
        ...mapGetters("user", {
            isUserLogged: "isLogged",
        }),
    },
    methods: {
        properPrice,
        confirmDeleteProduct() {
            Swal.fire({
                title: this.$t("header.headerShopCart.headerShopCartLine.youSure"),
                text: this.$t("header.headerShopCart.headerShopCartLine.youWillDelete"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: this.shopPrimaryColor,
                cancelButtonColor: "#D33",
                confirmButtonText: this.$t("header.headerShopCart.headerShopCartLine.yesDelete"),
                cancelButtonText: this.$t("header.headerShopCart.headerShopCartLine.cancel"),
            }).then(result => {
                if (result.value) {
                    this.deleteProduct();
                }
            });
        },
        async deleteProduct() {
            try {
                this.loading = true;
                await this.$store.dispatch("user/deleteToCart", this.product._id);
            } catch (err) {
                this.$error.displayError(err);
            } finally {
                this.loading = false;
            }
        },
        async updateCartProductQuantity(quantity) {
            try {
                this.loading = true;
                await this.$store.dispatch("user/updateToCart", { _id: this.product._id, quantity });
            } catch (err) {
                this.$error.displayError(err);
            } finally {
                this.loading = false;
            }
        },
        async decrementQuantity() {
            const newQuantity = this.product.quantity - 1;
            if (newQuantity) {
                await this.updateCartProductQuantity(newQuantity);
            }
        },
        async incrementQuantity() {
            const newQuantity = this.product.quantity + 1;
            await this.updateCartProductQuantity(newQuantity);
        },
    },
};
</script>

<style lang="scss" scoped>
    .change-quantity-button {
        font-size: 1.5rem;
        &.disabled {
            color: grey;
            cursor: default;
        }
    }
</style>