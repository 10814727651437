<template>
    <div id="administration">
        <PageTitle :title="administrationTitle">
            <template v-slot:left>
                <i class="fa text-primary fa-3x" :class="administrationIconClasses"/>
            </template>
        </PageTitle>
        <div class="container-fluid padding-bottom-3x mb-2">
            <transition name="fade" mode="out-in">
                <div key="loading" v-if="!isUserLogged">
                    <APILoading :font-size="30" :text="$t('Administration.loading')"/>
                </div>
                <div key="administration" v-else class="row">
                        <div class="col-lg-2">
                            <nav class="list-group">
                                <router-link to="/administration/users" class="list-group-item d-flex align-items-center text-uppercase"
                                             :class="{ active: $route.name === 'administrationUsers' }">
                                    <i class="fas fa-users mr-2"/>
                                    {{ $t("Administration.users") }}
                                </router-link>
                                <router-link to="/administration/products" class="list-group-item d-flex align-items-center"
                                             :class="{ active: $route.name === 'administrationProducts' }">
                                    <i class="fa fa-swatchbook mr-2"/>
                                    {{ $t("Administration.products") }}
                                </router-link>
                                <router-link to="/administration/orders" class="list-group-item d-flex align-items-center text-uppercase"
                                             :class="{ active: $route.name === 'administrationOrders' }">
                                    <i class="fa fa-boxes mr-2"/>
                                    {{ $t("Administration.orders") }}
                                </router-link>
                            </nav>
                        </div>
                        <div class="col-lg-10">
                            <router-view class="fadeIn"/>
                        </div>
                    </div>
            </transition>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import PageTitle from "@/components/shared/Misc/PageTitle";
import APILoading from "@/components/shared/Misc/APILoading";

export default {
    name: "Administration",
    components: {APILoading, PageTitle},
    computed: {
        ...mapGetters("user", { isUserLogged: "isLogged" }),
        administrationTitle() {
            return this.$t(`Administration.sectionsTitle.${this.$route.name}`);
        },
        administrationIconClasses() {
            const administrationIcons = {
                administrationUsers: "fa-users",
                administrationProducts: "fa-swatchbook",
                administrationOrders: "fa-boxes",
            };
            return administrationIcons[this.$route.name];
        },
    },
    async created() {
        await this.checkUserAuthentication(true);
    },
    methods: {
        ...mapActions("user", {
            checkUserAuthentication: "checkUserAuthentication",
        }),
    },
    watch: {
        user: {
            handler() {
                this.checkUserAuthentication(true);
            },
            deep: true,
        },
    },
};
</script>

<style scoped>

</style>