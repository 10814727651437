<template>
    <li v-if="frontConfig.isHelpSectionEnabled" :class="{ active: inHelpSection }">
        <router-link id="main-nav-help" to='/help'><span>{{$t("header.mainNav.help")}}</span></router-link>
        <ul class="sub-menu w-250">
            <li :class="{ active: inFAQSection }" class="has-children">
                <router-link to="/help/">
                    <span>{{ $t("header.mainNav.FAQ") }}</span>
                </router-link>
                <ul class="sub-menu">
                    <li :class="{ active: $route.name === 'helpAll' }">
                        <router-link to="/help/">
                            <span>{{ $t("header.mainNav.helpAll") }}</span>
                        </router-link>
                    </li>
                    <li v-if="frontConfig.isShopSectionEnabled" :class="{ active: $route.name === 'helpShop' }">
                        <router-link to="/help/shop">
                            <span>{{ $t("header.mainNav.helpShop") }}</span>
                        </router-link>
                    </li>
                    <li :class="{ active: $route.name === 'helpProfile' }">
                        <router-link to="/help/profile">
                            <span>{{ $t("header.mainNav.helpProfile") }}</span>
                        </router-link>
                    </li>
                    <li :class="{ active: $route.name === 'helpOrders' }">
                        <router-link to="/help/orders">
                            <span>{{ $t("header.mainNav.helpOrders") }}</span>
                        </router-link>
                    </li>
                    <li :class="{ active: $route.name === 'helpOther' }">
                        <router-link to="/help/other">
                            <span>{{ $t("header.mainNav.helpOther") }}</span>
                        </router-link>
                    </li>
                </ul>
            </li>
            <li>
                <a @click.prevent="startMainTour" href="#">
                    <span>{{ $t("header.mainNav.globalTour") }}</span>
                </a>
            </li>
            <li v-if="frontConfig.isShopSectionEnabled">
                <a @click.prevent="startShopTour" href="#">
                    <span>{{ $t("header.mainNav.shopTour") }}</span>
                </a>
            </li>
            <li :class="{ active: $route.name === 'about' }">
                <router-link to="/about/">
                    <span>{{ $t("header.mainNav.about") }}</span>
                </router-link>
            </li>
            <li :class="{ active: $route.name === 'contact' }">
                <router-link to="/contact/">
                    <span>{{ $t("header.mainNav.contact") }}</span>
                </router-link>
            </li>
            <li :class="{ active: $route.name === 'termsAndConditions' }">
                <router-link to="/terms-and-conditions/">
                    <span>{{ $t("header.mainNav.termsAndConditions") }}</span>
                </router-link>
            </li>
        </ul>
    </li>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { inHelpSection, inFAQSection } from "@/helpers/section";

export default {
    name: "HelpTab",
    computed: {
        ...mapGetters("config", {
            frontConfig: "front",
        }),
        inHelpSection() {
            return inHelpSection(this.$route.name);
        },
        inFAQSection() {
            return inFAQSection(this.$route.name);
        },
    },
    methods: {
        ...mapActions("tours", {
            startMainTour: "startMainTour",
            startShopTour: "startShopTour",
        }),
    },
};
</script>

<style scoped>

</style>