import _ from "lodash";
import JWT from "jsonwebtoken";

export function generateValidQueryStrings(params) {
    const validQueryStrings = [];
    for (const property in params) {
        if (params[property] !== undefined && params[property] !== null &&
            (typeof params[property] !== "string" || params[property].trim() !== "")) {
            validQueryStrings.push({key: property, value: params[property]});
        }
    }
    return validQueryStrings;
}

export function generateQueryStringURL(baseURL, params) {
    if (!_.isEmpty(params)) {
        const validQueryStrings = generateValidQueryStrings(params);
        for (let i = 0; i < validQueryStrings.length; i++) {
            if (!i) {
                baseURL += "?";
            }
            baseURL += `${validQueryStrings[i].key}=${validQueryStrings[i].value}`;
            if (validQueryStrings.length - 1 !== i) {
                baseURL += "&";
            }
        }
    }
    return baseURL;
}

export function decodeJWTToken(forceToken = false) {
    let decoded = { userId: 0 };
    const token = localStorage.getItem("token");
    const clientId = localStorage.getItem("clientId");
    if (token) {
        decoded = JWT.decode(token);
    }
    if (!forceToken && clientId && decoded.isAdmin) {
        decoded.userId = clientId;
    }
    return decoded;
}