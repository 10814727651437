<template>
    <div id="about-airvey-editions" class="container">
        <section class="row align-items-center padding-top-2x padding-bottom-2x text-justify">
            <p>
                Attention : Dorénavant, nous n'accepterons que les propositions de polars (adultes ou jeunesse).
            </p>
            <p>
                Notre préférence ira vers les auteurs souhaitant éditer leur premier roman. Si vous avez d'autres éditeurs pour ce genre, nous souhaiterions connaître la raison de votre souhait de mutation vers un autre éditeur.
            </p>
            <p>
                Notre catalogue est complet pour tout autre genre.
            </p>
            <p>
                Pour nous contacter concernant votre projet de publication, veuillez tout d'abord nous remettre un synopsis et un résumé, ainsi qu'une courte présentation de vous-même (avec votre lieu de résidence).
            </p>
            <p>
                Si nous pensons qu'il y a adéquation avec notre ligne éditoriale, nous vous demanderons de nous communiquer votre tapuscrit pour étude (réponse sous 8 semaines) et éventuelle publication (sous 6 mois maximum).
            </p>
            <p>
                Notre délai de réponse à votre premier message ne dépassera pas la quinzaine de jours.
            </p>
            <p>
                <span class="text-less-bold">Important</span> : Airvey éditions est un éditeur à compte d'éditeur. Nos auteurs ne sont pas censés acheter leurs livres pour être publiés, et aucune participation aux frais n'est demandée. Notre diffusion est réalisée en grande partie en participant à des salons du livre où il est demandé aux auteurs un minimum contractuel de présence les deux premières années.
            </p>
            <p>
                Contact : <a href="mailto:airvey.editions@orange.fr" type="mail">airvey.editions@orange.fr</a><br><br>
                Merci
            </p>
        </section>
    </div>
</template>

<script>
export default {
    name: "AboutAirveyEditions",
};
</script>

<style scoped>

</style>